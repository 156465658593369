<div class="dialog-container">
  <div class="flex title-container">
    <h1 class="title">Forward Voicemail</h1>
    <button
      matTooltip="Close"
      (click)="closeDialog()"
      mat-menu-item>
      <mat-icon class="more-color">close</mat-icon>
    </button>
  </div>
  <div class="phone-contact-list-container">
    <app-phone-contact-list
      [fixedContactListFilterOptions]="{ contactType: ContactType.Company }"
      (forwardClicked)="handleClickOnForward($event)"
      (searchChanged)="setSelectedExtension($event)"
      (contactNumberSelected)="setSelectedExtension($event.contact.ext)">
    </app-phone-contact-list>
  </div>
  <div class="flex flex-row-reverse">
    <button
      [disabled]="isProcessing || !selectedExt"
      color="primary"
      mat-raised-button
      matTooltip="Forward Voicemail"
      type="submit"
      (click)="forward()">
      <span *ngIf="!isProcessing; else spinner">Forward</span>
      <ng-template #spinner>
        <mat-spinner diameter="24"></mat-spinner>
      </ng-template>
    </button>
  </div>
</div>
