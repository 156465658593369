<section #container>
  <div
    class="container"
    [formGroup]="formGroup">
    <div class="title m-t-16">Blocked Numbers</div>
    <mat-divider class="m-t-6"></mat-divider>
    <div class="title m-t-16">Add a Number</div>
    <div class="flex-col">
      <div class="row items-holder m-t-16 m-b-16 number-input">
        <mat-form-field
          appearance="outline"
          hideRequiredMarker
          class="density-minus-3">
          <mat-label>Enter a Number</mat-label>
          <input
            autocomplete="off"
            formControlName="blockedNumber"
            matInput />
        </mat-form-field>
        <button
          data-cy="blocked-number-button"
          mat-icon-button
          color="primary"
          (click)="addBlockedNumbers()">
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
      <ng-container *ngFor="let number of blockedNumbers">
        <div class="number-item">
          <span
            data-cy="blocked-list-number"
            class="number">
            {{ number | phoneNumber }}
          </span>
          <span
            data-cy="blocked-list-button"
            (click)="removeBlockedNumber(number)"
            class="remove">
            <mat-icon> delete_outline</mat-icon>
          </span>
        </div>
      </ng-container>
      <mat-checkbox
        class="m-t-16 m-b-16"
        formControlName="blockAnonymous"
        (ngModelChange)="onCheckChangeBlockAnonymous()"
        color="primary">
        Block anonymous or unknown numbers
      </mat-checkbox>
    </div>
    <div class="title m-t-16">Allowed Numbers</div>
    <mat-divider class="m-t-16"></mat-divider>
    <div class="flex-col">
      <div class="row items-holder m-t-16 m-b-16 number-input">
        <mat-form-field
          appearance="outline"
          hideRequiredMarker
          class="density-minus-3">
          <mat-label>Enter a Number</mat-label>
          <input
            autocomplete="off"
            formControlName="allowedNumber"
            matInput />
        </mat-form-field>
        <button
          data-cy="allowed-number-button"
          mat-icon-button
          color="primary"
          (click)="addAllowedNumbers()">
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
      <ng-container *ngFor="let number of allowedNumbers">
        <div class="number-item">
          <span
            data-cy="allowed-list-number"
            class="number">
            {{ number | phoneNumber }}
          </span>
          <span
            data-cy="allowed-list-button"
            (click)="removeAllowedNumber(number)"
            class="remove">
            <mat-icon> delete_outline</mat-icon>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="editingDisabled"
    class="grey-mask"
    #greyMask></div>
</section>
