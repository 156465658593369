export enum UserRole {
  user = 'connect-uc-user',
  officeManager = 'connect-uc-user:manager',
}

export enum ProvisionRole {
  CallCenterAgent = 'pbx_call_center_agent',
  CallCenterSupervisor = 'pbx_call_center_supervisor',
  User = 'pbx_basic_user',
}

export interface Role {
  id: number;
  name: string;
  display_name: string;
  description: string;
  created_at: string;
  updated_at: string;
}
