import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/core/services/api.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import {
  Attachment,
  AttachmentType,
  PresignedMediaReponse,
  RejectedFileAttachment,
  UploadedAttachment,
} from '@app/sms/models/sms.models';
import { environment } from '@environment/environment';
import { BehaviorSubject, combineLatest, firstValueFrom, forkJoin, from, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

/**
 * Non-injectable service used for managing the a drafted message for a given channel. Instances are instantiated
 * and managed by MessageService.
 */
export class DraftMessageService extends ApiService {
  public static smsFileSizeLimit = Math.pow(10, 6) * 5; // 5MB... file size limit in bytes (byte to mega conversion is 1e+6)
  public static chatFileSizeLimit = Math.pow(10, 6) * 50; // 50MB... file size limit in bytes (byte to mega conversion is 1e+6)

  private static mimeTypes = {
    audio: [
      'audio/amr', // .amr
      'audio/3gpp', // .3ga
      'audio/mp4', // .m4a, .m4p, .m4b, .m4r
      'audio/mpeg', // .mp3
      'audio/wav', // .wav
    ],
    video: [
      'video/3gpp', // .3gp
      'video/h263', // .h263
      'video/h264', // .h264
      'video/mp4', // .mp4, m4v
    ],
    image: [
      'image/bmp', // .bmp, .dib
      'image/gif', // .gif
      'image/jpeg', // .jpg
      'image/png', // .png
    ],
    document: [
      'application/msword', // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.ms-excel', // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-powerpoint', // .ppt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
      'application/vnd.oasis.opendocument.text', // .odt
      'application/vnd.oasis.opendocument.spreadsheet', // .ods
      'application/vnd.oasis.opendocument.presentation', // .odp
      'application/pdf', // .pdf
      'application/rtf', // .rtf
      'application/xml', // .xml recommended as of RFC7303
      'text/xml', // .xml still may be used sometimes
      'text/csv', // .csv
      'text/plain', // .txt
    ],
  };

  private validUploadMimeTypes: Set<string> = new Set();

  protected override baseUrl: string;
  private baseSMSUrl = environment.messageHubGateway + '/sms';
  private baseChatUrl = environment.gateway + '/users/{me}/messages';

  public readonly contentSubject = new BehaviorSubject<string | null>(null);
  public readonly content$ = this.contentSubject.asObservable();
  public readonly attachmentsSubject = new BehaviorSubject<Attachment[]>([]);
  public readonly attachments$ = this.attachmentsSubject.asObservable();
  public readonly hasDraftedContentSubject = new BehaviorSubject<boolean>(false);
  public readonly hasDraftedContent$ = this.hasDraftedContentSubject.asObservable();

  private _content: string = '';
  public get content(): string {
    return this._content;
  }

  public set content(value: string) {
    this._content = value;
    this.persistDraftText(value, this.draftId);
    this.contentSubject.next(value);
  }

  public get fileSizeLimit(): number {
    return this.draftType === 'SMS' ? DraftMessageService.smsFileSizeLimit : DraftMessageService.chatFileSizeLimit;
  }

  // Limit file count since there are limits to the size of the metadata field we can send on the Chat side. For SMS, 6 seemed like
  // a reasonable number, but we can adjust as needed.
  public get fileCountLimit(): number {
    return this.draftType === 'SMS' ? 6 : 3;
  }

  constructor(
    http: HttpClient,
    private draftId: string,
    private draftType: 'SMS' | 'Chat',
    private storage: LocalStorageService,
    validAttachmentTypes: AttachmentType[]
  ) {
    super(http);
    this.content = this.storage.get(draftId) || '';
    this.baseUrl = draftType === 'SMS' ? this.baseSMSUrl : this.baseChatUrl;

    for (const attachmentType of validAttachmentTypes) {
      DraftMessageService.mimeTypes[attachmentType].forEach((type) => this.validUploadMimeTypes.add(type));
    }

    combineLatest([this.content$, this.attachments$]).subscribe(([text, attachments]) => {
      this.hasDraftedContentSubject.next((text && text.length > 0) || attachments.length > 0);
    });
  }

  // =========== Text ==========

  private persistDraftText(text: string | null, storageKey: string) {
    if (text) {
      this.storage.set(storageKey, text);
    } else {
      this.storage.delete(storageKey);
    }
  }

  // ========== Attachments ==========

  /**
   *
   * @param files An array of files to add as attachments
   * @returns A object containing all files and their status as either added or rejected
   */
  addAttachments(files: File[]): { added: File[]; rejected: RejectedFileAttachment[] } {
    const added: File[] = [];
    const rejected: RejectedFileAttachment[] = [];

    // If the files added here + all current attachments exceeds the count, reject all.
    if (files.length + this.attachmentsSubject.value.length <= this.fileCountLimit) {
      for (const file of files) {
        const fileSizeValid = file.size < this.fileSizeLimit;
        const mimeTypeValid = this.validUploadMimeTypes.has(file.type.toLowerCase());
        if (fileSizeValid && mimeTypeValid) {
          added.push(file);
        } else {
          rejected.push({ file, reason: fileSizeValid ? 'Invalid Type' : 'Size Limit' });
        }
      }
    } else {
      files.forEach((file) => rejected.push({ file, reason: 'Count Limit' }));
    }

    const newAttachments: Attachment[] = added.map((file) => {
      return { file, localId: uuidv4() };
    });
    const attachments = [...this.attachmentsSubject.value, ...newAttachments];
    this.attachmentsSubject.next(attachments);
    return { added, rejected };
  }

  removeAttachment(index: number) {
    const attachments = [...this.attachmentsSubject.value];
    attachments.splice(index, 1);
    this.attachmentsSubject.next(attachments);
  }

  /**
   * Upload all attachments currently a part of the draft.
   * @id Identifier for the corresponding channel / conversation.
   * @returns An observable containing an array of attachments that were uploaded successfully.
   */
  uploadAllAttachments(id: string): Observable<UploadedAttachment[]> {
    // Create an observable for each attachment that needs to be uploaded
    const uploadObservables = this.attachmentsSubject.value
      .filter((attachment) => !attachment.remoteUrl)
      .map((attachment) => from(this.uploadAttachment(attachment, id)));

    if (uploadObservables.length === 0) {
      return of([]);
    }

    // Once finished, the observable will complete.
    return forkJoin(uploadObservables);
  }

  private async uploadAttachment(attachment: Attachment, channelOrConversationId: string): Promise<UploadedAttachment> {
    const path = this.draftType === 'SMS' ? 'media' : 'attach';
    const idPropName = this.draftType === 'SMS' ? 'conversationId' : 'channelId';

    // Get a presigned url from the API to which we can upload attachments
    const presignResponse = await firstValueFrom(
      this.post<PresignedMediaReponse>(path, {
        key: attachment.file.name,
        [idPropName]: channelOrConversationId,
        fileName: attachment.file.name,
      })
    );

    // Upload the attachment
    await firstValueFrom(
      this.httpClient.put(presignResponse.presignedUrl, attachment.file, {
        headers: {
          'Content-Type': attachment.file.type,
          ...presignResponse.headers,
        },
      })
    );

    // At this point the attachments have finished uploading. Update the remote url for
    // the given attachment so we know the upload succeeded. Use the localId to perform the lookup
    const attachments = [...this.attachmentsSubject.value];
    const index = attachments.findIndex((a) => a.localId === attachment.localId);
    if (index !== -1) {
      attachments[index].remoteUrl = presignResponse.publicUrl;
    }
    this.attachmentsSubject.next(attachments);

    return {
      url: presignResponse.publicUrl,
      type: attachment.file.type,
      length: attachment.file.size,
      fileName: attachment.file.name,
    };
  }

  reset() {
    this.content = '';
    this.contentSubject.next(null);
    this.attachmentsSubject.next([]);
  }

  // ========== Helpers ==========

  public static contentTypeForMimeType(mimeType?: string): 'audio' | 'video' | 'image' | 'document' | undefined {
    if (!mimeType) {
      return undefined;
    }

    for (const [type, types] of Object.entries(DraftMessageService.mimeTypes)) {
      if (types.includes(mimeType)) {
        return type as 'audio' | 'video' | 'image' | 'document';
      }
    }

    return undefined;
  }
}
