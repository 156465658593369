/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import {
  Channel,
  ChannelMember,
  ChannelMembership,
  ChannelMessage,
  DeletedChannelMember,
} from '@app/chat/models/channel.models';
import { ChannelService } from '@app/chat/services/channel.service';
import { ApiService } from '@app/core/services/api.service';
import { ChannelDetails, MessagingEndpoint } from '@app/meetings/models/chime.models';
import {
  ChannelMembership as AWSChannelMembership,
  ChannelMembershipType,
  ChannelMessage as AWSChannelMessage,
  ChannelMessageSummary,
  ChannelMessageType,
  ChannelMode,
  ChannelPrivacy,
  ChimeSDKMessagingClient,
  SendChannelMessageCommand,
  SendChannelMessageCommandInput,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  ConsoleLogger,
  DefaultMessagingSession,
  LogLevel,
  Message,
  MessagingSessionConfiguration,
  MessagingSessionObserver,
  PrefetchOn,
} from 'amazon-chime-sdk-js';
import { distinctUntilChanged, firstValueFrom, map, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Abstraction around the AWS Chime Socket used for messaging (aka 'chat') updates. This
 * service handles setting itself up once the user has logged in as well as manging the actual socket
 * connection.
 */
export class ChimeSocketService extends ApiService implements MessagingSessionObserver {
  private session?: DefaultMessagingSession;
  private chime?: ChimeSDKMessagingClient;
  private logger = new ConsoleLogger('uc-web-v2', LogLevel.INFO);

  /** All socket events are published to this subject */
  public messageSubject$ = new Subject<Message>();

  /** Only channel message events with a payload are published to this subject */
  public channelMessageSubject$ = new Subject<{ channelArn: string; message: ChannelMessage }>();
  public channelDetailsSubject$ = new Subject<{ channel: Channel; messages: ChannelMessage[] }>();
  public createChannelSubject$ = new Subject<ChannelMembership>();

  constructor(private authService: AuthService, http: HttpClient) {
    super(http);
    this.authService.jwtClaims
      .pipe(
        distinctUntilChanged((previous, current) => {
          // Cheap way of comparing our claims objects
          return previous && current ? JSON.stringify(previous) === JSON.stringify(current) : false;
        })
      )
      .subscribe(async (value) => {
        if (value?.sub) {
          await this.connect(value.chimeArn);
        } else {
          this.close();
        }
      });
  }

  // Connection Management
  private async connect(chimeArn: string) {
    // Close any existing connection before starting a new one
    this.close();
    this.chime = new ChimeSDKMessagingClient({
      region: 'us-east-1',
      credentials: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const data = await this.getMessagingSessionEndpoint();
            console.log(`chime: got credentials for ${chimeArn} `);
            resolve({
              accessKeyId: data.credentials.accessKeyId,
              secretAccessKey: data.credentials.secretAccessKey,
              sessionToken: data.credentials.sessionToken,
              expiration: new Date(data.credentials.expiration),
            });
          } catch (error) {
            console.error(`Messaging session not started: ${error}`);
            reject(`Messaging session not started: ${error}`);
          }
        });
      },
    });

    const sessionConfig = new MessagingSessionConfiguration(chimeArn, null, undefined, this.chime);
    sessionConfig.prefetchOn = PrefetchOn.Connect;
    this.session = new DefaultMessagingSession(sessionConfig, this.logger);
    this.session.addObserver(this);
    await this.session.start();
  }

  private close() {
    try {
      this.session?.removeObserver(this);
      this.session?.stop();
    } catch {
      console.error('Failed to stop Messaging Session.');
    }
  }

  private getMessagingSessionEndpoint(): Promise<MessagingEndpoint> {
    return firstValueFrom(this.get('users/{me}/messaging/endpoint').pipe(map((value) => value as MessagingEndpoint)));
  }

  // MessagingSessionObserver
  messagingSessionDidStart() {
    console.log('Messaging Connection started!');
  }

  messagingSessionDidStartConnecting(reconnecting: boolean) {
    console.log(`Messaging Connection connecting = ${reconnecting}`);
  }

  async messagingSessionDidStop(event: CloseEvent) {
    console.log('Session stopped due to error:', event.reason);
    if (this.isTokenExpiredError(event)) {
      console.log('Token expired, attempting to reconnect...');
      await this.reconnectWithNewToken();
    } else {
      console.log('Messaging Connection received DidStop event');
    }
  }

  private isTokenExpiredError(error: CloseEvent): boolean {
    const reconnectableErrorCodes = [1001, 1006, 1011, 1012, 1013, 1014];
    const doNotReconnectCodes = [4002, 4003, 4401];

    // Check if the error code is one where reconnection is appropriate and not one of the codes where it's not.
    return (
      reconnectableErrorCodes.includes(error.code) ||
      (!doNotReconnectCodes.includes(error.code) && error.code >= 4000 && error.code < 5000)
    );
  }

  private async reconnectWithNewToken() {
    try {
      const Jwt = this.authService.jwtClaims.getValue();
      if (Jwt) {
        await this.connect(Jwt.chimeArn);
        console.log('Reconnected with new token.');
      }
    } catch (error) {
      console.error('Failed to reconnect:', error);
    }
  }

  messagingSessionDidReceiveMessage(message: Message) {
    console.log(`Messaging Connection received message: ${message.type}`);
    switch (message.type) {
      case 'CHANNEL_DETAILS': {
        const details = JSON.parse(message.payload) as ChannelDetails;
        const channelAndMessage = this.convertAWSChannelDetailsToSkyswitchChannelAndMessage(details);
        if (channelAndMessage) {
          this.channelDetailsSubject$.next(channelAndMessage);
        }
        break;
      }
      case 'CREATE_CHANNEL_MEMBERSHIP': {
        const payload = JSON.parse(message.payload);
        const membership = this.convertAWSCreateChannelMembershipToSkyswitch(payload);
        if (this.authService.jwtClaims.getValue()?.chimeArn !== membership.invitedByArn) {
          this.createChannelSubject$.next(membership);
        }

        break;
      }
      default: {
        if (message.payload) {
          // AWS provides the payload keys as capitalized camel case. Convert to lowercase camel case
          // before
          const awsMessage = JSON.parse(message.payload) as AWSChannelMessage;
          const channelMessage = this.convertAWSChannelMessageToSkyswitchChannelMessage(awsMessage);
          if (channelMessage) {
            console.log(`Messaging Connection received message content: ${channelMessage.content.slice(0, 4)}`);
            this.channelMessageSubject$.next({ channelArn: awsMessage.ChannelArn!, message: channelMessage });
          }
        }
      }
    }
    this.messageSubject$.next(message);
  }

  /**
   * Converts messages received from the ChimeSDK to a format we can consume in the app.
   *
   * @returns Message object formatted for consumption in the application or undefined if the input object cannot be converted.
   * @param details
   */
  public convertAWSCreateChannelMembershipToSkyswitch(details: AWSChannelMembership): ChannelMembership {
    // Force-unwrap the props here since Chime always has them. The TS definition for this type literally has every
    // prop as optional 🙄
    return {
      channelArn: details.ChannelArn!,
      invitedByArn: details.InvitedBy!.Arn!,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createdTimestamp: details.CreatedTimestamp as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      lastUpdatedTimestamp: details.LastUpdatedTimestamp as any,
      member: {
        id: details.Member!.Arn!.split('/').pop()!,
        arn: details.Member!.Arn!,
        name: details.Member!.Name!,
      },
    };
  }

  /**
   * Converts messages received from the ChimeSDK to a format we can consume in the app.
   *
   * @returns Message object formatted for consumption in the application or undefined if the input object cannot be converted.
   * @param details
   */
  public convertAWSChannelDetailsToSkyswitchChannelAndMessage(
    details: ChannelDetails
  ): { channel: Channel; messages: ChannelMessage[] } | undefined {
    const type: 'Chat Room' | 'Direct Message' =
      details.Channel.Metadata && details.Channel.Metadata.length > 0
        ? JSON.parse(details.Channel.Metadata)['type']
        : 'Direct Message';

    // Force-unwrap the props here since Chime always has them. The TS definition for this type literally has every
    // prop as optional 🙄
    const response = {
      channel: {
        id: details.Channel.ChannelArn!.split('/').pop()!,
        name: details.Channel.Name!,
        channelArn: details.Channel.ChannelArn!,
        mode: details.Channel.Mode as ChannelMode,
        privacy: details.Channel.Privacy as ChannelPrivacy,
        favorite: this.isChannelFavorite(details),
        mute: this.isChannelMuted(details),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        lastMessageTimestamp: details.Channel.LastMessageTimestamp as any,
        type,
        members: details.ChannelMemberships.map((chimeMember) => {
          return {
            id: chimeMember.Member!.Arn!.split('/').pop()!,
            arn: chimeMember.Member!.Arn,
            name: chimeMember.Member!.Name,
          } as ChannelMember;
        }),
        // TODO: Remove conditional
        appInstanceUserMembershipSummary: {
          type: ChannelMembershipType.DEFAULT,
          readMarkerTimestamp: details.ReadMarkerTimestamp,
        },
      },
      messages: details.ChannelMessages.map(
        (awsMessageSummary) => this.convertAWSChannelMessageToSkyswitchChannelMessage(awsMessageSummary)!
      ),
    };

    if (response.messages.some((message) => message.sender.arn === DeletedChannelMember.arn)) {
      // add user to senders if message indicates deleted user
      // at least it will show one deleted user for chats with multiple deleted users before deleted_users info was provided
      response.channel.members.push(DeletedChannelMember);
    }

    return response;
  }

  public isChannelFavorite(channelDetail: ChannelDetails): boolean {
    const metadata = channelDetail.Channel.Metadata;
    if (metadata) {
      try {
        const metadataObj = JSON.parse(metadata);
        return !!metadataObj.favorite_users?.includes(this.authService.jwtClaims.getValue()?.sub);
      } catch {
        return false;
      }
    }
    return false;
  }

  public isChannelMuted(channelDetail: ChannelDetails): boolean {
    const metadata = channelDetail.Channel.Metadata;
    if (metadata) {
      try {
        const metadataObj = JSON.parse(metadata);
        return !!metadataObj.mute_users?.includes(this.authService.jwtClaims.getValue()?.sub);
      } catch {
        return false;
      }
    }
    return false;
  }

  /**
   * Converts messages received from the ChimeSDK to a format we can consume in the app.
   *
   * @param awsMessage Message object received from Chime server
   * @returns Message object formatted for consumption in the application or undefined if the input object cannot be converted.
   */
  public convertAWSChannelMessageToSkyswitchChannelMessage(
    awsMessage: AWSChannelMessage | ChannelMessageSummary
  ): ChannelMessage | undefined {
    if (awsMessage.MessageId && awsMessage.Content && awsMessage.CreatedTimestamp && awsMessage.Type) {
      // See conversation here: https://bcm-one.slack.com/archives/C03QNKG88K1/p1694721438324659
      let content = awsMessage.Content;
      if (content === ChannelService.EmptyContentKeyword) {
        content = '';
      }
      return {
        content,
        createdTimestamp: awsMessage.CreatedTimestamp.toString(),
        lastUpdatedTimestamp: awsMessage.LastEditedTimestamp?.toString(),
        messageId: awsMessage.MessageId,
        metadata: awsMessage.Metadata,
        redacted: awsMessage.Redacted || false,
        // user was deleted if sender object is empty. set to DeletedChannelMember in this case
        sender:
          awsMessage.Sender?.Arn && awsMessage.Sender?.Name
            ? {
                id: awsMessage.Sender.Arn.split('/').pop() || awsMessage.Sender.Arn,
                arn: awsMessage.Sender.Arn,
                name: awsMessage.Sender.Name,
              }
            : DeletedChannelMember,
        type: awsMessage.Type as ChannelMessageType,
      };
    }

    return undefined;
  }

  public async sendChannelMessage(message: SendChannelMessageCommandInput) {
    const command = new SendChannelMessageCommand(message);
    try {
      await this.chime?.send(command);
    } catch (error) {
      console.log('send channel message error:', error);
    }
  }
}
