import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterTelHandlerComponent } from '@app/core/components/banners/register-tel-handler/register-tel-handler.component';
import { ProtocolHandlerService } from '@app/core/services/protocol-handler.service';
import { PwaService } from '@app/core/services/pwa.service';
import { RegisterTelHandlerDialogComponent } from '@app/shared/components/register-tel-handler-dialog/register-tel-handler-dialog.component';
import { environment } from '@environment/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';

@UntilDestroy()
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  pwaInstalled = true;
  readonly environment = environment;
  protected readonly AppFeature = AppFeature;

  constructor(
    private pwaService: PwaService,
    public appConfigService: AppConfigService,
    private dialog: MatDialog,
    public protocolHandlerService: ProtocolHandlerService
  ) {}

  ngOnInit() {
    this.checkPwaInstalled();
  }

  private checkPwaInstalled() {
    this.pwaService.pwaInstalled$.pipe(untilDestroyed(this)).subscribe((pwaInstalled) => {
      this.pwaInstalled = pwaInstalled;
    });
  }

  async installConnectUCPwa() {
    if (await this.pwaService.promptInstall()) {
      this.pwaInstalled = true;
      this.dialog.open(RegisterTelHandlerDialogComponent, {
        data: {
          protocolUrl: RegisterTelHandlerComponent.protocolUrl,
        },
        disableClose: true,
      });
    }
  }

  registerTelHandler() {
    try {
      this.protocolHandlerService.registerProtocolHandler('tel', RegisterTelHandlerComponent.protocolUrl);
    } catch (error) {
      console.error('registerProtocolHandler error', error);
    }
  }

  openInstallationUCLinkApp(url: string) {
    window.open(url);
  }
}
