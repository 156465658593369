import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { BehaviorSubject, catchError, finalize, Observable, of } from 'rxjs';

import { MeetingPayload } from '../models/video.models';

@Injectable({
  providedIn: 'root',
})
export class MeetingService extends ApiService {
  protected path = 'users/{me}/meetings';
  private readonly source: BehaviorSubject<MeetingPayload> = new BehaviorSubject<MeetingPayload>({} as MeetingPayload);
  public readonly data$ = this.source.asObservable();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private errorSubject = new BehaviorSubject<boolean>(false);
  public hasError$ = this.errorSubject.asObservable();
  private isLoaded = false;

  protected constructor(http: HttpClient) {
    super(http);
  }

  /**
   *  Should only be called once in the main component somewhere
   *
   * @returns {*}  {(void | Error)}
   */
  init(): void | Error {
    if (this.isLoaded) {
      return new Error('Should only ever be loaded once');
    }
    this.isLoaded = true;
  }

  /**
   * Trigger a http refresh of data,
   *
   */
  refreshData(): Observable<MeetingPayload> {
    this.loadingSubject.next(true);
    return this.getHttpData().pipe(
      catchError(() => {
        this.errorSubject.next(true);
        return of({} as MeetingPayload);
      }),
      finalize(() => this.loadingSubject.next(false))
    );
    // .subscribe((data) => this._setData(data));
  }

  /**
   * http request for voicemail
   */
  getHttpData(): Observable<MeetingPayload> {
    return this.get<MeetingPayload>(this.path);
  }

  /**
   * Returns a sync value of the collection, could be empty if early
   *
   * @returns {*}  {T[]}
   */
  getData(): MeetingPayload {
    return this.source.getValue();
  }

  /**
   *
   * Triggers Observers
   * @private
   */
  protected setData(data: MeetingPayload): void {
    this.source.next(data);
  }
}
