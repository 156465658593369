// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function convertKeysToHyphenated(object: { [key: string]: any }): { [key: string]: any } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hyphenatedObject: { [key: string]: any } = {};

  for (const [key, value] of Object.entries(object)) {
    const hyphenatedKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    hyphenatedObject[hyphenatedKey] = value;
  }

  return hyphenatedObject;
}
