import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseStateService } from '@app/core/services/base.state.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { WsService } from '@app/core/services/ws.service';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import { blobToUrl } from '@app/shared/utils/file.util';
import { catchError, finalize, firstValueFrom, map, Observable, of, tap } from 'rxjs';

import { Voicemail, VoicemailType, WSVoicemail } from '../models/voicemail.models';

@Injectable({
  providedIn: 'root',
})
export class VoicemailService extends BaseStateService<Voicemail> {
  protected path = 'voicemails';
  protected override readonly baseUrl = 'users/{me}';
  private voicemails: Voicemail[] = [];

  constructor(
    http: HttpClient,
    private wsService: WsService,
    private snackbar: SnackbarService,
    private notificationService: NotificationService
  ) {
    super(http);
    this.bindSocket();
    this.data$.subscribe((data) => {
      this.voicemails = data;
    });
    this.loading$.subscribe((loading) => {
      if (!loading) {
        this.getTrashVoicemailData();
      }
    });
  }

  /**
   * We need to talk with Yunior about this
   */
  override refreshData(): Observable<Voicemail[]> {
    this.loadingSubject.next(true);
    return <Observable<Voicemail[]>>this.getHttpData().pipe(
      catchError(() => {
        this.errorSubject.next(true);
        return of([]);
      }),
      map((data: Voicemail[]) => {
        return this.voicemailsSortedByDate(data);
      }),
      tap((data: Voicemail[]) => {
        this.setData(data);
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  private voicemailsSortedByDate(data: Voicemail[]) {
    return data.sort((a, b) => b.dateTime.localeCompare(a.dateTime));
  }

  setVoicemailReadState(id: string, state: boolean): Observable<{ success: boolean }> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.post<any>(`voicemail/${id}/${state ? 'save' : 'new'}`, null);
  }

  getVoicemailUrl(id: string): Observable<string> {
    return (
      this.get<string>(`voicemail/${id}/download`, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseType: 'blob' as any,
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .pipe(map((blob) => blobToUrl(blob as any)))
    );
  }

  deleteVoicemail(id: string) {
    return this.delete(`voicemail/${id}`);
  }

  private bindSocket() {
    this.wsService.socket.on('NewVoicemail', (data: WSVoicemail) => {
      this.handleNewVoicemailData(data);
    });

    this.wsService.socket.on('MarkAsReadVoicemail', (data: WSVoicemail) => {
      this.updateRead(data.model.id, true);
      this.snackbar.open('Voicemail Marked As Read');
    });

    this.wsService.socket.on('MarkAsUnReadVoicemail', (data: WSVoicemail) => {
      this.updateRead(data.model.id, false);
      this.snackbar.open('Voicemail Marked As UnRead');
    });

    this.wsService.socket.on('DeletedVoicemail', (data: WSVoicemail) => {
      this.updateVoicemail(data.model.id, { type: VoicemailType.RecentlyDeleted });
    });

    this.wsService.socket.on('FromTrashVoicemail', (data: WSVoicemail) => {
      console.log('FromTrashVoicemail', data.model);
    });

    this.wsService.socket.on('NewVoicemailTranscription', (data: WSVoicemail) => {
      this.handleNewVoicemailData(data);
    });
  }

  private handleNewVoicemailData(data: WSVoicemail) {
    const index = this.getData().findIndex((x) => x.id === data.model.id);
    if (index === -1) {
      this.addItemUnshift(data.model);
      this.snackbar.open('New Voicemail Received');
      this.notificationService.sendNotification(
        'New Voicemail Received',
        '',
        NotificationKeyBackend['Incoming Voicemail']
      );
    } else {
      const holder = [...this.getData()];
      holder[index] = data.model;
      this.setData(holder);
    }
  }

  forwardVoicemail(id: string, ext: string): Observable<{ success: boolean }> {
    return this.post<{ success: boolean }>(`voicemail/${id}/forward`, { ext: ext });
  }

  private getTrashVoicemailData() {
    this.get<Voicemail[]>(`voicemails/trash`).subscribe((data) => {
      this.setData(this.voicemailsSortedByDate([...this.voicemails, ...data]));
    });
  }

  getTrashVoicemail(): Voicemail[] {
    return this.voicemails.filter((voicemail) => voicemail.type === VoicemailType.RecentlyDeleted);
  }

  async retrieveVoicemail(id: string) {
    this.updateVoicemail(id, { type: VoicemailType.Voicemail, trash: false });
    const response = await firstValueFrom(this.post(`voicemail/${id}/new`, null));
    if (response) {
      this.snackbar.open('Voicemail Retrieved');
    }
  }

  updateVoicemail(id: string, changes: Partial<Voicemail>) {
    const index = this.voicemails.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.voicemails[index] = { ...this.voicemails[index], ...changes };
      this.setData(this.voicemails);
    }
  }
}
