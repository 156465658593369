import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpPostOptions } from '@app/core/models/api.models';
import { environment } from '@environment/environment';

@Injectable()
export abstract class ApiService {
  // default base Url which gets prepended to every request, can be a relative or absolute url.
  // in case being relative, we append the default base Url.
  protected readonly baseUrl: string = '';
  protected constructor(protected httpClient: HttpClient) {}

  post<T>(path: string, body: unknown, options?: HttpPostOptions) {
    switch (options?.method ?? 'POST') {
      case 'POST': {
        return this.httpClient.post<T>(this.getUrl(path), body, options);
      }
      case 'PUT': {
        return this.httpClient.put<T>(this.getUrl(path), body, options);
      }
      case 'PATCH': {
        return this.httpClient.patch<T>(this.getUrl(path), body, options);
      }
    }
  }

  delete(path: string, body?: unknown) {
    return this.httpClient.delete(this.getUrl(path), { body });
  }

  get<T>(path: string, options?: HttpPostOptions) {
    return this.httpClient.get<T>(this.getUrl(path), options);
  }

  private getUrl(path: string): string {
    try {
      new URL(this.baseUrl);
      return Location.joinWithSlash(this.baseUrl, path);
    } catch {
      return Location.joinWithSlash(environment.gateway, Location.joinWithSlash(this.baseUrl, path));
    }
  }
}
