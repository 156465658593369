<div
  class="logo m-b-12"
  *ngIf="layoutMode === 'full'">
  <img
    alt=""
    [src]="brandingData[BrandingField.FieldLogo]" />
</div>

<div class="p-16">
  <div class="column">
    <mat-form-field
      appearance="outline"
      class="search density-minus-3">
      <mat-label class="fs-14">{{ searchLabel }}</mat-label>
      <input
        #contactListHeaderInput
        data-cy="dialer-search-contact-list"
        [(ngModel)]="search"
        (ngModelChange)="handleContactSearchInputChange($event)"
        (focus)="searchFocus = true"
        (focusout)="searchFocus = false"
        (keyup)="onSearchInputKeyUp($event)"
        (keydown)="handleKeydown($event)"
        autocomplete="off"
        data-lpignore="true"
        matInput
        placeholder="{{ searchLabel }}" />
    </mat-form-field>
  </div>

  <div
    class="filter-container"
    *ngIf="filteringEnabled">
    <div class="select-option mb-0">
      <div
        class="selected-option"
        (click)="onContactFilterClicked()">
        {{ selectedContactType | contactTypeLabel }}
        <mat-icon>{{ contactFilterMenuVisible ? 'arrow_drop_up' : 'arrow_drop_down' }} </mat-icon>
      </div>

      <ul
        class="item-options"
        [ngClass]="{ 'is-open': contactFilterMenuVisible }">
        <label class="label">Show</label>
        <mat-radio-group
          color="primary"
          class="radio-options"
          [(ngModel)]="selectedContactType">
          <mat-radio-button
            *ngFor="let item of contactTypeValues"
            [value]="item"
            (click)="onContactTypeChanged(item)">
            <span data-cy="radio-option-text">{{ item | contactTypeLabel }}</span>
          </mat-radio-button>
        </mat-radio-group>
        <mat-divider></mat-divider>

        <label class="label">Group By</label>
        <mat-radio-group
          color="primary"
          class="radio-options"
          [(ngModel)]="selectedContactGrouping">
          <mat-radio-button
            *ngFor="let item of contactGroupingValues"
            [value]="item"
            (click)="onContactGroupingChanged(item)">
            <span data-cy="radio-option-text">{{ item | contactGroupingLabel }}</span>
          </mat-radio-button>
        </mat-radio-group>
        <mat-divider></mat-divider>

        <label class="label">Sort By</label>
        <mat-radio-group
          color="primary"
          class="radio-options"
          [(ngModel)]="selectedContactSort">
          <mat-radio-button
            *ngFor="let item of contactSortValues"
            [value]="item"
            (click)="onContactSortChanged(item)">
            <span data-cy="radio-option-text">{{ item | contactSortLabel }}</span>
          </mat-radio-button>
        </mat-radio-group>
      </ul>
    </div>

    <ng-container *ngIf="(configService.hasOfficeManagerRole$ | async) === false; else dropDownMenu">
      <button
        class="add-contact-button"
        *ngIf="layoutMode === 'full'"
        data-cy="add-contact"
        mat-icon-button
        color="primary"
        (click)="addContact()">
        <mat-icon class="material-icons-outlined">add_box_outlined</mat-icon>
      </button>
    </ng-container>
    <ng-template #dropDownMenu>
      <button
        class="add-contact-button"
        data-cy="add-contact"
        *ngIf="layoutMode === 'full'"
        [matMenuTriggerFor]="newMenu"
        #menuTrigger="matMenuTrigger"
        mat-icon-button
        color="primary">
        <mat-icon class="material-icons-outlined">add_box_outlined</mat-icon>
      </button>
      <mat-menu
        #newMenu="matMenu"
        class="margin-top-7">
        <span>
          <button
            mat-menu-item
            (click)="addContact()"
            data-cy="add-contact-menu">
            <mat-icon>add</mat-icon>
            <span>Add My Contact</span>
          </button>
          <button
            mat-menu-item
            (click)="addContact(true)">
            <mat-icon>add</mat-icon>
            <span>Add Shared Contact</span>
          </button>
        </span>
      </mat-menu>
    </ng-template>
  </div>

  <mat-divider></mat-divider>
</div>
