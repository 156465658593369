import { buffer, debounceTime, Observable, OperatorFunction } from 'rxjs';

/** From: https://stackoverflow.com/a/62180108/461277 */
type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;

const bufferDebounce: BufferDebounce = (debounce) => (source) =>
  new Observable((observer) =>
    source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
      next(x) {
        observer.next(x);
      },
      error(error) {
        observer.error(error);
      },
      complete() {
        observer.complete();
      },
    })
  );

export default bufferDebounce;
