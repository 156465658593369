import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/core/services/app-config.service';
import { ChimeMeetingService } from '@app/meetings/services/chime-meeting.service';

import {
  CallNotificationOptions,
  NotificationItems,
  NotificationKeyBackend,
  NotificationSettingsBackend,
  NotificationType,
  SoundAssets,
  Tones,
} from '../models/settings.models';
import { SoundEffectService } from './sound-effect.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private appConfigService: AppConfigService,
    private soundEffectService: SoundEffectService,
    private chimeMeetingService: ChimeMeetingService
  ) {
    this.appConfigService.data$.subscribe((config) => {
      if (config && config.settings?.notifications) {
        this.updateLocalNotifications(config.settings.notifications);
      }
    });
  }

  private readonly ringtones: Tones[] = [
    {
      title: 'Incoming Call ringtone',
      ringtone: SoundAssets.RingtoneIncomingCall,
    },
    {
      title: 'Minimal ringtone',
      ringtone: SoundAssets.RingtoneMinimal,
    },
    {
      title: 'Second Call waiting ringtone',
      ringtone: SoundAssets.RingtoneIncomingCallWaiting,
    },
    {
      title: 'Gentle ringtone',
      ringtone: SoundAssets.RingtoneGentle,
    },
  ];
  private readonly sounds: Tones[] = [
    {
      title: 'Missed call sound',
      ringtone: SoundAssets.NotificationDecorative2,
    },
    {
      title: 'Someone joins a meeting sound',
      ringtone: SoundAssets.NotificationSimple1,
    },
    {
      title: 'Incoming voicemail sound',
      ringtone: SoundAssets.AlertHighIntensity,
    },
    {
      title: 'Incoming SMS sound',
      ringtone: SoundAssets.NavigationBackwards,
    },
    {
      title: 'Alert simple',
      ringtone: SoundAssets.AlertSimple,
    },
    {
      title: 'Notification high intensity',
      ringtone: SoundAssets.NotificationHighIntensity,
    },
    {
      title: 'Hero simple celebration 1',
      ringtone: SoundAssets.HeroSimpleCelebration1,
    },
    {
      title: 'Hero simple celebration 2',
      ringtone: SoundAssets.HeroSimpleCelebration2,
    },
    {
      title: 'Hero simple celebration 3',
      ringtone: SoundAssets.HeroSimpleCelebration3,
    },
    {
      title: 'Hero decorative celebration 1',
      ringtone: SoundAssets.HeroDecorativeCelebration1,
    },
    {
      title: 'Hero decorative celebration 2',
      ringtone: SoundAssets.HeroDecorativeCelebration2,
    },
    {
      title: 'Hero decorative celebration 3',
      ringtone: SoundAssets.HeroDecorativeCelebration3,
    },
    {
      title: 'Notification ambient',
      ringtone: SoundAssets.NotificationAmbient,
    },
    {
      title: 'Notification decorative',
      ringtone: SoundAssets.NotificationDecorative1,
    },
    {
      title: 'Notification simple',
      ringtone: SoundAssets.NotificationSimple2,
    },
  ];
  private notifications: Record<NotificationType, NotificationItems> = {
    [NotificationType.IncomingCall]: {
      title: NotificationType.IncomingCall,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Call ringtone',
      ringtone: this.ringtones[0].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.ringtones, this.ringtones[0].title),
    },
    [NotificationType.IncomingSecondCall]: {
      title: NotificationType.IncomingSecondCall,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Second call ringtone',
      ringtone: this.ringtones[2].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.ringtones, this.ringtones[2].title),
    },
    [NotificationType.MissedCall]: {
      title: NotificationType.MissedCall,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Missed call tone',
      ringtone: this.sounds[0].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[0].title),
    },
    [NotificationType.GroupMeetingStarted]: {
      title: NotificationType.GroupMeetingStarted,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Group meeting started tone',
      ringtone: this.sounds[0].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[0].title),
    },
    [NotificationType.MeetingJoined]: {
      title: NotificationType.MeetingJoined,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Meeting joined tone',
      ringtone: this.sounds[1].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[1].title),
    },
    [NotificationType.IncomingVoicemail]: {
      title: NotificationType.IncomingVoicemail,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Voicemail tone',
      ringtone: this.sounds[2].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[2].title),
    },
    [NotificationType.SMS]: {
      title: NotificationType.SMS,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'SMS tone',
      ringtone: this.sounds[3].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[3].title),
    },
    [NotificationType.Chat]: {
      title: NotificationType.Chat,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Chat tone',
      ringtone: this.sounds[3].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[3].title),
    },
    [NotificationType.IncomingFax]: {
      title: NotificationType.IncomingFax,
      notificationSound: true,
      showNotification: true,
      showInForeground: true,
      selectLabel: 'Fax tone',
      ringtone: this.sounds[2].ringtone,
      selectOptions: this.getDefaultRingtoneTitle(this.sounds, this.sounds[2].title),
    },
  };

  transformToBackendFormat(notifications: NotificationItems[]) {
    const newNotifications: Record<string, NotificationSettingsBackend> = {};

    for (const { title: key, ...value } of notifications) {
      const newKey = NotificationKeyBackend[key];
      if (newKey) {
        newNotifications[newKey] = {
          ringtone: value.ringtone,
          showNotification: value.showNotification,
          notificationSound: value.notificationSound,
          showInForeground: value.showInForeground,
        };
      }
    }

    return newNotifications;
  }

  getNotificationItems(): Record<NotificationType, NotificationItems> {
    return this.notifications;
  }

  getDefaultRingtoneTitle(ringtones: Tones[], defaultValue: string): Tones[] {
    return ringtones.map((tone) => {
      return { ...tone, title: tone.title === defaultValue ? 'Default' : tone.title };
    });
  }

  sendNotification(
    displayTitle: string,
    body: string,
    notificationType: NotificationKeyBackend,
    isAudioSuspended = false,
    channelArn?: string,
    callId?: string
  ) {
    const notificationSettings = this.getNotificationSettings();
    if (this.shouldSendNotification(notificationSettings, notificationType, isAudioSuspended)) {
      if (notificationType === NotificationKeyBackend['Incoming Call']) {
        this.sendIncomingCallNotification(displayTitle, body, callId);
        return;
      }
      const notification = this.createNotification(displayTitle, {
        body: `${body}`,
        icon: 'assets/icons/icon-72x72.png',
      });
      notification.addEventListener('click', async () => {
        window.focus();
        notification.close();
        if (channelArn) {
          await this.chimeMeetingService.startMeeting(channelArn);
        }
      });

      if (
        notificationSettings[notificationType].notificationSound &&
        ![NotificationKeyBackend['Incoming Call'], NotificationKeyBackend['Incoming Second Call']].includes(
          notificationType
        )
      ) {
        this.soundEffectService
          .play(notificationSettings[notificationType].ringtone, notificationType)
          .catch((error) => console.error('Error playing sound', error));
      }
    }
  }

  private shouldSendNotification(
    notificationSettings: Record<string, NotificationSettingsBackend>,
    notificationType: NotificationKeyBackend,
    isAudioSuspended: boolean
  ) {
    const sendInForeground = notificationSettings[notificationType]?.showInForeground && document.hasFocus();
    const sendInBackground = notificationSettings[notificationType]?.showNotification && !document.hasFocus();
    return (
      sendInForeground ||
      sendInBackground ||
      (isAudioSuspended && notificationType === NotificationKeyBackend['Incoming Call'])
    );
  }

  createNotification(displayTitle: string, options: { icon: string; body: string }) {
    return new Notification(displayTitle, options);
  }

  updateNotificationSettings(notificationSettingsBackends: Record<string, NotificationSettingsBackend>) {
    return this.appConfigService.updateNotificationSettings(notificationSettingsBackends);
  }

  getNotificationSettings() {
    return this.appConfigService.getNotificationSettings();
  }

  private updateLocalNotifications(notifications: Record<NotificationKeyBackend, NotificationSettingsBackend>) {
    for (const [key, value] of Object.entries(notifications)) {
      const mappedKey = Object.keys(NotificationKeyBackend).find((k) => NotificationKeyBackend[k] === key);
      if (mappedKey) {
        const notification = this.notifications[mappedKey];
        if (notification) {
          notification.showNotification = value.showNotification;
          notification.notificationSound = value.notificationSound;
          if (value.ringtone !== 'Default' && (value.ringtone as SoundAssets)) {
            notification.ringtone = value.ringtone;
          }
          notification.showInForeground = value.showInForeground;
          this.notifications[mappedKey] = notification;
        }
      }
    }
  }

  private sendIncomingCallNotification(displayTitle: string, body: string, callId: string | undefined) {
    navigator.serviceWorker.getRegistration().then(async (registration) => {
      if (!registration) {
        return;
      }
      try {
        await registration.showNotification(displayTitle, {
          body: body,
          data: callId,
          actions: [
            {
              action: CallNotificationOptions.Answer,
              title: CallNotificationOptions.Answer,
              icon: 'assets/icons/telephone.png',
            },
            {
              action: CallNotificationOptions.Decline,
              title: CallNotificationOptions.Decline,
              icon: 'assets/icons/hang-up.png',
            },
          ],
        });
      } catch (error) {
        console.log('Notification not shown', error);
      }
    });
  }
}
