import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact, ContactGrouping, ContactSort, ContactType } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AudioType } from '@app/preferences/models/audio-type.enum';
import {
  VoicemailGreetingAudioItem,
  VoicemailSettingPageType,
  VoicemailSettingsModel,
} from '@app/preferences/models/voicemail.model';

@Component({
  selector: 'app-settings-voicemail-main-page',
  templateUrl: './voicemail-main-page.component.html',
  styleUrls: ['../../voicemail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicemailMainPageComponent {
  @Input() settings: VoicemailSettingsModel;
  @Input() greetings: VoicemailGreetingAudioItem[];
  @Input() recordedName: VoicemailGreetingAudioItem | undefined = undefined;
  @Input() playingAudioId: string | null = null;
  @Input() set contacts(contacts: Contact[]) {
    this._contacts = contacts;
    this.filterContacts();
  }
  @Output() playClicked = new EventEmitter<VoicemailGreetingAudioItem>();
  @Output() stopClicked = new EventEmitter<null>();
  @Output() selectedPageChanged = new EventEmitter<VoicemailSettingPageType>();
  @Output() saveSettings = new EventEmitter<VoicemailSettingsModel>();
  @Output() cancelClick = new EventEmitter<null>();
  @Output() addNewGreetingClicked = new EventEmitter();
  @Output() manageRecorderNameClicked = new EventEmitter();
  @Output() greetingDownloadClicked = new EventEmitter<VoicemailGreetingAudioItem>();
  @Output() greetingDeleteClicked = new EventEmitter<VoicemailGreetingAudioItem>();
  @Output() greetingEditClicked = new EventEmitter<VoicemailGreetingAudioItem>();

  newExtension: string = '';
  isSaveButtonEnabled = false;
  processing = false;
  filteredContacts: Contact[] = [];
  _contacts: Contact[] = [];
  protected readonly Number = Number;

  protected readonly AppFeature = AppFeature;

  constructor(protected configService: AppConfigService, private contactService: ContactService) {}

  toggleEnabled() {
    this.settings.enabled = !this.settings?.enabled;
    this.setSettingsChanged();
  }

  toggleSortInbox() {
    this.settings.sortInbox = !this.settings?.sortInbox;
    this.setSettingsChanged();
  }

  toggleAnnounceReceivedTime() {
    this.settings.announceReceivedTime = !this.settings.announceReceivedTime;
    this.setSettingsChanged();
  }

  toggleAnnounceIncomingCallId() {
    this.settings.announceIncomingCallId = !this.settings.announceIncomingCallId;
    this.setSettingsChanged();
  }

  toggleConfirmDeletion() {
    this.settings.confirmDeletion = !this.settings.confirmDeletion;
    this.setSettingsChanged();
  }

  toggleEmailWhenMailBoxIsFull() {
    this.settings.unifiedMessaging.emailWhenMailBoxIsFull = !this.settings.unifiedMessaging.emailWhenMailBoxIsFull;
    this.setSettingsChanged();
  }

  toggleEmailOnMissCall() {
    this.settings.unifiedMessaging.emailOnMissCall = !this.settings.unifiedMessaging.emailOnMissCall;
    this.setSettingsChanged();
  }

  addNewExtension() {
    if (this.newExtension.length > 0) {
      if (!this.settings.copyToExtensions.includes(this.newExtension)) {
        this.settings.copyToExtensions.push(this.newExtension);
      }
      this.newExtension = '';
      this.setSettingsChanged();
    }
  }

  deleteExtension(extension: string) {
    this.settings.copyToExtensions = this.settings.copyToExtensions.filter((ext) => ext !== extension);
    this.setSettingsChanged();
  }

  setSelectedPage(page: VoicemailSettingPageType) {
    this.selectedPageChanged.emit(page);
  }

  setSettingsChanged() {
    if (this.settings) {
      this.isSaveButtonEnabled = true;
    }
  }

  handleClickOnSave() {
    this.processing = true;
    this.saveSettings.emit(this.settings);
  }

  handleClickOnDelete(audio: VoicemailGreetingAudioItem, isName = false) {
    audio.type = isName ? 'name' : 'greeting';
    this.greetingDeleteClicked.emit(audio);
  }

  handleClickOnEdit(audio: VoicemailGreetingAudioItem, isName = false) {
    audio.type = isName ? 'name' : 'greeting';
    this.greetingEditClicked.emit(audio);
  }

  handleClickOnManageRecording() {
    this.manageRecorderNameClicked.emit();
  }

  greetingDownloadClickHandler(audio: VoicemailGreetingAudioItem, isName = false) {
    audio.type = isName ? 'name' : 'greeting';
    this.greetingDownloadClicked.emit(audio);
  }

  playClickHandler(audio: VoicemailGreetingAudioItem, isName = false) {
    if (this.playingAudioId === audio.index || (this.playingAudioId === AudioType.name && isName)) {
      this.stopClicked.emit();
    } else {
      audio.type = isName ? 'name' : 'greeting';
      this.playClicked.emit(audio);
    }
  }

  filterContacts() {
    let filtered = this.contactService.filterSortAndGroupContacts(
      { contactType: ContactType.Company },
      ContactSort.FirstName,
      ContactGrouping.Name,
      this._contacts
    ).contacts;

    if (this.newExtension.length > 0) {
      filtered = filtered.filter((c) => this.contactService.isContactMatch(c, this.newExtension));
    }

    this.filteredContacts = filtered;
  }
}
