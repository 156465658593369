<div class="container">
  <div class="content">
    <div class="label">{{ data.label }}</div>
    <div class="text">
      {{ data.text }}
    </div>
  </div>
  <div class="controls">
    <mat-icon
      class="close"
      (click)="closeAction()">
      close
    </mat-icon>
    <div
      class="action"
      (click)="onAction()">
      <span class="action-label">{{ data.actionLabel }}</span>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>
