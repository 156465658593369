import { Contact } from '@app/contacts/models/contact';

import { CallStatus } from './call-details.models';

export enum CallTerminationCause {
  LocalUserHangup = 'localUserHangup',
  Parked = 'parked',
  Transferred = 'transferred',
}

/**
 * A combination of status fields consisting of events from sip.js's
 * [SessionManagerDelegate](https://github.com/onsip/SIP.js/blob/main/src/platform/web/session-manager/session-manager-delegate.ts#L9)
 * and [OutgoingRequestDelegate](https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/core/messages/outgoing-request.ts#L36).
 * The combination of these two delegates is enough to constitute the full lifecycle of a SIP session (i.e. all
 * the state that we will be displaying for a single phone call).
 *
 * sip.js has no concept of a merged call built into its library so that functionality is outside
 * the scope of this enum.
 */
export enum SessionStatus {
  /** SessionManagerDelegate status fields. These will occur for new incoming and existing calls */
  Answered = 'Answered',
  Created = 'Created',
  Received = 'Received',
  Hangup = 'Hangup',
  Hold = 'Hold',

  /**
   * OutgoingRequestDelegate status fields. These will occur for outgoing calls and correspond
   * to [SIP response codes](https://en.wikipedia.org/wiki/List_of_SIP_response_codes)
   */
  Accept = 'Accept',
  Ringing = 'Ringing', // Codes 180 (Ringing) _and_ 183 (Session Progress)
  Reject = 'Reject',
  Trying = 'Trying',

  /**
   * Custom status field for indicating when the session has been destroyed. This is not a SIP status, but is useful
   * for handling cleanup of the session.
   */
  Destroy = 'Destroy',
}

export enum ConferenceStatus {
  /** A call has joined a conference */
  Joined = 'Joined',

  /** A call has been manually split from a conference. */
  Split = 'Split',

  /**
   * A call has left a conference because the conference no longer exists. This may occur if another call is split from a conference
   * and this is the only remaining call. In this case, the conference is destroyed and the remaining call is removed from the conference.
   */
  Left = 'Left',
}

export interface MergedCall {
  callIds: string[];
  mergedAudioStream: MediaStream;
  peerConnectionSenderTrackMap: Map<string, MediaStreamTrack | null>;
}

export interface Phone {
  contact?: Contact;
  status?: PhoneStatus;
  callId: string;
  time?: Date;
  createdTime?: Date;
  isOngoingOpen?: boolean;
  phoneCallDetails?: PhoneCallDetails;
  isSecondCall?: boolean;
}

export enum PhoneStatus {
  Ongoing = 'Ongoing',
  Dialer = 'Dialer',
  Answer = 'Answer',
}

export interface AddCallActions {
  contact: Contact;
  action: string;
}

export interface PhoneCallDetails {
  answered: boolean;
  callerId: string;
  contact: Contact;
  displayName: string;
  duration: string;
  id: string;
  phoneNumber: string;
  seconds: number;
  status: CallStatus;
  isSilent: boolean;
  avatar?: string;
  coin?: string;
  isHold: boolean;
}

export type SidePanelMode = 'ParkingLot' | 'DialPad' | 'Settings';

// prettier-ignore
export type DTMF =
  "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "#"
  | "*"
  | "a"
  | "A"
  | "b"
  | "B"
  | "c"
  | "C"
  | "d"
  | "D";

export const dtmfTones: Record<DTMF, string> = {
  '0': '../../../assets/dtmf/audiocheck.net_dtmf_0.wav',
  '1': '../../../assets/dtmf/audiocheck.net_dtmf_1.wav',
  '2': '../../../assets/dtmf/audiocheck.net_dtmf_2.wav',
  '3': '../../../assets/dtmf/audiocheck.net_dtmf_3.wav',
  '4': '../../../assets/dtmf/audiocheck.net_dtmf_4.wav',
  '5': '../../../assets/dtmf/audiocheck.net_dtmf_5.wav',
  '6': '../../../assets/dtmf/audiocheck.net_dtmf_6.wav',
  '7': '../../../assets/dtmf/audiocheck.net_dtmf_7.wav',
  '8': '../../../assets/dtmf/audiocheck.net_dtmf_8.wav',
  '9': '../../../assets/dtmf/audiocheck.net_dtmf_9.wav',
  '#': '../../../assets/dtmf/audiocheck.net_dtmf_pound.wav',
  '*': '../../../assets/dtmf/audiocheck.net_dtmf_star.wav',
  a: '../../../assets/dtmf/audiocheck.net_dtmf_a.wav',
  A: '../../../assets/dtmf/audiocheck.net_dtmf_a.wav',
  b: '../../../assets/dtmf/audiocheck.net_dtmf_b.wav',
  B: '../../../assets/dtmf/audiocheck.net_dtmf_b.wav',
  c: '../../../assets/dtmf/audiocheck.net_dtmf_c.wav',
  C: '../../../assets/dtmf/audiocheck.net_dtmf_c.wav',
  d: '../../../assets/dtmf/audiocheck.net_dtmf_d.wav',
  D: '../../../assets/dtmf/audiocheck.net_dtmf_d.wav',
};

export const EXTENSION_SUFFIX = 'w';
