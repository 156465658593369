import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { CallCenterService } from '@app/call-center/services/call-center.service';
import { CallHistoryService } from '@app/call-history/services/call-history.service';
import { ChannelService } from '@app/chat/services/channel.service';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BrandingService } from '@app/core/services/branding.service';
import { LinkUCService } from '@app/core/services/link-uc.service';
import { FaxService } from '@app/fax/services/fax.service';
import { ActiveMeetingService } from '@app/meetings/services/active-meeting.service';
import { MeetingService } from '@app/meetings/services/meeting.service';
import { CallParkService } from '@app/parking/services/call-park.service';
import { RecordingService } from '@app/phone/services/recording.service';
import { SMSService } from '@app/sms/services/sms.service';
import { SMSUnreadMessageService } from '@app/sms/services/sms-unread-message.service';
import { UnreadMessageService } from '@app/sms/services/unread-message.service';
import { VoicemailService } from '@app/voicemail/services/voicemail.service';
import { environment } from '@environment/environment';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  filter,
  finalize,
  forkJoin,
  map,
  Observable,
  startWith,
  tap,
} from 'rxjs';

import { FeatureAnnouncementService } from './feature-announcement.service';
import { WsService } from './ws.service';

/*
 *
 * Some socket events are delivered as soon as the socket is connected.
 * For those events, it is important the interested service
 * is initialized _before_ the socket service, so we don't miss any events needed to bootstrap our application.
 * Angular provides
 * no way to explicitly control how services get initialized.
 *
 * Any service meeting the criteria outlined above should implement this interface
 * and be added to `websocketDependentServices`
 * an array in this class.
 */
@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  isLoading$ = new BehaviorSubject(false);
  initialLoading$ = new BehaviorSubject(false);
  progress$ = new BehaviorSubject(0);
  services = [
    this.contactService,
    this.voicemailService,
    this.callHistoryService,
    this.meetingService,
    this.callParkService,
    this.channelService,
    this.smsService,
    this.faxService,
    this.callCenterService,
  ];

  constructor(
    private voicemailService: VoicemailService,
    private callHistoryService: CallHistoryService,
    private contactService: ContactService,
    private channelService: ChannelService,
    private meetingService: MeetingService,
    private authService: AuthService,
    private callParkService: CallParkService,
    private smsService: SMSService,
    private activeMeetingService: ActiveMeetingService,
    private unreadMessageService: UnreadMessageService,
    private unreadSMSMessageService: SMSUnreadMessageService,
    private faxService: FaxService,
    private wsService: WsService,
    private appConfigService: AppConfigService,
    private featureAnnouncementService: FeatureAnnouncementService,
    private callCenterService: CallCenterService,
    private brandingService: BrandingService,
    private linkUCService: LinkUCService,
    // Added the recording service because it was needed to be initialized at the start of the app
    private recordingService: RecordingService
  ) {}

  static init(injector: Injector): () => Promise<void> {
    const initializer = injector.get(AppInitializerService);
    return () =>
      new Promise((resolve) => {
        initializer.init();
        resolve();
      });
  }

  init() {
    this.initWsService();
    this.initServices();
  }

  private initServices() {
    this.services.forEach((service) => service.init());
    const dataFetches = this.services.map((service: { refreshData(): Observable<unknown> }, index: number) =>
      service.refreshData().pipe(tap(() => this.progress$.next((index * 100) / Math.max(this.services.length, 1))))
    );
    combineLatest([this.authService.isAuthenticated$, this.wsService.isConnected$])
      .pipe(
        map(([loggedIn, isConnected]) => loggedIn && (isConnected || environment.name === 'mocked')),
        startWith(false),
        distinctUntilChanged(),
        filter((shouldFetch) => shouldFetch),
        tap(() => this.isLoading$.next(true)),
        concatMap(() => this.brandingService.refreshData()),
        concatMap(() => forkJoin(dataFetches)),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe(() => {
        this.isLoading$.next(false);
        this.appConfigService.getUserRoles();
        this.featureAnnouncementService.init();
      });
  }

  private initWsService() {
    [this.activeMeetingService, this.unreadMessageService, this.unreadSMSMessageService, this.linkUCService].forEach(
      (service) => service.bindSocketEvents()
    );
    this.wsService.init();
  }
}
