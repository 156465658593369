import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PreferenceLayoutComponent } from '@app/preferences/components/preference-layout/preference-layout.component';

import { FeatureAnnoucementComponent } from '../components/feature-announcement/feature-announcement.component';
import {
  ConfirmedAnnouncements,
  FeatureAnnouncement,
  FeatureAnnouncementBarData,
} from '../models/feature-announcement.model';
import { GoogleAnalyticsService } from './google-analytics.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureAnnouncementService {
  private readonly STORAGE_KEY = 'announcements';
  private confirmedAnnoucements: ConfirmedAnnouncements = [];

  private readonly announcement: FeatureAnnouncement | null = null;

  constructor(
    private matSnackBar: MatSnackBar,
    private dialog: MatDialog,
    private localStorage: LocalStorageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  public init(): void {
    this.confirmedAnnoucements = this.localStorage.get<ConfirmedAnnouncements>(this.STORAGE_KEY) || [];
    if (this.announcement && !this.confirmedAnnoucements.includes(this.announcement.id)) {
      this.open(this.announcement.data);
    }
  }

  private confirmAnnouncement(): void {
    if (this.announcement) {
      this.confirmedAnnoucements.push(this.announcement.id);
      this.localStorage.set(this.STORAGE_KEY, this.confirmedAnnoucements);
    }
  }

  private open(data: FeatureAnnouncementBarData): void {
    const config = { duration: 0, panelClass: ['feature-announcement'], data: data };

    this.matSnackBar
      .openFromComponent(FeatureAnnoucementComponent, config)
      .afterDismissed()
      .subscribe(() => {
        this.confirmAnnouncement();
      });
  }

  private openPreferences(): void {
    this.dialog.open(PreferenceLayoutComponent);
    this.googleAnalyticsService.preferencesSelected();
  }
}
