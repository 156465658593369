import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { CallHistoryStorageService } from '@app/call-history/services/call-history-storage.service';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { ApiService } from '@app/core/services/api.service';
import { WsService } from '@app/core/services/ws.service';
import { LoggerService } from '@app/phone/services/logger.service';
import { ConfigOptions, CsvOutput, download, generateCsv, mkConfig } from 'export-to-csv';
import {
  BehaviorSubject,
  EMPTY,
  expand,
  finalize,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeLast,
  takeUntil,
  tap,
} from 'rxjs';

import {
  CallDirection,
  CallHistory,
  CallHistoryType,
  CallMissedReason,
  CallServiceResponse,
  MissedCallFromQueueActivated,
} from '../models/call-history.models';

@Injectable({
  providedIn: 'root',
})
export class CallHistoryService extends ApiService {
  protected path = 'v2/users/{me}/cdrs';

  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly downloadingSubject = new BehaviorSubject<boolean>(false);
  private readonly errorSubject = new BehaviorSubject<boolean>(false);

  /**
   * Used to handle duplicate calls to load more data. This subject emits a single time
   * when a page of data is loaded. This is used to prevent multiple calls to the API
   */
  private readonly pageLoadedSubject = new Subject<CallServiceResponse>();

  public data$ = this.callHistoryStorageService.liveQuery();
  public loading$ = this.loadingSubject.asObservable();
  public downloading$ = this.downloadingSubject.asObservable();
  public hasError$ = this.errorSubject.asObservable();

  protected isLoaded = false;

  private nextPage: string | null;
  private isAllCallHistoryLoaded = false;

  protected constructor(
    protected http: HttpClient,
    private wsService: WsService,
    private authService: AuthService,
    private contactService: ContactService,
    private loggerService: LoggerService,
    private callHistoryStorageService: CallHistoryStorageService
  ) {
    super(http);
    this.bindSocket();
    this.authService.jwtClaims.subscribe((next) => {
      if (next === null) {
        this.resetData();
      }
    });
  }

  init(): void | Error {
    if (this.isLoaded) {
      return new Error('Should only ever be loaded once');
    }
    this.isLoaded = true;
  }

  private bindSocket() {
    this.wsService.socket.on('CdrCreated', async (data: CallHistory) => {
      data.contactId = this.contactService.contactByTel(
        data.direction === CallDirection.incoming ? data.fromNumber : data.toNumber
      )?.id;
      await this.callHistoryStorageService.upsertCallHistory([data]);

      if (data.id) {
        await this.loggerService.createCallReport(data.id);
      }
    });

    this.wsService.socket.on('RecordingCreated', async (item: CallHistory) => {
      await this.callHistoryStorageService.updateCallHistoryItemByOrigCallId(item.origCallid, item);
    });

    this.wsService.socket.on('CallCompletedElsewhere', async (item: CallHistory) => {
      item.termToUri = 'sip:' + item.device + '@';
      await this.updateCallHistoryCancelReason(item, CallMissedReason.CompletedElsewhere);
    });

    this.wsService.socket.on('MissedCallFromQueue', async (item: CallHistory) => {
      await this.updateCallHistoryCancelReason(item, CallMissedReason.MissedQueue);
    });

    this.wsService.socket.on('MissedCallFromQueueActivated', async (item: MissedCallFromQueueActivated) => {
      item.termToUri = 'sip:' + item.term_sub + '@';
      item.cancelReason = CallMissedReason.CompletedElsewhere;
      await this.callHistoryStorageService.updateCallHistoryItem(item.id, item);
    });
  }

  // ========== Data Loading ==========

  public refreshData(): Observable<CallServiceResponse> {
    this.resetData();
    return this.getData();
  }

  public loadNextPage(): Observable<CallServiceResponse> {
    return this.getData();
  }

  private getData(): Observable<CallServiceResponse> {
    if (this.isAllCallHistoryLoaded) {
      return of({ cdrs: [], nextPage: null });
    }

    if (this.loadingSubject.value) {
      return this.pageLoadedSubject.asObservable();
    }

    this.loadingSubject.next(true);

    let parameters = new HttpParams();
    if (this.nextPage) {
      parameters = parameters.append('cursor', this.nextPage);
    }

    return new Observable<CallServiceResponse>((subscriber) => {
      const processRequest = async () => {
        let response: CallServiceResponse;
        try {
          response = await firstValueFrom(this.get<CallServiceResponse>(this.path, { params: parameters }));

          // Upsert the items and propagate the last contiguous timestamp
          const { lastContiguousTimestamp } = await this.callHistoryStorageService.upsertCallHistory(response.cdrs);

          // Update the cursor information for our next request
          this.nextPage = lastContiguousTimestamp;
          this.isAllCallHistoryLoaded = !response.nextPage;
        } catch (error) {
          console.error('Error fetching call service response:', error);
          this.errorSubject.next(true);
          response = { cdrs: [], nextPage: null };
        }

        // Emit the response and complete the observable
        this.pageLoadedSubject.next(response);
        this.loadingSubject.next(false);

        subscriber.next(response);
        subscriber.complete();
      };

      processRequest();
    });
  }

  getRecordingUrl(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.post<any>(`users/{me}/recordings/${id}/url`, {
      responseType: 'blob',
    });
  }

  public getCallHistoryObservable(options: {
    recordingsOnly: boolean;
    searchTerm?: string;
    filters?: CallHistoryType[];
  }): Observable<CallHistory[]> {
    return this.callHistoryStorageService.liveQuery((item: CallHistory) =>
      this.callHistoryItemPassesFilterOptions(item, options)
    );
  }

  private callHistoryItemPassesFilterOptions(
    item: CallHistory,
    options: {
      recordingsOnly: boolean;
      searchTerm?: string;
      filters?: CallHistoryType[];
    }
  ): boolean {
    let passesFilter = true;

    if (options.recordingsOnly) {
      passesFilter = passesFilter && item.recordingId !== '';
    }

    if (options.searchTerm) {
      const searchTerm = options.searchTerm.toLowerCase();

      const remoteLabel = item.direction === CallDirection.incoming ? item.fromLabel : item.toLabel;
      const remoteNumber = item.direction === CallDirection.incoming ? item.fromNumber : item.toNumber;
      const itemHasSearchTerm = [remoteLabel, remoteNumber].some((value) => value.toLowerCase().includes(searchTerm));
      let contactHasSearchTerm = false;
      if (item.contactId) {
        const contact = this.contactService.contactById(item.contactId);
        contactHasSearchTerm =
          (contact?.fullName?.toLowerCase().includes(searchTerm) || contact?.ext?.toLowerCase().includes(searchTerm)) ??
          false;
      }
      passesFilter = passesFilter && (itemHasSearchTerm || contactHasSearchTerm);
    }

    if (options.filters && options.filters.length > 0) {
      const filters = options.filters;
      passesFilter =
        passesFilter && item.missed
          ? filters.includes(CallHistoryType.missed)
          : filters.includes(
              item.direction == CallDirection.incoming ? CallHistoryType.incoming : CallHistoryType.outgoing
            );
    }

    return passesFilter;
  }

  private resetData() {
    this.nextPage = null;
    this.isAllCallHistoryLoaded = false;
    this.errorSubject.next(false);
  }

  async addContactToCallHistory(item: CallHistory, contact: Contact) {
    await this.callHistoryStorageService.updateCallHistoryItem(item.id, { contactId: contact.id });
  }

  public downloadCallHistoryList(): {
    observable: Observable<{ result: 'success' | 'canceled' | 'empty' }>;
    cancelRequestSubject: Subject<void>;
  } {
    // Continuously load the next page until all call history is loaded.
    this.downloadingSubject.next(true);

    let didCancel = false;
    const cancelRequestSubject = new Subject<void>();

    const observable = this.loadNextPage().pipe(
      // Repeat the calls using expand
      expand((response) => (response.nextPage ? this.loadNextPage() : EMPTY)),

      // Only take the last value. We don't need to accumulate results since they're stored in indexedDB
      takeLast(1),

      // Cancel the loop if the cancelRequestSubject emits
      takeUntil(cancelRequestSubject.asObservable().pipe(tap(() => (didCancel = true)))),

      // At this point we should have all the data and can grab it from storage
      switchMap(() => this.callHistoryStorageService.liveQuery()),

      // We only want a single emission from `liveQuery` so we take the first value
      take(1),

      // Map the data to a CSV
      map((fullCallHistory: CallHistory[]) => {
        let result: 'success' | 'canceled' | 'empty';
        let csvConfig: Required<ConfigOptions> | undefined;
        let csv: CsvOutput | undefined;

        if (didCancel) {
          result = 'canceled';
        } else if (fullCallHistory.length === 0) {
          result = 'empty';
        } else {
          const itemsToDownload: Array<Partial<CallHistory>> = fullCallHistory.map((item) => {
            return {
              fromLabel: item.fromLabel,
              fromNumber: item.fromNumber,
              toLabel: item.toLabel,
              toNumber: item.toNumber,
              duration: item.duration,
              dateTime: item.dateTime,
              direction: item.direction,
              miss: item.missed,
            };
          });
          const generatedCsv = this.generateCSVFromCallHistory(itemsToDownload);
          csvConfig = generatedCsv.csvConfig;
          csv = generatedCsv.csv;
          result = 'success';
        }
        return { result, csvConfig, csv };
      }),
      tap(({ csvConfig, csv }) => {
        if (csvConfig && csv) {
          this.downloadCSV(csvConfig, csv);
        }
      }),
      map(({ result }) => ({ result })),
      finalize(() => {
        this.downloadingSubject.next(false);
      })
    );

    return { observable, cancelRequestSubject };
  }

  private generateCSVFromCallHistory(callHistory: Array<Partial<CallHistory>>): {
    csvConfig: Required<ConfigOptions>;
    csv: CsvOutput;
  } {
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: 'Call History' });
    const csv = generateCsv(csvConfig)(JSON.parse(JSON.stringify(callHistory)));
    return { csvConfig, csv };
  }

  private downloadCSV(csvConfig: ConfigOptions, csvOutput: CsvOutput) {
    download(csvConfig)(csvOutput);
  }

  public updateCallHistoryItem(item: CallHistory) {
    this.callHistoryStorageService.updateCallHistoryItem(item.id, item);
  }

  private async updateCallHistoryCancelReason(data: CallHistory, reason: string) {
    data.contactId = this.contactService.contactByTel(data.fromNumber)?.id;
    data.cancelReason = reason;
    await this.callHistoryStorageService.upsertCallHistory([data]);
  }
}
