export interface QueuesResponse {
  online: boolean;
  status: CallCenterStatus | null;
  queues: Queue[];
}

export interface AllQueuesResponse {
  [key: string]: QueuesResponse | [];
}

export interface Queue {
  id?: string;
  queue: string;
  type: string;
  callersWaitingCount: string;
  description: string;
  loggedIn: boolean;
  priority: string;
  wrapUpTime: string;
  callersWaiting: Array<{
    callerId: string;
    name: string;
    status: 'queued' | 'dispatching' | 'talking';
    duration: number;
    orig_callid?: string;
    term_callid?: string;
    to?: string;
    time_start?: string;
  }>;
}

export interface QueuePayload {
  queue: string;
  orig_callid: string;
  term_callid: string;
  caller_id: string;
  caller_name: string;
  to?: string;
  status: 'queued' | 'dispatching' | 'talking';
  time_start: string;
}

export interface AgentUpdatedPayload {
  id: string;
  queue: string;
  entry_option: 'immediate' | 'available' | 'manual';
  entry_action: 'make_im' | 'make_av' | 'make_ma' | 'update';
  to_as_queue?: boolean;
  device?: string;
}

export interface AgentCustomStatusPayload {
  id: string;
  status: string;
  device?: string;
}
export interface CallEndedPayload {
  orig_callid: string;
  term_callid: string;
}
export interface StatsResponse {
  totalCalls: number;
  totalTimeTalking: number;
  avgTimeTalking: number;
  inboundCalls: number;
  inboundTimeTalking: number;
  inboundAvgTimeTalking: number;
  outboundCalls: number;
  outboundTimeTalking: number;
  outboundAvgTimeTalking: number;
  missedCalls: number;
  lunch: number;
  break: number;
}

export interface LoginMethod {
  device: string;
  name: string;
}
export interface Stats {
  name: string;
  value: string;
  info: string;
}
export interface Disposition {
  disposition: string;
  dispositionType: string;
  reasons: string[];
}

export interface CallNote {
  reason: string;
  disposition: string;
  note: string;
}

export enum CallCenterStatus {
  Online = 'Online',
  Offline = 'Offline',
  Lunch = 'Lunch',
  Break = 'Break',
  Meeting = 'Meeting',
  Other = 'Other',
  Web = 'Web',
  SingleCall = 'Single-Call',
}

export enum StatsDisplayNames {
  totalCalls = 'Calls handled',
  totalTimeTalking = 'Time talking',
  avgTimeTalking = 'Average talk time',
  inboundCalls = 'Inbound calls today',
  inboundTimeTalking = 'Inbound talk time',
  inboundAvgTimeTalking = 'Inbound average talk',
  outboundCalls = 'Outbound calls today',
  outboundTimeTalking = 'Outbound talk time',
  outboundAvgTimeTalking = 'Outbound average talk',
  missedCalls = 'Missed Calls',
  lunch = 'Lunch',
  break = 'Break',
}

export enum StatsHoverInfo {
  totalCalls = 'Number of calls answered by agent originating through a Call Queue.',
  totalTimeTalking = 'The number of minutes spent by agent on answered calls originating through a Call Queue.',
  avgTimeTalking = 'Average number of minutes spent by agent talking per answered call on calls originating through a Call Queue.',
  inboundCalls = 'Number of attempted calls inbound to agent. Includes call center calls. Excludes on-net calls and conference calls.',
  inboundTimeTalking = 'Number of minutes spent by an agent on inbound calls. Includes call center calls. Includes talk and hold time. Excludes on-net calls and conference calls.',
  inboundAvgTimeTalking = 'Average length of time spent by agent on inbound calls. Includes call center calls. Excludes on-net calls and conference calls.',
  outboundCalls = 'Number of outbound call attempts by agent. Excludes on-net calls and conference calls.',
  outboundTimeTalking = 'Number of minutes spent by agent on outbound calls. Includes talk and hold time. Excludes call center calls, on-net calls and conference calls.',
  outboundAvgTimeTalking = 'Average length of time spent by agent on outbound calls. Includes talk and hold time. Excludes call center calls, on-net calls and conference calls.',
  missedCalls = 'Calls Missed while Online and Available',
  lunch = 'Total time an agent status is set to Lunch.',
  break = 'Total time an agent status is set to Break.',
}
