export const ApplicationId = 5;

export enum BrandingField {
  FieldLogo = 11,
  FieldHighlightColorLeftSideMenuItems = 12,
  FieldColorBackgroundLeftSideMenu = 14,
  FieldColorFontIconsLeftSideMenuItems = 15,
  FieldColorBackgroundListArea = 16,
  FieldColorFontIconsListAreaItems = 17,
  FieldColorListAreaText = 18,
  FieldColorBackgroundDetailsPane = 19,
}

export function defaultBrandingSettings(): Record<BrandingField, string> {
  return {
    [BrandingField.FieldLogo]: 'assets/ConnectUC-logo-icon-01.png',
    [BrandingField.FieldHighlightColorLeftSideMenuItems]: '#0072e5',
    [BrandingField.FieldColorBackgroundLeftSideMenu]: '#363636',
    [BrandingField.FieldColorFontIconsLeftSideMenuItems]: '#f0f0f0',
    [BrandingField.FieldColorBackgroundListArea]: '#ffffff',
    [BrandingField.FieldColorFontIconsListAreaItems]: '#2166b2',
    [BrandingField.FieldColorListAreaText]: '#282828',
    [BrandingField.FieldColorBackgroundDetailsPane]: '#f5f5f5',
  };
}
