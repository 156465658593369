import { Injectable } from '@angular/core';
import { ChannelService } from '@app/chat/services/channel.service';
import { ContactService } from '@app/contacts/services/contact.service';
import { WebsocketInit } from '@app/core/models/connection-status';
import { WsService } from '@app/core/services/ws.service';
import { ActiveMeeting, AttendeeEvent, MeetingEvent, MeetingStartedEvent } from '@app/meetings/models/chime.models';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import { BehaviorSubject } from 'rxjs';

/**
 * Service for vending information about currently active meetings. Provided observable is a single
 * object where each key-value pair is a channel id mapping to an ActiveMeeting object.
 */
@Injectable({
  providedIn: 'root',
})
export class ActiveMeetingService implements WebsocketInit {
  public readonly activeMeetingSubject = new BehaviorSubject<Map<string, ActiveMeeting>>(new Map());
  public readonly activeMeeting$ = this.activeMeetingSubject.asObservable();

  constructor(
    private webSocketService: WsService,
    private channelService: ChannelService,
    private contactService: ContactService,
    private notificationService: NotificationService
  ) {}

  bindSocketEvents() {
    this.webSocketService.socket.on('ActiveMeetings', (channelIds: string[]) => {
      // When the active meetings event occurs, merge with what we have in memory
      const currentActiveMeetings = this.activeMeetingSubject.getValue();
      const meetings = channelIds.map((channelId) => {
        return currentActiveMeetings.get(channelId) || ({ channelId, memberIds: new Set() } as ActiveMeeting);
      });

      const meetingMap = new Map<string, ActiveMeeting>();
      for (const meeting of meetings) {
        meetingMap.set(meeting.channelId, meeting);
      }
      this.activeMeetingSubject.next(meetingMap);
    });

    this.webSocketService.socket.on(
      'UsersInMeeting',
      ({ channelId, userIds }: { channelId: string; userIds: string[] }) => {
        const meetingMap = this.activeMeetingSubject.getValue();
        const meeting = meetingMap.get(channelId);
        if (meeting) {
          meetingMap.set(channelId, { ...meeting, memberIds: new Set(userIds) });
          this.activeMeetingSubject.next(meetingMap);
        }
      }
    );

    this.webSocketService.socket.on('MeetingStarted', ({ channelId, createdBy }: MeetingStartedEvent) => {
      const meetingMap = this.activeMeetingSubject.getValue();
      if (!meetingMap.get(channelId)) {
        meetingMap.set(channelId, { channelId, memberIds: new Set() });
        this.activeMeetingSubject.next(meetingMap);

        // Play sound effect if the meeting was started by a different user and
        // the channelId is for a conversation we have stored.
        const userStartedMeeting = createdBy === this.contactService.currentUser?.id;
        if (!userStartedMeeting && this.channelService.getCachedChannelWithId(channelId)) {
          this.notificationService.sendNotification(
            'You have been invited to a meeting.',
            'Meeting Started',
            NotificationKeyBackend['Group Chat Meeting Started'],
            false,
            this.channelService.getData().find((channel) => channel.channelArn.includes(channelId))?.channelArn
          );
        }
      }
    });

    this.webSocketService.socket.on('AttendeeJoined', ({ channelId, userId }: AttendeeEvent) => {
      const meetingMap = this.activeMeetingSubject.getValue();
      const meeting = meetingMap.get(channelId);
      if (meeting) {
        meeting.memberIds.add(userId);
      } else {
        meetingMap.set(channelId, { channelId, memberIds: new Set([userId]) });
      }
      const userJoinedMeeting = userId === this.contactService.currentUser?.id;
      const contact = this.contactService.contactById(userId);
      if (!userJoinedMeeting && this.channelService.getCachedChannelWithId(channelId)) {
        this.notificationService.sendNotification(
          `${contact ? contact.fullName : 'Someone'} has joined the meeting.`,
          'Meeting Joined',
          NotificationKeyBackend['Someone Joins a Group Chat Meeting'],
          false,
          this.channelService.getData().find((channel) => channel.channelArn.includes(channelId))?.channelArn
        );
      }
      this.activeMeetingSubject.next(meetingMap);
    });

    this.webSocketService.socket.on('AttendeeLeft', ({ channelId, userId }: AttendeeEvent) => {
      const meetingMap = this.activeMeetingSubject.getValue();
      const meeting = meetingMap.get(channelId);
      if (meeting) {
        meeting.memberIds.delete(userId);
        if (meeting.memberIds.size === 0) {
          meetingMap.delete(channelId);
        }
        this.activeMeetingSubject.next(meetingMap);
      }
    });

    this.webSocketService.socket.on('MeetingEnded', ({ channelId }: MeetingEvent) => {
      const meetingMap = this.activeMeetingSubject.getValue();
      const meeting = meetingMap.get(channelId);
      if (meeting) {
        meetingMap.delete(channelId);
        this.activeMeetingSubject.next(meetingMap);
      }
    });
  }

  public activeMeetingForChannelArn(channelArn: string): ActiveMeeting | undefined {
    const channelId = channelArn.split('/').pop();
    return channelId ? this.activeMeetingSubject.getValue().get(channelId) : undefined;
  }
}
