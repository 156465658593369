import moment from 'moment';

export function getFormattedDateFromPastDaysCount(daysCount: number): string {
  return moment().subtract(daysCount, 'd').format('YYYY-MM-DD');
}

export function getDaysDifferenceFromToday(formattedDate: string): number {
  return moment().diff(formattedDate, 'days');
}

export function convertDateToIsoDate(date: Date): string {
  return moment.utc(date).format('YYYY-MM-DD');
}

export function convertTimeMillisecondsToFormattedDate(timeMilliseconds: number): string {
  return moment.utc(timeMilliseconds).format('YYYY-MM-DD');
}

export function convertFormattedDateToTimeMilliseconds(formattedDate: string): number {
  return moment(formattedDate, 'YYYY-MM-DD').valueOf() / 1000;
}

export function getFormattedDateWithDecimalZulu(date: Date): string {
  return moment(date).utc(false).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
}
