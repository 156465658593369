import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Contact,
  ContactAction,
  ContactGrouping,
  ContactListHeaderLayoutMode,
  ContactListHeaderSearchOptions,
  ContactNumberSelectedEvent,
  ContactSort,
  ContactType,
} from '@app/contacts/models/contact';
import { ContactListComponent } from '@app/shared/components/contact-list/contact-list.component';
import { ContactListHeaderComponent } from '@app/shared/components/contact-list-header/contact-list-header.component';
import { ContactNumberMenuComponent } from '@app/shared/components/contact-number-menu/contact-number-menu.component';
import { SinglePhoneNumberPipe } from '@app/shared/pipes/single-phone-number.pipe';
import { NgLetModule } from 'ng-let';

@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    ContactListHeaderComponent,
    ContactListComponent,
    ContactNumberMenuComponent,
    MatTooltipModule,
    SinglePhoneNumberPipe,
    NgLetModule,
  ],
  selector: 'app-phone-contact-list',
  templateUrl: './phone-contact-list.component.html',
  styleUrls: ['./phone-contact-list.component.scss'],
})
/**
 * Represents a component for displaying a phone contact list.
 */
export class PhoneContactListComponent implements OnChanges {
  @Input() isTransferMode: boolean = false;
  @Input() isAddToCallMode: boolean = false;

  /**
   * Set this value to restrict any of the search options that are available in the contact header.
   * If this value is set, the UI will omit the filter options from appearing in the header and the contact
   * list will be displayed with the default options of `ContactType.All`, `ContactSort.FirstName`, and `ContactGrouping.Name`
   * unless otherwise specified here.
   */
  @Input() fixedContactListFilterOptions?: Partial<ContactListHeaderSearchOptions>;
  @Input() searchTerm = '';
  @Input() searchLabel?: string;
  @Input() headerLayoutMode: ContactListHeaderLayoutMode = 'standard';

  @Output() searchChanged = new EventEmitter<string>();
  @Output() contactNumberSelected = new EventEmitter<ContactNumberSelectedEvent>();
  @Output() forwardClicked = new EventEmitter<string>();

  protected isContactListFilteringEnabled = true;
  protected contactListFilterOptions: ContactListHeaderSearchOptions = {
    contactType: ContactType.All,
    contactSort: ContactSort.FirstName,
    contactGrouping: ContactGrouping.Name,
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fixedContactListFilterOptions) {
      // Merge with new fixed options.
      this.contactListFilterOptions = {
        ...this.contactListFilterOptions,
        ...this.fixedContactListFilterOptions,
      };
      this.isContactListFilteringEnabled = this.fixedContactListFilterOptions === undefined;
    }
  }

  protected onContactListFiltered(contactListFilterOptions: ContactListHeaderSearchOptions) {
    // Merge provided options with fixed options
    this.contactListFilterOptions = {
      ...contactListFilterOptions,
      ...this.fixedContactListFilterOptions,
    };
  }

  protected onSelectPhoneContact(selectedContact: Contact, selectedNumber?: string) {
    const number = selectedNumber || this.getPrimaryContactNumber(selectedContact);
    if (number) {
      this.contactNumberSelected.emit({
        action: ContactAction.SelectEvent,
        contact: selectedContact,
        selectedNumber: number,
      });
    }
  }

  protected onTransfer(destinationContact: Contact, destinationNumber: string) {
    this.contactNumberSelected.emit({
      action: ContactAction.TransferEvent,
      contact: destinationContact,
      selectedNumber: destinationNumber,
    });
  }

  protected onAttendedTransfer(destinationContact: Contact, destinationNumber: string) {
    this.contactNumberSelected.emit({
      action: ContactAction.AttendedTransferEvent,
      contact: destinationContact,
      selectedNumber: destinationNumber,
    });
  }

  protected onVoicemailTransfer(destinationContact: Contact, ext: string) {
    this.contactNumberSelected.emit({
      action: ContactAction.VoicemailTransferEvent,
      contact: destinationContact,
      selectedNumber: ext,
    });
  }

  protected onAddCall(contact: Contact) {
    const number = contact.tels[0]?.number || this.getPrimaryContactNumber(contact);
    if (number) {
      this.contactNumberSelected.emit({ action: ContactAction.AddToCallClickedEvent, contact, selectedNumber: number });
    }
  }

  protected onSearchChanged($event: string) {
    this.searchChanged.emit($event);
    this.searchTerm = $event;
  }

  protected onForwardClicked($event: string) {
    this.forwardClicked.emit($event);
  }

  /**
   * Returns the contact's extension, if set. Otherwise, the first number in `contact.tels` is returned.
   * @param contact The contact to get the primary number from.
   * @returns The primary number for the contact or undefined if no number is available.
   */
  private getPrimaryContactNumber(contact: Contact): string | undefined {
    return contact.ext ?? contact.tels[0]?.number;
  }
}
