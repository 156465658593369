<div class="answering-rule-container">
  <div class="content">
    <mat-icon class="drag-indicator"> drag_indicator </mat-icon>
    <div class="time-frame-label">
      {{ answeringRule.timeFrame === '*' ? 'Default' : answeringRule.timeFrame }}
    </div>
    <ng-container *ngLet="answeringRuleService.activeRuleName$ | async; let activeRule">
      <div
        *ngIf="
          answeringRule.enabled &&
          (activeRule === answeringRule.timeFrame || (activeRule === 'Default' && answeringRule.timeFrame === '*'))
        "
        class="active-label">
        Active
      </div>
    </ng-container>
    <div class="time-frame-info">
      {{ ruleTypeString }}
      <div *ngIf="answeringRule.forwardAlways.enabled">
        {{ answeringRule.forwardAlways.destination }}
      </div>
    </div>
    <div
      class="buttons"
      [ngClass]="{ disabled: disabled, editable: editable }">
      <mat-icon (click)="editRule()"> edit_outline </mat-icon>
      <mat-icon
        *ngIf="answeringRule.timeFrame !== '*' && appConfigService.features[AppFeature.ModifyAnsweringRules]"
        (click)="deleteRule()">
        delete_outline
      </mat-icon>
    </div>
    <mat-slide-toggle
      [checked]="answeringRule.enabled"
      [disabled]="disabled"
      (change)="stateChanged($event)">
    </mat-slide-toggle>
  </div>
</div>
