<div>
  <div
    *ngIf="contactService.loading$ | async"
    class="spinner-container">
    <mat-progress-bar></mat-progress-bar>
  </div>
  <div class="toolbar p-r-12 p-l-12">
    <app-list-actions
      *ngIf="selectedContacts.length > 0"
      [selectedCount]="selectedContacts.length"
      [itemsCount]="filteredContactsCount"
      (deleteOrRestoreItemEvent)="deleteBatch()"
      (clearEvent)="clearAllSelected()"
      (selectOptionEvent)="multiSelect($event)"
      [multiSelectOptions]="MultiSelectOptions"></app-list-actions>
  </div>
</div>
<div
  *ngIf="(contactService.hasError$ | async) === false; else secondary"
  class="list">
  <cdk-virtual-scroll-viewport
    variableSizeStrategy
    minBufferPx="500"
    maxBufferPx="1000"
    [itemHeights]="filteredListViewModels"
    [bufferPx]="listBufferPx"
    [ngStyle]="{ height: '100%' }">
    <div *ngIf="filteredListViewModels.length === 0">No matches found</div>

    <div *cdkVirtualFor="let viewModel of filteredListViewModels; trackBy: trackByViewModel">
      <div *ngIf="viewModel.type === 'groupHeader'">
        <div
          class="drop-down-container header"
          (click)="onToggleGroupCollapsed(viewModel.groupName)">
          <mat-icon>{{ collapsedGroups.get(viewModel.groupName) ? 'chevron_right' : 'expand_more' }}</mat-icon>
          <span>{{ viewModel.groupName }}</span>
        </div>
      </div>

      <ng-container *ngIf="viewModel.type === 'contact' && !collapsedGroups[viewModel.groupName]">
        <div
          data-cy="contacts-group"
          *ngLet="viewModel.contact as contact"
          [class.active]="selectedId === contact.id"
          class="contact-list-item-container list-item"
          (click)="onContactSelected(contact)"
          (dragenter)="handleDragEnterEvent(contact, $event)"
          (dragover)="handleDragOverEvent($event)"
          (dragleave)="handleDragLeaveEvent(contact, $event)"
          (drop)="handleDragDropEvent(contact, $event)">
          <div class="drag-drop-outlet-container">
            <ng-container
              [ngTemplateOutlet]="dragDropOutlet"
              [ngTemplateOutletContext]="{ selectedContact: contact }"></ng-container>
          </div>

          <div class="contact-list-item-details">
            <div class="coin-holder">
              <app-name-coin
                [contact]="contact"
                [type]="contact.type"></app-name-coin>
              <div
                *ngIf="
                  contactFilterOptions.contactType !== ContactType.All &&
                  contactFilterOptions.contactType !== ContactType.Company
                "
                (click)="toggleToDelete($event, contact)"
                [ngClass]="{ 'trashed d-block': contact.trash }"
                class="select-circle">
                <mat-icon>check-mark</mat-icon>
              </div>
            </div>
            <div class="details contact-label">
              <span
                data-cy="outgoing-call-contact-text"
                class="text-align-left"
                >{{ contact.fullName }} {{ contact.emoji }}</span
              >
              <div
                data-cy="contactStatus"
                class="text-align-left meta-text status-overflow">
                {{ contact.status }}
              </div>
            </div>
          </div>
          <div class="contact-list-icon">
            <ng-container
              [ngTemplateOutlet]="itemActions"
              [ngTemplateOutletContext]="{ selectedContact: contact }"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #secondary>
  <div>Something went wrong please reload the page...</div>
</ng-template>
