import { Preferences } from './preferences.model';

export enum SettingType {
  Profile = 'Profile',
  Audio = 'Audio',
  Video = 'Video',
  AnsweringRules = 'Answering Rules',
  BlockedNumbers = 'Blocked Numbers',
  Contacts = 'Contacts',
  MusicOnHold = 'Music on Hold',
  Notifications = 'Notifications',
  Voicemail = 'Voicemail',
  Integrations = 'Integrations',
}

export type DNDMode = 'DND' | 'Calls' | 'Notifications' | 'CallsAndNotifications';

export interface Settings {
  notifications: Record<NotificationKeyBackend, NotificationSettingsBackend>;
  preferences: Preferences;
}

export enum NotificationKeyBackend {
  Chat = 'chatNotifications',
  SMS = 'smsNotifications',
  'Incoming Fax' = 'faxNotifications',
  'Incoming Call' = 'callNotifications',
  'Incoming Second Call' = 'secondCallNotifications',
  'Missed Call' = 'missedCallNotifications',
  'Group Chat Meeting Started' = 'groupMeetingStartedNotifications',
  'Someone Joins a Group Chat Meeting' = 'meetingJoinedNotifications',
  'Incoming Voicemail' = 'voicemailNotifications',
}

export enum SoundAssets {
  AlertHighIntensity = 'alert_high-intensity',
  NavigationBackwards = 'navigation_backward-selection-minimal',
  NotificationHighIntensity = 'notification_high-intensity',
  RingtoneIncomingCall = 'ringtone-incoming-call',
  RingtoneIncomingCallWaiting = 'ringtone-incoming-call-waiting',
  RingtoneMinimal = 'ringtone_minimal',
  RingtoneGentle = 'alarm_gentle',
  AlertSimple = 'alert_simple',
  NotificationAmbient = 'notification_ambient',
  NotificationDecorative2 = 'notification_decorative-02',
  NotificationDecorative1 = 'notification_decorative-01',
  NotificationSimple1 = 'notification_simple-01',
  NotificationSimple2 = 'notification_simple-02',
  HeroSimpleCelebration1 = 'hero_simple-celebration-01',
  HeroSimpleCelebration2 = 'hero_simple-celebration-02',
  HeroSimpleCelebration3 = 'hero_simple-celebration-03',
  HeroDecorativeCelebration1 = 'hero_decorative-celebration-01',
  HeroDecorativeCelebration2 = 'hero_decorative-celebration-02',
  HeroDecorativeCelebration3 = 'hero_decorative-celebration-03',
}

export enum NotificationType {
  Chat = 'Chat',
  SMS = 'SMS',
  IncomingFax = 'Incoming Fax',
  IncomingCall = 'Incoming Call',
  IncomingSecondCall = 'Incoming Second Call',
  MissedCall = 'Missed Call',
  GroupMeetingStarted = 'Group Chat Meeting Started',
  MeetingJoined = 'Someone Joins a Group Chat Meeting',
  IncomingVoicemail = 'Incoming Voicemail',
}

export const defaultNotificationSettings: Record<NotificationKeyBackend, NotificationSettingsBackend> = {
  [NotificationKeyBackend.Chat]: {
    ringtone: SoundAssets.NavigationBackwards,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend.SMS]: {
    ringtone: SoundAssets.NavigationBackwards,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Incoming Fax']]: {
    ringtone: SoundAssets.AlertHighIntensity,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Incoming Call']]: {
    ringtone: SoundAssets.RingtoneIncomingCall,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Incoming Second Call']]: {
    ringtone: SoundAssets.RingtoneIncomingCallWaiting,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Missed Call']]: {
    ringtone: SoundAssets.NotificationDecorative2,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Group Chat Meeting Started']]: {
    ringtone: SoundAssets.NotificationDecorative2,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Someone Joins a Group Chat Meeting']]: {
    ringtone: SoundAssets.NotificationSimple1,
    notificationSound: true,
    showNotification: true,
    showInForeground: true,
  },
  [NotificationKeyBackend['Incoming Voicemail']]: {
    ringtone: SoundAssets.AlertHighIntensity,
    notificationSound: true,
    showNotification: true,
    showInForeground: false,
  },
};

export const defaultPreferences: Preferences = {
  doNotDisturb: false,
  blockAnonymous: false,
  disableNotifications: false,
  disableCalls: false,
  hourFormat: '24',
  timezone: 'America/New_York',
  notifications: defaultNotificationSettings,
  calling: {
    disableContactMatching: false,
    showCallsAnswElse: false,
  },
  audio: {
    quietRinging: false,
    speakerDevice: '',
    notificationSpeakerDevice: '',
    microphoneDevice: '',
    ringingSoundVolume: 50,
    notificationSoundVolume: 50,
  },
  video: {
    cameraDevice: '',
    mirrorLocalVideo: false,
    cameraOn: false,
    blurBackground: false,
  },
  callCenter: {
    showStats: false,
    showGraphs: false,
    showQueues: false,
    showCallHistory: false,
    showCalls: false,
    loginMethod: null,
  },
  attendantConsole: {
    calls: false,
    contacts: false,
    parking: false,
    callQueues: false,
    callHistory: false,
    activeCalls: false,
    autoAttendant: false,
    isOnline: false,
  },
  contactFilter: null,
  answeringRules: {
    returnBusy: false,
    autoAnswerIncomingCalls: false,
    autoAnswerTime: 4,
  },
};

export interface NotificationSettingsBackend {
  ringtone: string;
  notificationSound: boolean;
  showNotification: boolean;
  showInForeground: boolean;
}

export interface NotificationItems {
  title: NotificationType;
  notificationSound: boolean;
  showNotification: boolean;
  showInForeground: boolean;
  selectLabel: string;
  ringtone: string;
  selectOptions: Tones[];
}

export enum CallNotificationOptions {
  Answer = 'Answer',
  Decline = 'Decline',
}

export interface Tones {
  title: string;
  ringtone: SoundAssets;
}

export interface AudioSettings {
  quietRinging: boolean;
  speakerDevice: string;
  notificationSpeakerDevice: string;
  microphoneDevice: string;
  ringingSoundVolume: number;
  notificationSoundVolume: number;
}

export interface VideoSettings {
  cameraDevice?: string;
  mirrorLocalVideo: boolean;
  cameraOn: boolean;
  blurBackground: boolean;
}

export interface CallingSettings {
  disableContactMatching?: boolean;
  showCallsAnswElse?: boolean;
  showMissedQueueCalls?: boolean;
}

export interface CallCenterSettings {
  showStats: boolean;
  showGraphs: boolean;
  showQueues: boolean;
  showCallHistory: boolean;
  showCalls: boolean;
  loginMethod: string | null;
  enableAgentMessage?: boolean;
  screenPopUrl?: string | null;
}

export interface AutoAnsweringRulesSettings {
  returnBusy?: boolean;
  autoAnswerIncomingCalls?: boolean;
  autoAnswerTime?: number;
}

export interface AttendantConsoleSettings {
  calls: boolean;
  contacts: boolean;
  parking: boolean;
  callQueues: boolean;
  callHistory: boolean;
  activeCalls: boolean;
  autoAttendant: boolean;
  isOnline: boolean;
}

export interface MohSettings {
  enableMoh: boolean;
  randomizeMoh: boolean;
  greetingType: string;
  greetingMessage: string;
  greetingLanguage: string;
  greetingVoice: string;
  greetingIndex: number;
}

export interface MohLanguages {
  id: string;
  country: string;
  name: string;
  nativeName: string;
}

export interface TTSSettings {
  description: string;
  voice_id: string;
  voice_language: string;
  tts_script: string;
  source_type: string;
}

export enum AudioType {
  moh = 'moh',
  greeting = 'greeting',
}

export interface GreetingAudioList {
  description: string;
  duration: string;
  filename: string;
  index: number;
  mediaUrl: string;
  size: string;
  sortOrder: number;
}

export interface MohAudioList {
  description: string;
  duration: string;
  filename: string;
  index: number;
  mediaUrl: string;
  size: string;
  sortOrder: number;
  edit: boolean;
  descriptionEdit: string;
  changed: boolean;
}

export interface VoiceOptions {
  gender: string;
  id: string;
  languageCode: string;
  name: string;
  subType: string;
  type: string;
}
