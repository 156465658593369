import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  public readonly audioContext = new AudioContext();
  private isAudioSuspended = new BehaviorSubject<boolean>(this.audioContext.state === 'suspended');

  async resumeAudioContext() {
    console.log('audio-permission: resuming audio context for low MEI score...');
    await this.audioContext.resume();
    console.log(
      `audio-permission: resumed audio context for low MEI score. Context state = ${this.audioContext.state}`
    );
    this.isAudioSuspended.next(this.audioContext.state === 'suspended');
  }

  isAudioSuspend(): boolean {
    return this.isAudioSuspended.value;
  }
}
