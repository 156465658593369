import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { validatePhoneNumber } from '@app/shared/utils/phone.util';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-blocked-numbers',
  templateUrl: './blocked-numbers.component.html',
  styleUrls: ['./blocked-numbers.component.scss'],
})
export class BlockedNumbersComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('greyMask') greyMask?: ElementRef<HTMLDivElement>;

  protected editingDisabled = true;

  blockedNumbers: string[] = [];
  allowedNumbers: string[] = [];
  formGroup: FormGroup;

  constructor(private appConfigService: AppConfigService, private fb: FormBuilder) {}

  async ngOnInit() {
    this.editingDisabled = !this.appConfigService.features[AppFeature.ModifyBlockedNumbers];

    this.createFormGroup();
    await this.getBlockedNumbers();
    await this.getAllowedNumbers();
    await this.getPreferences();
  }

  ngAfterViewInit(): void {
    if (this.editingDisabled) {
      // pass scroll events from grey mask to main container
      this.greyMask?.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
        this.container.nativeElement.scrollBy(event.deltaX, event.deltaY);
      });

      // block mouse and keyboard events
      this.container.nativeElement.addEventListener('click', this.stopAndPreventDefault, true);
      this.container.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key !== 'Escape') {
          this.stopAndPreventDefault(event);
        }
      });
    }
  }

  private stopAndPreventDefault(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private createFormGroup() {
    this.formGroup = this.fb.group({
      blockedNumber: ['', [Validators.required, validatePhoneNumber]],
      allowedNumber: ['', [Validators.required, validatePhoneNumber]],
      blockAnonymous: [false],
    });
  }

  private async getBlockedNumbers() {
    const response = await firstValueFrom(this.appConfigService.getBlockedNumbers());
    this.blockedNumbers = response.numbers;
  }

  private async getAllowedNumbers() {
    const response = await firstValueFrom(this.appConfigService.getAllowedNumbers());
    this.allowedNumbers = response.numbers;
  }

  private async getPreferences() {
    const response = await firstValueFrom(this.appConfigService.getPreferences());
    this.formGroup.controls['blockAnonymous'].patchValue(response.blockAnonymous || false);
  }

  async addBlockedNumbers() {
    if (this.formGroup.controls['blockedNumber'].invalid) {
      return;
    }
    await firstValueFrom(this.appConfigService.setBlockedNumbers(this.formGroup.controls['blockedNumber'].value));
    this.blockedNumbers.push(this.formGroup.controls['blockedNumber'].value);
    this.formGroup.controls['blockedNumber'].patchValue('');
    this.formGroup.controls['blockedNumber'].setErrors(null);
  }

  async removeBlockedNumber(number: string) {
    await firstValueFrom(this.appConfigService.deleteBlockedNumbers(number));
    this.blockedNumbers = this.blockedNumbers.filter((item) => item !== number);
  }

  async addAllowedNumbers() {
    if (this.formGroup.controls['allowedNumber'].invalid) {
      return;
    }
    await firstValueFrom(this.appConfigService.setAllowedNumbers(this.formGroup.controls['allowedNumber'].value));
    this.allowedNumbers.push(this.formGroup.controls['allowedNumber'].value);
    this.formGroup.controls['allowedNumber'].patchValue('');
    this.formGroup.controls['allowedNumber'].setErrors(null);
  }

  async removeAllowedNumber(number: string) {
    await firstValueFrom(this.appConfigService.deleteAllowedNumbers(number));
    this.allowedNumbers = this.allowedNumbers.filter((item) => item !== number);
  }

  async onCheckChangeBlockAnonymous() {
    await firstValueFrom(
      this.appConfigService.updatePreferences({ blockAnonymous: this.formGroup.controls['blockAnonymous'].value })
    );
  }
}
