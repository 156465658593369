import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { CallNotificationOptions } from '@app/preferences/models/settings.models';
import { checkUpdate } from '@app/shared/utils/update.util';
import { filter, Subscription } from 'rxjs';

import { authLogoutUrl, AuthService } from './auth/services/auth.service';
import { EventName } from './core/models/event-bus.models';
import { CustomIconsService } from './core/services/custom-icons.service';
import { EventBusService } from './core/services/event-bus.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { PwaService } from './core/services/pwa.service';
import { WsService } from './core/services/ws.service';
import { PhoneService } from './phone/services/phone.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  logoutEvenSub?: Subscription;

  constructor(
    private authService: AuthService,
    private eventBusService: EventBusService,
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    protected wsService: WsService,
    private phoneService: PhoneService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private ngZone: NgZone,
    private customIconsService: CustomIconsService,
    private pwaService: PwaService
  ) {}

  async ngOnInit() {
    this.pwaService.listenForInstallPrompt();
    this.customIconsService.registerIcons();
    if ('launchQueue' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.launchQueue.setConsumer((launchParams) => {
        if (launchParams.targetURL.toString().includes('tel')) {
          const params = launchParams.targetURL.toString().split('/');
          if (params.length > 0) {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/calls/history/' + params[params.length - 1]);
            });
          }
        }
      });
    }
    this.checkPopupPermission();
    // (window as any).isCypress = true;

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.googleAnalyticsService.pageView(event);
    });

    // Listens for an event for when the user logouts
    // previously was in main modules ViewComponent
    this.logoutEvenSub = this.eventBusService.on(EventName.Logout, () => {
      this.wsService.disconnect();
      this.dialog.closeAll();
      this.phoneService.dispose();
      this.authService.clearAccess();
      document.location.href = authLogoutUrl;
    });

    await checkUpdate(this.swUpdate);

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data.action === CallNotificationOptions.Answer) {
        this.phoneService.answerIncomingCall(event.data.callId);
      } else if (event.data.action === CallNotificationOptions.Decline) {
        this.phoneService.declineIncomingCall(event.data.callId);
      }
    });
  }

  private checkPopupPermission() {
    const newWin = window.open(
      'https://connectuc.io',
      `popoutWindow${Date.now()}`,
      `width=1px,height=1px,left=1000,top=10000,location=10`
    );
    newWin?.moveTo(0, window.screen.availHeight + 10);
    newWin?.close();
  }

  ngOnDestroy(): void {
    this.logoutEvenSub?.unsubscribe();
  }
}
