<section>
  <form [formGroup]="pinForm" class="h-100 flex flex-col">
    <div class="pin-top">
      <div class="flex align-items-center greeting-title">
        <button mat-icon-button class="back-button" (click)="setSelectedPage('Main')">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <h1 class="title mb-0">Voicemail Pin</h1>
      </div>
      <div class="section">
        <div class="content flex flex-col">
          <mat-form-field appearance="outline" class="density-minus-3 w-100">
            <mat-label>New PIN</mat-label>
            <input autocomplete="off" type="password" appOnlyNumberInput matInput formControlName="pin" [type]="isPinHide ? 'password' : 'text'"/>
            <mat-icon matSuffix color="primary" (click)="toggleIsPinHide()">{{isPinHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <mat-error class="mb-1" *ngIf="(pinForm.controls.pin.value.length < 7 || pinForm.controls.pin.value.length > 10) && (pinForm.controls.pin.dirty ||
                         pinForm.controls.pin.touched)">Should be between 7 - 10 digits</mat-error>
          <mat-form-field appearance="outline" class="density-minus-3 w-100">
            <mat-label>Confirm PIN</mat-label>
            <input autocomplete="off" type="password" appOnlyNumberInput matInput formControlName="confirmPin" [type]="isPinHide ? 'password' : 'text'"/>
            <mat-icon matSuffix color="primary" (click)="toggleIsPinHide()">{{isPinHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
          <mat-error *ngIf="pinForm.controls.confirmPin.errors?.confirmedValidator && (pinForm.controls.confirmPin.dirty ||
                         pinForm.controls.confirmPin.touched)">PINs do not match.</mat-error>
          <mat-error *ngIf="(pinForm.controls.confirmPin.value.length < 7 || pinForm.controls.confirmPin.value.length > 10) && (pinForm.controls.confirmPin.dirty ||
                         pinForm.controls.confirmPin.touched)">Should be between 7 - 10 digits</mat-error>
        </div>
      </div>
    </div>
    <div
      class="flex justify-content-end align-center bottom-bar mt-auto">
      <button
        class="cancel-button"
        mat-button
        type="button" (click)="setSelectedPage('Main')">
        Cancel
      </button>
      <button
        color="primary"
        mat-raised-button
        class="m-l-12 save-button"
        type="button" [disabled]="!pinForm.valid" (click)="save()">
        <span *ngIf="!processing; else spinner">Save</span>
        <ng-template #spinner>
          <mat-spinner class="processing-spinner" color="accent"></mat-spinner>
        </ng-template>
      </button>
    </div>
  </form>
</section>
