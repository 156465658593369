import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppFeature } from '@app/core/models/config.models';
import { ApiService } from '@app/core/services/api.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom } from 'rxjs';

import { DialogMessageComponent } from '../components/dialog-message/dialog-message.component';
import { Call, CallId } from '../models/call.model';
import { SessionStatus } from '../models/phone.models';
import { CallRecordingAction, RecordingState } from '../models/recording-call.model';
import { SipjsService } from './sipjs.service';

/**
 * Service for managing the recording of calls. This service is responsible for starting, pausing and resuming, and stopping.
 * It also tracks the current recording state of each call.
 *
 * This service also manages the automatic recording feature. When enabled, calls will automatically start recording when answered.
 * This is accomplished by listening to the SessionStatus of each call and starting recording when the call is answered.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RecordingService extends ApiService {
  /** In milliseconds */
  public static readonly AutomaticRecordingResumeDuration = 60 * 1000;

  protected override baseUrl = 'users/{me}/recordings';
  private readonly callRecordingStateSubject = new BehaviorSubject<RecordingService['callRecordingStateMap']>(
    new Map()
  );
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);

  /**
   * Subject that tracks the number of outstanding http requests being made. Since this serice
   * may be used to record multiple calls at once, we need to track the number of requests being made
   * so we can show a loading spinner when requests are in progress.
   */
  private readonly httpRequestCountSubject = new BehaviorSubject<number>(0);

  /**
   * Observable that emits any time the state of a call recording changes. This emits a map
   * containing all call recording states so it's possible an event will be
   * emitted for a call that you're not currently interested in. If so, use `distinctUntilChanged`
   * to filter state you don't care about.
   */
  public readonly callRecordingState$ = this.callRecordingStateSubject.asObservable();
  public readonly loading$ = this.loadingSubject.asObservable().pipe(distinctUntilChanged());

  private readonly callRecordingStateMap = new Map<CallId, RecordingState>(); // [callId, RecordingState]
  private readonly callWasRecordedMap = new Map<CallId, boolean>(); // [callId, wasRecorded]
  private automaticResumeRecordingTimerMap = new Map<CallId, NodeJS.Timeout>(); // [callId, timer]

  private _isAutomaticRecordingEnabled = false;

  // ========== Getters / Setters ==========

  public get isAutomaticRecordingEnabled(): boolean {
    return this._isAutomaticRecordingEnabled;
  }

  public get isRecordingPlaybackFeatureEnabled(): boolean {
    return this.configService.features[AppFeature.CallRecordingPlayback];
  }

  public get isRecordingOnDemandFeatureEnabled(): boolean {
    return this.configService.features[AppFeature.OnDemandRecording];
  }

  public get isPauseRecordingFeatureEnabled(): boolean {
    return this.configService.features[AppFeature.CallRecordingPause];
  }

  private get hasPresentedCallRecordingDialog(): boolean {
    return this.localStorageService.get<boolean>('didPresentCallRecordingDialog') || false;
  }

  private set hasPresentedCallRecordingDialog(value: boolean) {
    this.localStorageService.set('didPresentCallRecordingDialog', value);
  }

  // ========== Lifecycle ==========

  constructor(
    httpClient: HttpClient,
    private configService: AppConfigService,
    private sipJsService: SipjsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService,
    private matDialog: MatDialog
  ) {
    super(httpClient);

    this.fetchAutomaticRecordingEnabled().then((result) => {
      this._isAutomaticRecordingEnabled = result;
    });

    this.httpRequestCountSubject
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe((count) => {
        this.loadingSubject.next(count > 0);
      });

    // Observe SessionStatus changes from SipjsService. For each session, track the current recording state.
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(async ({ call, status }) => {
      switch (status) {
        case SessionStatus.Created: {
          this.updateRecordingState(call.id, RecordingState.NotRecording);
          break;
        }
        case SessionStatus.Answered: {
          // Update the answering state only the first time the call is answered. That ensures we don't accidentally
          // change state when transitioning from hold to answered.
          if (this.getAnsweredStatusCountForCall(call) === 1) {
            this.updateRecordingState(
              call.id,
              this.isAutomaticRecordingEnabled ? RecordingState.Recording : RecordingState.NotRecording
            );
          }
          break;
        }
        case SessionStatus.Hangup: {
          clearTimeout(this.automaticResumeRecordingTimerMap.get(call.id));
          this.automaticResumeRecordingTimerMap.delete(call.id);

          this.updateRecordingState(call.id, RecordingState.NotRecording);

          // If the call was recorded, show the call recording dialog.
          if (!this.hasPresentedCallRecordingDialog && this.wasCallRecorded(call.id)) {
            this.matDialog.open(DialogMessageComponent, {
              disableClose: true,
              data: { message: 'Call recordings will be available in call history when they are ready.' },
            });
            this.hasPresentedCallRecordingDialog = true;
          }

          break;
        }
        case SessionStatus.Destroy: {
          this.callRecordingStateMap.delete(call.id);
          this.callRecordingStateSubject.next(new Map(this.callRecordingStateMap));
          break;
        }
      }
    });
  }

  // ========== Recording Methods ==========

  public async startRecording(callId: CallId) {
    await this.postRecordAction(CallRecordingAction.START, callId);
  }

  public async pauseRecording(callId: CallId) {
    if (!this.wasCallRecorded(callId)) {
      console.debug(`Ignoring pausing recording for call ${callId} since it was not started`);
      return;
    }

    // If a call is paused that has automatic recording enabled, start a timer
    // that when fired, will automatically restart call recording.
    if (this._isAutomaticRecordingEnabled) {
      clearTimeout(this.automaticResumeRecordingTimerMap.get(callId));

      const timer = setTimeout(async () => {
        this.automaticResumeRecordingTimerMap.delete(callId);
        await this.resumeRecording(callId);
      }, RecordingService.AutomaticRecordingResumeDuration);
      this.automaticResumeRecordingTimerMap.set(callId, timer);
    }
    await this.postRecordAction(CallRecordingAction.PAUSE, callId);
  }

  public async resumeRecording(callId: CallId) {
    if (!this.wasCallRecorded(callId)) {
      console.debug(`Ignoring resuming recording for call ${callId} since it was not started.`);
      return;
    }

    clearTimeout(this.automaticResumeRecordingTimerMap.get(callId));
    await this.postRecordAction(CallRecordingAction.RESUME, callId);
  }

  public async stopRecording(callId: CallId) {
    if (!this.wasCallRecorded(callId)) {
      console.debug(`Ignoring stopping recording for call ${callId} since it was not started.`);
      return;
    }

    clearTimeout(this.automaticResumeRecordingTimerMap.get(callId));
    await this.postRecordAction(CallRecordingAction.STOP, callId);
  }

  private async postRecordAction(action: CallRecordingAction, callId: CallId): Promise<boolean> {
    //When a call starts recording show that on the Google Analytics dashboard (we don't need events when paused/resumed/stopped)
    if (action == CallRecordingAction.START) {
      this.googleAnalyticsService.callRecording();
    }

    try {
      this.incrementHttpRequestCount();

      const normalizedCallId = this.sipJsService.getNormalizedCallId(callId);
      const result = await firstValueFrom(
        this.post<{ success: boolean }>('manage', {
          action,
          orig_callid: normalizedCallId,
        })
      );

      const success = result.success;
      if (success) {
        switch (action) {
          case CallRecordingAction.START: {
            this.updateRecordingState(callId, RecordingState.Recording);
            break;
          }
          case CallRecordingAction.PAUSE: {
            this.updateRecordingState(callId, RecordingState.Paused);
            break;
          }
          case CallRecordingAction.RESUME: {
            this.updateRecordingState(callId, RecordingState.Recording);
            break;
          }
          case CallRecordingAction.STOP: {
            this.updateRecordingState(callId, RecordingState.NotRecording);
            break;
          }
        }
      }
      return result.success;
    } catch {
      return false;
    } finally {
      this.decrementHttpRequestCount();
    }
  }

  private async fetchAutomaticRecordingEnabled(): Promise<boolean> {
    try {
      this.incrementHttpRequestCount();

      const result = await firstValueFrom(this.get<{ enabled: boolean }>('auto-recording'));
      return result.enabled;
    } catch {
      return false;
    } finally {
      this.decrementHttpRequestCount();
    }
  }

  // ========== Helper Methods ==========

  public isCallRecording(callId: CallId): boolean {
    return this.callRecordingStateMap.get(callId) === RecordingState.Recording;
  }

  public wasCallRecorded(callId: CallId): boolean {
    return this.callWasRecordedMap.get(callId) || false;
  }

  private updateRecordingState(callId: CallId, state: RecordingState) {
    this.callRecordingStateMap.set(callId, state);
    if (state === RecordingState.Recording) {
      this.callWasRecordedMap.set(callId, true);
    }
    this.callRecordingStateSubject.next(new Map(this.callRecordingStateMap));
  }

  private getAnsweredStatusCountForCall(call: Call): number {
    return call.statusHistory.filter((s) => s === SessionStatus.Answered).length;
  }

  private incrementHttpRequestCount() {
    this.httpRequestCountSubject.next(this.httpRequestCountSubject.value + 1);
  }

  private decrementHttpRequestCount() {
    this.httpRequestCountSubject.next(this.httpRequestCountSubject.value - 1);
  }
}
