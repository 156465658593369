export enum Device {
  UNKNOWN = 'unknown',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  WEB = 'web',
}

export enum MultiSelectOption {
  All = 'All',
  None = 'None',
  Read = 'Read',
  Unread = 'Unread',
}

export interface SelectableItem {
  trash?: boolean;
  read?: boolean;
}
