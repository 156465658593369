<div [class.transfer-menu-holder]="useCustomMenuStyling">
  <span
    class="transfer-label"
    *ngIf="title"
    >{{ title }} to:</span
  >
  <div
    class="dropdown-menu-item"
    *ngFor="let tel of selectedContact.tels"
    (click)="handleNumberSelected(tel.number)">
    <span class="contact-numbers">{{ tel.type | titlecase }} - {{ tel.number | phoneNumber }}</span>
  </div>
  <div
    *ngIf="selectedContact.ext"
    class="dropdown-menu-item"
    (click)="handleNumberSelected(selectedContact.ext)">
    <span class="contact-numbers"> {{ 'Extension - ' + selectedContact.ext }}</span>
  </div>
</div>
