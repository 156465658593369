export type SMSMessageDisposition = 'sent' | 'failed' | 'delivered';

export interface SMSConversation {
  id: string;
  conversationId: string;
  local: string;
  remotes: string[];
  unreadMessageCount: number;
  lastMessageTimestamp: string;
  lastMessage?: SMSMessage;
  lastReadTimestamp: string;
  trash?: boolean;
  read?: boolean;
  optStatus?: OptStatus;
}

export interface SMSMessageMedia {
  url: string;
  type?: string;
  length: number;
  key?: string; // encryption key provided if message encrypted via ReachUC
  fileName: string;
}

export interface SMSMessage {
  conversationId: string;
  messageId: string;
  type?: 'notification' | 'message';

  /**
   * Server property that we don't care about on the frontend other than for assisting the backend with deduplication.
   * See https://skyswitch.atlassian.net/browse/CUC-1585 for more information.
   */
  referenceId: string;

  /** ContactId of the sender. This is useful for SharedSMS where multiple users may be sending messages from a single DID */
  originatorId?: string;

  direction: 'incoming' | 'outgoing';
  sender: string;
  recipients: string[];
  content?: string;
  createdTimestamp: string;
  updatedTimestamp: string;
  sentTimestamp?: string;
  deliveredTimestamp?: string;
  disposition?: SMSMessageDisposition;
  readTimestamp?: string;
  media?: SMSMessageMedia[];
  metadata?: {
    status?: string;
  };
  notification?: NotificationEvent;
}

export interface NotificationEvent {
  type: string;
}

export const STREAM_UPDATE_TYPE = 'streamupdate' as const;
export interface StreamUpdateNotificationEvent extends NotificationEvent {
  type: typeof STREAM_UPDATE_TYPE;
  streamid: string;
  event: OptStatusType;
}

export interface OptStatus {
  campaignId: string;
  remotes: {
    [key: string]: OptStatusType;
  };
}

export enum OptStatusType {
  OptNone = 'opt-none', // no opt status (new remote)
  OptIn = 'opt-in', // explicitly opted-in
  OptOut = 'opt-out', // explicitly opted-out
  OptPending = 'opt-pending', // consent requested but not yet received
  OptInImplied = 'opt-in-implied', // implicitly opted-in by remote starting conversation
  OptInOverride = 'opt-in-override', // implicitly opted in by partner override (external consent)
  OptInInternal = 'opt-in-internal', // implicitly opted-in by virtue of being an on-network number
  OptInExempt = 'opt-in-exempt', // implicitly opted-in by virtue of being exempt (international numbers, etc)
}

export interface SMSMessageDispositionUpdatedPayload {
  conversationId: string;
  messageId: string;
  status: SMSMessageDisposition;
}

export const AllLocalNumber: LocalNumber = {
  number: 'all',
  priority: '0',
  createdTimestamp: new Date().toString(),
};

export interface LocalNumber {
  number: string;
  priority: string;
  description?: string;
  ownerUuid?: string;
  createdTimestamp: string;
  shared?: boolean;
  sharedUsers?: string[];
}

export interface ListSMSMessagesResponse {
  messages: SMSMessage[];
  nextToken?: string;
}

export interface ListSMSNumbersResponse {
  numbers: LocalNumber[];
  sharedNumbers: LocalNumber[];
}

export interface Attachment {
  file: File;
  remoteUrl?: string;
  localId: string; // id created locally for the attachment that we can use for updating it via lookup instead of by index
}

export interface UploadedAttachment {
  url: string;
  type?: string;
  fileName: string;
  length: number;
}

export interface PresignedMediaReponse {
  presignedUrl: string;
  publicUrl: string;
  headers?: {
    [key: string]: string;
  };
}

export interface DraftMessageContent {
  content: string;
  media: UploadedAttachment[];
}

export interface RejectedFileAttachment {
  file: File;
  reason: 'Size Limit' | 'Invalid Type' | 'Count Limit';
}

export type AttachmentType = 'image' | 'audio' | 'video' | 'document';

export interface UnreadCount {
  [key: string]: number;
}
