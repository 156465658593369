<div
  *ngIf="!image" [ngClass]="{'profile-container-edition':!isContactPage}">
  <div class="contact-container">
    <div class="contact-header">
      <h1 class="header-topic">{{ title }}</h1>
      <mat-icon
        *ngIf="isContactPage"
        class="close-icon"
        (click)="close()">
        close
      </mat-icon>
    </div>
    <div class="contact-form">
      <div class="contact flex-row">
        <div class="middleCoin">
          <img
            *ngIf="contact?.avatar; let avatar"
            alt="avatar"
            [src]="avatar"/>
          <span *ngIf="!contact?.avatar && contact?.coin">{{ contact?.coin }}</span>
        </div>
        <div class="flex-col header-space">
          <h1 class="header-fullName">
            {{ contact?.fullName }}
          </h1>
          <input
            data-cy="contact-input-file"
            #fileInput
            appAvatar
            (changeEvent)="changeInputProfilePhoto($event)"/>

          <button
            class="button-contact"
            (click)="addProfilePhoto()">
            Upload profile photo
          </button>
        </div>
      </div>
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="contactForm"
        [monitorChanges]="true"
        #form="monitorChanges"
        class="form-space form-add-contact">
        <div class="row items-holder">
          <mat-form-field appearance="outline" class="density-minus-3">
            <mat-label>First Name</mat-label>
            <input
              autocomplete="off"
              formControlName="firstName"
              matInput
              placeholder="First Name"/>
          </mat-form-field>
          <mat-form-field appearance="outline" class="density-minus-3">
            <mat-label>Last Name</mat-label>
            <input
              autocomplete="off"
              formControlName="lastName"
              matInput
              placeholder="Last Name"/>
          </mat-form-field>
        </div>
        <div class="row items-holder">
          <mat-form-field
            appearance="outline"
            class="full density-minus-3">
            <mat-label>Company</mat-label>
            <input
              autocomplete="off"
              formControlName="company"
              matInput
              placeholder="Company"/>
          </mat-form-field>
        </div>
        <div class="row items-holder">
          <mat-form-field
            appearance="outline"
            class="full density-minus-3">
            <mat-label>Title</mat-label>
            <input
              autocomplete="off"
              formControlName="title"
              matInput
              placeholder="Title"/>
          </mat-form-field>
        </div>
        <div class="row form-space">
          <h4 class="header-label m-t-8">Phone
            <i
              *ngIf="!isContactPage"
              (click)="addTel()"
              class="edit">
              <mat-icon>add</mat-icon>
              Add New Phone
            </i>
          </h4>
          <ng-container formArrayName="tels">
            <div
              *ngFor="let _ of tels.controls; index as i"
              class="repeater">
              <ng-container [formGroupName]="i">
                <mat-form-field
                  appearance="outline"
                  class="type density-minus-3">
                  <mat-label>Type</mat-label>
                  <mat-select
                    formControlName="type"
                    (selectionChange)="extensionTypeChanged($event, i)">
                    <mat-option [value]="ExtensionTypes.Work">Work</mat-option>
                    <mat-option [value]="ExtensionTypes.Home">Home</mat-option>
                    <mat-option [value]="ExtensionTypes.Cell">Cell</mat-option>
                    <mat-option [value]="ExtensionTypes.Fax">Fax</mat-option>
                    <mat-option *ngIf="tels.controls[i].disabled" [value]="ExtensionTypes.PbxSms">PBX-SMS</mat-option>
                    <mat-option [value]="ExtensionTypes.SMS">SMS</mat-option>
                    <mat-option [value]="ExtensionTypes.Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="number density-minus-3">
                  <mat-label>Number</mat-label>
                  <input
                    autocomplete="off"
                    formControlName="number"
                    matInput
                    placeholder="Number"
                    (blur)="formatPhoneNumber(i)"
                  />
                </mat-form-field>
              </ng-container>
              <span
                (click)="removeTel(i)"
                *ngIf="!tels.controls[i].disabled"
                class="remove"
              ><mat-icon>close</mat-icon></span
              >
            </div>
          </ng-container>
          <i
            data-cy="contact-add-phone"
            *ngIf="isContactPage"
            (click)="addTel()"
            class="edit">
            <mat-icon>add</mat-icon>
            Add Phone
          </i>
        </div>
        <div class="row form-space">
          <h4 class="header-label">Emails
            <i
              *ngIf="!isContactPage"
              (click)="addEmail()"
              class="edit">
              <mat-icon>add</mat-icon>
              Add New Email
            </i>
          </h4>
          <ng-container formArrayName="emails">
            <div
              *ngFor="let emailControl of emails.controls; index as i"
              class="repeater">
              <ng-container [formGroupName]="i">
                <mat-form-field
                  appearance="outline"
                  class="type density-minus-3">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option [value]="EmailType.Work">Work</mat-option>
                    <mat-option [value]="EmailType.Home">Home</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  appearance="outline"
                  class="email density-minus-3">
                  <mat-label>Email</mat-label>
                  <input
                    autocomplete="off"
                    formControlName="value"
                    matInput
                    placeholder="Email"/>
                </mat-form-field>
              </ng-container>
              <span
                (click)="removeEmail(i)"
                [ngClass]="{disabled: emailControl.disabled}"
                class="remove"
              ><mat-icon>close</mat-icon></span
              >
            </div>
          </ng-container>
          <i
            data-cy="contact-add-email"
            *ngIf="isContactPage"
            (click)="addEmail()"
            class="edit">
            <mat-icon>add</mat-icon>
            Add Email
          </i>
        </div>
        <div class="row form-space">
          <h4 class="header-label">Addresses
            <i
              *ngIf="!isContactPage"
              (click)="addAddress()"
              class="edit">
              <mat-icon>add</mat-icon>
              Add New Address
            </i>
          </h4>
          <ng-container formArrayName="addresses">
            <div
              *ngFor="let _ of addresses.controls; index as i"
              class="address-repeater">
              <ng-container [formGroupName]="i">
                <div class="row repeater">
                  <mat-form-field
                    appearance="outline"
                    class="type density-minus-3">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="type">
                      <mat-option [value]="ExtensionTypes.Work">Work</mat-option>
                      <mat-option [value]="ExtensionTypes.Home">Home</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="email density-minus-3">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                      <mat-option [value]="null">Select a country</mat-option>
                      <mat-option
                        *ngFor="let country of countries"
                        [value]="country.name">
                        {{ country.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span
                    (click)="removeAddress(i)"
                    class="remove"
                  ><mat-icon>close</mat-icon></span
                  >
                </div>
                <div class="row items-holder">
                  <mat-form-field
                    appearance="outline"
                    class="full density-minus-3">
                    <mat-label>Address 1</mat-label>
                    <input
                      autocomplete="off"
                      formControlName="addressLine1"
                      matInput
                      placeholder="Address 1"/>
                  </mat-form-field>
                </div>
                <div class="row items-holder">
                  <mat-form-field
                    appearance="outline"
                    class="full density-minus-3">
                    <mat-label>Address 2</mat-label>
                    <input
                      autocomplete="off"
                      formControlName="addressLine2"
                      matInput
                      placeholder="Address 2"/>
                  </mat-form-field>
                </div>
                <div class="row form-address items-holder">
                  <mat-form-field
                    appearance="outline"
                    class="city density-minus-3">
                    <mat-label>City</mat-label>
                    <input
                      autocomplete="off"
                      formControlName="city"
                      matInput
                      placeholder="City"/>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="state density-minus-3">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                      <mat-option [value]="null">Select a state</mat-option>
                      <mat-option
                        *ngFor="let state of states$[i] | async"
                        [value]="state.name">
                        {{ state.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="outline"
                    class="zip density-minus-3">
                    <mat-label>Zip</mat-label>
                    <input
                      autocomplete="off"
                      formControlName="postalCode"
                      matInput
                      placeholder="zip"/>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <i
            data-cy="contact-add-address"
            *ngIf="isContactPage"
            (click)="addAddress()"
            class="edit">
            <mat-icon>add</mat-icon>
            Add Address
          </i>
        </div>
        <div class="row form-space" style="margin-left: -10px" *ngIf="(appConfigService.hasOfficeManagerRole$ | async) && showSharedContact">
          <mat-checkbox formControlName="is_shared" [color]="'primary'" [disableRipple]="true">Shared Contact?
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>
  <div class="static-bottom">
    <button
      data-cy="contact-cancel"
      (click)="close()"
      mat-button
      color="primary"
      type="button">
      Cancel
    </button>
    <button
      data-cy="contact-save"
      [disabled]="(contactForm.invalid || loading || !contactForm.dirty) && !profilePhoto"
      color="primary"
      mat-raised-button
      (click)="onSubmit()"
      type="submit">
      <mat-spinner
        diameter="24"
        *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">Save</span>
    </button>
  </div>
</div>

<ng-template [ngIf]="image">
  <div class="image-picker-container">
    <app-transform-image [title]="'Set Contact Photo'" [imageChangedEvent]="imageChangedEvent"
                         (cancelEvent)="image = false"
                         (saveEvent)="onSaveAvatarPhoto($event)"/>
  </div>
</ng-template>
