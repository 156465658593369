export enum CallRecordingAction {
  START = 'start',
  STOP = 'stop',
  RESUME = 'resume',
  PAUSE = 'pause',
}

export enum RecordingState {
  NotRecording = 'notRecording',
  Recording = 'recording',
  Paused = 'paused',
}
