<div class="flex">
  <div
    class="div-checkbox"
    [matMenuTriggerFor]="menu">
    <span
      class="check-box"
      [ngClass]="{
        checked: itemsCount === selectedCount,
        indeterminate: itemsCount !== selectedCount
      }"></span>
    <div class="arrow-down"></div>
  </div>
  <span>{{ selectedCount }} Selected</span>
  <div class="m-l-auto">
    <span
      class="link"
      data-cy="delete-all-button"
      (click)="deleteBatch()">
      {{ actionButtonText }}
    </span>
    <span
      (click)="clearAll()"
      data-cy="clear-all-button"
      class="link p-l-16">
      Cancel
    </span>
  </div>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let option of multiSelectOptions"
      (click)="selectOption(option)">
      {{ option }}
    </button>
  </mat-menu>
</div>
