<div class="container">
  <div>
    <h1 class="title">Voicemail</h1>
    <mat-divider class="m-t-16"></mat-divider>
  </div>
  <ng-container *ngIf="settings">
    <div class="general-setting-section">
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [checked]="settings.enabled"
          (change)="toggleEnabled()"></mat-slide-toggle>
        <div class="text">
          <span>Enable voicemail</span>
        </div>
      </div>
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [checked]="settings.sortInbox"
          (change)="toggleSortInbox()"></mat-slide-toggle>
        <div class="text">
          <span>Sort voicemail inbox by latest first</span>
        </div>
      </div>
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [checked]="settings.announceReceivedTime"
          (change)="toggleAnnounceReceivedTime()"></mat-slide-toggle>
        <div class="text">
          <span>Announce voicemail received time</span>
        </div>
      </div>
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [checked]="settings.announceIncomingCallId"
          (change)="toggleAnnounceIncomingCallId()"></mat-slide-toggle>
        <div class="text">
          <span>Announce incoming call ID</span>
        </div>
      </div>
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [checked]="settings.confirmDeletion"
          (change)="toggleConfirmDeletion()"></mat-slide-toggle>
        <div class="text">
          <span>Confirm Voicemail deletion</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="!settings.residential"
      class="section">
      <span class="sub-title d-block w-100">Operator Forward</span>
      <div class="content">
        <mat-form-field
          appearance="outline"
          class="density-minus-3">
          <mat-label>Enter operator extension</mat-label>
          <input
            autocomplete="off"
            matInput
            [(ngModel)]="settings.operatorForward"
            (ngModelChange)="setSettingsChanged()" />
        </mat-form-field>
      </div>
    </div>
    <div class="section">
      <span class="sub-title d-block w-100">Copy to Extension(s)</span>
      <div class="content flex align-items-center">
        <mat-form-field
          appearance="outline"
          class="density-minus-3">
          <mat-label>Enter name or extension</mat-label>
          <input
            [(ngModel)]="newExtension"
            (ngModelChange)="filterContacts()"
            [matAutocomplete]="auto"
            matInput />
          <mat-autocomplete #auto="matAutocomplete">
            <cdk-virtual-scroll-viewport [itemSize]="50" class="extensions-list">
              <mat-option
                *cdkVirtualFor="let contact of filteredContacts"
                [value]="contact.ext">
                <div class="extensions-item">
                  <app-name-coin
                    [contact]="contact"
                  />
                  <div class="ml-1">{{contact.fullName}}</div>
                </div>
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </mat-autocomplete>
        </mat-form-field>
        <button
          mat-icon-button
          color="primary"
          class="add-button"
          (click)="addNewExtension()"
          [disabled]="newExtension.length < 3">
          <mat-icon>add_box</mat-icon>
        </button>
      </div>
      <div
        *ngFor="let extension of settings.copyToExtensions"
        class="content flex align-items-center">
        <mat-form-field
          appearance="outline"
          floatLabel="auto"
          class="density-minus-3">
          <mat-label></mat-label>
          <input
            autocomplete="off"
            matInput
            readonly
            [ngModel]="extension" />
        </mat-form-field>
        <button
          mat-icon-button
          class="add-button"
          (click)="deleteExtension(extension)">
          <mat-icon class="delete-extension-button">close_box</mat-icon>
        </button>
      </div>
    </div>
    <div class="section">
      <div class="sub-title flex space-between align-items-baseline">
        <span>Voicemail Greeting</span>
        <button
          class="action-button"
          (click)="addNewGreetingClicked.emit()">
          <mat-icon color="primary">add</mat-icon>
          <span class="action">Add new greeting</span>
        </button>
      </div>
      <div class="content">
        <div class="greeting-header">
          <div></div>
          <div>Name</div>
          <div>Duration</div>
          <div></div>
        </div>
        <ng-container *ngIf="greetings.length > 0; else noGreeting">
          <mat-radio-group
            [(ngModel)]="settings.selectedGreeting"
            (ngModelChange)="setSettingsChanged()">
            <div
              class="voicemail-greeting-player"
              *ngFor="let greeting of greetings">
              <div class="w-20 flex align-items-center">
                <mat-radio-button
                  color="primary"
                  [value]="greeting.index.toString()"></mat-radio-button>
                <button
                  (click)="playClickHandler(greeting)"
                  class="play-pause"
                  color="primary"
                  mat-mini-fab>
                  <mat-icon
                    aria-hidden="true"
                    class="play-track">
                    {{ playingAudioId === greeting.index ? 'pause' : 'play_arrow' }}
                  </mat-icon>
                </button>
              </div>
              <div class="w-40">{{ greeting.description }}</div>
              <div class="w-20">{{ Number(greeting.duration) | secondsToMinutes }}</div>
              <div class="w-20 actions">
                <button
                  (click)="greetingDownloadClickHandler(greeting)"
                  color="primary"
                  mat-icon-button>
                  <mat-icon>download</mat-icon>
                </button>
                <button
                  (click)="handleClickOnDelete(greeting)"
                  color="primary"
                  mat-icon-button>
                  <mat-icon>delete_outline</mat-icon>
                </button>
                <button
                  (click)="handleClickOnEdit(greeting)"
                  color="primary"
                  mat-icon-button>
                  <mat-icon>edit_outline</mat-icon>
                </button>
              </div>
            </div>
          </mat-radio-group>
        </ng-container>
        <ng-template #noGreeting>
          <div class="voicemail-greeting-player disabled-player">
            <div class="w-20 flex align-items-center disabled-player-button-container">
              <button
                class="play-pause"
                disabled
                color="primary"
                mat-mini-fab>
                <mat-icon
                  aria-hidden="true"
                  class="play-track"
                  >play_arrow
                </mat-icon>
              </button>
            </div>
            <div class="w-40">Not Set</div>
            <div class="w-40"></div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="section">
      <div class="sub-title flex space-between align-items-baseline">
        <span>Recorded Name</span>
        <button
          class="action-button"
          (click)="handleClickOnManageRecording()">
          <span class="action">Manage recorded name</span>
          <mat-icon color="primary">chevron_right</mat-icon>
        </button>
      </div>
      <div class="content">
        <div class="greeting-header">
          <div></div>
          <div>Name</div>
          <div></div>
          <div></div>
        </div>
        <div
          *ngIf="settings.recordedNames && recordedName; else NoRecorderName"
          class="voicemail-greeting-player">
          <div class="w-20 recorded-button">
            <button
              (click)="playClickHandler(recordedName, true)"
              class="play-pause"
              color="primary"
              mat-mini-fab>
              <mat-icon
                aria-hidden="true"
                class="play-track"
                >{{ playingAudioId === 'name' ? 'pause' : 'play_arrow' }}
              </mat-icon>
            </button>
          </div>
          <div class="w-40">{{ settings.recordedNames.name }}</div>
          <div class="w-20"></div>
          <div class="w-20 actions">
            <button
              (click)="greetingDownloadClickHandler(recordedName, true)"
              color="primary"
              mat-icon-button>
              <mat-icon>download</mat-icon>
            </button>
            <button
              (click)="handleClickOnDelete(recordedName, true)"
              color="primary"
              mat-icon-button>
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button
              (click)="handleClickOnManageRecording()"
              color="primary"
              mat-icon-button>
              <mat-icon>edit_outline</mat-icon>
            </button>
          </div>
        </div>
        <ng-template #NoRecorderName>
          <div class="voicemail-greeting-player disabled-player">
            <div class="w-20 recorded-button disabled-player-button-container">
              <button
                disabled
                class="play-pause"
                color="primary"
                mat-mini-fab>
                <mat-icon
                  aria-hidden="true"
                  class="play-track"
                  >play_arrow
                </mat-icon>
              </button>
            </div>
            <div class="w-40">Not Set</div>
            <div class="w-40"></div>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      class="section"
      *ngIf="settings.unifiedMessaging; else noUnifiedMessaging">
      <div class="sub-title flex space-between align-items-baseline">
        <span>Unified Messaging</span>
      </div>
      <div class="content">
        <mat-form-field
          appearance="outline"
          class="select-dropdown density-minus-3">
          <mat-label>Email notification</mat-label>
          <div class="flex">
            <mat-select
              [(value)]="settings.unifiedMessaging.emailNotification"
              (valueChange)="setSettingsChanged()">
              <mat-option value="no"> None</mat-option>
              <mat-option value="notify_only"> Notification Only (no link or attachment)</mat-option>
              <ng-container *ngIf="!configService.features[AppFeature.Hipaa]">
                <mat-option value="yes"> Send hyperlink</mat-option>
                <mat-option value="attnew"> Send attachment (leave vmail as new)</mat-option>
                <mat-option value="attsave"> Send attachment (move vmail to saved)</mat-option>
                <mat-option value="atttrash"> Send attachment (move vmail to trash)</mat-option>
              </ng-container>
            </mat-select>
          </div>
        </mat-form-field>
        <mat-checkbox
          class="w-100"
          color="primary"
          [checked]="settings.unifiedMessaging.emailWhenMailBoxIsFull"
          (change)="toggleEmailWhenMailBoxIsFull()">
          Send email when mailbox is full
        </mat-checkbox>
        <mat-checkbox
          class="w-100"
          color="primary"
          [checked]="settings.unifiedMessaging.emailOnMissCall"
          (change)="toggleEmailOnMissCall()">
          Send email after missed call
        </mat-checkbox>
        <button
          class="action-button pin-button"
          (click)="setSelectedPage('Pin')">
          <span class="action">Change voicemail PIN</span>
          <mat-icon color="primary">chevron_right</mat-icon>
        </button>
        <br />
      </div>
    </div>
    <ng-template #noUnifiedMessaging>
      <div class="section">
        <div class="content">
          <button
            class="action-button pin-button"
            (click)="setSelectedPage('Pin')">
            <span class="action">Change voicemail PIN</span>
            <mat-icon color="primary">chevron_right</mat-icon>
          </button>
          <br />
        </div>
      </div>
    </ng-template>
    <div class="flex justify-content-end align-center bottom-bar">
      <button
        (click)="cancelClick.emit()"
        class="cancel-button"
        id="cancel-button"
        mat-button
        type="button">
        Cancel
      </button>
      <button
        color="primary"
        mat-raised-button
        class="m-l-12 save-button"
        type="button"
        [disabled]="!isSaveButtonEnabled || processing"
        (click)="handleClickOnSave()">
        <span *ngIf="!processing; else spinner">Save</span>
        <ng-template #spinner>
          <mat-spinner
            class="processing-spinner"
            color="accent"></mat-spinner>
        </ng-template>
      </button>
    </div>
  </ng-container>
</div>
