import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackBarType } from '@app/core/models/snack-bar-data.model';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { catchError, Observable, throwError } from 'rxjs';

export const ShowError = new HttpContextToken<(err: HttpErrorResponse) => boolean>(
  // eslint-disable-next-line unicorn/consistent-function-scoping
  () => (error) => error.status != 422
);
export const amazonRequestIdHeaderKey = 'x-amzn-RequestId';

export const ERROR_MESSAGE_HANDLER = new HttpContextToken<ErrorMessageHandler | null>(() => null);

type ErrorMessageHandler = (error: HttpErrorResponse) => string;

export function createErrorMessageHandlerContext(handler: ErrorMessageHandler | null): HttpContext {
  return new HttpContext().set(ERROR_MESSAGE_HANDLER, handler);
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackbar: SnackbarService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let result = next.handle(request);
    result = result.pipe(catchError((x) => this.handleError(x, request)));
    return result;
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<unknown>) {
    if (request.context.get(ShowError)(error)) {
      const referenceId = error.headers.get(amazonRequestIdHeaderKey);
      let errorMessage = error.error?.message ?? `An Error occurred: ${error.status}`;
      if (referenceId) {
        errorMessage += ` (Reference ID: ${referenceId})`;
      }
      const errorMessageHandler: ErrorMessageHandler | null = request.context.get(ERROR_MESSAGE_HANDLER);
      if (errorMessageHandler) {
        errorMessage = errorMessageHandler(error);
      }
      this.snackbar.open(errorMessage, '', {
        duration: 5000,
        panelClass: [error.status >= 500 ? SnackBarType.ERROR : SnackBarType.WARNING],
      });
      console.error(error);
    }
    return throwError(() => error);
  }
}
