import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public set(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public get<T = string>(key: string) {
    const item = localStorage.getItem(key);
    return item == null ? null : (JSON.parse(item) as T);
  }

  public delete(key: string) {
    localStorage.removeItem(key);
  }
}
