<section #scrollArea>
  <div
    class="container"
    [ngSwitch]="selectedPage">
    <ng-container *ngIf="!isLoading && voicemailSettings; else spinner">
      <app-settings-voicemail-main-page
        *ngSwitchCase="'Main'"
        [settings]="voicemailSettings"
        [greetings]="greetings"
        [recordedName]="recordedName"
        [playingAudioId]="playingAudioId"
        [contacts]="(this.contactService.data$ | async) ?? []"
        (playClicked)="playAudio($event)"
        (stopClicked)="stopAudio()"
        (saveSettings)="saveSettings($event)"
        (cancelClick)="voicemailSettingsSaved.emit(false)"
        (selectedPageChanged)="setSelectedPage($event)"
        (addNewGreetingClicked)="goToManageGreetingPage(AudioType.greeting)"
        (manageRecorderNameClicked)="onManageRecorderNameClicked()"
        (greetingEditClicked)="onGreetingEditClicked($event)"
        (greetingDownloadClicked)="downloadAudioFile($event)"
        (greetingDeleteClicked)="onAudioDeleteClicked($event)">
      </app-settings-voicemail-main-page>
      <app-settings-voicemail-manage-greeting
        *ngSwitchCase="'ManageGreeting'"
        [audioItem]="itemToEdit"
        [audioType]="editAudioType"
        [ttsLanguages]="ttsLanguages"
        [ttsVoices]="voicesOfSelectedLanguage"
        [index]="indexToEdit"
        [isRecording]="isRecording"
        [recordingSeconds]="recordingSeconds"
        [recordedFile]="recordedFile"
        [recordingTrack]="track"
        [playingAudioId]="playingAudioId"
        [user]="currentUser"
        [audio]="audioToEdit"
        (stopPreview)="stopAudio()"
        (selectedPageChanged)="setSelectedPage($event)"
        (playPreviewTtsClicked)="playTtsPreview($event)"
        (selectedTTSLanguageChanged)="getVoicesOfLanguage($event)"
        (saveClickedTTS)="uploadTTS($event)"
        (saveClickedFile)="uploadAudioFile($event)"
        (saveClickedRecord)="uploadAudioFile($event)"
        (recorderClicked)="toggleRecording($event)"
        (deleteAudioClicked)="audioToEdit = null"
        (togglePlayRecordedFile)="playRecordedFile()"
        (togglePlayAudio)="playAudioToEdit()">
      </app-settings-voicemail-manage-greeting>
      <app-settings-voicemail-change-pin
        *ngSwitchCase="'Pin'"
        (submitForm)="updatePin($event)"
        (selectedPageChanged)="setSelectedPage($event)">
      </app-settings-voicemail-change-pin>
    </ng-container>
    <ng-template #spinner>
      <div class="w-100 h-100 flex justify-content-center align-items-center">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="editingDisabled"
    class="grey-mask"
    #greyMask></div>
</section>
