import { Injectable } from '@angular/core';
import { BeforeInstallPromptEvent } from '@app/core/models/pwa.models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  private _promptEvent?: BeforeInstallPromptEvent;

  private _pwaInstalled = new BehaviorSubject<boolean>(true);
  pwaInstalled$ = this._pwaInstalled.asObservable();

  listenForInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
      // Prevent the default browser prompt from showing up immediately.
      e.preventDefault();
      this._pwaInstalled.next(false);
      this._promptEvent = e;
    });
  }

  promptInstall(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this._promptEvent?.prompt() ?? reject();

      this._promptEvent?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this._pwaInstalled.next(true);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}
