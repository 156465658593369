<div class="outer">
  <audio
    #audioPlayer
    [src]="currentTrack.link | sanitize : 'url'"></audio>
  <button
    (click)="handlePlayPauseButtonClick()"
    [disabled]="loaderDisplay"
    class="play-pause"
    mat-icon-button
    [loading]="loaderDisplay">
    <mat-icon
      *ngIf="!loaderDisplay && !isPlaying"
      aria-hidden="true"
      class="play-track"
      >play_arrow
    </mat-icon>
    <mat-icon
      *ngIf="!loaderDisplay && isPlaying"
      aria-hidden="true"
      class="pause-track">
      pause
    </mat-icon>
  </button>
  <div class="inner">
    <div class="m-r-21">
      <span *ngIf="duration !== DefaultDuration">
        {{ playerCurrentTime | secondsToMinutes }}
      </span>
    </div>
    <mat-slider
      [min]="0"
      [max]="duration"
      style="width: 100%"
      class="voice-mail-slider"
      #ngSlider>
      <input
        matSliderThumb
        [value]="playerCurrentTime"
        (change)="handleSliderTimePositionChange(ngSliderThumb.value)"
        #ngSliderThumb="matSliderThumb" />
    </mat-slider>
    <div
      class="m-l-6"
      [hidden]="loaderDisplay">
      <span *ngIf="duration !== DefaultDuration">
        {{ duration | secondsToMinutes }}
      </span>
    </div>
    <button
      (click)="handleDownloadClick(message)"
      data-cy="download-file"
      mat-icon-button>
      <mat-icon>download</mat-icon>
    </button>
    <button
      *ngIf="showForwardButton"
      data-cy="forward-file"
      mat-icon-button
      (click)="handleForwardVoicemailClick()">
      <mat-icon>phone_forwarded</mat-icon>
    </button>
    <button
      *ngIf="showDeleteButton"
      (click)="handleDeleteClick(message)"
      mat-icon-button>
      <mat-icon>delete_outline</mat-icon>
    </button>
    <button
      *ngIf="showRetrieveButton"
      (click)="handleRetrieveVoicemailClick(message)"
      mat-icon-button>
      <mat-icon>restore_from_trash</mat-icon>
    </button>
  </div>
</div>
