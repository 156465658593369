<div
  [ngClass]="['container', sizeStyle, borderStyle + 'Border']"
  [ngStyle]="{ 'background-color': backgroundColor }">
  <ng-container *ngIf="contact; else nn">
    <!-- For huddles, we want to load a larger image. -->
    <img
      *ngIf="contact.avatar"
      [class]="!contact.firstName && !contact.lastName ? 'gray-bg' : ''"
      [src]="sizeStyle === 'huddle' ? (contact.avatar | gravatarUrl : 400) : contact.avatar"/>
    <span
      class="contact-coin"
      *ngIf="!contact.avatar && contact.coin">{{ contact.coin }}
    </span>
    <div
      class="person-icon-container"
      *ngIf="!contact.avatar && !contact.coin">
      <mat-icon [ngStyle]="{ transform: 'scale(' + personIconScale + '%)' }">person</mat-icon>
    </div>
  </ng-container>

  <ng-template #nn>
    <div *ngIf="type !== undefined">
      <mat-icon *ngIf="type === RecordingType.Audio">phone</mat-icon>
      <mat-icon *ngIf="type === RecordingType.Video">videocam</mat-icon>
    </div>
    <div
      class="person-icon-container"
      *ngIf="type === undefined">
      <mat-icon [ngStyle]="{ transform: 'scale(' + personIconScale + '%)', margin: 0 }">person</mat-icon>
    </div>
  </ng-template>

  <i
    *ngIf="indicator === 'presence' && contact && contact.type === ContactType.Company"
    class="indicator {{ contact.presence }}"
    [ngStyle]="{ 'border-color': '#' + indicatorBorderColor }">
    <i></i>
  </i>

  <i
    *ngIf="indicator !== 'presence' && indicator !== 'none'"
    class="indicatorNumber">{{ indicator }}
  </i>
</div>
