import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/core/services/app-config.service';
import { saveAs } from 'file-saver-es';

import { AudioType } from '../models/settings.models';

@Injectable({
  providedIn: 'root',
})
export class MusicOnHoldService {
  public blobData: Map<string, Blob> = new Map();

  constructor(private appConfigService: AppConfigService) {}

  downloadAudioFile(id: number, type: AudioType) {
    this.downloadWavFile(id, type).then((response) => {
      saveAs(response, `${type}-${id}.wav`);
    });
  }

  downloadWavFile(id: number, type: AudioType) {
    return this.appConfigService.downloadWavFile(id, type);
  }
}
