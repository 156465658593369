import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { validateAvatarFile } from '../utils/file.util';

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[appAvatar]',
})
/**
 * Validate the file type and size of the avatar image before uploading it.
 */
export class AvatarDirective {
  @Output() changeEvent = new EventEmitter<Event>();

  constructor(private dialog: MatDialog, private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'type', 'file');
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'accept',
      'image/jpeg, image/png, image/gif, image/svg+xml'
    );
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
  }

  @HostListener('change', ['$event', '$event.target']) fileChangeEvent(event: Event): void {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    if (!file) {
      return;
    }
    const validationResult = validateAvatarFile(file);
    if (validationResult === 'IsValid') {
      this.changeEvent.emit(event);
      return;
    }
    const dialogData =
      validationResult === 'LargeSize'
        ? {
            title: 'Image size is too large',
            icon: 'warning',
            description: 'Image file size should be less than 1024 KB.',
            buttons: [{ label: 'OK', action: () => null, color: 'primary' }],
          }
        : {
            title: 'Image type is not supported',
            icon: 'warning',
            description: 'Allowed types are jpg, png, jpeg, gif, svg',
            buttons: [{ label: 'OK', action: () => null, color: 'primary' }],
          };
    const dialogReference = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      enterAnimationDuration: 0,
      exitAnimationDuration: 0,
    });
    dialogReference
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.elementRef.nativeElement.value = '';
      });
  }

  close() {
    this.dialog.closeAll();
  }
}
