<div
  class="audio-settings-container"
  [ngClass]="{ 'audio-settings-container-overflow': displayedInPopupWindow }">
  <div class="audio-header">
    <h1 class="header-title">Audio Settings</h1>
    <button
      class="rescan-button"
      (click)="scanDevices()">
      Rescan Devices
    </button>
  </div>
  <form
    [formGroup]="audioForm"
    class="text-left">
    <div class="title-audio-setting">Notifications and Ringing</div>
    <div class="volume-settings">
      <div class="volume-setting-div">
        <mat-label class="label-style">Notification Sound Volume</mat-label>
        <mat-slider
          data-cy="NotificationSoundVolume"
          class="volume-slider"
          [max]="100"
          [min]="0"
          (change)="onChange('Notification')">
          <input
            matSliderThumb
            formControlName="notificationSoundVolume" />
        </mat-slider>
      </div>
      <div class="volume-setting-div add-padding">
        <mat-label class="label-style">Ringing Sound Volume</mat-label>
        <mat-slider
          data-cy="RingingSoundVolume"
          class="volume-slider"
          [max]="100"
          [min]="0"
          (change)="onChange('Call')">
          <input
            matSliderThumb
            formControlName="ringingSoundVolume" />
        </mat-slider>
      </div>
      <mat-form-field
        data-cy="NotificationSpeakerDevice"
        appearance="outline"
        class="speaker-dropdown density-minus-3">
        <mat-label>Ringing & Notification Speaker</mat-label>
        <mat-select
          *ngIf="!displayedInPopupWindow"
          formControlName="notificationSpeakerDevice"
          (selectionChange)="disabled = false"
          class="device-list density-minus-3">
          <mat-option
            data-cy="NotificationSpeakerDeviceOption"
            *ngFor="let device of settingsService.outputDevices$ | async"
            [value]="device.deviceId"
            >{{ device.label }}</mat-option
          >
        </mat-select>
        <select
          (change)="disabled = false"
          *ngIf="displayedInPopupWindow"
          formControlName="notificationSpeakerDevice"
          matNativeControl
          required>
          <option
            *ngFor="let device of settingsService.outputDevices$ | async"
            [ngValue]="device.deviceId">
            {{ device.label }}
          </option>
        </select>
      </mat-form-field>
      <ng-template
        [ngTemplateOutlet]="testSound"
        [ngTemplateOutletContext]="{ $implicit: notificationSpeakerDevice, type: 'Notification' }"></ng-template>
      <!--      TODO: remove *ngIf="false" when we return to the quietRinging option-->
      <mat-checkbox
        *ngIf="false"
        formControlName="quietRinging"
        color="primary"
        >Quiet Ringing When in Meeting
      </mat-checkbox>
    </div>
    <div class="title-audio-setting">Calls & Meetings</div>
    <div class="speaker-settings">
      <div class="output-settings">
        <mat-form-field
          appearance="outline"
          data-cy="SpeakerDevices"
          class="speaker-dropdown density-minus-3">
          <mat-label>Calls and Meetings Speaker</mat-label>
          <mat-select
            *ngIf="!displayedInPopupWindow"
            formControlName="speakerDevice"
            (selectionChange)="disabled = false"
            class="device-list density-minus-3">
            <mat-option
              data-cy="SpeakerDeviceOption"
              *ngFor="let device of settingsService.outputDevices$ | async"
              [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <select
            (change)="disabled = false"
            *ngIf="displayedInPopupWindow"
            formControlName="speakerDevice"
            matNativeControl
            required>
            <option
              *ngFor="let device of settingsService.outputDevices$ | async"
              [ngValue]="device.deviceId">
              {{ device.label }}
            </option>
          </select>
        </mat-form-field>
        <ng-template
          [ngTemplateOutlet]="testSound"
          [ngTemplateOutletContext]="{ $implicit: speakerDevice, type: 'Call' }"></ng-template>
      </div>
      <div class="input-settings">
        <mat-form-field
          appearance="outline"
          data-cy="MicrophoneDevices"
          class="speaker-dropdown density-minus-3">
          <mat-label>Microphone</mat-label>
          <mat-select
            *ngIf="!displayedInPopupWindow"
            formControlName="microphoneDevice"
            (selectionChange)="disabled = false"
            [aria-label]="'Input'"
            class="device-list density-minus-3">
            <mat-option
              data-cy="MicrophoneDeviceOption"
              *ngFor="let device of settingsService.inputDevices$ | async"
              [value]="device.deviceId">
              {{ device.label }}
            </mat-option>
          </mat-select>
          <select
            (change)="disabled = false"
            *ngIf="displayedInPopupWindow"
            formControlName="microphoneDevice"
            matNativeControl
            required>
            <option
              *ngFor="let device of settingsService.inputDevices$ | async"
              [ngValue]="device.deviceId">
              {{ device.label }}
            </option>
          </select>
        </mat-form-field>
        <div class="result-div">
          <div>
            <button
              data-cy="MicrophoneTestButton"
              class="test-button"
              *ngIf="isRecording === false"
              (click)="testInputSound()">
              Test Sound
            </button>
            <button
              data-cy="MicrophoneStopTestButton"
              class="stop-test-button"
              *ngIf="isRecording === true"
              (click)="stopInputTest()">
              Stop Test and Hear Recording
            </button>
            <p
              *ngIf="counter > 0"
              class="counter-time">
              {{ isRecording ? 'Recording time ' : 'Playing time left ' }} : {{ counter }} sec
            </p>
          </div>
          <div class="sound-bar">
            <div class="meter">
              <span
                class="dot"
                [ngStyle]="{ 'background-color': i < currentMicrophoneVolume ? '#42a010' : '#E6E6E6' }"
                *ngFor="let item of [].constructor(10); let i = index"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-template
  #testSound
  let-device
  let-type="type">
  <div class="result-div">
    <button
      data-cy="SpeakerTestButton"
      class="test-button"
      *ngIf="settingsService.isSetSinkIdSupported && activeTest !== type"
      (click)="testOutputDevice(device, type)">
      Test Sound
    </button>
    <button
      data-cy="SpeakerStopTestButton"
      class="stop-test-button"
      *ngIf="activeTest === type"
      (click)="stopOutputTest()">
      Stop Test
    </button>
    <div class="sound-bar">
      <div class="meter">
        <span
          class="dot"
          [ngStyle]="{
            'background-color': i < currentSpeakerVolume && activeTest === type ? '#42a010' : '#E6E6E6'
          }"
          *ngFor="let item of [].constructor(10); let i = index"></span>
      </div>
    </div>
    <p
      *ngIf="!settingsService.isSetSinkIdSupported"
      class="warning-support-text">
      Your browser does not support this feature, please use Chrome, Edge or Opera to be able to use it.
    </p>
  </div>
</ng-template>
