import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import { Call } from '@app/phone/models/call.model';
import { ConferenceCall } from '@app/phone/models/conference-call.model';
import { map, Observable, of } from 'rxjs';

import { PhoneNumberPipe } from './phone-number.pipe';

@Pipe({
  name: 'callDisplayName',
  standalone: true,
})
export class CallDisplayNamePipe implements PipeTransform {
  private static phoneNumberPipe = new PhoneNumberPipe();

  transform(call: Call | ConferenceCall): Observable<string> {
    if (call instanceof ConferenceCall) {
      return of('Conference');
    }

    return call.contact$.pipe(map((contact) => this.determineCallDisplayName(call, contact)));
  }

  determineCallDisplayName(call: Call, contact: Contact | undefined): string {
    if (contact && contact.fullName) {
      return contact.fullName;
    } else if (call.remoteDisplayName && call.remoteDisplayName.length > 0) {
      return call.remoteDisplayName;
    } else {
      return CallDisplayNamePipe.phoneNumberPipe.transform(call.remoteUriUser);
    }
  }
}
