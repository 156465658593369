export interface ParkingLotDto extends Omit<ParkingLot, 'id'> {
  lotId: string;
}

export interface ParkingLot {
  id: string;
  department: string;
  site: string;
  coin: string;
  title: string;
  parkedCalls: ParkedCall[];
}

export interface ParkedCall {
  startDate: Date;
  callerName: string;
  callerPhone: string;
}

export const PARK_LOT_EXT_PREFIX = '*55';
