import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Role, UserRole } from '@app/auth/models/users.model';
import { Contact, PresenceType } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { Response, ResponseUrl } from '@app/core/models/api.models';
import { Sip } from '@app/phone/models/sip';
import { Preferences } from '@app/preferences/models/preferences.model';
import {
  AttendantConsoleSettings,
  AudioSettings,
  AudioType,
  AutoAnsweringRulesSettings,
  CallCenterSettings,
  defaultNotificationSettings,
  DNDMode,
  GreetingAudioList,
  MohAudioList,
  MohLanguages,
  MohSettings,
  NotificationKeyBackend,
  NotificationSettingsBackend,
  Settings,
  VideoSettings,
  VoiceOptions,
} from '@app/preferences/models/settings.models';
import { SettingsService } from '@app/preferences/services/settings.service';
import { ReportIssue, ReportIssueResponse } from '@app/shared/components/report-issue/report-issue.model';
import { createErrorMessageHandlerContext } from '@app/shared/interceptors/error-interceptor';
import { Device } from '@app/shared/models/utils.models';
import { detectDevice } from '@app/shared/utils/detect-device';
import { formatPhoneNumber } from '@app/shared/utils/phone.util';
import { environment } from '@environment/environment';
import moment from 'moment';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  fromEvent,
  map,
  mapTo,
  merge,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { AppFeature, CallSocketData, Colors, Config, ConfigPayload, FeatureConfig } from '../models/config.models';
import { Credentials } from '../models/credentials.model';
import { CustomCallerId, Provision } from '../models/provision.model';
import { Status } from '../models/status.model';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService extends ApiService {
  private readonly configUrl = 'users/{me}/config';
  public readonly defaults = {
    colors: {
      primaryText: '#2166b2',
      baseText: '#282828',
      accentText: '#6e6e6e',
      primaryAccent: '#0059b2',
      sideNavActive: '#284465',
    },
    sip: {} as Sip,
    settings: {
      notifications: defaultNotificationSettings,
    } as Settings,
  };

  private readonly source = new BehaviorSubject<Config | null>(null);
  private readonly dnd = new BehaviorSubject<boolean | null>(null);
  private readonly featuresSubject = new BehaviorSubject<Record<AppFeature, boolean>>(this.getDefaultFeatures());
  private readonly outboundCallerIdsSubject = new BehaviorSubject<CustomCallerId[]>([]);
  private readonly selectedOutboundCallerIdSubject = new BehaviorSubject<CustomCallerId | undefined>(undefined);
  private readonly userRolesSubject = new BehaviorSubject<string[]>([]);

  public readonly data$ = this.source.asObservable();
  public readonly dnd$ = this.dnd.asObservable();
  public readonly features$ = this.featuresSubject.asObservable();
  public readonly outboundCallerIds$ = this.outboundCallerIdsSubject.asObservable();
  public readonly selectedOutboundCallerId$ = this.selectedOutboundCallerIdSubject.asObservable();
  public isOnline$: Observable<boolean>;

  private readonly _deviceType = detectDevice(window.navigator.userAgent);

  get hasOfficeManagerRole$(): Observable<boolean> {
    return this.userRolesSubject.pipe(
      map((roles) => roles.includes(UserRole.officeManager)),
      distinctUntilChanged()
    );
  }

  get hasOfficeManagerRole() {
    return this.userRolesSubject.value.includes(UserRole.officeManager);
  }

  get attendantConsoleSettings(): AttendantConsoleSettings | null {
    return this.getData()?.settings.preferences?.attendantConsole || null;
  }

  get autoAnsweringRulesSettings(): AutoAnsweringRulesSettings | null {
    return this.getData()?.settings.preferences?.answeringRules || null;
  }

  get features(): Record<AppFeature, boolean> {
    return this.featuresSubject.value;
  }

  get deviceType(): Device {
    return this._deviceType;
  }

  //* Call Center current settings GETTER/SETTER */
  private _callCenterSettings: CallCenterSettings = this.defaultCallCenterSettings;

  public get defaultCallCenterSettings(): CallCenterSettings {
    return {
      showCallHistory: true,
      showGraphs: false,
      showQueues: true,
      showCalls: true,
      showStats: false,
      loginMethod: this._callCenterSettings?.loginMethod || '',
    };
  }

  public get callCenterSettings(): CallCenterSettings {
    return this._callCenterSettings;
  }

  public set callCenterSettings(settings: CallCenterSettings) {
    this._callCenterSettings = settings;
  }

  constructor(
    http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private wsService: WsService,
    private settingsService: SettingsService,
    private contactService: ContactService,
    private storage: LocalStorageService
  ) {
    super(http);
    this.dndStatusWebSocket();
    this.dnd$.pipe(distinctUntilChanged()).subscribe((dnd) => {
      if (this.contactService.currentUser) {
        this.contactService.currentUser.presence = dnd ? PresenceType.Closed : PresenceType.Open;
      }
    });
    this.isOnline$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }

  dndStatusWebSocket(): void {
    this.wsService.socket.on('PhoneFeature', (event: { feature: string; enabled: boolean }) => {
      if (event.feature === 'dnd') {
        this.dnd.next(event.enabled);
      }
    });
  }

  /**
   * http request for voicemail
   */
  getHttpData(): Observable<Config> {
    const currentConfig = this.storage.get<ConfigPayload>('config-cache');
    if (currentConfig && currentConfig.time >= moment().subtract(1, 'm').valueOf()) {
      return of(currentConfig.config);
    }
    return this.get<Config>(this.configUrl).pipe(
      map((data: Config) => {
        this.updateCache({ time: moment().valueOf(), config: data });
        return data;
      })
    );
  }

  getConfig(): Observable<Config> {
    const preferences$ = this.getPreferences();
    const credentials$ = this.getCredentials();

    return combineLatest([preferences$, credentials$]).pipe(
      catchError(() => of({ ...this.defaults, failed: true })),
      switchMap<[Preferences, Credentials], Observable<Config>>(([preferences, credentials]) => {
        this.callCenterSettings = preferences.callCenter || this.defaultCallCenterSettings;
        return of<Config>({
          ...this.defaults,
          sip: environment.name === 'mocked' ? this.getMockSipSettings(credentials.sip) : credentials.sip,
          settings: {
            notifications: this.setNotifications(preferences.notifications),
            preferences: preferences,
          },
        });
      })
    );
  }

  getMockSipSettings(data: Sip): Sip {
    return {
      ext: data.ext,
      host: environment.sip.host,
      password: environment.sip.password,
      registerDomain: environment.sip.registerDomain,
    };
  }

  getStatus(): Observable<Status> {
    return this.get<Status>('users/{me}/status');
  }

  public getPreferences(): Observable<Preferences> {
    return this.get<Preferences>('v2/users/{me}/preferences');
  }

  public getProvision(): Observable<Provision> {
    return this.get<Provision>('users/{me}/provision').pipe(
      tap((provision) => {
        this.storage.set('provision', provision);
        this.updateOutboundCallerIdsForProvisioning(provision);
      })
    );
  }

  private async updateOutboundCallerIdsForProvisioning(provision: Provision) {
    // Format the name based on the number and name of each caller id
    const callerIds = provision.data.customCallerIds ?? [];
    callerIds.forEach((item) => {
      const formattedPhonenumber = formatPhoneNumber(item.number);
      item.name = item.name ? `${formattedPhonenumber} - ${item.name}` : formattedPhonenumber;
    });
    this.outboundCallerIdsSubject.next(callerIds);
    this.selectedOutboundCallerIdSubject.next(callerIds.find((item) => item.number === provision.data.callerId));
  }

  public async setSelectedOutboundCallerId(callerId: CustomCallerId) {
    await firstValueFrom(
      this.post<ResponseResult>(`users/{me}/did/outbound`, { did: callerId.number }, { method: 'PUT' })
    );
    this.selectedOutboundCallerIdSubject.next(callerId);
  }

  // TODO: type should be an enum
  private getCredentials(type = 'all'): Observable<Credentials> {
    return this.get<Credentials>(`users/{me}/credentials/${type}`);
  }

  public setData(data: Config): void {
    data = this.setNotificationsDefaultValue(data);
    this.source.next(data);
    if (data.settings.preferences?.audio) {
      this.settingsService.setAudioSettings(data.settings.preferences.audio);
    }
    if (data.settings.preferences?.video) {
      this.settingsService.setVideoSettings(data.settings.preferences.video);
    }
  }

  public setNotificationsDefaultValue(data: Config): Config {
    data.settings.notifications = { ...this.defaults.settings.notifications, ...data.settings.notifications };
    return data;
  }

  public updateCache(data: ConfigPayload): void {
    this.storage.set('config-cache', data);
  }

  public updateColors(_data: Colors): void {
    this.setUIColors(_data);
    const config = this.getData();
    if (config) {
      const newState = { ...config, colors: _data };
      this.updateCache({ time: moment().valueOf(), config: newState });
      this.setData(newState);
    }
  }

  private getData(): Config | null {
    return this.source.getValue();
  }

  getDIDs(): string[] {
    return (<Provision>this.storage.get('provision')).data.dids;
  }

  resetColors(): void {
    this.updateColors(this.defaults.colors);
  }

  public setUIColors(colors: Colors): void {
    for (const key of Object.keys(colors)) {
      this.document.documentElement.style.setProperty(`--${key}`, colors[key as keyof Colors]);
    }
  }

  public updateProfileStatus(data: Status) {
    return this.post<Response>('users/{me}/status', data, { method: 'PUT' }).pipe();
  }

  public updatePreferences(updatedData: Partial<Preferences>) {
    return this.patchPreferences(updatedData, () => {
      const data = this.getData();
      if (!data) {
        return;
      }
      data.settings.preferences = { ...data.settings.preferences, ...updatedData };
      this.updateCache({ time: moment().valueOf(), config: data });
      this.setData(data);
    });
  }

  public saveAudioSettings(value: AudioSettings) {
    value = this.settingsService.audioReplaceNullsWithDefaults(value);
    return this.patchPreferences({ audio: value }, () => {
      const data = this.getData();
      if (!data) {
        return;
      }
      data.settings.preferences.audio = value;
      this.settingsService.setAudioSettings(value);
      this.updateCache({ time: moment().valueOf(), config: data });
      this.setData(data);
    });
  }

  public saveVideoSettings(value: VideoSettings) {
    value = this.settingsService.videoReplaceNullsWithDefaults(value);
    return this.patchPreferences({ video: value }, () => {
      const data = this.getData();
      if (!data) {
        return;
      }
      data.settings.preferences.video = value;
      this.settingsService.setVideoSettings(value);
      this.updateCache({ time: moment().valueOf(), config: data });
      this.setData(data);
    });
  }

  private patchPreferences(body: Partial<Preferences>, onSuccess: () => void) {
    return this.post<Response>('v2/users/{me}/preferences', body, { method: 'PATCH' }).pipe(
      tap((response) => {
        if (response.success) {
          onSuccess();
        }
      })
    );
  }

  getDndMode(currentUser: Contact | undefined, preferences: Preferences | null): DNDMode | undefined {
    if (currentUser?.presence === PresenceType.Closed) {
      return 'DND';
    } else if (preferences?.disableCalls && preferences?.disableNotifications) {
      return 'CallsAndNotifications';
    } else if (preferences?.disableCalls) {
      return 'Calls';
    } else if (preferences?.disableNotifications) {
      return 'Notifications';
    } else {
      return undefined;
    }
  }

  getDnd(): Observable<[Contact | undefined, Preferences | null, boolean | null]> {
    return combineLatest<[Contact | undefined, Preferences | null, boolean | null]>([
      this.contactService.currentUser$,
      this.data$.pipe(map((config) => config?.settings.preferences || null)),
      this.dnd$,
    ]);
  }

  isDNDEnabled(): boolean {
    return !!this.dnd.value;
  }

  public setDnd(data: { dnd: boolean }) {
    this.dnd.next(data.dnd);
    return this.post<Response>('users/{me}/dnd/update', data);
  }

  // ========== Notification Settings ==========

  public updateNotificationSettings(settings: Record<NotificationKeyBackend, NotificationSettingsBackend>) {
    const settingsBeforeUpdate = {
      ...(this.getData()?.settings.notifications || this.defaults.settings.notifications),
    };
    this.setCachedNotificationSettings(settings);

    return this.post<Response>('v2/users/{me}/preferences', { notifications: settings }, { method: 'PUT' }).pipe(
      catchError((error) => {
        console.error(error);

        // Reset what we have cached if the update failed for whatever reason
        this.setCachedNotificationSettings(settingsBeforeUpdate);
        return of(<Response>{ success: false });
      })
    );
  }

  public getNotificationSettings(): Record<NotificationKeyBackend, NotificationSettingsBackend> {
    return this.getData()?.settings?.notifications || this.defaults.settings.notifications;
  }

  private setCachedNotificationSettings(settings: Record<NotificationKeyBackend, NotificationSettingsBackend>) {
    const data = this.getData();
    if (!data) {
      return;
    }
    const newState = { ...data };
    newState.settings.notifications = settings;
    this.updateCache({ time: moment().valueOf(), config: newState });
    this.setData(newState);
  }

  getUserPBXPortal(): Observable<{ url: string }> {
    return this.get<{ url: string }>('users/{me}/portal');
  }

  public isContactMatchingDisabled(): boolean {
    return this.getData()?.settings.preferences.calling?.disableContactMatching || false;
  }

  postIssue(data: ReportIssue) {
    return this.post<ReportIssueResponse>('users/{me}/issues', data, { method: 'PUT' });
  }

  getFeatures(): Observable<FeatureConfig[]> {
    const features = { ...this.features };

    return this.get<[FeatureConfig]>(`uc/configs?user_uuid={me}&check_entitlement=0&class=connectuc`).pipe(
      tap((featureConfigs) => {
        featureConfigs.forEach(
          (featureConfig) => (features[featureConfig.setting.name] = featureConfig.setting_value == 1)
        );
        this.featuresSubject.next(features);
      })
    );
  }

  public isFeatureEnabled(feature: AppFeature): boolean {
    return this.features[feature];
  }

  private getDefaultFeatures(): Record<AppFeature, boolean> {
    const features = <Record<AppFeature, boolean>>{};
    for (const value of Object.values(AppFeature)) {
      features[value] = false;
    }
    return features;
  }

  getBlockedNumbers(): Observable<{ numbers: string[] }> {
    return this.get<{ numbers: string[] }>('users/{me}/blocked-numbers');
  }

  setBlockedNumbers(number: string): Observable<Response> {
    return this.post<Response>(`users/{me}/blocked-numbers/${number}`, {}, { method: 'PUT' }).pipe();
  }

  deleteBlockedNumbers(number: string) {
    return this.delete(`/users/{me}/blocked-numbers/${number}`);
  }

  getAllowedNumbers(): Observable<{ numbers: string[] }> {
    return this.get<{ numbers: string[] }>('users/{me}/allowed-numbers');
  }

  setAllowedNumbers(number: string): Observable<Response> {
    return this.post<Response>(`users/{me}/allowed-numbers/${number}`, {}, { method: 'PUT' }).pipe();
  }

  deleteAllowedNumbers(number: string) {
    return this.delete(`/users/{me}/allowed-numbers/${number}`);
  }

  setNotifications(notifications: Record<NotificationKeyBackend, NotificationSettingsBackend> | null) {
    const notificationSettings = this.defaults.settings.notifications;
    if (notifications) {
      for (const key of Object.keys(notifications)) {
        if (notificationSettings[key] && notifications[key]) {
          notificationSettings[key].showNotification = notifications[key].showNotification ?? true;
          notificationSettings[key].notificationSound = notifications[key].notificationSound ?? true;
          notificationSettings[key].showInForeground = notifications[key].showInForeground ?? true;
          notificationSettings[key].ringtone =
            notifications[key].ringtone ?? this.defaults.settings.notifications[key].ringtone;
        }
      }
    }
    return notificationSettings;
  }

  async getLocalMohSettings(): Promise<MohSettings> {
    return await firstValueFrom(this.get<MohSettings>('users/{me}/moh/settings'));
  }

  async setLocalMohSettings(data: MohSettings): Promise<Response> {
    return await firstValueFrom(this.post<Response>('users/{me}/moh/settings', data, { method: 'PUT' }));
  }

  async getTextToSpeechLanguages(): Promise<{ languages: MohLanguages[] }> {
    return await firstValueFrom(this.get<{ languages: MohLanguages[] }>('users/{me}/tts/languages'));
  }

  async getTextToSpeechVoices(language: string): Promise<VoiceOptions[]> {
    return await firstValueFrom(this.get<VoiceOptions[]>('users/{me}/tts/voices/' + language));
  }

  async getAudioFiles(): Promise<MohAudioList[]> {
    return await firstValueFrom(this.get<MohAudioList[]>('users/{me}/audio/moh/list'));
  }

  async getAudioGreeting(): Promise<GreetingAudioList[]> {
    return await firstValueFrom(this.get<GreetingAudioList[]>('users/{me}/audio/greeting/list'));
  }

  async downloadWavFile(id: number, type: AudioType): Promise<Blob> {
    return await firstValueFrom(
      this.get<Blob>(`/users/{me}/audio/${type}/${id}/download`, {
        responseType: 'blob' as 'json',
      })
    );
  }

  deleteAudioFile(id: number, type: AudioType) {
    return this.delete(`/users/{me}/audio/${type}/${id}`);
  }

  async updateAudioFiles(id: number, data): Promise<Response> {
    return await firstValueFrom(this.post<Response>(`users/{me}/audio/moh/${id}`, data, { method: 'PUT' }));
  }

  async uploadAudioFile(id: string, data: unknown, file: File, type: string): Promise<boolean> {
    const responseUrl = await firstValueFrom(
      this.post<ResponseUrl>(`users/{me}/audio/${type}/${id}/upload`, data, { method: 'POST' })
    );
    // Upload the attachment
    await firstValueFrom(
      this.httpClient.put(responseUrl['url'], file, {
        headers: {
          'Content-Type': 'audio',
        },
      })
    );

    return true;
  }

  async addAudioFile(id, type, data): Promise<ResponseUrl> {
    return await firstValueFrom(
      this.post<ResponseUrl>(`users/{me}/audio/${type}/${id}/upload`, data, { method: 'POST' })
    );
  }

  async reorderAudioFiles(order: string): Promise<Response> {
    return await firstValueFrom(this.post<Response>(`users/{me}/audio/moh/reorder`, { order }, { method: 'PUT' }));
  }

  async getPreSignedUrl(name: string, issueId: string) {
    return await firstValueFrom(
      this.post<ResponseUrl>(`users/{me}/issues/${issueId}/files`, { filename: name }, { method: 'PUT' })
    );
  }

  async uploadFileToS3(preSignedUrl: string, file: Blob, customErrorMessage?: string) {
    let context: HttpContext | undefined;
    if (customErrorMessage) {
      context = createErrorMessageHandlerContext(() => customErrorMessage);
    }
    await firstValueFrom(
      this.httpClient.put(preSignedUrl, file, {
        context,
      })
    );
  }

  linkFileWithIssue(issueId: string, preSignedUrl: string, name: string) {
    return this.post<Response>(`users/{me}/issues/${issueId}/files`, {
      files: [
        {
          description: name,
          s3Url: preSignedUrl,
        },
      ],
    });
  }

  private fetchRoles(): Observable<Role[]> {
    return this.get('users/{me}/roles');
  }

  getUserRoles() {
    this.fetchRoles().subscribe((roles) => {
      this.userRolesSubject.next(roles?.map((role: { name: string }) => role.name));
    });
  }

  sendSipEvent(model: CallSocketData) {
    return this.post('users/{me}/events/calls', model);
  }
}

interface ResponseResult {
  result: string;
}
