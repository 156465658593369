import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { ButtonType, DialogButton, DialogData } from '@app/shared/models/dialog-data';

@Component({
  standalone: true,
  imports: [MatIconModule, CommonModule, MatCheckboxModule, MatButtonModule],
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  protected readonly ButtonType = ButtonType;

  constructor(
    public dialogReference: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private storage: LocalStorageService
  ) {}

  onButtonClick(button: DialogButton) {
    button.action();
    this.dialogReference.close(button);
  }

  onCheckChange() {
    this.storage.set(this.data.rememberId!, true);
  }
}
