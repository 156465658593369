import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Contact,
  ContactGrouping,
  ContactListHeaderLayoutMode,
  ContactListHeaderSearchOptions,
  ContactSort,
  ContactType,
} from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { BrandingField } from '@app/core/models/branding.models';
import { EventData, EventName } from '@app/core/models/event-bus.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BrandingService } from '@app/core/services/branding.service';
import { EventBusService } from '@app/core/services/event-bus.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { ContactUpdatesComponent } from '@app/shared/components/contact-updates/contact-updates.component';
import {
  ContactGroupingLabelPipe,
  ContactSortLabelPipe,
  ContactTypeLabelPipe,
} from '@app/shared/pipes/contact-filter-labels.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, firstValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    ContactTypeLabelPipe,
    MatRadioModule,
    ContactGroupingLabelPipe,
    ContactSortLabelPipe,
  ],
  selector: 'app-contact-list-header',
  templateUrl: './contact-list-header.component.html',
  styleUrls: ['./contact-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactListHeaderComponent implements OnInit {
  @ViewChild('contactListHeaderInput') contactListHeaderInput: ElementRef<HTMLInputElement>;

  @Input() layoutMode: ContactListHeaderLayoutMode = 'full';
  @Input() searchLabel?: string;
  @Input() filteringEnabled: boolean = true;

  @Output() searchChange = new EventEmitter<string>();
  @Output() contactFiltered = new EventEmitter<ContactListHeaderSearchOptions>();

  protected search = '';
  protected selectedContactType = ContactType.All;
  protected selectedContactSort = ContactSort.FirstName;
  protected selectedContactGrouping = ContactGrouping.Name;
  protected contactFilterMenuVisible = false;
  protected searchFocus: boolean = false;

  protected readonly ContactType = ContactType;
  protected contactTypeValues: ContactType[] = Object.values(ContactType);
  protected contactGroupingValues = Object.values(ContactGrouping).filter((v) => v !== ContactGrouping.FavoritesOnly);
  protected contactSortValues = Object.values(ContactSort);

  protected BrandingField = BrandingField;
  brandingData = this.brandingService.brandingData;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    protected configService: AppConfigService,
    private dialog: MatDialog,
    private brandingService: BrandingService,
    private eventBusService: EventBusService
  ) {}

  ngOnInit() {
    this.searchLabel = this.searchLabel ?? 'Search for a contact';

    this.eventBusService.on(EventName.DialerInputTabKeydown, () => {
      this.contactListHeaderInput.nativeElement.focus();
    });

    combineLatest([this.configService.data$, this.contactService.data$])
      .pipe(untilDestroyed(this))
      .subscribe(([config]) => {
        this.selectedContactType = config?.settings.preferences.contactFilter?.type ?? ContactType.All;
        this.selectedContactGrouping = config?.settings.preferences.contactFilter?.grouping ?? ContactGrouping.Name;
        this.selectedContactSort = config?.settings.preferences.contactFilter?.sort ?? ContactSort.FirstName;
        this.emitContactFilteredEventWithCurrentState();
      });
  }

  async addContact(isShared = false) {
    const dialogReference = this.dialog.open(ContactUpdatesComponent, {
      data: {
        contact: null,
        status: 'Add',
        isShared,
      },
      hasBackdrop: true,
      disableClose: true,
      panelClass: ['edit-contact-dialog'],
    });
    const contact = (await firstValueFrom(dialogReference.afterClosed())) as Contact;
    if (contact) {
      void this.router.navigate(['../' + contact.id], { relativeTo: this.route });
    }
  }

  protected handleContactSearchInputChange(event: string) {
    this.searchChange.emit(event);
  }

  protected onContactFilterClicked() {
    this.contactFilterMenuVisible = !this.contactFilterMenuVisible;
  }

  async onContactTypeChanged(contactType: ContactType) {
    this.selectedContactType = contactType;
    this.contactFilterMenuVisible = false;
    try {
      await this.updateContactFilter();
    } catch (error) {
      console.error(error);
    }

    this.emitContactFilteredEventWithCurrentState();
    this.googleAnalyticsService.contactsSearched();
  }

  async onContactGroupingChanged(contactGrouping: ContactGrouping) {
    this.selectedContactGrouping = contactGrouping;
    this.contactFilterMenuVisible = false;
    try {
      await this.updateContactFilter();
    } catch (error) {
      console.error(error);
    }

    this.emitContactFilteredEventWithCurrentState();
    this.googleAnalyticsService.contactsSearched();
  }

  async onContactSortChanged(contactSort: ContactSort) {
    this.selectedContactSort = contactSort;
    this.contactFilterMenuVisible = false;
    try {
      await this.updateContactFilter();
    } catch (error) {
      console.error(error);
    }

    this.emitContactFilteredEventWithCurrentState();
    this.googleAnalyticsService.contactsSearched();
  }

  private async updateContactFilter() {
    await firstValueFrom(
      this.configService.updatePreferences({
        contactFilter: {
          sort: this.selectedContactSort,
          grouping: this.selectedContactGrouping,
          type: this.selectedContactType,
        },
      })
    );
  }

  private emitContactFilteredEventWithCurrentState() {
    console.log(
      `contact filter: ${JSON.stringify({
        contactType: this.selectedContactType,
        contactSort: this.selectedContactSort,
        contactGrouping: this.selectedContactGrouping,
      })}`
    );
    this.contactFiltered.emit({
      contactType: this.selectedContactType,
      contactSort: this.selectedContactSort,
      contactGrouping: this.selectedContactGrouping,
    });
  }

  onSearchInputKeyUp(event) {
    //Only fire event for the first key press after the input gains focus
    //Do not fire event if the search length is 1 due to clearing text
    if (this.searchFocus && this.search.length == 1 && event.key != 'Backspace') {
      this.googleAnalyticsService.contactsSearched();
    }
  }

  protected handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.eventBusService.emit(new EventData(EventName.ContactListHeaderInputTabKeydown));
      event.preventDefault();
    }
  }
}
