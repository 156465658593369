import { Contact } from '@app/contacts/models/contact';
import { BehaviorSubject } from 'rxjs';
import { Invitation, Session, SessionState } from 'sip.js';
import { ManagedSession, SessionManagerMediaLocal, SessionManagerMediaRemote } from 'sip.js/lib/platform/web';

import { ConferenceId } from './conference-call.model';
import { CallTerminationCause, SessionStatus } from './phone.models';

/**
 * Nominal type used to distinguish CallId and ConferenceIds. This ensures when calling
 * methods that take a call id, that we don't accidentally mix up conference and call id.
 *
 * See more info on nominal types here: https://www.typescriptlang.org/play#example/nominal-typing
 */
export type CallId = string & { readonly __brand: unique symbol };

export class Call implements ManagedSession {
  // ManagedSession properties
  held: boolean;
  muted: boolean;
  session: Session;
  mediaLocal?: SessionManagerMediaLocal | undefined;
  mediaRemote?: SessionManagerMediaRemote | undefined;

  /** The call's conference id. This is set when a call is merged with another call. All merged calls will have the same id. */
  public conferenceId?: ConferenceId;

  /**
   * The contact object associated with the call. The contact is derived from remoteUriUser. If the contact lookup fails
   * or is disabled, this value will be undefined.
   *
   * This value is observable because it's possible for the contact to change if a call is picked up from
   * a parking lot and we need to be able to respond to said changes
   */
  private contactSubject = new BehaviorSubject<Contact | undefined>(undefined);
  public contact$ = this.contactSubject.asObservable();

  /** Timestamp the call was created by sip.js */
  public readonly createdTimestamp: Date;

  /** Timestamp the call's session state reaching `SessionState.Established` */
  public establishedTimestamp?: Date;

  /** Timestamp the call's session state reaching `SessionState.Terminated` */
  public terminatedTimestamp?: Date;

  /** A collection of statuses that directly correspond to SIP events. */
  public readonly statusHistory: SessionStatus[];

  /** The reason for a call being terminated. This isn't part of the SIP event lifecycle, but is useful to have for UI purposes. */
  public terminationCause: CallTerminationCause | undefined;

  /** The direction of the call. Determined by the Session type (`Invitation` or `Inviter`) */
  public readonly direction: 'incoming' | 'outgoing';

  /** The call's session id. Convenience accessor for `session.id` */
  public get id(): CallId {
    return this.session.id as CallId;
  }

  /**
   * The call's SIP display name. Convenience accessor for `session.remoteIdentity.displayName`. This value
   * may be the caller id value for the remote party, or it may be a string like "anonymous" or "unknown".
   */
  public get remoteDisplayName(): string | undefined {
    return this.session.remoteIdentity.displayName;
  }

  /**
   * The call's SIP remote uri user field. Convenience accessor for `session.remoteIdentity.uri.user`. This could be
   * a phone number or some other non-numeric value (e.g. "anonymous").
   */
  public get remoteUriUser(): string {
    return this.session.remoteIdentity.uri.user ?? '';
  }

  /**
   * The calls latest status. Derived by taking the latest value from `statusHistory`. If the status history is empty,
   * `SessionStatus.Created` is returned.
   */
  public get status(): SessionStatus {
    return this.statusHistory[this.statusHistory.length - 1] ?? SessionStatus.Created;
  }

  /**
   * The call's current state. This is a convenience accessor for `session.state`.
   */
  public get state(): SessionState {
    return this.session.state;
  }

  public get contact(): Contact | undefined {
    return this.contactSubject.value;
  }

  constructor(session: Session, contact: Contact | undefined) {
    this.contactSubject.next(contact);
    this.held = false;
    this.muted = false;
    this.session = session;
    this.createdTimestamp = new Date();
    this.establishedTimestamp = undefined;
    this.statusHistory = [];
    this.terminationCause = undefined;
    this.direction = session instanceof Invitation ? 'incoming' : 'outgoing';
  }

  public updateContact(contact: Contact | undefined): void {
    this.contactSubject.next(contact);
  }
}
