import { FormControl } from '@angular/forms';
import { AsYouType, isValidNumber, parse, parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export default function normalizePhoneNumber(number: string): string {
  // If the passed string is 10 digits long and is in fact a number, prepend a 1.
  return sanitizePhoneNumber(number.length === 10 && !Number.isNaN(Number.parseInt(number)) ? `1${number}` : number);
}

/**
 * Formats an input string provided by the user into a format that can be used for contact lookup
 * Format should be the number with country code.
 */
export function formatUserInputPhoneNumberForContactLookup(number: string): string | undefined {
  const value = number.trim();
  const valueAsPhoneNumber = normalizePhoneNumber(value);

  if (valueAsPhoneNumber.length === 0 || !canBePhoneNumber(valueAsPhoneNumber)) {
    return undefined;
  }

  const parsed = formatPhoneNumber(valueAsPhoneNumber);
  return normalizePhoneNumber(parsePhoneNumber(parsed, 'US').nationalNumber);
}

export function formatPhoneNumber(value: string): string {
  // If less than 9 digits, just return the digits.
  if (value.length <= 9) {
    return value;
  }
  try {
    // Attempt to parse the phone number with "US" as default country.
    let phoneNumber = parsePhoneNumberFromString(value, 'US');

    // If the phone number is not valid, try again without the default country.
    if (!phoneNumber || !phoneNumber.isValid()) {
      phoneNumber = parsePhoneNumberFromString(value.startsWith('+') ? value : '+' + value);
    }

    // If the phone number is still not valid, return the number with "US" as default country.
    if (!phoneNumber || !phoneNumber.isValid()) {
      const prefix = value.startsWith('+') || value.startsWith('1') ? value : '1 ' + value;
      return new AsYouType('US').input(prefix);
    }

    // If US numbers, return in "1 (xxx) yyy-zzzz" format.
    if (phoneNumber.country === 'US' || phoneNumber.country === 'CA') {
      const formattedPhoneNumber = phoneNumber.formatNational();
      return `1 ${formattedPhoneNumber}`;
    } else {
      // If foreign numbers, return in "cc xx yyyy zzzz" format.
      const formattedPhoneNumber = phoneNumber.number
        .replace(' ', '')
        .replace(/(\+\d{2})(\d{2})(\d{4})(\d{4})$/, '$1 $2 $3 $4');
      return /(\+\d{1,3}) (\d{1,4}) (\d{1,4}) (\d{1,4})/.test(formattedPhoneNumber)
        ? formattedPhoneNumber
        : phoneNumber.formatInternational();
    }
  } catch (error) {
    console.error('Failed to parse the phone number.', error);
    return value;
  }
}

export function validatePhoneNumber(control: FormControl) {
  let phoneNumber = formatPhoneNumber(control.value);
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = '+' + phoneNumber;
  }
  const parsedPhoneNumber = parse(phoneNumber);
  if (!isValidNumber(parsedPhoneNumber)) {
    return { invalidPhone: true };
  }
  return null;
}

export function canBePhoneNumber(value: string) {
  const letterPattern = /(?!\+)[\p{L}\p{S}\p{Po}]/gu;
  return !letterPattern.test(value);
}

export function sanitizePhoneNumber(value: string) {
  return value.replace(/[^\d#*+]/g, '');
}

export function formatInternationalPhoneNumber(phoneNumber: string) {
  phoneNumber = sanitizePhoneNumber(phoneNumber.startsWith('011') ? `+${phoneNumber.slice(3)}` : phoneNumber);

  const formattedNumber = parsePhoneNumberFromString(phoneNumber);
  if (!formattedNumber || !formattedNumber.country) {
    return phoneNumber;
  }
  return (
    ['US', 'CA'].includes(formattedNumber.country) ? formattedNumber.number : `011${formattedNumber.number}`
  ).replace('+', '');
}
