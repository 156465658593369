import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import { PhoneNumberPipe } from '@app/shared/pipes/phone-number.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, PhoneNumberPipe],
  selector: 'app-contact-number-menu',
  templateUrl: './contact-number-menu.component.html',
  styleUrls: ['./contact-number-menu.component.scss'],
})
export class ContactNumberMenuComponent {
  @Input() selectedContact: Contact;
  @Input() title?: string;
  @Input() useCustomMenuStyling = true; // disable if this component is embedded within a MatMenu which has its own styling
  @Output() numberSelected = new EventEmitter<string>();

  protected handleNumberSelected(number: string) {
    this.numberSelected.emit(number);
  }
}
