import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';

/**
 * This pipe is used to map a contact to a phone number if and only if the
 * contact has a single phone number. If the contact has multiple phone numbers, the pipe will return undefined.
 *
 * The transformed value is useful when we want to allow a user to avoid needing to use a dropdown selector for a list of contact
 * numbers when a contact only has one.
 */
@Pipe({ standalone: true, name: 'singlePhoneNumber' })
export class SinglePhoneNumberPipe implements PipeTransform {
  transform(contact: Contact): string | undefined {
    const telsCount = contact.tels.length;
    const extCount = contact.ext ? 1 : 0;
    if (telsCount + extCount > 1) {
      return undefined;
    }
    return contact.tels[0]?.number ?? contact.ext;
  }
}
