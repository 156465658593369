import { Pipe, PipeTransform } from '@angular/core';
import { ContactGrouping, ContactSort, ContactType } from '@app/contacts/models/contact';

@Pipe({
  standalone: true,
  name: 'contactTypeLabel',
})
export class ContactTypeLabelPipe implements PipeTransform {
  transform(value: ContactType): string {
    switch (value) {
      case ContactType.Personal: {
        return 'My Contacts';
      }
      case ContactType.Company: {
        return 'Company';
      }
      case ContactType.Shared: {
        return 'Shared';
      }
      case ContactType.All: {
        return 'All Contacts';
      }
    }
  }
}

@Pipe({
  standalone: true,
  name: 'contactSortLabel',
})
export class ContactSortLabelPipe implements PipeTransform {
  transform(value: ContactSort): string {
    switch (value) {
      case ContactSort.FirstName: {
        return 'First Name';
      }
      case ContactSort.LastName: {
        return 'Last Name';
      }
    }
  }
}

@Pipe({
  standalone: true,
  name: 'contactGroupingLabel',
})
export class ContactGroupingLabelPipe implements PipeTransform {
  transform(value: ContactGrouping): string {
    switch (value) {
      case ContactGrouping.Name: {
        return 'Name';
      }
      case ContactGrouping.Site: {
        return 'Site';
      }
      case ContactGrouping.Company: {
        return 'Company';
      }
      case ContactGrouping.Department: {
        return 'Department';
      }
      default: {
        return '';
      }
    }
  }
}
