import { Injectable } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import {
  TTSLanguage,
  TTSVoice,
  TTSVoicesOfLanguage,
  VoicemailGreetingAudioItem,
  VoicemailSettingsModel,
} from '@app/preferences/models/voicemail.model';
import { AudioUtil } from '@app/shared/utils/audio.util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class VoicemailSettingsStateService {
  private voicemailSettings = new BehaviorSubject<VoicemailSettingsModel | undefined>(undefined);
  private voicemailSettingsSaved = new Subject<boolean>();
  private greetingsList = new BehaviorSubject<VoicemailGreetingAudioItem[]>([]);
  private recordedName = new BehaviorSubject<VoicemailGreetingAudioItem | undefined>(undefined);
  private voicemailPinUpdated = new Subject<boolean>();
  private audioUploaded = new Subject<boolean>();
  private ttsLanguages = new BehaviorSubject<TTSLanguage[]>([]);
  private ttsVoices = new BehaviorSubject<TTSVoice[]>([]);
  private playingAudio = new BehaviorSubject<AudioUtil | undefined>(undefined);
  private currentUser = new BehaviorSubject<Contact | undefined>(undefined);
  private ttsVoicesOfLanguages: TTSVoicesOfLanguage[] = [];

  public getVoiceMailSettings$(): Observable<VoicemailSettingsModel | undefined> {
    return this.voicemailSettings.asObservable();
  }

  public updateVoiceMailSettings(settings: VoicemailSettingsModel) {
    settings.greetings = settings.greetings.map((greeting) => {
      return { ...greeting, recordingMethod: greeting.recordingMethod ?? 'Upload' };
    });
    this.voicemailSettings.next(settings);
  }

  public getVoicemailSettingsSaved$(): Observable<boolean> {
    return this.voicemailSettingsSaved.asObservable();
  }

  public updateVoicemailSettingsSaved(successful: boolean) {
    this.voicemailSettingsSaved.next(successful);
  }

  public getGreetingsList$(): Observable<VoicemailGreetingAudioItem[]> {
    return this.greetingsList.asObservable();
  }

  public updateGreetingsList(greetings: VoicemailGreetingAudioItem[]) {
    this.greetingsList.next([...greetings]);
  }

  public getRecordedName$(): Observable<VoicemailGreetingAudioItem | undefined> {
    return this.recordedName.asObservable();
  }

  public setRecordedName(recordedName?: VoicemailGreetingAudioItem) {
    this.recordedName.next(recordedName);
  }
  public deleteGreetingAudioItem(greetingAudioItem: VoicemailGreetingAudioItem) {
    const transformedAudioList = this.greetingsList
      .getValue()
      .filter((greeting) => greeting.index != greetingAudioItem.index);
    this.updateGreetingsList(transformedAudioList);
    const settingsValue = this.voicemailSettings.getValue();
    if (settingsValue) {
      const transformedGreetingList = settingsValue?.greetings.filter(
        (greeting) => greeting.index != greetingAudioItem.index
      );
      this.updateVoiceMailSettings({ ...settingsValue, greetings: transformedGreetingList });
    }
  }

  public getCurrentUser$(): Observable<Contact | undefined> {
    return this.currentUser.asObservable();
  }

  public setCurrentUser(user: Contact | undefined) {
    this.currentUser.next(user);
  }

  public getVoicemailPinUpdated$(): Observable<boolean> {
    return this.voicemailPinUpdated.asObservable();
  }

  public setVoicemailPinUpdated() {
    this.voicemailPinUpdated.next(true);
  }

  public getAudioUploaded$(): Observable<boolean> {
    return this.audioUploaded.asObservable();
  }

  public setAudioUploaded() {
    this.audioUploaded.next(true);
  }

  public getTTSLanguages$(): Observable<TTSLanguage[]> {
    return this.ttsLanguages.asObservable();
  }

  public setTTSLanguages$(languages: TTSLanguage[]) {
    return this.ttsLanguages.next(languages);
  }

  public getTTSVoices$(): Observable<TTSVoice[]> {
    return this.ttsVoices.asObservable();
  }

  public setTTSVoices(language: string) {
    const ttsVoiceOfLanguage: TTSVoicesOfLanguage | undefined = this.ttsVoicesOfLanguages.find(
      (ttsVoicesOfLanguage: TTSVoicesOfLanguage) => ttsVoicesOfLanguage.language === language
    );
    if (ttsVoiceOfLanguage) {
      this.ttsVoices.next(ttsVoiceOfLanguage.voices);
    }
  }
  public setTTSVoicesOfLanguage(ttsVoiceOfLanguage: TTSVoicesOfLanguage) {
    this.ttsVoicesOfLanguages.push(ttsVoiceOfLanguage);
  }

  public isVoiceOfLanguageLoaded(language: string): boolean {
    return this.ttsVoicesOfLanguages.some(
      (ttsVoiceOfLanguage: TTSVoicesOfLanguage) => ttsVoiceOfLanguage.language === language
    );
  }

  public getPlayingAudio$(): Observable<AudioUtil | undefined> {
    return this.playingAudio.asObservable();
  }

  public setPlayingAudio(audio?: AudioUtil) {
    const currentAudio = this.playingAudio.getValue();
    currentAudio?.pause();
    this.playingAudio.next(audio);
  }
}
