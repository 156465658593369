import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AudioPlayerComponent } from '@app/shared/components/audio-player/audio-player.component';
import { Track } from '@app/shared/models/track.model';
import { AudioUtil, microphonePermission } from '@app/shared/utils/audio.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { getWaveBlob } from 'webm-to-wav-converter';

import {
  AudioType,
  GreetingAudioList,
  MohAudioList,
  MohLanguages,
  MohSettings,
  VoiceOptions,
} from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';
import { MusicOnHoldService } from '../../services/music-on-hold.service';
import { SettingsService } from '../../services/settings.service';
import { UploadMusicDialogComponent } from '../upload-music-dialog/upload-music-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-music-on-hold',
  templateUrl: './music-on-hold.component.html',
  styleUrls: ['./music-on-hold.component.scss'],
})
export class MusicOnHoldComponent extends SettingsPage implements OnInit, AfterViewInit {
  @Output() toggleEvent = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild(AudioPlayerComponent) player: AudioPlayerComponent;
  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('greyMask') greyMask?: ElementRef<HTMLDivElement>;

  protected mohSettings: MohSettings = {
    enableMoh: false,
    randomizeMoh: false,
    greetingType: 'TTS',
    greetingMessage: '',
    greetingLanguage: '',
    greetingVoice: '',
    greetingIndex: 0,
  };
  readonly AudioType = AudioType;
  protected enablePlayIntroductoryGreeting = false;
  protected mohFiles: MohAudioList[] = [];
  protected greetingFiles: GreetingAudioList[];
  protected voiceOptions: VoiceOptions[];
  protected languages: MohLanguages[] = [];
  protected greetingText: string;
  protected uploadGreetingFileName: string = '';
  private files: File[] = [];
  protected showUploadOptions: boolean = false;
  protected greetingChanged: boolean = false;

  protected inputSound: AudioUtil = new Audio() as AudioUtil;
  protected isRecording: boolean | null = false; //null means playing back the recorded sound
  private recorder: MediaRecorder;
  private stream: MediaStream | null = null;
  protected recordedFile: File | null = null;
  protected showUploadAudioPlayer: boolean = false;
  protected showGreetingAudioPlayer: boolean = false;
  protected track: Track = { title: '', link: '' };
  protected recordingFound: boolean = false;
  private recorderTimeout?: NodeJS.Timeout = undefined;
  protected microphoneAccessDenied: boolean = false;
  private remoteAudioElement = new Audio() as AudioUtil;
  protected playingIndex: number | null = null;
  protected readonly Number = Number;
  protected recordingIntervalId: NodeJS.Timer;
  protected recordingSeconds: number = 0;
  protected playingAudioId: string | null;
  private playingAudio = new BehaviorSubject<AudioUtil | undefined>(undefined);
  protected editingDisabled = true;

  constructor(
    private musicOnHoldService: MusicOnHoldService,
    private appConfigService: AppConfigService,
    private settingsService: SettingsService,
    private dialog: MatDialog
  ) {
    super();
  }

  async ngOnInit() {
    try {
      this.editingDisabled = !this.appConfigService.features[AppFeature.ModifyMusicOnHold];

      const [languages, settings] = await Promise.all([
        this.appConfigService.getTextToSpeechLanguages(),
        this.appConfigService.getLocalMohSettings(),
        this.getAudioFiles(),
      ]);

      this.languages = languages.languages;
      this.mohSettings = settings;

      this.setupMohSettings();

      //Upload and Recording - check if greetingFiles contains a file
      //TTS - check if the message is empty then set enablePlayIntroductoryGreeting to false
      if (
        (this.mohSettings.greetingType === 'TTS' && this.mohSettings.greetingMessage.length > 0) ||
        (this.mohSettings.greetingType !== 'TTS' && this.greetingFiles.length > 0)
      ) {
        this.enablePlayIntroductoryGreeting = true;
      }

      if (this.player) {
        this.player.loaderDisplay = true;
      }
    } catch (error) {
      console.error('music-on-hold.component --> ngOnInit:', error.message);
    }
  }

  ngAfterViewInit(): void {
    if (this.editingDisabled) {
      // pass scroll events from grey mask to main container
      this.greyMask?.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
        this.container.nativeElement.scrollBy(event.deltaX, event.deltaY);
      });

      // block mouse and keyboard events
      this.container.nativeElement.addEventListener('click', this.stopAndPreventDefault, true);
      this.container.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key !== 'Escape') {
          this.stopAndPreventDefault(event);
        }
      });
    }
  }

  private stopAndPreventDefault(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  private async setupMohSettings() {
    //If TTS was set, load the voices based on language set
    switch (this.mohSettings.greetingType) {
      case 'TTS': {
        if (this.mohSettings.greetingLanguage.length > 0) {
          await this.getTextToSpeechVoices(this.mohSettings.greetingLanguage);
        }
        break;
      }
      case 'Recording': {
        //Setup the audio file
        const greeting = this.greetingFiles.find((c) => c.index === this.mohSettings.greetingIndex);
        if (greeting) {
          this.inputSound.src = greeting.mediaUrl;
          this.recordingSeconds = Number(greeting.duration);
          this.track = { title: '', link: this.inputSound.src as string, duration: this.recordingSeconds };
          this.recordingFound = true;
          this.recordedFile = new File([greeting.mediaUrl], 'RecordedGreeting.wav', { type: 'audio/wav' });
          this.inputSound.load();
        }
        this.showGreetingAudioPlayer = this.inputSound.src === '' ? false : true;
        break;
      }
      case 'Upload': {
        //Setup the audio file
        const greeting = this.greetingFiles.find((c) => c.index === this.mohSettings.greetingIndex);
        if (greeting) {
          this.inputSound.src = greeting.mediaUrl;
          this.showUploadAudioPlayer = true;
          this.track = { title: '', link: this.inputSound.src as string };
        }
        break;
      }
    }

    return true;
  }

  private async getAudioFiles() {
    try {
      //Split out the greeting and moh files (there will only be one greeting file)
      const allFiles = await this.appConfigService.getAudioFiles();
      this.mohFiles = allFiles.filter((c) => c.index > 0);
      this.greetingFiles = allFiles.filter((c) => c.index === 0);
      this.mohFiles.sort((a, b) => a.index - b.index);
      this.mohFiles.forEach((file) => {
        //Include values needed for better handling of the data
        file.changed = false;
        file.edit = false;
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  private async getTextToSpeechVoices(language: string) {
    this.voiceOptions = await this.appConfigService.getTextToSpeechVoices(language);
  }

  toggleMusicOnHoldSettings() {
    this.mohSettings.enableMoh = !this.mohSettings.enableMoh;
    this.disabled = false;
  }

  toggleRandomizeMusicOnHold() {
    this.mohSettings.randomizeMoh = !this.mohSettings.randomizeMoh;
    this.disabled = false;
  }

  async updateMusicOnHoldSettings() {
    await this.appConfigService.setLocalMohSettings(this.mohSettings);
  }

  editDescription(data) {
    data.descriptionEdit = data.description;
    data.edit = true;
  }

  async saveDescriptionChange(data) {
    data.description = data.descriptionEdit;
    await this.appConfigService.updateAudioFiles(data.index, data);
    data.edit = false;
  }

  cancelDescriptionChange(data) {
    data.descriptionEdit = data.description;
    data.edit = false;
  }

  async onLanguageChanged(option: string) {
    this.mohSettings.greetingLanguage = option;
    await this.getTextToSpeechVoices(option);
    this.greetingChanged = true;
  }

  onVoiceChanged(option: string) {
    this.mohSettings.greetingVoice = option;
    this.disabled = false;
    this.greetingChanged = true;
  }

  async save() {
    if (this.editingDisabled) {
      return;
    }
    if (this.greetingChanged) {
      //Use index=0 for greeting files
      const newIndex = '0';

      switch (this.mohSettings.greetingType) {
        case 'TTS': {
          //get the current highest index used and validate the index to make sure it's unique
          const ttsSettings = {
            description: 'Text to speech greeting',
            voice_id: this.mohSettings.greetingVoice,
            voice_language: this.mohSettings.greetingLanguage,
            tts_script: this.mohSettings.greetingMessage,
            source_type: 'tts',
          };
          await this.appConfigService.addAudioFile(newIndex, 'moh', ttsSettings);
          this.mohSettings.greetingIndex = Number(newIndex);
          break;
        }
        case 'Upload': {
          const fileType = this.files[0].type === 'audio/mpeg' ? 'audio/mp3' : this.files[0].type;
          const data = {
            description: this.uploadGreetingFileName,
            name: this.uploadGreetingFileName,
            format: fileType,
          };
          await this.appConfigService.uploadAudioFile(newIndex, data, this.files[0], 'moh');
          this.mohSettings.greetingIndex = Number(newIndex);
          this.mohSettings.greetingMessage = '';
          this.mohSettings.greetingLanguage = '';
          this.mohSettings.greetingVoice = '';
          break;
        }
        case 'Recording': {
          if (this.recordedFile !== null) {
            const data = { description: 'RecordedGreeting', name: 'RecordedGreeting.wav' };
            await this.appConfigService.uploadAudioFile(newIndex, data, this.recordedFile, 'moh');
          }
          this.mohSettings.greetingIndex = Number(newIndex);
          this.mohSettings.greetingMessage = '';
          this.mohSettings.greetingLanguage = '';
          this.mohSettings.greetingVoice = '';
          break;
        }
      }
    }
    this.updateMusicOnHoldSettings();
    const newIndexOrder = this.mohFiles.map(({ index }) => index);

    //After all changes have been saved, reorder the files
    const orderChanged = this.mohFiles.some((file) => file.changed);
    if (orderChanged) {
      this.reorderMohFiles(newIndexOrder);
    }
  }

  async reorderMohFiles(newIndexOrder) {
    try {
      await this.appConfigService.reorderAudioFiles(newIndexOrder.toString());
    } catch (error) {
      console.log(error);
    }
  }

  //Pass in the current index we want to use, check if it's valid and if not then increment it
  generateIndex(value) {
    if (this.validateIndex(value)) {
      return value;
    } else {
      this.generateIndex(value + 1);
    }
  }

  validateIndex(value) {
    const duplicateValues = this.mohFiles.filter((c) => c.index === value);
    return duplicateValues.length > 0 ? false : true;
  }

  togglePlayIntroGreeting() {
    this.enablePlayIntroductoryGreeting = !this.enablePlayIntroductoryGreeting;
    if (this.enablePlayIntroductoryGreeting) {
      if (this.mohSettings.greetingType === 'TTS' && this.mohSettings.greetingLanguage === '') {
        this.setDefaultBrowserLanguage();
      }
    } else {
      this.mohSettings.greetingIndex = 0;
      this.mohSettings.greetingMessage = '';
      this.mohSettings.greetingLanguage = '';
      this.mohSettings.greetingVoice = '';
      this.mohSettings.greetingType = 'TTS';
      this.disabled = false;
    }
  }

  openFileUploadDialog() {
    const highestIndex =
      this.mohFiles
        .map((file) => file.index)
        .sort()
        .reverse()[0] ?? 0;
    const newIndex = this.generateIndex(highestIndex + 1);
    this.toggleEvent.emit(false);

    const dialogReference = this.dialog.open(UploadMusicDialogComponent, {
      data: {
        Name: 'Test File Name',
        Id: newIndex,
      },
      hasBackdrop: true,
      panelClass: ['upload-music-dialog'],
      autoFocus: false,
    });

    dialogReference
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.getAudioFiles();
      });
  }

  onGreetingChange() {
    this.disabled = false;
    this.greetingChanged = true;
    if (this.mohSettings.greetingType === 'TTS' && this.mohSettings.greetingLanguage === '') {
      this.setDefaultBrowserLanguage();
    }
  }

  async setDefaultBrowserLanguage() {
    //Default to the browser language
    this.mohSettings.greetingLanguage = window.navigator.language;
    await this.getTextToSpeechVoices(this.mohSettings.greetingLanguage);
    //Default to the first voice in the list
    this.mohSettings.greetingVoice = this.voiceOptions[0].id;
  }

  selectGreetingFile() {
    this.fileInput.nativeElement.click();
  }

  protected addFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      // eslint-disable-next-line unicorn/prefer-spread
      this.files = Array.from(target.files);
      this.uploadGreetingFileName = this.files.map((file) => file.name).join('; ');
    }

    target.value = '';

    //Enable the save button
    this.greetingChanged = true;
    this.disabled = false;
  }

  playSelectedAudioFile(url: string, id: number) {
    this.remoteAudioElement.src = url;
    this.remoteAudioElement.load();
    this.remoteAudioElement.addEventListener('loadedmetadata', () => {
      this.playingIndex = id;
      this.settingsService.playAudio(this.remoteAudioElement).catch((error) => {
        console.error('Failed to play media!', error);
        this.playingIndex = null;
      });
    });

    this.remoteAudioElement.addEventListener('ended', () => {
      this.playingIndex = null;
    });
  }

  pauseSelectedAudioFile() {
    this.playingIndex = null;
    this.remoteAudioElement.pause();
  }

  async downloadAudioFile(id: number) {
    await this.musicOnHoldService.downloadAudioFile(id, AudioType.moh);
  }

  async deleteAudioFile(id: number) {
    const response = await firstValueFrom(this.appConfigService.deleteAudioFile(id, AudioType.moh));
    if (response) {
      //On success, reload the audio files
      this.getAudioFiles();
    }
  }

  //Recording
  async startInputSound() {
    try {
      if (await microphonePermission.check()) {
        this.microphoneAccessDenied = false;
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const { source, destination } = await this.setupAudioContextAndNodes(this.stream);
        await this.setupAndStartRecorder(destination);
        source.connect(destination);
      } else {
        this.microphoneAccessDenied = true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  stopInputSound() {
    this.inputSound.pause();
    if (this.isRecording) {
      this.recorder.stop();
    }
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
      this.stream = null;
    }

    this.greetingChanged = true;
    this.disabled = false;
  }

  setupAudioContextAndNodes(audio: MediaStream | HTMLAudioElement) {
    const audioContext = new AudioContext();
    const source =
      audio instanceof HTMLAudioElement
        ? audioContext.createMediaElementSource(audio)
        : audioContext.createMediaStreamSource(audio);
    const destination = audioContext.createMediaStreamDestination();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);
    return { source, destination, analyser };
  }

  async setupAndStartRecorder(destination: MediaStreamAudioDestinationNode) {
    if (await microphonePermission.check()) {
      this.recordingSeconds = 0;
      this.recorder = new MediaRecorder(destination.stream);
      this.recordingIntervalId = setInterval(() => this.recordingSeconds++, 1000);
      const chunks: Blob[] = [];
      this.isRecording = true;
      this.recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      this.recorder.onstop = async () => {
        this.inputSound.pause();
        const audioBlob = new Blob(chunks, { type: 'audio/webm' });

        try {
          //Create a wav file for the new recording so it can be used to upload on Save
          const wavBlob = await getWaveBlob(audioBlob, false);
          this.recordedFile = new File([wavBlob], 'RecordedGreeting.wav', { type: 'audio/wav' });
          this.inputSound.src = URL.createObjectURL(wavBlob);
          this.track = {
            link: this.inputSound.src,
            title: 'RecordedGreeting.wav',
            duration: this.recordingSeconds,
          };
          this.inputSound.load();
          this.showGreetingAudioPlayer = true;
        } catch {
          console.error('Error converting blob format');
        }

        this.isRecording = false;
        clearInterval(this.recordingIntervalId);
        this.recordingSeconds = 0;
      };

      this.recorder.start();

      this.recorderTimeout = setTimeout(() => {
        this.stopInputSound();
      }, 10_000);
    }
  }

  deleteRecording(id: number) {
    //Make sure recording has stopped
    if (this.isRecording) {
      this.isRecording = false;
      this.recorder.stop();
    }

    //Reset the audio controls src so no file is loaded
    this.inputSound.src = '';

    //Reset the audio stream and file so it's not accidentally saved
    this.stream = null;
    this.recordedFile = null;
    this.track.link = this.inputSound.src;
    this.showGreetingAudioPlayer = false;
    this.recordingFound = false;
    this.recordingSeconds = 0;
    this.deleteAudioFile(id);
  }

  async drop(evt: CdkDragDrop<MohAudioList[]>) {
    if (evt.previousContainer === evt.container) {
      //Reorders the array to match the display order
      await moveItemInArray(evt.container.data, evt.previousIndex, evt.currentIndex);

      //Use the changed flag so only the updated rows are saved
      for (let i = 0; i < this.mohFiles.length; i++) {
        if (i === evt.currentIndex || i === evt.previousIndex) {
          this.mohFiles[i].changed = true;
        }
      }
    } else {
      transferArrayItem(evt.previousContainer.data, evt.container.data, evt.previousIndex, evt.currentIndex);
    }

    //Enable the save button
    this.disabled = false;
  }

  async onRecordedFilePlayClicked() {
    if (this.track && this.playingAudioId != 'recorded') {
      const audioUtil: AudioUtil = new Audio(this.track.link) as AudioUtil;
      await this.playAudio(audioUtil);
      this.recordingSeconds = 0;
      this.recordingIntervalId = setInterval(() => this.recordingSeconds++, 1000);
      this.playingAudioId = 'recorded';
    } else {
      this.stopAudio();
    }
  }

  async playAudio(audio: AudioUtil) {
    audio.addEventListener('play', () => {
      this.setPlayingAudio(audio);
    });
    audio.addEventListener('ended', () => {
      this.stopAudio();
    });
    await this.settingsService.playAudio(audio);
  }

  stopAudio() {
    this.playingAudioId = null;
    this.recordingSeconds = 0;
    clearInterval(this.recordingIntervalId);
    this.setPlayingAudio();
  }

  public setPlayingAudio(audio?: AudioUtil) {
    const currentAudio = this.playingAudio.getValue();
    currentAudio?.pause();
    this.playingAudio.next(audio);
  }
}
