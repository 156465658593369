import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { VoicemailSettingsApiService } from '@app/preferences/services/voicemail-settings-api.service';
import { VoicemailSettingsFacadeService } from '@app/preferences/services/voicemail-settings-facade.service';
import { VoicemailSettingsStateService } from '@app/preferences/services/voicemail-settings-state.service';
import { checkUpdate, updateRejected } from '@app/shared/utils/update.util';
import { environment } from '@environment/environment';
import { delay } from 'rxjs';

import { SettingType } from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';
import { AppConfigService } from '@app/core/services/app-config.service';

@Component({
  selector: 'app-preference-layout',
  templateUrl: './preference-layout.component.html',
  styleUrls: ['./preference-layout.component.scss'],
  providers: [VoicemailSettingsFacadeService, VoicemailSettingsApiService, VoicemailSettingsStateService],
})
export class PreferenceLayoutComponent implements OnInit, AfterViewInit {
  @ViewChildren('page') pages: QueryList<SettingsPage>;
  currentSelect: string = SettingType.Profile;
  currentPage?: SettingsPage;
  profile?: Contact;
  readonly SettingType = SettingType;
  debugVersion?: string;
  protected readonly updateRejected = updateRejected;

  constructor(
    private contactService: ContactService,
    private appConfigService: AppConfigService,
    @Inject(DIALOG_DATA) public data: SettingType,
    private swUpdate: SwUpdate
  ) {
    this.debugVersion = environment.awsCommitId;
  }

  ngOnInit(): void {
    this.contactService.currentUser$.subscribe((contact) => {
      this.profile = contact;
    });
  }

  ngAfterViewInit() {
    // Since currentPage is used with `ngIf` in our template and it starts with a value of `undefined`,
    // delay this observable to the next cycle of the run loop to allow for the view change detection
    // to stabilize and avoid the "Expression has changed after it was checked" error in development mode.
    // See https://blog.angular-university.io/angular-debugging/ for more info.
    this.pages.changes.pipe(delay(0)).subscribe(() => {
      // due to ngIf, each time just one page is visible on the screen, that's why we obtain it via `first`
      this.currentPage = this.pages.first;
    });

    if (this.data) {
      this.currentSelect = this.data;
    }
  }

  iterableList(): string[] {
    return Object.values(SettingType);
  }

  async save(page: SettingsPage) {
    await page.save();
    this.currentSelect = SettingType.Profile;
  }

  async onUpdate() {
    if (updateRejected) {
      // the reason why we don't call checkUpdate is swUpdate.checkForUpdate() will return false if the user rejects
      // the update at the first time
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    } else {
      await checkUpdate(this.swUpdate);
    }
  }
}
