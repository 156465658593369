import { Call } from '@app/phone/models/call.model';
import { ListItem } from '@app/shared/models/date-list-item';

export interface ContactsGroup {
  [key: string]: Contact[];
}

export function ContactObject(): Contact {
  return {
    id: '',
    fullName: '',
    firstName: '',
    lastName: '',
    emails: [],
    tels: [],
    avatar: '',
    company: '',
    coin: '',
    addresses: [],
    favorite: false,
    username: '',
    type: ContactType.All,
    ext: '',
    inMeeting: false,
    directoryListing: true,
  };
}

export interface Contact extends ListItem {
  id: string;
  fullName: string;
  firstName: string;
  lastName: string;
  emails: Email[];
  tel?: Telephone;
  tels: Telephone[];
  avatar: string | null;
  company: string;
  title?: string;
  coin: string;
  addresses: Address[];
  pbxUser?: string;
  pbxSite?: string;
  pbxDepartment?: string;
  pbxDomain?: string;
  favorite: boolean;
  serverStatus?: string;
  status?: string;
  serverEmoji?: string;
  emoji?: string;
  presence?: PresenceType;
  username: string;
  type: ContactType;
  ext?: string;
  inMeeting: boolean;
  directoryListing: boolean;
  /**
   * We want to specially handle the "No Portal"-scoped contacts from Chat.
   * There are other values, but we don't care about those on the frontend.
   */
  scope?: ContactScope.NoPortal | string;
  trash?: boolean;
  is_shared?: boolean;
}

export enum ContactScope {
  NoPortal = 'No Portal',
}

export interface ContactUpdateResult {
  avatar: File;
  contact: Contact;
}

export interface ContactsAndGroup {
  contacts: Contact[];
  groups: ContactsGroup;
}

export interface Status {
  status: string;
  emoji: string;
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: number;
  country: string;
  type: ExtensionTypes | string;
}

export interface Telephone {
  number: string;
  type: ExtensionTypes | string;
  ext?: number | null;
  readonly?: boolean;
}

export interface Email {
  value: string;
  type: EmailType | string;
  readonly: boolean;
}

export enum ExtensionTypes {
  Work = 'work',
  Home = 'home',
  Cell = 'cell',
  Fax = 'fax',
  SMS = 'sms',
  Other = 'other',
  PbxSms = 'pbx-sms',
}

export enum ExtensionFilterOptions {
  All = 'all',
  Work = 'work',
  Home = 'home',
  Cell = 'cell',
  Fax = 'fax',
  Other = 'other',
}

export enum PresenceType {
  Open = 'open',
  Closed = 'closed',
  Inactive = 'inactive',
  Inuse = 'inuse',
  Ringing = 'ringing',
}

export enum EmailType {
  Home = 'home',
  Work = 'work',
}

export interface AttendantContactListHeaderSearchOptions {
  pbxSite?: string;
  pbxDepartment?: string;
}

export interface ContactListHeaderSearchOptions {
  contactType: ContactType;
  contactSort: ContactSort;
  contactGrouping: ContactGrouping;
}

export interface ContactFilterOptions {
  contactType?: ContactType;
  pbxSite?: string;
  pbxDepartment?: string;
}

export enum ContactType {
  All = 'All Contacts',
  Company = 'company',
  Shared = 'shared',
  Personal = 'personal',
}

export enum ContactSort {
  FirstName = 'firstName',
  LastName = 'lastName',
}

export enum ContactGrouping {
  Name = 'name',
  Site = 'site',
  Department = 'department',
  Company = 'company',
  FavoritesOnly = 'favoritesOnly',
}

export type ContactListHeaderLayoutMode = 'full' | 'standard';

// TOOD: This enum isn't named for what it does. These are actions performed on a call, not a contact.
export enum ContactAction {
  DeclineEvent = 'decline',
  AnswerEvent = 'answer',
  EndAndAcceptEvent = 'endAndAccept',
  HoldAndAcceptEvent = 'holdAndAccept',
  CloseClickEvent = 'closeClick',
  SelectEvent = 'select',
  TransferEvent = 'transfer',
  AttendedTransferEvent = 'attendedTransfer',
  VoicemailTransferEvent = 'voicemailTransfer',
  AddToCallClickedEvent = 'addToCallClicked',
}

export interface IncomingCallAction {
  action: ContactAction;
  call: Call;
}

export interface ContactNumberSelectedEvent {
  action: ContactAction;
  contact: Contact;

  /**
   * The number that was specifically selected from the contact. If a contact was selected without a
   * particular number, the extension will be used. If the contact has no extension, the first number
   * in `tels` will be used.
   */
  selectedNumber: string;
}

export type ViewModelType = 'contact' | 'groupHeader';

export interface ListViewModel {
  type: ViewModelType;
  groupName: string;
  height: number;
}

export interface ContactItemViewModel extends ListViewModel {
  contact: Contact;
}

export const CONTACT_GROUP_FAVORITE_KEY = 'favorites';
export const CONTACT_GROUP_ALL_KEY = 'all';
