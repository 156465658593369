<div class="password h-100">
  <h1 data-cy="change-password-title">
    <mat-icon (click)="close()">chevron_left</mat-icon>
    Edit Password
  </h1>
  <div class="requirements">
    <b>Password Requirements:</b>
    <div>
      <div>
        <div>
          <i [ngClass]="{ ok: !field['newPassword'].hasError('hasSmallCase') }"></i>
          <span>One lowercase character</span>
        </div>
        <div>
          <i [ngClass]="{ ok: !field['newPassword'].hasError('hasCapitalCase') }"></i>
          <span>One uppercase character</span>
        </div>
      </div>
      <div>
        <div>
          <i [ngClass]="{ ok: !field['newPassword'].hasError('hasNumber') }"></i>
          <span>One number</span>
        </div>
        <div>
          <i [ngClass]="{ ok: field['newPassword'].value !== null && field['newPassword'].value.length >= 8 }"></i>
          <span>8 characters minimum</span>
        </div>
      </div>
    </div>
  </div>
  <div class="form">
    <form
      (ngSubmit)="changePassword()"
      [formGroup]="passwordForm">
      <mat-form-field
        appearance="outline"
        class="full density-minus-3">
        <mat-label>Current Password</mat-label>
        <input
          [type]="type"
          autocomplete="off"
          formControlName="currentPassword"
          matInput
          placeholder="Current Password"/>
        <mat-icon
          (click)="peaky()"
          *ngIf="type === 'text'"
          class="peek"
        >visibility_off
        </mat-icon
        >
        <mat-icon
          data-cy="change-password-peaky"
          (click)="peaky()"
          *ngIf="type === 'password'"
          class="peek"
        >visibility
        </mat-icon
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="full density-minus-3">
        <mat-label>New Password</mat-label>
        <input
          [type]="type"
          autocomplete="off"
          formControlName="newPassword"
          matInput
          placeholder="New Password"/>
        <mat-icon
          (click)="peaky()"
          *ngIf="type === 'text'"
          class="peek"
        >visibility_off
        </mat-icon
        >
        <mat-icon
          (click)="peaky()"
          *ngIf="type === 'password'"
          class="peek"
        >visibility
        </mat-icon
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="full density-minus-3">
        <mat-label>Confirm New Password</mat-label>
        <input
          [type]="type"
          autocomplete="off"
          formControlName="confirmPassword"
          matInput
          placeholder="Confirm new Password"/>
        <mat-icon
          (click)="peaky()"
          *ngIf="type === 'text'"
          class="peek"
        >visibility_off
        </mat-icon
        >
        <mat-icon
          (click)="peaky()"
          *ngIf="type === 'password'"
          class="peek"
        >visibility
        </mat-icon
        >
      </mat-form-field>
      <div class="bottom-bar">
        <button
          data-cy="change-password-close"
          (click)="close()"
          style="color: #0072e5"
          mat-button
          type="button">
          Cancel
        </button>
        <button
          data-cy="change-password-save"
          [disabled]="(passwordForm.invalid && passwordForm.dirty)||passwordForm.pristine"
          color="primary"
          mat-raised-button
          type="submit">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
