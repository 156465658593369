import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactType } from '@app/contacts/models/contact';
import { VoicemailService } from '@app/voicemail/services/voicemail.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-forward-voice-mail-dialog',
  templateUrl: './forward-voice-mail-dialog.component.html',
  styleUrls: ['./forward-voice-mail-dialog.component.scss'],
})
@UntilDestroy()
export class ForwardVoiceMailDialogComponent {
  isProcessing = false;
  selectedExt: string = '';
  protected readonly ContactType = ContactType;

  constructor(
    private dialogRef: MatDialogRef<ForwardVoiceMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private voicemailService: VoicemailService
  ) {}

  setSelectedExtension($event: string | undefined) {
    if (!$event) {
      return;
    }
    this.selectedExt = $event;
  }

  forward() {
    this.isProcessing = true;
    this.voicemailService
      .forwardVoicemail(this.data.id, this.selectedExt)
      .pipe(untilDestroyed(this))
      .subscribe({
        complete: () => {
          this.isProcessing = false;
          this.closeDialog();
        },
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  handleClickOnForward($event: string) {
    this.setSelectedExtension($event);
    this.forward();
  }
}
