import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MenuCloseReason } from '@angular/material/menu';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { TimeFrame, TimeFrameType, TimeRangeData } from '@app/preferences/models/answering-rules.models';
import { AnsweringRulesService } from '@app/preferences/services/answering-rules.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-add-timeframe',
  templateUrl: './add-time-frame.component.html',
  styleUrls: ['./add-time-frame.component.scss'],
})
export class AddTimeFrameComponent implements OnInit {
  @Input() timeFrame: TimeFrame | undefined;
  @Input() standalone: boolean = true;
  @Input() invalid: boolean | undefined;

  @Output() invalidChange = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<TimeFrame>();
  @Output() cancel = new EventEmitter<void>();

  protected readonly TimeFrameType = TimeFrameType;

  protected get selectedOption() {
    return this.form.get('selectedOption') as FormControl;
  }

  protected daysAndTimes: FormArray;
  protected specificDates: FormArray;

  protected daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  protected form!: FormGroup;

  protected copyTimesModel = [false, false, false, false, false, false, false];
  protected copyTimesInitiator = -1;
  protected copyTimesSelectedRangeIndex = -1;

  protected saving = false;

  private lastOrderIndex: number = -1;

  constructor(
    private answeringRuleService: AnsweringRulesService,
    private fb: FormBuilder,
    private snackBar: SnackbarService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1)]],
      selectedOption: TimeFrameType.Always,
    });

    this.specificDates = this.fb.array([]);
    this.daysAndTimes = this.fb.array([]);
    this.daysAndTimes.addValidators(this.daysAndTimesValidator());

    for (let i = 0; i < 7; i++) {
      this.daysAndTimes.push(
        this.fb.group({
          checked: false,
          ranges: this.fb.array([]),
        })
      );
    }
  }

  ngOnInit(): void {
    if (this.timeFrame) {
      const tfType = this.answeringRuleService.timeFrameType(this.timeFrame);
      this.form.patchValue({
        selectedOption: tfType,
      });

      if (tfType === TimeFrameType.SpecificDates) {
        this.form.addControl('specificDates', this.specificDates);
        for (const range of this.timeFrame.time_range_data) {
          if (Number(range.order) > this.lastOrderIndex) {
            this.lastOrderIndex = Number(range.order);
          }
          this.specificDates.push(
            this.fb.group({
              fromDate: [range.date_from, [Validators.required]],
              fromTime: [range.tod_from, [Validators.required]],
              toDate: [range.date_to, [Validators.required]],
              toTime: [
                range.tod_to === '23:59' ? range.tod_to : this.addMinutes(range.tod_to, 1),
                [Validators.required, this.maxTimeValidator()],
              ],
            })
          );
        }
      }

      if (tfType === TimeFrameType.DaysAndTimes) {
        this.form.addControl('daysAndTimes', this.daysAndTimes);
        for (const trData of this.timeFrame.time_range_data) {
          if (Number(trData.order) > this.lastOrderIndex) {
            this.lastOrderIndex = Number(trData.order);
          }
          const ctrl = this.daysAndTimes.at(Number(trData.days));
          ctrl.patchValue({
            checked: true,
          });
          this.daysAndTimesRanges(Number(trData.days)).push(
            this.fb.group({
              timeFrom: [trData.tod_from, [Validators.required]],
              timeTo: [
                trData.tod_to === '23:59' ? trData.tod_to : this.addMinutes(trData.tod_to, 1),
                [Validators.required, this.maxTimeValidator()],
              ],
            })
          );
        }
      }

      if (this.timeFrame.owner === '*' && !this.answeringRuleService.isCurrentUserOfficeManager) {
        // 'clone' shared time frame so non-office manager user can save it as personal one
        this.timeFrame = undefined;
      } else {
        this.form.get('name')?.disable(); // can not edit name for existing time frame
        this.form.patchValue({
          name: this.timeFrame.time_frame,
        });
      }
    }

    this.form.statusChanges.subscribe((status) => {
      this.invalidChange.emit(status === 'INVALID');
    });

    this.form.updateValueAndValidity();
  }

  public getTimeFrameData(): TimeFrame {
    return {
      time_frame: this.form.get('name')?.value,
      owner: '',
      in_use: (this.timeFrame && this.timeFrame.in_use) || false,
      time_range_data: this.getFormData(),
    };
  }

  protected onSelectionChange(): void {
    const option: TimeFrameType = this.selectedOption.value;
    switch (option) {
      case TimeFrameType.Always: {
        this.form.removeControl('daysAndTimes');
        this.form.removeControl('specificDates');
        break;
      }
      case TimeFrameType.DaysAndTimes: {
        this.form.addControl('daysAndTimes', this.daysAndTimes);
        this.form.removeControl('specificDates');
        break;
      }
      case TimeFrameType.SpecificDates: {
        this.form.addControl('specificDates', this.specificDates);
        this.form.removeControl('daysAndTimes');
        if (this.specificDates.length === 0) {
          this.addSpecificDatesRange();
        }
      }
    }
  }

  protected onDayOfWeekChange(event: MatCheckboxChange, dayOfWeek: number): void {
    if (event.checked) {
      this.addDaysAndTimesRange(dayOfWeek);
    } else {
      this.daysAndTimesRanges(dayOfWeek).clear();
    }
  }

  protected addSpecificDatesRange(): void {
    this.specificDates.push(
      this.fb.group(
        {
          fromDate: ['', Validators.required],
          fromTime: ['00:00', Validators.required],
          toDate: ['', Validators.required],
          toTime: ['23:59', [Validators.required, this.maxTimeValidator()]],
        },
        { validators: [this.toTimeValidator()] }
      )
    );
  }

  protected removeSpecificDatesRange(idx: number): void {
    this.specificDates.removeAt(idx);
    this.specificDates.markAsDirty();
  }

  protected addDaysAndTimesRange(dayOfWeek: number): void {
    this.daysAndTimesRanges(dayOfWeek).push(
      this.fb.group({
        timeFrom: ['09:00', Validators.required],
        timeTo: ['17:00', [Validators.required, this.maxTimeValidator()]],
      })
    );
  }

  protected removeDaysAndTimesRange(dayOfWeek: number, index: number): void {
    this.daysAndTimesRanges(dayOfWeek).removeAt(index);
    if (this.daysAndTimesRanges(dayOfWeek).length === 0) {
      this.daysAndTimes.at(dayOfWeek).patchValue({
        checked: false,
      });
    }
    this.daysAndTimes.markAsDirty();
  }

  protected confirmDeleteTimeFrame(): void {
    const timeFrame = this.form.get('name')?.value;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        description: `Are you sure you want to delete time frame '${timeFrame}'? This action cannot be undone.`,
        buttons: [
          {
            label: 'Cancel',
            action: () => null,
            type: ButtonType.matFlatButton,
          },
          {
            label: 'Delete Time Frame',
            action: async () => await this.deleteTimeFrame(timeFrame),
            type: ButtonType.matRaisedButton,
            default: true,
            color: 'primary',
          },
        ],
      },
    });
  }

  protected async onSaveEvent() {
    if (this.timeFrame?.owner === '*' && this.answeringRuleService.isCurrentUserOfficeManager) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Are You Sure?',
          description: 'Editing Time Frames may affect system call routing.',
          buttons: [
            {
              label: 'Cancel',
              action: () => null,
              type: ButtonType.matFlatButton,
            },
            {
              label: 'OK',
              action: async () => await this.saveTimeFrame(),
              type: ButtonType.matRaisedButton,
              default: true,
              color: 'primary',
            },
          ],
        },
      });
    } else {
      this.saveTimeFrame();
    }
  }

  protected onCancelEvent(): void {
    this.cancel.emit();
  }

  protected daysAndTimesRanges(dayOfWeek: number): FormArray {
    return this.daysAndTimes.at(dayOfWeek).get('ranges') as FormArray;
  }

  protected copyTimesOpen(dayOfWeek: number, timeRangeIdx: number): void {
    this.copyTimesInitiator = dayOfWeek;
    this.copyTimesSelectedRangeIndex = timeRangeIdx;
  }

  protected copyMenuClosed(event: MenuCloseReason): void {
    if (event === undefined) {
      this.clearCopyTimesModel();
    }
  }

  protected copyTimesClick(): void {
    const ctrl = this.daysAndTimesRanges(this.copyTimesInitiator).at(this.copyTimesSelectedRangeIndex);
    const timeFrom = ctrl.get('timeFrom')?.value;
    const timeTo = ctrl.get('timeTo')?.value;

    for (let i = 0; i < this.daysOfWeek.length; i++) {
      if (this.copyTimesModel[i]) {
        this.insertTimeRange(i, timeFrom, timeTo);
      }
    }

    this.clearCopyTimesModel();
  }

  protected fromDateSelected(index: number): void {
    const ctrl = this.specificDates.at(index);
    const fromDate = ctrl.get('fromDate')?.value;

    if (fromDate && fromDate !== '') {
      ctrl.get('toDate')?.setValue(fromDate);
      // mark following control as touched so validation can mark it red in case of error
      ctrl.get('toTime')?.markAsTouched();
    }
  }

  private async saveTimeFrame() {
    this.saving = true;
    try {
      await firstValueFrom(
        this.answeringRuleService.addUpdateTimeFrame(this.form.get('name')?.value, this.getFormData())
      );
      if (this.standalone) {
        this.cancel.emit();
      }
    } catch (error) {
      console.error('Save time frame error:', error);
      this.snackBar.open('Time frame was not saved', 'OK');
    } finally {
      this.saving = false;
    }
  }

  private async deleteTimeFrame(timeFrame: string) {
    try {
      await firstValueFrom(this.answeringRuleService.deleteTimeFrame(timeFrame));
      if (this.standalone) {
        this.cancel.emit();
      }
    } catch (error) {
      console.error('Delete time frame error:', error);
      this.snackBar.open('Delete time frame failed', 'OK');
    }
  }

  private addMinutes(timeString: string, amount: number): string {
    return moment(timeString, 'HH:mm').add(amount, 'm').format('HH:mm');
  }

  private getFormData(): TimeRangeData[] {
    const tfType = this.selectedOption.value;
    if (tfType === TimeFrameType.DaysAndTimes) {
      return this.daysAndTimesData();
    } else if (tfType === TimeFrameType.SpecificDates) {
      return this.specificDatesData();
    } else {
      return this.alwaysData();
    }
  }

  private alwaysData(): TimeRangeData[] {
    return [
      {
        order: '0',
        date_from: 'now',
        date_to: 'never',
        days: '*',
        tod_from: '00:00',
        tod_to: '23:59',
        invert: 'no',
      },
    ];
  }

  private daysAndTimesData(): TimeRangeData[] {
    const data: TimeRangeData[] = [];
    let order = 0;
    for (let i = 0; i < this.daysAndTimes.length; i++) {
      const ctrl = this.daysAndTimes.at(i);
      const ranges = this.daysAndTimesRanges(i);
      if (ctrl.get('checked')?.value) {
        for (let j = 0; j < ranges.length; j++) {
          const rangeCtrl = this.daysAndTimesRanges(i).controls.at(j);
          const timeTo = rangeCtrl?.get('timeTo')?.value;
          data.push({
            order: String(order++),
            date_from: 'now',
            date_to: 'never',
            days: String(i),
            tod_from: rangeCtrl?.get('timeFrom')?.value,
            tod_to: timeTo === '23:59' ? timeTo : this.addMinutes(timeTo, -1),
            invert: 'no',
          });
        }
      }
    }

    return data;
  }

  private specificDatesData(): TimeRangeData[] {
    const data: TimeRangeData[] = [];
    for (let i = 0; i < this.specificDates.length; i++) {
      const ctrl = this.specificDates.at(i);
      const toTime = ctrl.get('toTime')?.value;
      data.push({
        order: String(i),
        date_from: moment(ctrl.get('fromDate')?.value).format('YYYY-MM-DD'),
        date_to: moment(ctrl.get('toDate')?.value).format('YYYY-MM-DD'),
        days: '*',
        tod_from: ctrl.get('fromTime')?.value,
        tod_to: toTime === '23:59' ? toTime : this.addMinutes(toTime, -1),
        invert: 'no',
      });
    }

    return data;
  }

  private insertTimeRange(dayOfWeek: number, fromTime: string, toTime: string): void {
    const from = moment(fromTime, 'HH:mm');
    const ctrl = this.daysAndTimesRanges(dayOfWeek);

    this.daysAndTimes.at(dayOfWeek).patchValue({
      checked: true,
    });
    this.removeEmptyRanges(dayOfWeek);

    let i = 0;
    while (i < ctrl.length && moment(ctrl.at(i).get('timeTo')?.value, 'HH:mm').isBefore(from)) {
      i++;
    }

    if (
      i < ctrl.length &&
      ctrl.at(i).get('timeFrom')?.value === fromTime &&
      ctrl.at(i).get('timeTo')?.value === toTime
    ) {
      // do not insert range that is already there
      return;
    }

    ctrl.insert(
      i,
      this.fb.group({
        timeFrom: [fromTime, [Validators.required]],
        timeTo: [toTime, [Validators.required]],
      })
    );

    ctrl.markAsDirty();
  }

  private removeEmptyRanges(dayOfWeek: number): void {
    const ctrl = this.daysAndTimesRanges(dayOfWeek);
    for (let i = ctrl.length - 1; i >= 0; i--) {
      if (ctrl.at(i).get('timeFrom')?.value == '' || ctrl.at(i).get('timeTo')?.value == '') {
        ctrl.removeAt(i);
      }
    }
  }

  private clearCopyTimesModel(): void {
    this.copyTimesModel = [false, false, false, false, false, false, false];
  }

  private daysAndTimesValidator(): ValidatorFn {
    // checks at least one day in a week is checked
    return (): ValidationErrors | null => {
      let someChecked = false;
      for (let i = 0; i < this.daysAndTimes.length; i++) {
        someChecked = someChecked || this.daysAndTimes.at(i).get('checked')?.value;
      }

      return someChecked ? null : { noneCheckedError: 'None checked' };
    };
  }

  private maxTimeValidator(): ValidatorFn {
    // checks time is not midnight
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === '00:00' ? { maxTimeError: 'End Time should not be midnight' } : null;
    };
  }

  private toTimeValidator(): ValidatorFn | null {
    // checks if the 'toTime' is greater than 'fromTime'
    return (control: AbstractControl): ValidationErrors | null => {
      const fromTimeCtrl = control.get('fromTime');
      const toTimeCtrl = control.get('toTime');
      const fromDateCtrl = control.get('fromDate');
      const toDateCtrl = control.get('toDate');

      if (!fromTimeCtrl || !toTimeCtrl || !fromDateCtrl || !toDateCtrl) {
        return null; // One of the controls is missing, cannot validate
      }

      const fromTime = moment(fromTimeCtrl.value, 'HH:mm');
      const toTime = moment(toTimeCtrl.value, 'HH:mm');
      const from = moment(fromDateCtrl.value).hours(fromTime.hours()).minutes(fromTime.minutes());
      const to = moment(toDateCtrl.value).hours(toTime.hours()).minutes(toTime.minutes());

      if (to.isSameOrBefore(from)) {
        toTimeCtrl.setErrors({ toTimeError: 'End Time is same or before Start Time' });
      } else if (toTimeCtrl.hasError('toTimeError')) {
        // Remove the 'toTimeError' if it no longer applies
        delete toTimeCtrl.errors!['toTimeError'];
        toTimeCtrl.updateValueAndValidity();
      }

      return null;
    };
  }
}
