<div class="contact-container">
  <app-contact-list-header
    [layoutMode]="headerLayoutMode"
    [searchLabel]="searchLabel"
    [filteringEnabled]="isContactListFilteringEnabled"
    (searchChange)="onSearchChanged($event)"
    (contactFiltered)="onContactListFiltered($event)">
  </app-contact-list-header>

  <app-contact-list
    class="contact-list-container"
    [contactFilterOptions]="{ contactType: contactListFilterOptions.contactType }"
    [contactSort]="contactListFilterOptions.contactSort"
    [contactGrouping]="contactListFilterOptions.contactGrouping"
    [search]="searchTerm"
    [listBufferPx]="1000"
    (selectedContact)="onSelectPhoneContact($event)">
    <!-- This template is referenced within the contact list component and can be used for rendering buttons on the right side of a contact item -->
    <ng-template
      #itemActions
      let-selectedContact="selectedContact">
      <!-- Transfer Mode Contact Selection Buttons -->
      <div
        class="transfer-holder"
        *ngIf="isTransferMode">
        <!-- Transfer Button. Single click if extension-only. Dropdown otherwise. -->
        <div
          class="dropdown-holder"
          data-cy="transfer-indicate">
          <ng-container *ngIf="selectedContact.tels.length > 0 || selectedContact.ext">
            <mat-icon
              *ngLet="selectedContact | singlePhoneNumber; let singlePhoneNumber"
              (click)="singlePhoneNumber && onTransfer(selectedContact, singlePhoneNumber)">
              phone_forwarded
            </mat-icon>
            <div class="dropdown-menu-card transfer-menu-position">
              <app-contact-number-menu
                [selectedContact]="selectedContact"
                title="Transfer"
                (numberSelected)="onTransfer(selectedContact, $event)"></app-contact-number-menu>
            </div>
          </ng-container>
        </div>

        <!-- Attended Transfer Button -->
        <div
          class="dropdown-holder"
          data-cy="attended-transfer-indicate">
          <ng-container *ngIf="selectedContact.tels.length > 0 || selectedContact.ext">
            <!-- Icon handles clicks if there is only one option for the menu item -->
            <mat-icon
              *ngLet="selectedContact | singlePhoneNumber; let singlePhoneNumber"
              (click)="singlePhoneNumber && onAttendedTransfer(selectedContact, singlePhoneNumber)"
              >swap_horiz</mat-icon
            >
            <div class="dropdown-menu-card">
              <app-contact-number-menu
                [selectedContact]="selectedContact"
                title="Attended transfer"
                (numberSelected)="onAttendedTransfer(selectedContact, $event)"></app-contact-number-menu>
            </div>
          </ng-container>
        </div>

        <!-- Voicemail Transfer Button. -->
        <div
          *ngIf="selectedContact.ext"
          class="dropdown-holder"
          data-cy="transfer-voicemail-indicate"
          title="Send to Voicemail">
          <mat-icon (click)="onVoicemailTransfer(selectedContact, selectedContact.ext)"> voicemail </mat-icon>
        </div>
      </div>

      <!-- Non Transfer-Mode UI -->
      <div
        *ngIf="!isTransferMode && isContactListFilteringEnabled"
        class="dropdown-holder">
        <mat-icon
          *ngIf="isAddToCallMode"
          (click)="onAddCall(selectedContact)"
          >person_add
        </mat-icon>

        <ng-container *ngIf="!isTransferMode && !isAddToCallMode">
          <ng-container *ngIf="selectedContact.tels.length > 0 || selectedContact.ext">
            <mat-icon
              *ngLet="selectedContact | singlePhoneNumber; let singlePhoneNumber"
              (click)="onSelectPhoneContact(selectedContact, singlePhoneNumber)"
              >phone</mat-icon
            >
            <div class="dropdown-menu-card">
              <app-contact-number-menu
                [selectedContact]="selectedContact"
                (numberSelected)="onSelectPhoneContact(selectedContact, $event)"></app-contact-number-menu>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div
        class="transfer-holder"
        *ngIf="!isContactListFilteringEnabled">
        <div class="dropdown-holder">
          <mat-icon
            matTooltip="Forward Voicemail"
            (click)="onForwardClicked(selectedContact.ext)"
            >phone_forwarded</mat-icon
          >
        </div>
      </div>
    </ng-template>
  </app-contact-list>
</div>
