export interface TimeRangeData {
  order: string;
  date_from: string;
  date_to: string;
  days: string;
  tod_from: string;
  tod_to: string;
  invert: string;
}

export interface TimeFrame {
  time_frame: string;
  owner: string;
  in_use: boolean;
  time_range_data: TimeRangeData[];
}

export interface SimRingDestination {
  destination: string;
  delay: number;
}

export interface AnsweringRule {
  priority?: string;
  timeFrame?: string;
  enabled: boolean;
  doNotDisturb: boolean;
  callScreening: boolean;
  forwardAlways: {
    enabled: boolean;
    destination: string;
  };
  forwardOnActive: {
    enabled: boolean;
    destination: string;
  };
  forwardBusy: {
    enabled: boolean;
    destination: string;
  };
  forwardNoAnswer: {
    enabled: boolean;
    destination: string;
  };
  forwardOffline: {
    enabled: boolean;
    destination: string;
  };
  simRing: {
    enabled: boolean;
    usersExtension: boolean;
    allDevices: boolean;
    confirmOffnet: boolean;
    otherDestinations: SimRingDestination[];
  };
}

export interface AnsweringRuleResponse {
  id?: string;
  priority: string;
  domain: string;
  user: string;
  timeframe: string;
  enabled: boolean;
  doNotDisturb: boolean;
  callScreening: boolean;
  forwardAlways: {
    enabled: boolean;
    destination: string | Record<string, never> | null;
  };
  forwardOnActive: {
    enabled: boolean;
    destination: string | Record<string, never> | null;
  };
  forwardBusy: {
    enabled: boolean;
    destination: string | Record<string, never> | null;
  };
  forwardNoAnswer: {
    enabled: boolean;
    destination: string | Record<string, never> | null;
  };
  forwardOffline: {
    enabled: boolean;
    destination: string | Record<string, never> | null;
  };
  simRing: {
    enabled: boolean;
    usersExtension: boolean;
    allDevices: boolean;
    confirmOffnet?: boolean;
    otherDestinations: SimRingDestination[];
  };
}

export enum AnsweringRuleType {
  ForwardAllCalls = 'forwardAllCalls',
  DoNotDisturb = 'doNotDisturb',
  OnlyPrimaryDeskphone = 'onlyPrimaryDeskphone',
  SimultaneousRing = 'simultaneousRing',
}

export enum TimeFrameType {
  Always = 'always',
  DaysAndTimes = 'daysAndTimes',
  SpecificDates = 'specificDates',
}

export interface GeneralResponse {
  success: boolean;
}

export interface RingDurationResponse {
  success: boolean;
  duration: number;
}

export interface InUseResponse {
  inuse: boolean;
}

export interface ForwardDestination {
  value: string;
  description: string;
}

export interface ActiveRuleResponse {
  active: string;
  time_frame: string;
  dnd_control: string;
  domain: string;
  enable: string;
  fbu_control: string;
  fna_control: string;
  fnr_control: string;
  foa_control: string;
  for_control: string;
  priority: string;
  scr_control: string;
  sim_control: string;
  user: string;
}
