import { Directive, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import equal from 'fast-deep-equal';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formGroup][monitorChanges]',
  exportAs: 'monitorChanges',
  standalone: true,
})
export class FormDirective implements OnInit {
  @Input() monitorChanges = false;

  private readonly isDirty = new BehaviorSubject<boolean>(false);
  public isDirty$ = this.isDirty.asObservable();
  private snapshot: object;

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    this.snapshot = this.formGroupDirective.form.getRawValue();
    this.formGroupDirective.form.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.monitorChanges),
        debounceTime(300)
      )
      .subscribe(() => this.isDirty.next(!equal(this.snapshot, this.formGroupDirective.form.getRawValue())));
  }
}
