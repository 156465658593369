import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  Contact,
  CONTACT_GROUP_FAVORITE_KEY,
  ContactFilterOptions,
  ContactGrouping,
  ContactItemViewModel,
  ContactSort,
  ContactType,
  ExtensionFilterOptions,
  ListViewModel,
} from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { ListActionsComponent } from '@app/shared/components/list-actions/list-actions.component';
import { NameCoinComponent } from '@app/shared/components/name-coin/name-coin.component';
import { VariableSizeVirtualScrollStrategyDirective } from '@app/shared/directives/variable-size-virtual-scroll-strategy-directive';
import { MultiSelectOption } from '@app/shared/models/utils.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgLetModule } from 'ng-let';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { DragDropTransferMenuComponent } from '../drag-drop-transfer-menu/drag-drop-transfer-menu.component';

/**
 * This component displays the app's contacts in a virtualized list. Without virtualization, the app will load
 * all of our contacts into the DOM, each of which has an image which needs to be fetched. After virtualization
 * it works out to ~20 images or so.
 *
 * Angular Material CDK's virtual scrolling component expects a flat list of items. The UI for this list has section headers
 * which are better represented as nested arrays, but we can't do that since we need a flat list so there's some
 * logic within this component to handle that conversion.
 */
@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatProgressBarModule,
    NameCoinComponent,
    CdkVirtualForOf,
    NgLetModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    VariableSizeVirtualScrollStrategyDirective,
    ListActionsComponent,
    MatMenuModule,
    DragDropTransferMenuComponent,
  ],
  selector: 'app-contact-list',
  styleUrls: ['./contact-list.component.scss'],
  templateUrl: './contact-list.component.html',
})
export class ContactListComponent implements OnInit, OnChanges {
  @ContentChild('itemActions') itemActions: TemplateRef<ElementRef>;
  @ContentChild('dragDropOutlet') dragDropOutlet: TemplateRef<ElementRef>;
  @Input() selectedId: string;
  @Input() contactFilterOptions: ContactFilterOptions;
  @Input() contactSort: ContactSort;
  @Input() contactGrouping: ContactGrouping;
  @Input() extensionType: string | undefined;
  @Input() search: string;
  @Input() listBufferPx: number = 100;
  @Input() batchDeleteDone: Observable<boolean> = new BehaviorSubject(true);

  @Output() selectedContact = new EventEmitter<Contact>();
  @Output() deleteBatchEvent = new EventEmitter<Contact[]>();
  @Output() itemClickedEvent = new EventEmitter<void>();
  @Output() dragDropEvent = new EventEmitter<{ contact: Contact; event: DragEvent }>();

  protected ContactType = ContactType;
  protected allListViewModels: ListViewModel[] = [];
  protected filteredListViewModels: ListViewModel[] = [];
  protected collapsedGroups = new Map<string, boolean>();
  public selectedContacts: Contact[] = [];

  protected get filteredContactsCount(): number {
    return this.filteredListViewModels.filter((item) => item.type === 'contact').length;
  }

  protected MultiSelectOptions = [MultiSelectOption.All, MultiSelectOption.None];

  constructor(protected contactService: ContactService, private appConfigService: AppConfigService) {}

  ngOnInit() {
    this.contactService.data$
      .pipe(
        untilDestroyed(this),
        map((contacts) => contacts.filter((contact) => contact.directoryListing))
      )
      .subscribe((contacts) => {
        this.allListViewModels = this.contactsToViewModels(contacts);
        this.filteredListViewModels = this.filterAllViewModels(
          this.contactFilterOptions,
          this.extensionType,
          this.search,
          this.collapsedGroups
        );
      });
    this.batchDeleteDone.subscribe((_isDone) => {
      this.clearAllSelected();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.contactFilterOptions ||
      changes.contactSort ||
      changes.contactGrouping ||
      changes.search ||
      changes.extensionType
    ) {
      if (changes.contactSort || changes.contactGrouping) {
        this.allListViewModels = this.contactsToViewModels(this.contactService.source.value);
      }
      if (!this.deepEqual(<ContactFilterOptions>changes.contactFilterOptions, this.contactFilterOptions)) {
        this.clearAllSelected();
      }

      this.filteredListViewModels = this.filterAllViewModels(
        this.contactFilterOptions,
        this.extensionType,
        this.search,
        this.collapsedGroups
      );
    }
  }

  /** Converts the provided contacts into a flat list of view models. This list will be sorted
   * and then grouped as defined by the inputs to the component. */
  private contactsToViewModels(contacts: Contact[]): ListViewModel[] {
    const sortedContacts = this.contactService.sortContactsBy(this.contactSort, contacts);
    const groupedContacts = this.contactService.groupContactsBy(this.contactGrouping, this.contactSort, sortedContacts);
    const viewModels: ListViewModel[] = [];
    const sortedGroupNames = Object.keys(groupedContacts).sort();

    // Move favorites to front of list if it exists
    const favoriteIndex = sortedGroupNames.indexOf(CONTACT_GROUP_FAVORITE_KEY);
    if (favoriteIndex !== -1) {
      // Splice it out of its current location and append it to the front
      sortedGroupNames.unshift(...sortedGroupNames.splice(favoriteIndex, 1));
    }

    // For each group key, push a header and array of contacts
    for (const group of sortedGroupNames) {
      viewModels.push(
        { type: 'groupHeader', groupName: group, height: 20 },
        ...groupedContacts[group].map((contact) => {
          return {
            type: 'contact',
            contact,
            groupName: group,
            height: 56,
          } as ContactItemViewModel;
        })
      );
    }

    return viewModels;
  }

  /**
   * Filtering view models happens in two stages:
   * - Filter based on filter options and search term
   * - After filtering, if a group doesn't have any adjacent contact items, it should be removed as well. This also accounts
   *   for the collapsed state.
   */
  private filterAllViewModels(
    contactFilterOptions: ContactFilterOptions,
    extensionType: string | undefined,
    searchTerm: string | undefined,
    collapsedGroups: Map<string, boolean>
  ): ListViewModel[] {
    const groupNamesWithContactsWhenFiltered = new Set<string>();
    const search = searchTerm?.toLowerCase();
    const isSearching = search && search.length > 0;

    // We're going to filter twice. The first time we will filter all contacts based on the provided
    // contactType and searchTerm. Once contacts are filtered, we can determine whether we want to
    // show a group based on whether it has any contacts in the list with the same group name.
    let filteredViewModels: ListViewModel[] = [];
    for (const viewModel of this.allListViewModels) {
      // Always add the group header in the first pass
      if (viewModel.type === 'groupHeader') {
        filteredViewModels.push(viewModel);
        continue;
      }

      const contactViewModel = viewModel as ContactItemViewModel;
      let passedFilter = contactViewModel.contact.directoryListing;

      // Ensure the contact passes the filter options provided
      passedFilter =
        passedFilter && this.contactService.contactPassesFilterOptions(contactViewModel.contact, contactFilterOptions);

      // Ensure we're filtering by extension, if set.
      if (passedFilter && extensionType && extensionType !== ExtensionFilterOptions.All) {
        passedFilter = passedFilter && contactViewModel.contact.tels.some((t) => t.type === extensionType);
      }

      // Ensure the contact matches the search term.
      if (passedFilter && isSearching) {
        const contact = contactViewModel.contact;
        passedFilter =
          passedFilter &&
          ((contact.fullName && contact.fullName.toLowerCase().includes(search)) ||
            (contact.username && contact.username.toLowerCase().includes(search)) ||
            (contact.ext && contact.ext.toLowerCase() === search) ||
            (contact.tels && contact.tels.some((t) => t.number.includes(search))));
      }

      // If we get this far, the contact should be displayed unless the group is collapsed. Add the contact id to the Set
      // tracking which results are valid, so we can decide whether the associated GroupHeader should be displayed in the next pass.
      if (passedFilter) {
        groupNamesWithContactsWhenFiltered.add(contactViewModel.groupName);

        if (collapsedGroups.get(contactViewModel.groupName) !== true) {
          filteredViewModels.push(viewModel);
        }
      }
    }

    // At this point we have a flat array of view models where only relevant contacts have made it
    // through filtering. We now need to iterate again removing any GroupHeaderViewModels without
    // any associated contacts.
    filteredViewModels = filteredViewModels.filter((viewModel) => {
      return viewModel.type === 'groupHeader' ? groupNamesWithContactsWhenFiltered.has(viewModel.groupName) : true;
    });
    return filteredViewModels;
  }

  protected trackByViewModel(_index: number, item: ListViewModel): string {
    let key = `${item.type}-${item.groupName}-${item.height}`;
    if ('contact' in item) {
      key += `-${(item as ContactItemViewModel).contact.id}`;
    }
    return key;
  }

  protected isGroupNameCollapsed(groupName: string): boolean {
    return this.collapsedGroups.get(groupName) || false;
  }

  onToggleGroupCollapsed(groupName: string) {
    this.collapsedGroups.set(groupName, !this.isGroupNameCollapsed(groupName));

    this.filteredListViewModels = this.filterAllViewModels(
      this.contactFilterOptions,
      this.extensionType,
      this.search,
      this.collapsedGroups
    );
  }

  onContactSelected(contact: Contact) {
    this.selectedId = contact.id;
    this.selectedContact.emit(contact);
    this.itemClickedEvent.emit();
  }

  toggleToDelete(event: Event | null, record: Contact): void {
    event?.stopPropagation();
    if (this.appConfigService.hasOfficeManagerRole && record.type === ContactType.Shared) {
      this.addToDeleteList(record);
    } else if (record.type === ContactType.Personal) {
      this.addToDeleteList(record);
    }
  }

  private addToDeleteList(record: Contact): void {
    record.trash = !record.trash;
    this.selectedContacts.includes(record)
      ? this.selectedContacts.splice(this.selectedContacts.indexOf(record), 1)
      : this.selectedContacts.push(record);
  }

  deleteBatch(): void {
    this.deleteBatchEvent.emit(this.selectedContacts);
  }

  clearAllSelected() {
    this.selectedContacts = [];
    for (const item of this.filteredListViewModels) {
      if (item.type === 'contact') {
        (item as ContactItemViewModel).contact.trash = false;
      }
    }
  }

  multiSelect(event: MultiSelectOption) {
    switch (event) {
      case MultiSelectOption.All: {
        this.selectedContacts = this.getSelectedContacts(this.filteredListViewModels);
        for (const item of this.filteredListViewModels) {
          if (item.type === 'contact') {
            (item as ContactItemViewModel).contact.trash = true;
          }
        }
        break;
      }
      case MultiSelectOption.None: {
        this.clearAllSelected();
        break;
      }
    }
  }

  private getSelectedContacts(filteredViewModels: ListViewModel[]) {
    return filteredViewModels.map((item) => (item as ContactItemViewModel).contact).filter((item) => !!item);
  }

  private deepEqual(contactFilterOptions: ContactFilterOptions, contactFilterOptions2: ContactFilterOptions) {
    return (
      contactFilterOptions?.contactType === contactFilterOptions2?.contactType &&
      contactFilterOptions?.pbxDepartment === contactFilterOptions2?.pbxDepartment &&
      contactFilterOptions?.pbxSite === contactFilterOptions2?.pbxSite
    );
  }

  protected handleDragEnterEvent(contact: Contact, event: DragEvent) {
    event.stopPropagation();
    this.setDraggingForEvent(event, true);
    this.selectedId = contact.id;
  }

  protected handleDragOverEvent(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  protected handleDragLeaveEvent(contact: Contact, event: DragEvent) {
    event.stopPropagation();
    this.setDraggingForEvent(event, false);

    if (this.selectedId === contact.id) {
      this.selectedId = '';
    }
  }

  protected handleDragDropEvent(contact: Contact, event: DragEvent) {
    event.stopPropagation();
    this.setDraggingForEvent(event, false);
    this.dragDropEvent.emit({ contact, event });
  }

  private setDraggingForEvent(event: DragEvent, isDragging: boolean) {
    if (isDragging) {
      (event.currentTarget as HTMLElement)
        ?.querySelector('.contact-list-item-details')
        ?.classList.add('disable-pointer-events');
    } else {
      (event.currentTarget as HTMLElement)
        ?.querySelector('.contact-list-item-details')
        ?.classList.remove('disable-pointer-events');
    }
  }
}
