import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactType } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { ImportContactsComponent } from '@app/preferences/components/contacts/import-contacts/import-contacts.component';
import { CallingSettings } from '@app/preferences/models/settings.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver-es';
import { firstValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  protected selectedContactOption: ContactType = ContactType.Personal;
  protected readonly ContactType = ContactType;
  public contactMatching: FormControl;
  protected contactMatchingLoading = false;
  private callingConfig: CallingSettings;

  constructor(
    private contactService: ContactService,
    protected appConfigService: AppConfigService,
    private snackBar: SnackbarService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private configService: AppConfigService
  ) {
    this.contactMatching = this.fb.control(true);
  }

  ngOnInit(): void {
    this.configService.data$.pipe(untilDestroyed(this)).subscribe((config) => {
      if (config && config.settings?.preferences) {
        this.callingConfig = config.settings.preferences.calling || {};
        this.contactMatching.setValue(!config.settings.preferences.calling?.disableContactMatching);
      }
    });
  }

  async exportContacts() {
    const response = await firstValueFrom(this.contactService.exportContacts(this.selectedContactOption));
    if (response) {
      saveAs(new Blob([response], { type: 'application/octet-stream' }), 'contacts.csv');
      this.snackBar.open('Contacts has been downloaded', 'Ok');
    }
  }

  handleClickOnImportContact() {
    this.dialog.open(ImportContactsComponent);
  }

  protected async onChangeContactMatching() {
    this.contactMatchingLoading = true;
    await firstValueFrom(
      this.configService.updatePreferences({
        calling: {
          ...this.callingConfig,
          disableContactMatching: !this.contactMatching.value,
        },
      })
    );
    this.contactMatchingLoading = false;
  }
}
