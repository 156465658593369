import { NgClass, NgForOf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MultiSelectOption } from '@app/shared/models/utils.models';

@Component({
  standalone: true,
  selector: 'app-list-actions',
  templateUrl: './list-actions.component.html',
  imports: [MatMenuModule, NgForOf, NgClass],
  styleUrls: ['./list-actions.component.scss'],
})
export class ListActionsComponent {
  @Input() itemsCount: number;
  @Input() selectedCount: number;
  @Input() actionButtonText: string = 'Delete';
  @Input() multiSelectOptions: MultiSelectOption[] = [
    MultiSelectOption.All,
    MultiSelectOption.None,
    MultiSelectOption.Read,
    MultiSelectOption.Unread,
  ];
  @Output() deleteOrRestoreItemEvent = new EventEmitter<void>();
  @Output() clearEvent = new EventEmitter<void>();
  @Output() selectOptionEvent = new EventEmitter<MultiSelectOption>();

  clearAll() {
    this.clearEvent.emit();
  }

  deleteBatch() {
    this.deleteOrRestoreItemEvent.emit();
  }

  selectOption(option: MultiSelectOption) {
    this.selectOptionEvent.emit(option);
  }
}
