<div class="h-100 flex flex-col">
  <div>
    <div class="flex align-items-center greeting-title">
      <button
        mat-icon-button
        class="back-button"
        (click)="setSelectedPage('Main')">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <h1 class="title mb-0">Manage {{ audioType === AudioType.greeting ? 'Greeting' : 'Recorded Name' }}</h1>
    </div>
    <div class="content-secondary">
      <mat-form-field
        appearance="outline"
        class="w-100 density-minus-3">
        <mat-label>{{ audioType === 'greeting' ? 'Enter greeting name' : 'Enter name' }}</mat-label>
        <input
          [(ngModel)]="greetingName"
          (ngModelChange)="validate()"
          autocomplete="off"
          matInput />
      </mat-form-field>
    </div>
    <div class="section">
      <span class="sub-title d-block w-100 border-none">Recording Method</span>
      <div class="content">
        <mat-radio-group
          ngDefaultControl
          color="primary"
          class="flex flex-col"
          [(ngModel)]="recordingMethod"
          (ngModelChange)="onRecordingMethodChanged()">
          <mat-radio-button [value]="'TextToSpeech'">Text-to-speech</mat-radio-button>
          <mat-radio-button [value]="'Upload'">Upload or Record</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <ng-container *ngIf="recordingMethod === 'TextToSpeech'">
      <div class="section">
        <span class="sub-title d-block w-100 border-none">Message</span>
        <div class="content">
          <mat-form-field
            appearance="outline"
            class="w-100 fs-14 density-minus-3">
            <textarea
              [(ngModel)]="ttsScript"
              (ngModelChange)="validate()"
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="7"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section">
        <span class="sub-title d-block w-100 border-none">Language</span>
        <div class="content">
          <mat-form-field
            appearance="outline"
            class="select-dropdown density-minus-3">
            <div class="flex">
              <mat-select
                [(value)]="selectedTTSLanguage"
                (valueChange)="onTtsLanguageChanged($event)">
                <mat-option
                  *ngFor="let language of ttsLanguages"
                  [value]="language">
                  {{ language.name }} ({{ language.country }})
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="section">
        <span class="sub-title d-block w-100 border-none">Voice</span>
        <div class="content flex align-items-center relative">
          <div
            class="spinner voice-spinner"
            *ngIf="isLoadingVoices">
            <mat-spinner [diameter]="22"></mat-spinner>
          </div>
          <mat-form-field
            appearance="outline"
            class="select-dropdown density-minus-3">
            <div class="flex">
              <mat-select
                [disabled]="!selectedTTSLanguage"
                [(value)]="selectedTTSVoice"
                (valueChange)="validate()">
                <mat-option
                  *ngFor="let voice of ttsVoices"
                  [value]="voice">
                  {{ voice.name }} ({{ voice.type }} {{ voice.subType }})
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
          <button
            class="play-pause--square"
            (click)="playTtsPreview()"
            color="primary"
            mat-mini-fab>
            <mat-icon
              aria-hidden="true"
              class="play-track"
              >{{ playingAudioId === 'voice' ? 'pause' : 'play_arrow' }}
            </mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="recordingMethod === 'Upload'">
      <ng-container *ngIf="audio === null && recordedFile === null && fileToUpload === undefined">
        <div class="flex align-items-center m-t-32">
          <button
            [disabled]="isRecording"
            color="primary"
            mat-raised-button
            type="button"
            (click)="handleClickOnUpload()">
            Upload a file
          </button>
          <span class="m-r-8 m-l-8"> - or - </span>
          <button
            [disabled]="isRecording"
            color="primary"
            mat-raised-button
            type="button"
            (click)="handleClickOnShowRecorder()">
            Record New {{ audioType === AudioType.name ? 'Name' : 'Greeting' }}
          </button>
        </div>
      </ng-container>
      <input
        #file
        (change)="onFileSelect($event)"
        type="file"
        class="d-none"
        accept="audio/mpeg, audio/wav" />
      <ng-container *ngIf="fileToUpload !== undefined">
        <mat-form-field
          appearance="outline"
          class="w-100 density-minus-3 file-upload-container m-t-32">
          <mat-label>File name</mat-label>
          <input
            readonly
            [ngModel]="fileToUpload.name"
            (ngModelChange)="validate()"
            autocomplete="off"
            matInput />
        </mat-form-field>
        <div class="w-100 flex justify-content-end">
          <button
            class="action-button"
            (click)="file.click()">
            <mat-icon color="primary">upload</mat-icon>
            <span class="action">Select a different file</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showRecorder && recordingMethod === 'Upload'">
      <div
        class="recorder relative"
        *ngIf="recordedFile === null">
        <div
          class="spinner recorder-spinner"
          *ngIf="recorderIsDisabled && isRecording">
          <mat-spinner [diameter]="22"></mat-spinner>
        </div>
        <div class="flex align-items-center">
          <div class="record-icon-container justify-content-center align-items-center">
            <div
              class="record-icon cursor-pointer"
              *ngIf="!isRecording"
              (click)="recordClickHandler()"></div>
            <div
              class="recording-icon cursor-pointer"
              *ngIf="isRecording"
              (click)="recordClickHandler()"></div>
          </div>
          <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
          <div class="voice-mail-slider-container">
            <mat-slider
              disabled
              min="0"
              [max]="audioType === AudioType.name ? 10 : 20"
              step="1"
              ngDefaultControl
              class="voice-mail-slider w-100"
              #ngSlider
              ><input
                matSliderThumb
                [(ngModel)]="recordingSeconds"
                #ngSliderThumb="matSliderThumb"
            /></mat-slider>
          </div>
        </div>
      </div>
      <div
        class="recorder"
        *ngIf="recordedFile !== null && recordingTrack && recordingTrack.duration">
        <div class="flex align-items-center">
          <button
            class="play-pause"
            color="primary"
            (click)="onRecordedFilePlayClicked()"
            mat-mini-fab>
            <mat-icon
              aria-hidden="true"
              class="play-track"
              >{{ playingAudioId === 'recorded' ? 'pause' : 'play_arrow' }}
            </mat-icon>
          </button>
          <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
          <div class="voice-mail-slider-container">
            <mat-slider
              min="0"
              [max]="recordingTrack.duration"
              step="1"
              ngDefaultControl
              class="voice-mail-slider w-100"
              #ngSlider
              ><input
                matSliderThumb
                [(ngModel)]="recordingSeconds"
                #ngSliderThumb="matSliderThumb"
            /></mat-slider>
          </div>
          <div class="recorder-delete-container">
            <button
              (click)="deleteRecordedFile()"
              color="primary"
              mat-icon-button>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="audio !== null && recordingMethod === 'Upload'">
      <div class="recorder">
        <div class="flex align-items-center">
          <button
            class="play-pause"
            color="primary"
            (click)="togglePlayAudio.emit()"
            mat-mini-fab>
            <mat-icon
              aria-hidden="true"
              class="play-track"
              >{{ playingAudioId === audio.index ? 'pause' : 'play_arrow' }}
            </mat-icon>
          </button>
          <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
          <div class="voice-mail-slider-container">
            <mat-slider
              min="0"
              [max]="Number(audio.duration)"
              step="1"
              ngDefaultControl
              class="voice-mail-slider w-100"
              #ngSlider
              ><input
                matSliderThumb
                [(ngModel)]="recordingSeconds"
                #ngSliderThumb="matSliderThumb"
            /></mat-slider>
          </div>
          <div class="recorder-delete-container">
            <button
              (click)="deleteAudioToEdit()"
              color="primary"
              mat-icon-button>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex justify-content-end align-center bottom-bar mt-auto">
    <button
      [disabled]="isRecording"
      class="cancel-button"
      mat-button
      type="button"
      (click)="setSelectedPage('Main')">
      Cancel
    </button>
    <button
      color="primary"
      mat-raised-button
      class="m-l-12 save-button"
      type="button"
      [disabled]="!isValid || isProcessing">
      <span
        *ngIf="!isProcessing; else spinner"
        (click)="handleSaveClicked()"
        >Save</span
      >
      <ng-template #spinner>
        <mat-spinner
          class="processing-spinner"
          color="accent"></mat-spinner>
      </ng-template>
    </button>
  </div>
</div>
