<app-banner
  [open]="open"
  [text]="'Try one-click dialing - ' + (pwaInstalled ? '' : 'Install and ') + 'Register ConnectUC so it can make calls when you click phone number links.'"
  [actions]="[
    {
      text: 'Learn more',
      tooltip: 'Link to ConnectUC one-click dialing instructions',
    },
    pwaInstalled
      ? {
        text: 'Register now',
        tooltip: 'ConnectUC is installed, Register ConnectUC so it can make calls when you click on phone number links',
      }
      : {
        text: 'Install app',
        tooltip: 'Installs ConnectUC as a Progressive Web App and then Register it to open phone number links',
      },
  ]"
  (actionClick)="handleActionClick($event)"
  type="info"
  customIcon="notifications_outline"
  (closeClick)="dismiss()"
  closeTooltip="Dismiss this message"
></app-banner>
