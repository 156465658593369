import { DraftMessageService } from '@app/sms/services/draft-message.service';

export function blobToUrl(blob: Blob) {
  return window.URL.createObjectURL(blob);
}

export function validateAvatarFile(file: File): 'IsValid' | 'LargeSize' | 'WrongType' {
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
  const maxSizeInKB = 1024;
  if (!allowedFileTypes.includes(file.type)) {
    return 'WrongType';
  } else if (file.size / 1024 > maxSizeInKB) {
    return 'LargeSize';
  }
  return 'IsValid';
}

export type ContentType = 'image' | 'video' | 'audio' | 'document' | 'other';

export function contentTypeForFileType(fileType?: string): ContentType {
  if (!fileType) {
    return 'other';
  }

  if (fileType.includes('image')) {
    return 'image';
  } else if (fileType.includes('video')) {
    return 'video';
  } else if (fileType.includes('audio')) {
    return 'audio';
  } else if (DraftMessageService.contentTypeForMimeType(fileType) === 'document') {
    return 'document';
  } else {
    return 'other';
  }
}
