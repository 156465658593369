import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { CallHistory } from '@app/call-history/models/call-history.models';
import { AudioPlayerService } from '@app/core/services/audio-player.service';
import { SettingsService } from '@app/preferences/services/settings.service';
import { MatButtonLoadingDirective } from '@app/shared/directives/mat-button-loading.directive';
import { Track } from '@app/shared/models/track.model';
import { SanitizePipe } from '@app/shared/pipes/sanitize.pipe';
import { SecondsToMinutesPipe } from '@app/shared/pipes/seconds-to-minutes.pipe';
import { AudioUtil } from '@app/shared/utils/audio.util';
import { ForwardVoiceMailDialogComponent } from '@app/voicemail/components/forward-voice-mail-dialog/forward-voice-mail-dialog.component';
import { Voicemail } from '@app/voicemail/models/voicemail.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, throttleTime } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    SecondsToMinutesPipe,
    MatSliderModule,
    SanitizePipe,
    MatButtonModule,
    MatButtonLoadingDirective,
  ],
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
  private static readonly DefaultDuration = 0.01;

  @ViewChild('audioPlayer', { static: true }) player: ElementRef<HTMLMediaElement>;

  @Input() message: Voicemail | CallHistory;
  @Input() showDeleteButton = true;
  @Input() showForwardButton = false;
  @Input() showRetrieveButton = false;
  @Input() set audio(playlist: Track) {
    this.audioPlayerService.setCurrentTrack(playlist);
  }
  @Output() delete = new EventEmitter<Voicemail | CallHistory>();
  @Output() download = new EventEmitter<Voicemail | CallHistory>();
  @Output() retrieve = new EventEmitter<Voicemail | CallHistory>();
  @Output() played = new EventEmitter<void>();
  @Output() timeUpdate = new EventEmitter<number>();

  public loaderDisplay = true;
  protected currentTrack: Track = {} as Track;
  protected isPlaying = false;

  protected get playerCurrentTime(): number {
    return this.player.nativeElement.currentTime ?? 0;
  }
  private timeUpdateSubject = new Subject<number>();

  protected get duration(): number {
    const duration = this.player.nativeElement.duration;
    //Recorded audio sets the duration to Infinity, set it to a min value
    return duration === Number.POSITIVE_INFINITY || duration === Number.NaN
      ? AudioPlayerComponent.DefaultDuration
      : duration;
  }

  protected readonly DefaultDuration = AudioPlayerComponent.DefaultDuration;

  constructor(
    private audioPlayerService: AudioPlayerService,
    private settingService: SettingsService,
    public dialog: MatDialog
  ) {
    // HTMLAudioElement's `timeupdate` event can refresh at a high rate so we throttle it here
    // to prevent the UI from updating too frequently.
    this.timeUpdateSubject
      .pipe(untilDestroyed(this), throttleTime(500))
      .subscribe((value) => this.timeUpdate.emit(value));
  }

  async ngOnInit() {
    this.player.nativeElement.addEventListener('playing', () => (this.isPlaying = true));
    this.player.nativeElement.addEventListener('pause', () => (this.isPlaying = false));
    this.player.nativeElement.addEventListener('loadedmetadata', () => (this.loaderDisplay = false));
    this.player.nativeElement.addEventListener('timeupdate', () => {
      const currentTime = this.playerCurrentTime;
      this.audioPlayerService.setCurrentTime(currentTime);
      this.timeUpdateSubject.next(currentTime);
    });

    this.audioPlayerService
      .getCurrentTrack()
      .pipe(untilDestroyed(this))
      .subscribe((track) => {
        if (track) {
          this.isPlaying = false;
          this.currentTrack = track;
          this.player.nativeElement.currentTime = 0;
        }
      });
  }

  async ngAfterViewInit() {
    this.settingService.audioSettings$
      .pipe(untilDestroyed(this))
      .subscribe(async () => await this.settingService.setSpeaker(this.player.nativeElement as AudioUtil));
  }

  /** Begins playback from the provided time offset (in seconds) */
  public playAtTime(time: number) {
    this.player.nativeElement.currentTime = time;
    this.player.nativeElement.play();
  }

  protected handleSliderTimePositionChange(value: number) {
    this.player.nativeElement.currentTime = value;
  }

  protected handlePlayPauseButtonClick() {
    if (this.loaderDisplay) {
      return;
    }

    if (this.player.nativeElement.paused) {
      this.player.nativeElement.play();
      this.played.emit();
    } else {
      this.player.nativeElement.pause();
    }
  }

  protected handleDownloadClick(message: Voicemail | CallHistory) {
    this.download.emit(message);
  }

  protected handleDeleteClick(message: Voicemail | CallHistory) {
    this.delete.emit(message);
  }

  protected handleForwardVoicemailClick() {
    this.dialog.open(ForwardVoiceMailDialogComponent, {
      data: { id: this.message.id },
      panelClass: 'forwardVoicemailModal',
      disableClose: true,
      height: '705px',
    });
  }

  protected handleRetrieveVoicemailClick(message: Voicemail | CallHistory) {
    this.retrieve.emit(message);
  }
}
