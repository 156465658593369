import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChimeSocketService } from '@app/chat/services/chime-socket.service';
import { WebsocketInit } from '@app/core/models/connection-status';
import { ApiService } from '@app/core/services/api.service';
import { WsService } from '@app/core/services/ws.service';
import { UserMessageReadSummary } from '@app/meetings/models/chime.models';
import { UnreadCount } from '@app/sms/models/sms.models';
import { ChannelMessageType } from '@aws-sdk/client-chime-sdk-messaging';
import { BehaviorSubject, catchError, filter, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnreadMessageService extends ApiService implements WebsocketInit {
  private readonly source: BehaviorSubject<UnreadCount> = new BehaviorSubject<UnreadCount>({});
  public readonly data$ = this.source.asObservable();
  private summaries: {
    [key: string]: UserMessageReadSummary;
  } = {};

  constructor(private chimeSocketService: ChimeSocketService, http: HttpClient, private webSocketService: WsService) {
    super(http);

    this.chimeSocketService.channelDetailsSubject$.subscribe(({ channel, messages }) => {
      // Count how many messages are between the readMarkerTimestamp and the channel's lastMessageTimestamp
      // Get the current source value, update it with the new unread count, and broadcast the change
      const unreadCountMap = this.source.getValue();
      unreadCountMap[channel.channelArn] = channel.appInstanceUserMembershipSummary.readMarkerTimestamp
        ? messages.filter(
            (message) => message.createdTimestamp > channel.appInstanceUserMembershipSummary.readMarkerTimestamp
          ).length
        : messages.length;
      this.source.next(unreadCountMap);
    });

    this.chimeSocketService.channelMessageSubject$
      .pipe(filter(({ message }) => message.type !== ChannelMessageType.CONTROL))
      .subscribe(({ channelArn }) => {
        // When a message is received on the socket, check it see if we're currently rendering that conversation.
        // If not, increment the unread count.
        // TODO: We may want to eventually make this more sophisticated so if the channelArn _does_ match the current
        // conversation, we still may increment the count if the user isn't scrolled to the bottom of the conversation.
        const channelId = channelArn.split('/').pop();
        if (channelId && document.location.href.search(channelId) === -1) {
          this.incrementUnreadCount(channelArn);
        }
      });
  }

  bindSocketEvents() {
    this.webSocketService.socket.on('UpdateChannelReadMarker', (data: { channelArn: string }) => {
      this.setUnreadCount(data.channelArn, 0);
    });
  }

  public getChannelSummary(channelArn: string): UserMessageReadSummary | undefined {
    return this.summaries[channelArn];
  }

  public getUnreadCount(channelArn: string): number {
    return this.source.getValue()[channelArn] || 0;
  }

  private incrementUnreadCount(channelArn: string) {
    const unreadCount = this.source.getValue();
    const count = (unreadCount[channelArn] || 0) + 1;
    this.setUnreadCount(channelArn, count);
  }

  private setUnreadCount(channelArn: string, count: number) {
    const unreadCount = this.source.getValue();
    unreadCount[channelArn] = count;
    this.source.next(unreadCount);
  }

  public updateReadMarker(channelArn: string | string[]) {
    return this.post('users/{me}/user/readmarker', {
      channel_arn: channelArn,
    })
      .pipe(
        catchError(() => {
          return of();
        })
      )
      .subscribe((failedChannels: string[]) => {
        const channels = (Array.isArray(channelArn) ? channelArn : [channelArn]).filter(
          (channel) => !failedChannels.includes(channel)
        );
        channels.forEach((arn) => this.setUnreadCount(arn, 0));
      });
  }
}
