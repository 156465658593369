import { Injectable } from '@angular/core';
import { eventCallback, EventData, EventName } from '@app/core/models/event-bus.models';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  subject$ = new Subject<EventData>();

  /**
   * Fire a new event
   * @param event
   */
  emit(event: EventData): void {
    this.subject$.next(event);
  }

  /**
   * Register a new listener for any event pass a message to that callback
   * @param eventName
   * @param action
   */
  on(eventName: EventName, action: eventCallback): Subscription {
    return this.subject$.pipe(filter((event: EventData) => event.name === eventName)).subscribe(action);
  }
}
