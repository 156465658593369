import { Pipe, PipeTransform } from '@angular/core';
import { canBePhoneNumber, formatPhoneNumber } from '@app/shared/utils/phone.util';

@Pipe({
  standalone: true,
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value?: string | number): string {
    if (!value) {
      return '';
    }

    if (typeof value === 'string' && !canBePhoneNumber(value)) {
      return value;
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    if (value.startsWith('011')) {
      value = value.replace(/^011/, '+');
    }

    return formatPhoneNumber(value);
  }
}
