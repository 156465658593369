import { Sip } from '@app/phone/models/sip';
import { Settings } from '@app/preferences/models/settings.models';

export interface Colors {
  primaryText: string;
  baseText: string;
  accentText: string;
  primaryAccent: string;
  sideNavActive: string;
}

export interface ConfigPayload {
  config: Config;
  time: number;
}

export interface Config {
  colors: Colors;
  sip: Sip;
  settings: Settings;
}

export interface FeatureConfig {
  setting_value: number;
  setting: {
    name: string;
  };
}

export enum AppFeature {
  Chat = 'cuc:enableMessaging',
  SMS = 'cuc:enableWebSMS',
  Fax = 'cuc:enableFax',
  Meetings = 'cuc:enableMeetings',
  Park = 'cuc:enablePark',
  OnDemandRecording = 'cuc:enableOnDemandRecording',
  AttendantConsole = 'cuc:enableAttendantConsole',
  AttendantConsoleActiveCalls = 'cuc:enableAttendantConsoleActiveCalls',
  CallRecordingPlayback = 'cuc:callRecordingPlayback',
  CallRecordingPause = 'cuc:recordPause',
  Hipaa = 'cuc:enableHipaa',
  ReportIssue = 'cuc:enableReportIssue',
  PBXPortal = 'cuc:enablePbxPortal',
  CallCenterAgent = 'cuc:enableAgentConsole',
  CallCenterAgentQueueLogin = 'cuc:enableCCAGranularLogin',
  ModifyAnsweringRules = 'cuc:web_modifyAnsweringRule',
  ModifyTimeFrames = 'cuc:web_modifyTimeFrames',
  ModifyBlockedNumbers = 'cuc:web_modifyBlockedNumbers',
  ModifyMusicOnHold = 'cuc:web_modifyHoldPreferences',
  ModifyVoicemail = 'cuc:web_modifyVoicemailPreferences',
  Branding = 'cuc:web_enableBranding',
  UCWebAccess = 'cuc:web_enableConnectUC',
  WebPopIntegration = 'cuc:enableWebPopIntegrations',
}

export interface CallSocketData {
  senderId: string;
  senderPhone: string;
  receiverId: string;
  receiverPhone: string;
  event: CallSocketEvent;
}

export enum CallSocketEvent {
  Hold = 'hold',
  UnHold = 'unHold',
}
