import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';

@Component({
  standalone: true,
  imports: [MatIconModule, CommonModule, ImageCropperModule],
  selector: 'app-transform-image',
  templateUrl: 'transform-image.component.html',
  styleUrls: ['transform-image.component.scss'],
})
export class TransformImageComponent {
  @Output() cancelEvent = new EventEmitter<void>();
  @Output() saveEvent = new EventEmitter<string>();
  @Input() title: string;
  @Input() imageChangedEvent!: Event;
  resizedImage = '';
  rotation = 0;

  imageCropped(event: ImageCroppedEvent): void {
    this.resizedImage = event.base64!;
  }

  changeRotation() {
    this.rotation = --this.rotation;
  }

  onSaved() {
    this.saveEvent.emit(this.resizedImage);
  }

  onCanceled(): void {
    this.cancelEvent.emit();
  }
}
