import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { FeatureAnnouncementBarData } from '@app/core/models/feature-announcement.model';

@Component({
  selector: 'app-feature-annoucement',
  templateUrl: './feature-announcement.component.html',
  styleUrls: ['./feature-announcement.component.scss'],
})
export class FeatureAnnoucementComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) protected data: FeatureAnnouncementBarData,
    private snackBar: MatSnackBarRef<FeatureAnnoucementComponent>
  ) {}

  protected closeAction() {
    this.snackBar.dismiss();
  }

  protected onAction() {
    this.closeAction();
    this.data.action();
  }
}
