import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { environment } from '@environment/environment';

interface VersionEventAppData {
  minVersion: string;
}

export async function checkUpdate(swUpdate: SwUpdate) {
  if (!swUpdate.isEnabled) {
    return;
  }

  /**
   * Subscribe to verison updates and then call `checkForUpdate` manually. Calling manually will ensures the check happens at
   * launch. While the check will return a boolean indicating the presence of an update, it does not have the `appData` payload
   * we need to determine if the update should be optional or required.
   *
   * Manually calling checkForUpdate is necessary instead of only subscribing to `versionUpdates` (how it was done previously)
   * since the time it takes for that event to be emitted is outside the bounds of what we want. With this approach, the app's manifest file
   * is downloaded immediately (as part of the check) and triggers a version update.
   */
  swUpdate.versionUpdates.subscribe((data: VersionEvent) => {
    console.log(`update: version update: ${data.type}`);
    if (data.type === 'VERSION_READY') {
      console.log(`update: version ready: ${data.type}`);

      // If the hashes are the same, don't prompt the user to update.
      if (data.currentVersion.hash === data.latestVersion.hash) {
        return;
      }

      // If there's no appData, only an optional update is needed
      const latestAppData = data.latestVersion.appData as VersionEventAppData;
      if (!latestAppData) {
        promptOptionalUpdate();
        return;
      }

      // If the min version is greater than the current version, force the user to update. Otherwise
      // the update is optional
      const forceUpdateNeeded = compareSemver(latestAppData.minVersion, environment.awsCommitId) > 0;
      forceUpdateNeeded ? promptForceUpdate() : promptOptionalUpdate();
    }
  });

  const result = await swUpdate.checkForUpdate();
  console.log('update: check for update result', result);
}

function promptOptionalUpdate() {
  if (confirm('New version available. Load New Version?')) {
    window.location.reload();
  } else {
    updateRejected = true;
  }
}

function promptForceUpdate() {
  alert(
    'A critical update is available. You must update to continue using this application. Note: This is a quick process but updating will end all active calls and refresh the app.'
  );
  window.location.reload();
}

/**
 * Returns -1 if version1 < version2, 0 if equal, 1 if version1 > version2
 */
export function compareSemver(version1: string, version2: string): number {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const parseVersion = (version: string) => {
    const [mainVersion] = version.split('-');
    const [major, minor, patch] = mainVersion.split('.').map(Number);
    return [major || 0, minor || 0, patch || 0];
  };

  const [major1, minor1, patch1] = parseVersion(version1);
  const [major2, minor2, patch2] = parseVersion(version2);

  if (major1 !== major2) {
    return major1 > major2 ? 1 : -1;
  }

  if (minor1 !== minor2) {
    return minor1 > minor2 ? 1 : -1;
  }

  if (patch1 !== patch2) {
    return patch1 > patch2 ? 1 : -1;
  }

  return 0;
}

export let updateRejected = false;
