import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';

import { AnsweringRule, TimeFrame, TimeFrameType } from '../../models/answering-rules.models';
import { AnsweringRulesService } from '../../services/answering-rules.service';
import { AddTimeFrameComponent } from './add-time-frame/add-time-frame.component';

@UntilDestroy()
@Component({
  selector: 'app-answering-rules',
  templateUrl: './answering-rules.component.html',
  styleUrls: ['./answering-rules.component.scss'],
})
export class AnsweringRulesComponent implements AfterViewInit, OnInit {
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('timeFrame') timeFrame: AddTimeFrameComponent;

  protected readonly AppFeature = AppFeature;

  protected loading = false;

  protected answeringRules: AnsweringRule[] = [];
  protected filteredTimeFrames: TimeFrame[] = [];
  protected filtered = false;

  protected ringDuration: FormControl;
  protected returnBusyIfOnCall: FormControl;
  protected autoAnswerIncomingCalls: FormControl;
  protected autoAnswerTime: FormControl;
  protected addingRule: boolean = false;
  protected actionRule: AnsweringRule | undefined;
  protected addingTimeFrame: boolean = false;
  protected actionTimeFrame: TimeFrame | undefined;
  protected timeFramesUsedByMe: string[] = [];

  protected reordered = false;

  protected rulesEditingDisabled = true;
  protected timeFramesEditingDisabled = true;

  constructor(
    protected answeringRulesService: AnsweringRulesService,
    protected appConfigService: AppConfigService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: SnackbarService
  ) {
    this.ringDuration = this.fb.control('', [Validators.min(5), Validators.max(7200)]);
    this.autoAnswerIncomingCalls = this.fb.control(false);
    this.returnBusyIfOnCall = this.fb.control(false);
    this.autoAnswerTime = this.fb.control(4, [Validators.min(0), Validators.max(7200)]);

    this.answeringRulesService.fetchData();
  }

  ngOnInit(): void {
    this.rulesEditingDisabled = !this.appConfigService.features[AppFeature.ModifyAnsweringRules];
    this.timeFramesEditingDisabled = !this.appConfigService.features[AppFeature.ModifyTimeFrames];

    this.filtered = this.timeFramesEditingDisabled;

    this.answeringRulesService.answeringRules$.pipe(untilDestroyed(this)).subscribe((data) => {
      // creating copy of data so reordering rules doesn't change original data
      this.answeringRules = [...data];
      this.timeFramesUsedByMe = data.map((rule) => {
        return rule.timeFrame!;
      });
    });

    this.answeringRulesService.timeFrames$.pipe(untilDestroyed(this)).subscribe(() => {
      this.filterTimeFrames();
    });

    this.appConfigService.data$.pipe(untilDestroyed(this)).subscribe((config) => {
      this.autoAnswerIncomingCalls.setValue(
        config?.settings.preferences.answeringRules?.autoAnswerIncomingCalls ?? false
      );
      this.autoAnswerTime.setValue(config?.settings.preferences.answeringRules?.autoAnswerTime ?? 4);
      this.returnBusyIfOnCall.setValue(config?.settings.preferences.answeringRules?.returnBusy ?? false);
    });
  }

  ngAfterViewInit(): void {
    this.getRingDuration();
  }

  protected drop(event: CdkDragDrop<AnsweringRule[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.answeringRules, event.previousIndex, event.currentIndex);
      if (!this.reordered) {
        // show message only once
        this.snackBar.open(
          'You have reprioritized your answering rules. When you are done, save the changes you have made.',
          'OK',
          { duration: 6000 }
        );
      }
      this.reordered = true;
    }
  }

  protected editRule(index: number): void {
    this.actionRule = this.answeringRules[index];
    this.addingRule = true;
  }

  protected confirmDeleteRule(index: number): void {
    const timeFrame = this.answeringRules[index].timeFrame!;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        description: `Are you sure you want to delete rule '${timeFrame}'? This action cannot be undone.`,
        buttons: [
          {
            label: 'Cancel',
            action: () => null,
            type: ButtonType.matFlatButton,
          },
          {
            label: 'Delete Rule',
            action: async () => await this.deleteRule(timeFrame),
            type: ButtonType.matRaisedButton,
            default: true,
            color: 'primary',
          },
        ],
      },
    });
  }

  protected editOrCopyTimeFrame(index: number): void {
    this.actionTimeFrame = this.filteredTimeFrames[index];
    this.addingTimeFrame = true;
  }

  protected confirmDeleteTimeFrame(index: number): void {
    const timeFrame = this.filteredTimeFrames[index].time_frame;
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        description: `Are you sure you want to delete time frame '${timeFrame}'? This action cannot be undone.`,
        buttons: [
          {
            label: 'Cancel',
            action: () => null,
            type: ButtonType.matFlatButton,
          },
          {
            label: 'Delete Time Frame',
            action: async () => await this.deleteTimeFrame(timeFrame),
            type: ButtonType.matRaisedButton,
            default: true,
            color: 'primary',
          },
        ],
      },
    });
  }

  protected timeFrameDescription(timeFrame: TimeFrame): string {
    const type = this.answeringRulesService.timeFrameType(timeFrame);
    if (type === TimeFrameType.Always) {
      return 'Always';
    } else if (type === TimeFrameType.DaysAndTimes) {
      return 'Days and Times';
    } else {
      return 'Specific Dates';
    }
  }

  protected async onSave() {
    if (this.ringDuration.dirty) {
      await this.saveRingDuration();
    }
    if (this.autoAnswerIncomingCalls.dirty || this.autoAnswerTime.dirty || this.returnBusyIfOnCall.dirty) {
      await this.saveAutoAnswer();
    }
    if (this.reordered) {
      this.saveRulesOrder();
    }
  }

  protected onCancel(): void {
    // get original state before possible reordering
    // it could show wrong rules order after re-entering aswering rules panel without closing preferences window
    this.answeringRules = this.answeringRulesService.answeringRulesSubject.value;
    this.cancel.emit();
  }

  protected onSubpanelCancel(): void {
    this.addingRule = false;
    this.actionRule = undefined;
    this.addingTimeFrame = false;
    this.actionTimeFrame = undefined;
  }

  protected deleteIconTooltipText(timeFrame: TimeFrame): string {
    if (timeFrame.owner === '*' && !this.answeringRulesService.isCurrentUserOfficeManager) {
      return 'No permission to delete this time frame';
    } else if (timeFrame.in_use) {
      return 'This time frame is used by you or assigned to multiple users and cannot be removed';
    }

    return '';
  }

  protected filterTimeFrames(): void {
    this.filteredTimeFrames = this.answeringRulesService.filterTimeFrames(this.filtered);
  }

  private async getRingDuration() {
    try {
      const response = await firstValueFrom(this.answeringRulesService.getRingDuration());
      this.ringDuration.patchValue(response.duration);
    } catch (error) {
      console.error('Get ring duration error:', error);
      this.snackBar.open('Get ring duration failed.', 'OK');
    }
  }

  private async saveRingDuration() {
    this.loading = true;
    try {
      await firstValueFrom(this.answeringRulesService.setRingDuration(this.ringDuration.value));
      this.ringDuration.markAsPristine();
    } catch (error) {
      console.error('Saving ring duration error:', error);
      this.snackBar.open('Saving ring duration failed.', 'OK');
    } finally {
      this.loading = false;
    }
  }

  private async saveAutoAnswer() {
    this.loading = true;
    try {
      await firstValueFrom(
        this.appConfigService.updatePreferences({
          answeringRules: {
            returnBusy: this.returnBusyIfOnCall.value,
            autoAnswerIncomingCalls: this.autoAnswerIncomingCalls.value,
            autoAnswerTime: this.autoAnswerTime.value,
          },
        })
      );
      this.autoAnswerIncomingCalls.markAsPristine();
      this.autoAnswerTime.markAsPristine();
    } catch (error) {
      console.error('Saving auto answering rules error:', error);
      this.snackBar.open('Saving auto answering rules failed.', 'OK');
    } finally {
      this.loading = false;
    }
  }

  private async saveRulesOrder() {
    this.loading = true;
    try {
      await firstValueFrom(this.answeringRulesService.setPriority(this.getRulesOrderString()));
      this.reordered = false;
    } catch (error) {
      console.error('Saving answering rules order error:', error);
      this.snackBar.open('Saving new order failed.', 'OK');
    } finally {
      this.loading = false;
    }
  }

  private getRulesOrderString(): string {
    return this.answeringRules
      .map((rule) => {
        return rule.priority;
      })
      .join(',');
  }

  private async deleteRule(timeFrame: string) {
    try {
      await firstValueFrom(this.answeringRulesService.deleteRule(timeFrame));
      this.snackBar.open('Answering rule deleted.', 'OK');
    } catch (error) {
      console.warn('Delete answering rule error:', error);
      this.snackBar.open('Answering rule delete failed.', 'OK');
    }
  }

  private async deleteTimeFrame(timeFrame: string) {
    try {
      await firstValueFrom(this.answeringRulesService.deleteTimeFrame(timeFrame));
      this.snackBar.open('Time frame deleted.', 'OK');
    } catch (error) {
      console.warn('Delete time frame error:', error);
      this.snackBar.open('Time frame delete failed.', 'OK');
    }
  }
}
