import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authUrl } from '@app/auth/services/auth.service';
import { defaultBrandingSettings } from '@app/core/models/branding.models';
import { ApiService } from '@app/core/services/api.service';
import { firstValueFrom, from, Observable } from 'rxjs';

import { AppFeature } from '../models/config.models';
import { AppConfigService } from '../services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class BrandingService extends ApiService {
  protected override baseUrl = '';
  private _brandingData = defaultBrandingSettings();
  fusionauthUrl: string = authUrl;

  get brandingData() {
    return this._brandingData;
  }

  constructor(httpClient: HttpClient, private configService: AppConfigService) {
    super(httpClient);
  }

  public refreshData(): Observable<unknown> {
    return from(Promise.all([this.setFusionauthUrl(), this.setBrandingData()]));
  }

  async setBrandingData() {
    try {
      if (this.configService.features[AppFeature.Branding]) {
        const response = await firstValueFrom(this.get(`users/{me}/branding`));
        if (response) {
          response[window.location.host].forEach((item: { field_id: number; field_value: string }) => {
            this._brandingData[item.field_id] = item.field_value;
          });
        }
      }
    } catch (error) {
      console.error('Error getting branding settings', error);
    }
  }

  async setFusionauthUrl() {
    try {
      const response = await firstValueFrom(this.get<{ url: string }>(`uc/branding/sso/url`));
      if (response) {
        this.fusionauthUrl = response.url;
      }
    } catch (error) {
      console.error('Error getting fusionauth url', error);
    }
  }
}
