import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact, ContactUpdateResult } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { ContactComponent } from '@app/shared/components/contact/contact.component';
import { sanitizePhoneNumber } from '@app/shared/utils/phone.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { base64ToFile } from 'ngx-image-cropper';
import { finalize } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [ContactComponent],
  selector: 'app-contact-updates',
  templateUrl: './contact-updates.component.html',
  styleUrls: ['./contact-updates.component.scss'],
})
export class ContactUpdatesComponent {
  title: string;
  isEditMode: boolean;
  isSaving: boolean;

  constructor(
    private snackBar: SnackbarService,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogReference: MatDialogRef<Contact>
  ) {
    this.isEditMode = this.data.status === 'Edit';
    this.title = this.isEditMode ? 'Edit Contact' : 'Add Contact';
  }

  onSave(result): void {
    if (this.isEditMode) {
      this.editContact(result);
    } else {
      this.addContact(result);
    }
  }

  private editContact(result: ContactUpdateResult) {
    this.isSaving = true;
    this.contactService
      .update(result.contact)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isSaving = false))
      )
      .subscribe((response: Contact) => {
        if (result.avatar) {
          this.saveProfilePhoto(result);
        }
        this.close(response);
        this.contactService.refreshData().subscribe();
      });
  }

  private saveProfilePhoto(result) {
    if (result.avatar && result.contact) {
      const formData = new FormData();
      formData.append('avatar', base64ToFile(result.contact.avatar));
      this.contactService
        .uploadContactAvatar(result.contact, formData)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.snackBar.open('Profile has been updated', 'Ok');
          this.close(result.contact);
        });
    } else {
      this.snackBar.open('Profile has been updated', 'Ok');
      this.close(result.contact);
    }
  }

  private addContact(result: ContactUpdateResult) {
    this.isSaving = true;
    const data = result.contact;
    data['first_name'] = result.contact.firstName;
    data['last_name'] = result.contact.lastName;
    data['full_name'] = result.contact.fullName;
    for (let index = 0; index < result.contact.tels.length; index++) {
      data['tels'][index]['number'] = sanitizePhoneNumber(result.contact.tels[index].number);
    }

    this.contactService
      .create(data)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isSaving = false))
      )
      .subscribe((response: Contact) => {
        this.snackBar.open('Contact has been created', 'Ok');
        this.close(response);
      });
  }

  close(contact?: Contact): void {
    this.dialogReference.close(contact);
  }
}
