import { HttpClient } from '@angular/common/http';
import { getFormattedDateWithDecimalZulu } from '@app/shared/utils/date.util';
import convertKeysToHyphenated from '@app/shared/utils/object.util';
import { environment } from '@environment/environment';
import { BehaviorSubject, catchError, combineLatest, finalize, map, Observable, of, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { DraftMessageContent, ListSMSMessagesResponse, SMSMessage, STREAM_UPDATE_TYPE } from '../models/sms.models';

interface FetchMessageOptions {
  notBefore?: string; // Fetch messages after a given timestamp. Is inclusive.
  notAfter?: string; // Fetch messages before a given timestamp. Is inclusive.
  before?: string; // Fetch messages before a given timestamp. Is exclusive.
  after?: string; // Fetch messages after a given timestamp. Is exclusive.
  maxResults?: number;
  nextToken?: string;
  sortOrder: 'ascending' | 'descending';
}

type PageDirection = 'PREVIOUS' | 'NEXT';

/**
 * Non-injectable service used for fetching messages for a given sms conversation. Instances are instantiated
 * and managed by SMSService which provides an interface for getting a conversation's messages.
 *
 * This logic could have lived in SMSService, but making it its own class made sense given how
 * closely its functionality mimics other services (even though this class isn't injectable).
 */
export class SMSMessageService {
  private static MaxPagingResultsCount = 30;
  private readonly messagesSubject: BehaviorSubject<SMSMessage[]> = new BehaviorSubject([]);
  public readonly messages$ = this.messagesSubject.asObservable();

  // Latest message is stored separately from the rest of the message array since it may come from a separate source
  private readonly latestMessageSubject: BehaviorSubject<SMSMessage | null> = new BehaviorSubject(null);
  public readonly lastestMessage$ = this.latestMessageSubject.asObservable();

  // Used to count how many fetchMessages requests are currently in-flight
  private activeFetchMessagesRequestCountSubject = new BehaviorSubject(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private errorSubject = new BehaviorSubject<boolean>(false);
  public hasError$ = this.errorSubject.asObservable();
  public pageTokens?: {
    previous?: string;
    next?: string;
  };

  constructor(private httpClient: HttpClient, private conversationId: string) {
    // subscribe to messages to ensure we're always updating latest message correctly
    this.messagesSubject.subscribe((messages) => {
      if (messages.length === 0) {
        return;
      }
      // Update latest message if what we have as the last item in the list is newer than what's currently stored
      const lastMessageInList = messages[messages.length - 1];
      this.updateLatestMessageIfNecessary(lastMessageInList);
    });

    this.activeFetchMessagesRequestCountSubject.asObservable().subscribe((count) => {
      this.loadingSubject.next(count > 0);
    });
  }

  private set messages(data: SMSMessage[]) {
    this.messagesSubject.next(data);
  }

  public get messages(): SMSMessage[] {
    return this.messagesSubject.getValue();
  }

  private get latestMessage(): SMSMessage | null {
    return this.latestMessageSubject.getValue();
  }

  private set latestMessage(message: SMSMessage | null) {
    this.latestMessageSubject.next(message);
  }

  public updateLatestMessageIfNecessary(toMessage: SMSMessage) {
    if (!this.latestMessage || toMessage.createdTimestamp > this.latestMessage.createdTimestamp) {
      this.latestMessage = toMessage;
    }
  }

  public messageWithId(messageId: string): SMSMessage | undefined {
    return this.messages.find((message) => message.messageId === messageId);
  }

  // =========== API ===========

  resetData() {
    this.pageTokens = undefined;
    this.messages = [];
  }

  /**
   * Fetches the next page of messages from the history. Messages will be stored as a prop on this service,
   * but the observable is returned to make reacting to stream emissions more convenient.
   * @returns An observable which emits the newly fetched message payload once the messages have been retrieved.
   */
  fetchMessageHistory(pageDirection: PageDirection): Observable<SMSMessage[]> {
    if (!this.pageTokens || !this.hasMoreMessagesInDirection(pageDirection)) {
      return of([]);
    }

    const pageToken = pageDirection === 'PREVIOUS' ? this.pageTokens.previous : this.pageTokens.next;
    const options: FetchMessageOptions = {
      nextToken: pageToken,
      maxResults: SMSMessageService.MaxPagingResultsCount,
      sortOrder: pageDirection === 'PREVIOUS' ? 'descending' : 'ascending',
    };

    return this.fetchMessages(options).pipe(
      tap((response) => {
        if (!this.pageTokens) {
          return;
        }

        if (pageDirection === 'PREVIOUS') {
          this.pageTokens.previous = response?.nextToken;
        } else {
          this.pageTokens.next = response?.nextToken;
        }
      }),
      map((response) => {
        return response ? [...response.messages] : [];
      }),
      tap((messages) => {
        this.mergeMessageSubsetWithCached(messages);
      })
    );
  }

  fetchPagesAroundTimestamp(lastReadMessageTimestamp?: string): Observable<SMSMessage[]> {
    // If timestamp is undefined, just fetch the latest messages and don't worry about the "next" page token
    const fetchObservables = lastReadMessageTimestamp
      ? [
          this.fetchMessages({
            notAfter: lastReadMessageTimestamp,
            sortOrder: 'descending',
            maxResults: SMSMessageService.MaxPagingResultsCount,
          }),
          this.fetchMessages({
            after: lastReadMessageTimestamp,
            sortOrder: 'ascending',
            maxResults: SMSMessageService.MaxPagingResultsCount,
          }),
        ]
      : [this.fetchMessages({ sortOrder: 'descending', maxResults: SMSMessageService.MaxPagingResultsCount })];

    return combineLatest(fetchObservables).pipe(
      tap((responses) => {
        this.pageTokens = {
          previous: responses[0]?.nextToken, // paging backward in history from lastReadMessageTimestamp
          next: responses.length > 1 ? responses[1]?.nextToken : undefined, // forward in history from lastReadMessageTimestamp
        };
      }),
      map((responses) => {
        return responses.flatMap((response) => response?.messages || []);
      }),
      tap((messages) => {
        this.mergeMessageSubsetWithCached(messages);
      })
    );
  }

  private fetchMessages(options: FetchMessageOptions): Observable<ListSMSMessagesResponse | null> {
    this.activeFetchMessagesRequestCountSubject.next(this.activeFetchMessagesRequestCountSubject.value + 1);
    console.log(
      `sms: fetch messages for conversation: ${this.conversationId} with options: ${JSON.stringify(options)}`
    );

    return this.httpClient
      .get<ListSMSMessagesResponse>(
        `${environment.messageHubGateway}/sms/conversations/${this.conversationId}/messages`,
        {
          params: convertKeysToHyphenated(options), // API takes hyphenated keys
        }
      )
      .pipe(
        map((response) => {
          // Filter out any notification messages that are not streamupdates
          response.messages = response.messages.filter(
            (message) => message.type === 'message' || message.notification?.type === STREAM_UPDATE_TYPE
          );
          return response;
        }),
        map((response) => {
          for (const message of response.messages) {
            message.media?.forEach((media) => {
              // If the filename is not provided, extract the last path component from the URL.
              // This is for backwards compatibility with older messages that don't have the filename field.
              if (!media.fileName) {
                media.fileName = decodeURI(media.url.split('/').pop() || '');
              }
            });
          }
          return response;
        }),
        map((response) => {
          // If the sortOrder is descending, we need to reverse the order of the results
          if (options.sortOrder === 'descending') {
            response.messages.reverse();
          }
          return response;
        }),
        catchError((error) => {
          console.log(`sms: fetchSMSMessages error: ${error}`);
          this.errorSubject.next(true);
          return of(null);
        }),
        finalize(() =>
          this.activeFetchMessagesRequestCountSubject.next(this.activeFetchMessagesRequestCountSubject.value - 1)
        )
      );
  }

  private mergeMessageSubsetWithCached(subset: SMSMessage[]) {
    // Determine where to insert the newly fetched messages into our array.
    // Typically messages fetched should be on one of the edges of our current array (i.e. all messages
    // should be timestamp before or after the current set). Since contents are ordered we can
    // simply look at the first and last element.
    // If for some reason there is overlap, favor the new data replacing what is stored locally.

    // O(n^2) filter any messages in the subset with the same id as a message in the existing set
    const filteredSubset = subset.filter(
      (subsetMessage) => !this.messages.some((existingMessage) => existingMessage.messageId === subsetMessage.messageId)
    );

    if (filteredSubset.length === 0) {
      return;
    }

    if (this.messages.length === 0) {
      this.messages = filteredSubset;
    } else if (this.messages[0].createdTimestamp > filteredSubset.slice(-1)[0].createdTimestamp) {
      // Subset messages are earlier and should appear before what is currently cached
      this.messages = [...filteredSubset, ...this.messages];
    } else if (this.messages[this.messages.length - 1].createdTimestamp < filteredSubset[0].createdTimestamp) {
      // subset messages are all later and should appear after what is currently cached
      this.messages = [...this.messages, ...filteredSubset];
    } else {
      // We've managed to get some message overlap. Concat the two arrays and sort. We've already filtered
      // duplicates so we shouldn't need to worry about that here.
      this.messages = [...this.messages, ...filteredSubset].sort(this.comparisonResultForMessages);
    }
  }

  // ========== Message Management ==========

  /**
   * Adds a message with the provided content to the service's message list.
   * @param conversationId
   * @param draft The message draft
   * @param local
   * @param remotes
   * @param messageId
   * @returns The created message object.
   */
  public insertUserCreatedMessage(
    conversationId: string,
    draft: DraftMessageContent,
    local: string,
    remotes: string[],
    messageId = uuidv4()
  ): SMSMessage {
    const dateString = getFormattedDateWithDecimalZulu(new Date());

    const message: SMSMessage = {
      messageId,
      referenceId: messageId,
      content: draft.content,
      conversationId: conversationId,
      createdTimestamp: dateString,
      updatedTimestamp: dateString,
      sender: local,
      direction: 'outgoing',
      recipients: remotes,
      media: draft.media,
    };

    this.upsertMessage(message.messageId, message);
    return message;
  }

  /**
   * Peform an upsert with the given payload. If the created date has been modified, the message
   * will be sorted into its correct position.
   * @param messageId The messageId to use for searching for an existing message
   * @param payload The message payload to be upserted
   */
  public upsertMessage(messageId: string, payload: SMSMessage): 'inserted' | 'updated' {
    console.log(
      `sms: upserting message ${payload.messageId} content: ${(payload.content ?? '').slice(0, 4)} ${
        payload.createdTimestamp
      }`
    );
    const messages = [...this.messagesSubject.value];
    const existingMessageIndex = messages.findIndex((m) => m.messageId === messageId);

    // If the message already exists, remove it.
    if (existingMessageIndex > -1) {
      console.log(
        `sms: removing existing message ${messageId} for ${payload.messageId} with content: ${payload.content}`
      );
      messages.splice(existingMessageIndex, 1);
    }

    // Add the new message by finding its insertion index. `messages` should already be sorted
    // so we only need to find the first index where the comparison result is > 0.
    let insertionIndex = messages.findIndex((m) => this.comparisonResultForMessages(m, payload) > 0);
    insertionIndex = insertionIndex > -1 ? insertionIndex : messages.length;

    console.log(
      `sms: adding message at index ${insertionIndex} ${payload.messageId} content: ${(payload.content ?? '').slice(
        0,
        4
      )}`
    );
    messages.splice(insertionIndex, 0, payload);
    this.messagesSubject.next(messages);
    return existingMessageIndex > -1 ? 'updated' : 'inserted';
  }

  private comparisonResultForMessages(lhs: SMSMessage, rhs: SMSMessage): number {
    let comparisonResult = lhs.createdTimestamp.localeCompare(rhs.createdTimestamp);

    // If two messages are sent within the same second, they will have the same createdTimestamp
    // value since the server truncates sub-second values. Use the messageId to sort in that case since
    // messageIds are incremented sequentially
    if (comparisonResult === 0) {
      comparisonResult = lhs.messageId.localeCompare(rhs.messageId);
    }

    // If the created timestamp is the same, sort by updated timestamp if it exists.
    // Favor the object that has a timestamp if both objects don't have the value set
    if (comparisonResult === 0) {
      if (lhs.updatedTimestamp && rhs.updatedTimestamp) {
        comparisonResult = lhs.updatedTimestamp?.localeCompare(rhs.updatedTimestamp);
      } else if (lhs.updatedTimestamp) {
        comparisonResult = -1;
      } else {
        comparisonResult = 1;
      }
    }

    return comparisonResult;
  }

  /**
   * Adds the passed metadata object to the message's metadata.
   * @param message The message to append the metadata field to
   * @param metadata The metadata object to add
   * @returns A new SMSMessage with the metadata argument appended to the existing metadata
   */
  public static messageWithAddedMetadata(message: SMSMessage, metadata: SMSMessage['metadata']): SMSMessage {
    return { ...message, metadata: { ...message.metadata, ...metadata } };
  }

  public removeMessage(messageId: string) {
    this.messages = this.messages.filter((message) => message.messageId !== messageId);
  }

  public hasMoreMessagesInDirection(pageDirection: PageDirection): boolean {
    if (!this.pageTokens) {
      return true;
    }

    const token = pageDirection === 'PREVIOUS' ? this.pageTokens.previous : this.pageTokens.next;
    return token !== undefined;
  }

  public deleteConversation(conversationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.messageHubGateway}/sms/conversations/${conversationId}`);
  }
}
