import { DateListItem } from '@app/shared/models/date-list-item';

export interface WSVoicemail {
  action: string;
  model: Voicemail;
}

export interface Voicemail extends DateListItem {
  id: string;
  label: string;
  tel: string;
  transcription: unknown;
  filename: string;
  type: VoicemailType;
}

export enum VoicemailType {
  RecentlyDeleted = 'trash',
  Voicemail = 'vmail/new',
}

export const RecentlyDeleted = 'Recently Deleted';
