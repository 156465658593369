import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import {
  Address,
  Contact,
  ContactUpdateResult,
  EmailType,
  ExtensionTypes,
  Telephone,
} from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { TransformImageComponent } from '@app/shared/components/resize-image/transform-image.component';
import { AvatarDirective } from '@app/shared/directives/avatar.directive';
import { FormDirective } from '@app/shared/directives/form.directive';
import { formatPhoneNumber, sanitizePhoneNumber, validatePhoneNumber } from '@app/shared/utils/phone.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Country, ICountry, IState, State } from 'country-state-city';
import { base64ToFile } from 'ngx-image-cropper';
import { catchError, Observable, startWith, switchMap, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    AvatarDirective,
    FormDirective,
    TransformImageComponent,
  ],
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() isContactPage?: boolean;
  @Input() contact: Contact | undefined;
  @Input() title: string;
  @Input() loading: boolean;
  @Input() showSharedContact: boolean = true;
  @Input() isDirty: boolean = false;

  @Input() set isShared(value: boolean) {
    if (value) {
      this.contactForm.controls['is_shared'].setValue(true);
    }
  }

  @Output() saveAction = new EventEmitter<ContactUpdateResult>();
  @Output() cancelAction = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput: ElementRef;

  protected readonly ExtensionTypes = ExtensionTypes;
  protected readonly EmailType = EmailType;

  public contactForm!: FormGroup;
  protected profilePhoto: File;
  protected imageChangedEvent!: Event;
  protected image = false;
  private croppedImage = '';

  private readonly fullNameValidator = (x: AbstractControl) => {
    const value = x.value as Contact;
    return value.firstName?.trim() || value.lastName?.trim() || value.company?.trim() ? null : { required: true };
  };

  protected states$: Array<Observable<IState[]>> = [];

  constructor(
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    private contactService: ContactService,
    protected appConfigService: AppConfigService
  ) {
    if (!this.contact) {
      this.resetContact();
    }

    this.contactForm = this.fb.group(
      {
        firstName: ['', [Validators.minLength(2)]],
        lastName: ['', [Validators.minLength(2)]],
        company: [''],
        title: [''],
        tels: this.fb.array([]),
        emails: this.fb.array([]),
        addresses: this.fb.array([]),
        is_shared: [false],
      },
      { validators: [this.fullNameValidator] }
    );
  }

  protected get tels() {
    return this.contactForm.get('tels') as FormArray;
  }

  protected get emails() {
    return this.contactForm.get('emails') as FormArray;
  }

  protected get addresses() {
    return this.contactForm.get('addresses') as FormArray;
  }

  protected get countries(): ICountry[] {
    return Country.getAllCountries();
  }

  protected getStates(countryName: string): IState[] {
    const countryMatchingName = Country.getAllCountries().find((country) => country.name === countryName);
    const states = State.getStatesOfCountry(countryMatchingName?.isoCode);
    return states.length > 0 ? states : [{ name: 'No State', isoCode: '', countryCode: '' } as IState];
  }

  private resetContact() {
    this.contact = this.contactService.defaultContact;
  }

  ngOnInit(): void {
    if (this.contact) {
      for (const email of this.contact?.emails ?? []) {
        const emailGroup = this.fb.group({
          value: [email.value, [Validators.required, Validators.email]],
          type: [email.type, [Validators.required]],
          readonly: email.readonly,
        });
        if (email.readonly) {
          emailGroup.disable();
        }
        this.emails.push(emailGroup);
      }

      for (const tel of this.contact?.tels ?? []) {
        this.addTel(tel);
      }

      for (const address of this.contact?.addresses ?? []) {
        this.addAddress(address);
      }

      this.contactForm.patchValue({
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        title: this.contact.title ? this.contact.title : '',
        company: this.contact.company,
        is_shared: this.contact.is_shared,
      });

      if (this.isDirty) {
        this.contactForm.markAsDirty();
      }
    }
  }

  protected onSubmit(): void {
    if (this.contactForm.invalid) {
      this.snackbar.open('Form data is not valid');
      return;
    }
    const contact = { ...this.contact, ...this.contactForm.getRawValue() };
    contact.fullName = contact.firstName + ' ' + contact.lastName;
    contact.avatar = this.croppedImage;
    contact.tels = [];
    this.contactForm.controls['tels'].value.forEach((tel: Telephone) => {
      if (tel.type !== ExtensionTypes.PbxSms) {
        contact.tels.push({
          type: tel.type,
          number: sanitizePhoneNumber(tel.number),
        });
      }
    });
    delete contact.type;
    const result: ContactUpdateResult = {
      contact: contact,
      avatar: this.profilePhoto,
    };
    this.saveAction.emit(result);
  }

  protected addTel(tel?: Telephone): void {
    this.tels.push(
      this.fb.group({
        type: [tel?.type ?? '', [Validators.required]],
        number: [
          tel?.number ?? '',
          tel?.type === ExtensionTypes.Other ? [Validators.required] : [Validators.required, validatePhoneNumber],
        ],
      })
    );
    if (tel?.readonly) {
      this.tels.controls[this.tels.length - 1].disable();
    }
    this.formatPhoneNumber(this.tels.length - 1);
  }

  protected extensionTypeChanged(event: MatSelectChange, index: number): void {
    const ctrl = this.tels.at(index).get('number');
    if (event.value === ExtensionTypes.Other && ctrl?.hasValidator(validatePhoneNumber)) {
      ctrl.removeValidators(validatePhoneNumber);
    } else if (event.value !== ExtensionTypes.Other && !ctrl?.hasValidator(validatePhoneNumber)) {
      ctrl?.addValidators(validatePhoneNumber);
    }

    ctrl?.updateValueAndValidity();
  }

  protected addEmail(): void {
    this.emails.push(
      this.fb.group({
        type: ['', [Validators.required]],
        value: ['', [Validators.required, Validators.email]],
        readonly: false,
      })
    );
  }

  protected removeEmail(index: number): void {
    this.emails.removeAt(index);
    this.contactForm.markAsDirty();
  }

  protected removeTel(index: number): void {
    this.tels.removeAt(index);
    this.contactForm.markAsDirty();
  }

  protected removeAddress(index: number) {
    this.addresses.removeAt(index);
    this.contactForm.markAsDirty();
  }

  protected addAddress(address?: Address) {
    const addressGroup = this.fb.group({
      country: [address?.country || '', [Validators.required]],
      type: [address?.type || '', [Validators.required]],
      addressLine1: [address?.addressLine1 || '', [Validators.required]],
      addressLine2: [address?.addressLine2 || ''],
      city: [address?.city || '', [Validators.required]],
      state: [address?.state || '', [Validators.required]],
      postalCode: [address?.postalCode || '', [Validators.required]],
    });

    this.setupStateSubscription(addressGroup);
    this.addresses.push(addressGroup);
  }

  private setupStateSubscription(addressGroup: FormGroup) {
    this.states$.push(
      addressGroup.get('country')?.valueChanges.pipe(
        startWith(addressGroup.get('country')?.value),
        untilDestroyed(this),
        switchMap(async (country) => this.getStates(country))
      ) as Observable<IState[]>
    );
  }

  protected addProfilePhoto() {
    this.fileInput.nativeElement.click();
  }

  protected changeInputProfilePhoto(event) {
    const file = (event.target as HTMLInputElement)?.files?.[0];
    this.profilePhoto = file!;
    this.imageChangedEvent = event;
    this.image = true;
  }

  protected close() {
    this.cancelAction.emit();
  }

  protected onSaveAvatarPhoto(resultImageEvent: string) {
    if (this.contact?.id) {
      this.loading = true;
      this.saveAvatarPhoto(resultImageEvent);
    }

    this.image = false;
    const file = base64ToFile(resultImageEvent);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      if (this.contact) {
        this.contact.avatar = reader.result as string;
      } else {
        this.resetContact();
        this.contact!.avatar = reader.result as string;
      }
    });
  }

  private saveAvatarPhoto(resultImage: string) {
    this.croppedImage = resultImage;
    const avatar = base64ToFile(resultImage);
    const formData = new FormData();
    formData.append('avatar', avatar);
    this.contactService
      .uploadContactAvatar(this.contact!, formData)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.onFailedAvatarUploading(error))
      )
      .subscribe(() => {
        this.contact!.avatar = resultImage;
        this.onSuccessAvatarUploading();
      });
  }

  private onFailedAvatarUploading(error) {
    this.loading = false;
    return throwError(error);
  }

  private onSuccessAvatarUploading() {
    this.loading = false;
    this.snackbar.open('Contact has been updated', 'Ok');
  }

  protected formatPhoneNumber(index: number): void {
    const control = this.tels.at(index).get('number');
    if (control) {
      const formattedNumber = formatPhoneNumber(control.value);
      control.setValue(formattedNumber);
    }
  }
}
