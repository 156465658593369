<section #container>
  <div class="container">
    <h2 class="m-b-10"><b>Music on Hold</b></h2>
    <mat-divider class="m-b-16"></mat-divider>

    <div class="flex-col">
      <div
        class="item flex moh-switch"
        [matTooltip]="'Enable music on hold'"
        [matTooltipPosition]="'right'">
        <mat-slide-toggle
          [checked]="mohSettings.enableMoh"
          (change)="toggleMusicOnHoldSettings()"></mat-slide-toggle>
        <label class="text moh-switch-label"> Enable Music on Hold </label>
      </div>
      <div
        class="item flex moh-switch"
        [matTooltip]="'Randomize music on hold'"
        [matTooltipPosition]="'right'">
        <mat-slide-toggle
          [checked]="mohSettings.randomizeMoh"
          (change)="toggleRandomizeMusicOnHold()"></mat-slide-toggle>
        <label class="text"> Randomize Music on Hold </label>
      </div>
    </div>

    <label class="m-t-20 m-b-10 sub-header"><b>Manage Music</b></label>
    <mat-divider class="m-b-16"></mat-divider>
    <div
      class="flex-col"
      cdkDropListGroup
      [cdkDropListGroupDisabled]="editingDisabled">
      <p
        class="empty-file-list"
        *ngIf="this.mohFiles.length === 0">
        No music on hold files have been added for this user. Music will be inherited from the group.
      </p>
      <table
        class="moh-file-list"
        *ngIf="this.mohFiles.length > 0">
        <tr>
          <th colspan="2"></th>
          <th>Name</th>
          <th>Duration</th>
          <th>File Size</th>
          <th></th>
        </tr>
        <tbody
          cdkDropList
          [cdkDropListData]="mohFiles"
          (cdkDropListDropped)="drop($event)">
          <tr
            *ngFor="let item of mohFiles"
            cdkDrag>
            <td class="rearrange-files-btn">
              <mat-icon>drag_indicator</mat-icon>
            </td>
            <td class="play-file-btn">
              <mat-icon
                color="primary"
                (click)="playSelectedAudioFile(item.mediaUrl, item.index)"
                *ngIf="playingIndex !== item.index"
                >play_circle</mat-icon
              >
              <mat-icon
                color="primary"
                (click)="pauseSelectedAudioFile()"
                *ngIf="playingIndex === item.index"
                >stop_circle</mat-icon
              >
            </td>
            <td>
              <span *ngIf="!item.edit">{{ item.description }}</span>
              <span
                *ngIf="item.edit"
                class="inline-file-action-btns">
                <input [(ngModel)]="item.descriptionEdit" />
                <mat-icon
                  color="primary"
                  (click)="saveDescriptionChange(item)"
                  title="Save"
                  >save</mat-icon
                >
                <mat-icon
                  color="primary"
                  (click)="cancelDescriptionChange(item)"
                  title="Cancel"
                  >cancel</mat-icon
                >
              </span>
            </td>
            <td>{{ Number(item.duration) | secondsToMinutes }}</td>
            <td>{{ item.size }}</td>
            <td class="file-action-btns">
              <mat-icon
                color="primary"
                (click)="downloadAudioFile(item.index)"
                >download</mat-icon
              >
              <mat-icon
                color="primary"
                (click)="editDescription(item)"
                >edit</mat-icon
              >
              <mat-icon
                color="primary"
                (click)="deleteAudioFile(item.index)"
                >delete</mat-icon
              >
            </td>
          </tr>
        </tbody>
      </table>

      <button
        color="primary"
        mat-raised-button
        class="upload-btn"
        type="button"
        (click)="openFileUploadDialog()">
        <span>Upload a file</span>
      </button>
    </div>

    <h2 class="m-t-30 m-b-10"><b>Introductory Greeting</b></h2>
    <mat-divider class="m-b-16"></mat-divider>
    <div
      class="item flex moh-switch"
      [matTooltip]="'Play Introductory Greeting'"
      [matTooltipPosition]="'right'">
      <mat-slide-toggle
        [checked]="enablePlayIntroductoryGreeting"
        (change)="togglePlayIntroGreeting()"></mat-slide-toggle>
      <label class="text"> Play Introductory Greeting </label>
    </div>

    <div
      class="intro-greeting-options m-t-25 m-b-10"
      *ngIf="enablePlayIntroductoryGreeting">
      <label class="sub-header"><b>New Greeting</b></label>
      <mat-radio-group
        class="greeting-radio-btns m-b-10"
        color="primary"
        [(ngModel)]="mohSettings.greetingType"
        (change)="onGreetingChange()">
        <mat-radio-button value="TTS">Text to Speech</mat-radio-button>
        <mat-radio-button value="Upload">Upload</mat-radio-button>
        <mat-radio-button value="Recording">Record</mat-radio-button>
      </mat-radio-group>
      <div
        class="text-to-speech-options"
        *ngIf="mohSettings.greetingType === 'TTS'">
        <label class="sub-header"><b>Message</b></label>

        <mat-form-field
          appearance="outline"
          class="density-minus-3">
          <textarea
            #greetingTextArea
            matInput
            [(ngModel)]="mohSettings.greetingMessage"
            cdkAutosizeMinRows="3"
            maxlength="200"
            (change)="disabled = false; greetingChanged = true"></textarea>
        </mat-form-field>

        <label class="sub-header"><b>Language</b></label>
        <mat-form-field
          appearance="outline"
          class="file-dropdown density-minus-3">
          <mat-select
            class="moh-file-select"
            placeholder="Language"
            (selectionChange)="onLanguageChanged($event.value)"
            [(ngModel)]="mohSettings.greetingLanguage">
            <mat-option
              *ngFor="let item of languages"
              [value]="item.id"
              >{{ item.name }} ({{ item.country }})</mat-option
            >
          </mat-select>
        </mat-form-field>
        <label class="sub-header"><b>Voice</b></label>
        <mat-form-field
          appearance="outline"
          class="file-dropdown density-minus-3">
          <mat-select
            class="moh-file-select"
            placeholder="Voice"
            (selectionChange)="onVoiceChanged($event.value)"
            [(ngModel)]="mohSettings.greetingVoice">
            <mat-option
              *ngFor="let item of voiceOptions"
              [value]="item.id"
              >{{ item.name }} ({{ item.type }})</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="text-to-speech-options"
        *ngIf="mohSettings.greetingType === 'Upload'">
        <app-audio-player
          *ngIf="showUploadAudioPlayer"
          [audio]="track"
          class="player"
          (delete)="deleteAudioFile(mohSettings.greetingIndex)"
          (download)="downloadAudioFile(mohSettings.greetingIndex)"></app-audio-player>

        <button
          (click)="showUploadOptions = true"
          mat-raised-button
          class="m-l-12 save-button"
          type="button"
          color="primary"
          *ngIf="!showUploadOptions">
          Upload a file
        </button>

        <div *ngIf="showUploadOptions">
          <mat-form-field
            appearance="outline"
            class="file-upload form-no-error density-minus-3">
            <mat-label>File Name</mat-label>
            <input
              [(ngModel)]="uploadGreetingFileName"
              autocomplete="off"
              class="search-call-history"
              matInput
              disabled />
          </mat-form-field>
          <div class="download-btn">
            <button
              class="btn"
              color="primary"
              (click)="selectGreetingFile()">
              Select a Different File
              <mat-icon color="primary">upload</mat-icon>
            </button>
            <input
              type="file"
              #fileInput
              accept="audio"
              (change)="addFile($event)"
              [style.display]="'none'" />
          </div>
        </div>
      </div>
      <div
        class="warning-support-text"
        *ngIf="microphoneAccessDenied">
        Recording canceled. Please enable microphone access and refresh.
      </div>

      <ng-container *ngIf="mohSettings.greetingType === 'Recording'">
        <div
          class="recorder relative"
          *ngIf="recordedFile === null">
          <div class="flex align-items-center">
            <div class="record-icon-container justify-content-center align-items-center">
              <div
                class="record-icon cursor-pointer"
                *ngIf="!isRecording"
                (click)="startInputSound()"></div>
              <div
                class="recording-icon cursor-pointer"
                *ngIf="isRecording"
                (click)="stopInputSound()"></div>
            </div>
            <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
            <div class="recorder-slider-container">
              <mat-slider
                disabled
                min="0"
                max="10"
                step="1"
                ngDefaultControl
                class="recorder-slider w-100"
                #ngSlider
                ><input
                  matSliderThumb
                  [(ngModel)]="recordingSeconds"
                  #ngSliderThumb="matSliderThumb"
              /></mat-slider>
            </div>
          </div>
        </div>
        <div
          class="recorder relative"
          *ngIf="recordedFile !== null && track && track.duration">
          <div class="flex align-items-center">
            <div class="justify-content-center align-items-center">
              <button
                class="play-pause"
                color="primary"
                (click)="onRecordedFilePlayClicked()"
                mat-mini-fab>
                <mat-icon
                  aria-hidden="true"
                  class="play-track"
                  >{{ playingAudioId === 'recorded' ? 'pause' : 'play_arrow' }}
                </mat-icon>
              </button>
            </div>
            <div class="track-duration">{{ Number(recordingSeconds) | secondsToMinutes }}</div>
            <div class="recorder-slider-container">
              <mat-slider
                min="0"
                [max]="track.duration"
                step="1"
                ngDefaultControl
                class="recorder-slider w-100"
                #ngSlider
                ><input
                  matSliderThumb
                  [(ngModel)]="recordingSeconds"
                  #ngSliderThumb="matSliderThumb"
              /></mat-slider>
            </div>
            <div class="recorder-delete-container">
              <button
                (click)="deleteRecording(mohSettings.greetingIndex)"
                color="primary"
                mat-icon-button>
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="editingDisabled"
    class="grey-mask"
    #greyMask></div>
</section>
