import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BaseStateService } from '@app/core/services/base.state.service';
import { PhoneNumberPipe } from '@app/shared/pipes/phone-number.pipe';
import { environment } from '@environment/environment';
import { BehaviorSubject, catchError, finalize, firstValueFrom, forkJoin, map, Observable, of, tap } from 'rxjs';

import {
  ActiveRuleResponse,
  AnsweringRule,
  AnsweringRuleResponse,
  AnsweringRuleType,
  ForwardDestination,
  GeneralResponse,
  InUseResponse,
  RingDurationResponse,
  TimeFrame,
  TimeFrameType,
  TimeRangeData,
} from '../models/answering-rules.models';

@Injectable({
  providedIn: 'root',
})
export class AnsweringRulesService extends BaseStateService<AnsweringRuleResponse> {
  protected path = '';
  protected override baseUrl = `${environment.gateway}/users/{me}`;

  private answeringRulesLoadingSubject = new BehaviorSubject<boolean>(true);
  public answeringRulesLoading$ = this.answeringRulesLoadingSubject.asObservable();

  private timeFramesLoadingSubject = new BehaviorSubject<boolean>(false);
  public timeFramesLoading$ = this.timeFramesLoadingSubject.asObservable();

  public readonly answeringRulesSubject = new BehaviorSubject<AnsweringRule[]>([]);
  public readonly answeringRules$ = this.answeringRulesSubject.asObservable();

  private readonly timeFramesSubject = new BehaviorSubject<TimeFrame[]>([]);
  public readonly timeFrames$ = this.timeFramesSubject.asObservable();

  private forwardDestinationsSubject = new BehaviorSubject<ForwardDestination[]>([]);
  public readonly forwardDestinations$ = this.forwardDestinationsSubject.asObservable();

  private readonly activeRuleNameSubject = new BehaviorSubject<string>('');
  public readonly activeRuleName$ = this.activeRuleNameSubject.asObservable();

  public isCurrentUserOfficeManager = false;

  constructor(
    httpClient: HttpClient,
    private contactService: ContactService,
    private appConfigService: AppConfigService
  ) {
    super(httpClient);

    this.contactService.currentUser$.subscribe((currentUser) => {
      this.isCurrentUserOfficeManager = currentUser?.scope?.includes('Office Manager') || false;
    });
  }

  public fetchData() {
    this.getDestinationsAndRules();
    this.getTimeFrames();
  }

  public getRingDuration(): Observable<RingDurationResponse> {
    return this.get<RingDurationResponse>('ring-duration');
  }

  public setRingDuration(duration: number): Observable<GeneralResponse> {
    return this.post<GeneralResponse>(`ring-duration/${duration}`, '', { method: 'PUT' });
  }

  public createRule(timeFrame: string, rule: AnsweringRule): Observable<GeneralResponse> {
    return this.post<GeneralResponse>(`answering-rules/${timeFrame}`, rule, { method: 'POST' }).pipe(
      tap(() => {
        this.getAnsweringRules(true);
        this.updateInUseState(timeFrame);
      })
    );
  }

  public updateRule(timeFrame: string, rule: AnsweringRule, withLoadingObserver = true): Observable<GeneralResponse> {
    return this.post<GeneralResponse>(`answering-rules/${timeFrame}`, rule, { method: 'PUT' }).pipe(
      tap(() => {
        this.getAnsweringRules(withLoadingObserver);
      })
    );
  }

  public deleteRule(timeFrame: string): Observable<GeneralResponse> {
    return this.delete(`answering-rules/${timeFrame}`).pipe(
      tap(() => {
        this.getAnsweringRules(false);
        this.updateInUseState(timeFrame);
      })
    ) as Observable<GeneralResponse>;
  }

  public getActiveRule(): Observable<ActiveRuleResponse> {
    return this.get<ActiveRuleResponse>('answering-rules/active').pipe(
      tap((data) => {
        this.activeRuleNameSubject.next(data.time_frame);
      })
    );
  }

  public addUpdateTimeFrame(name: string, data: TimeRangeData[], inUse = false): Observable<GeneralResponse> {
    const timeFrameData = {
      shared: this.isCurrentUserOfficeManager && this.appConfigService.features[AppFeature.ModifyTimeFrames],
      time_range_data: data,
    };
    return this.post<GeneralResponse>(`time-frames/${name}`, timeFrameData, { method: 'PUT' }).pipe(
      tap(() => {
        this.addUpdateLocalTimeFrame(name, data, inUse);
      })
    );
  }

  public deleteTimeFrame(timeFrame: string): Observable<GeneralResponse> {
    return this.delete(`time-frames/${timeFrame}`).pipe(
      tap(() => {
        this.deleteLocalTimeFrame(timeFrame);
      })
    ) as Observable<GeneralResponse>;
  }

  public setPriority(rulesOrder: string): Observable<GeneralResponse> {
    return this.post<GeneralResponse>('answering-rules', { priority: rulesOrder }, { method: 'PUT' }).pipe(
      tap(() => {
        this.getAnsweringRules(false);
      })
    );
  }

  public timeFrameInUse(timeFrame: string): Observable<InUseResponse> {
    return this.get<InUseResponse>(`time-frames-inuse/${timeFrame}`);
  }

  public answeringRuleType(answeringRule: AnsweringRule): AnsweringRuleType {
    if (answeringRule.doNotDisturb) {
      return AnsweringRuleType.DoNotDisturb;
    } else if (answeringRule.simRing.enabled) {
      return AnsweringRuleType.SimultaneousRing;
    } else if (answeringRule.forwardAlways.enabled) {
      return AnsweringRuleType.ForwardAllCalls;
    } else {
      return AnsweringRuleType.OnlyPrimaryDeskphone;
    }
  }

  public timeFrameType(timeFrame: TimeFrame): TimeFrameType {
    if (timeFrame.time_range_data.some((tf) => tf.date_from === 'now' && tf.date_to === 'never' && tf.days === '*')) {
      return TimeFrameType.Always;
    } else if (timeFrame.time_range_data.length > 0 && timeFrame.time_range_data[0].days.match('\\d')) {
      return TimeFrameType.DaysAndTimes;
    } else {
      return TimeFrameType.SpecificDates;
    }
  }

  public filterTimeFrames(filterPersonal: boolean): TimeFrame[] {
    return filterPersonal
      ? this.timeFramesSubject.value.filter((tf) => tf.owner !== '*')
      : this.timeFramesSubject.value;
  }

  public fwdDestDescriptionToValueString(description: string): string {
    const dest = this.forwardDestinationsSubject.value.find((item) => item.description === description);
    return dest ? dest.value : description;
  }

  public fwdDestValueToDescriptionString(value: string): string {
    const dest = this.forwardDestinationsSubject.value.find((item) => item.value === value);
    return dest ? dest.description : value;
  }

  private getTimeFrames() {
    this.timeFramesLoadingSubject.next(true);
    return this.get<TimeFrame[]>('time-frames')
      .pipe(
        catchError((error) => {
          this.timeFramesSubject.next([]);
          return error;
        }),
        finalize(() => this.timeFramesLoadingSubject.next(false))
      )
      .subscribe((data: TimeFrame[]) => {
        this.timeFramesSubject.next(this.sortTimeFrames(data));
      });
  }

  private getAnsweringRulesAPI(withLoadingObserver: boolean): Observable<AnsweringRuleResponse[]> {
    if (withLoadingObserver) {
      this.answeringRulesLoadingSubject.next(true);
    }
    this.getActiveRuleInternal();

    return this.get<AnsweringRuleResponse[]>('answering-rules').pipe(
      catchError((error) => {
        console.warn('Get answering rules error', error);
        return of([]);
      })
    );
  }

  private async getActiveRuleInternal() {
    try {
      await firstValueFrom(this.getActiveRule());
    } catch (error) {
      console.error('Get active rule error:', error);
      this.activeRuleNameSubject.next('');
    }
  }

  private getAnsweringRules(withLoadingObserver: boolean) {
    return this.getAnsweringRulesAPI(withLoadingObserver).subscribe((data: AnsweringRuleResponse[]) => {
      this.setAnsweringRules(data);
    });
  }

  private setAnsweringRules(rulesResponse: AnsweringRuleResponse[]): void {
    const rules: AnsweringRule[] = rulesResponse.map((rule) => {
      return {
        enabled: rule.enabled,
        timeFrame: rule.timeframe,
        priority: rule.priority,
        doNotDisturb: rule.doNotDisturb,
        callScreening: rule.callScreening,
        forwardAlways: {
          enabled: rule.forwardAlways.enabled,
          destination: this.fwdDestValueToDescriptionString(this.destString(rule.forwardAlways.destination)),
        },
        forwardOnActive: {
          enabled: rule.forwardOnActive.enabled,
          destination: this.fwdDestValueToDescriptionString(this.destString(rule.forwardOnActive.destination)),
        },
        forwardBusy: {
          enabled: rule.forwardBusy.enabled,
          destination: this.fwdDestValueToDescriptionString(this.destString(rule.forwardBusy.destination)),
        },
        forwardNoAnswer: {
          enabled: rule.forwardNoAnswer.enabled,
          destination: this.fwdDestValueToDescriptionString(this.destString(rule.forwardNoAnswer.destination)),
        },
        forwardOffline: {
          enabled: rule.forwardOffline.enabled,
          destination: this.fwdDestValueToDescriptionString(this.destString(rule.forwardOffline.destination)),
        },
        simRing: {
          enabled: rule.simRing.enabled,
          usersExtension: rule.simRing.usersExtension,
          allDevices: rule.simRing.allDevices,
          confirmOffnet: rule.simRing.confirmOffnet || false,
          otherDestinations: rule.simRing.otherDestinations,
        },
      };
    });

    this.answeringRulesSubject.next(rules);
    this.answeringRulesLoadingSubject.next(false);
  }

  private getForwardDestinations(): Observable<ForwardDestination[]> {
    return this.get<ForwardDestination[]>('answering-rules/forward').pipe(
      catchError(() => {
        return of([]);
      })
    );
  }

  private getDestinationsAndRules() {
    forkJoin([this.getForwardDestinations(), this.getAnsweringRulesAPI(true)])
      .pipe(
        map(([destinations, rules]) => {
          const filtered = destinations.filter((item) => item.description !== undefined && item.value !== undefined);

          const numberPipe = new PhoneNumberPipe();
          const contactDestinations = this.contactService.source.value
            .flatMap((contact) => {
              // Map each tel value to an individual entry.
              return (contact.tels ?? []).map((tel) => ({ tel, contact }));
            })
            .map((item) => {
              return {
                value: item.tel.number,
                description: `Contact - ${numberPipe.transform(item.tel.number)} (${item.contact.fullName})`,
              };
            }) as ForwardDestination[];

          this.forwardDestinationsSubject.next([...filtered, ...contactDestinations]);

          this.setAnsweringRules(rules);
        })
      )
      .subscribe();
  }

  private deleteLocalTimeFrame(timeFrame: string): void {
    const timeFrames = this.timeFramesSubject.value.filter((tf) => {
      return tf.time_frame !== timeFrame;
    });
    this.timeFramesSubject.next(timeFrames);
  }

  private updateInUseState(timeFrame: string): void {
    this.timeFrameInUse(timeFrame)
      .pipe(
        catchError((error) => {
          return error;
        })
      )
      .subscribe((response: InUseResponse) => {
        const foundTf = this.timeFramesSubject.value.find((tf) => tf.time_frame === timeFrame);
        if (foundTf) {
          foundTf.in_use = response.inuse;
          const updatedTimeFrames = this.timeFramesSubject.value.map((tf) =>
            tf.time_frame === timeFrame ? foundTf : tf
          );
          this.timeFramesSubject.next(updatedTimeFrames);
        }
      });
  }

  private addUpdateLocalTimeFrame(name: string, data: TimeRangeData[], inUse: boolean): void {
    const timeFrame = this.timeFramesSubject.value.find((tf) => tf.time_frame === name);

    let newTimeFrame: TimeFrame;

    if (timeFrame) {
      newTimeFrame = timeFrame;
      newTimeFrame.time_range_data = data;
    } else {
      const owner =
        this.isCurrentUserOfficeManager && this.appConfigService.features[AppFeature.ModifyTimeFrames]
          ? '*'
          : this.contactService.currentUser?.ext || 'ext';
      newTimeFrame = { time_frame: name, owner: owner, in_use: inUse, time_range_data: data };
    }

    const updatedTimeFrames = timeFrame
      ? this.timeFramesSubject.value.map((tf) => (tf.time_frame === name ? newTimeFrame : tf))
      : [...this.timeFramesSubject.value, newTimeFrame];

    this.timeFramesSubject.next(this.sortTimeFrames(updatedTimeFrames));
  }

  private sortTimeFrames(timeFrames: TimeFrame[]): TimeFrame[] {
    const personal = this.sortArray(timeFrames.filter((tf) => tf.owner !== '*'));
    const shared = this.sortArray(timeFrames.filter((tf) => tf.owner === '*'));

    return [...personal, ...shared];
  }

  private sortArray(timeFrames: TimeFrame[]): TimeFrame[] {
    return timeFrames.sort((a, b) => {
      return a.time_frame.localeCompare(b.time_frame);
    });
  }

  private destString(dest: string | Record<string, never> | null): string {
    return typeof dest === 'string' ? dest : '';
  }
}
