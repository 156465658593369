import { AppInstanceUserMembershipSummary } from '@app/meetings/models/chime.models';
import { UploadedAttachment } from '@app/sms/models/sms.models';

// Note: Update change detection in ChatStreamComponent if other
// props are added to this interface which get modified locally.
// For now, that is `favorite` and `mute`.
export interface Channel {
  id: string;
  name: string;
  channelArn: string;
  mode: 'UNRESTRICTED' | 'RESTRICTED';
  privacy: 'PUBLIC' | 'PRIVATE';
  lastMessageTimestamp?: string;
  type: 'Direct Message' | 'Chat Room';
  metadata?: string;
  members?: ChannelMember[];
  appInstanceUserMembershipSummary: AppInstanceUserMembershipSummary;
  favorite: boolean;
  mute: boolean;
  trash?: boolean;
  read?: boolean;
}

export interface ChannelDisplayData {
  channel: Channel | undefined;
  unreadCount: number;
  groupName: 'FAVORITES' | 'DIRECT MESSAGE' | undefined;
}

export interface ChannelMember {
  id: string;
  arn: string;
  name: string;
}

export const DeletedChannelMember: ChannelMember = {
  id: 'deleted-channel-member',
  arn: 'arn/deleted-channel-member',
  name: 'User Not Found',
};

export interface ListMessagesResponse {
  messages: ChannelMessage[];
  nextToken?: string;
  hasMore: boolean;
}

export type ChannelMessageType = 'STANDARD' | 'CONTROL';

export interface ChannelMessage {
  content: string;
  createdTimestamp: string;
  lastUpdatedTimestamp?: string;
  messageId: string;
  metadata?: string;
  redacted: boolean;
  sender: ChannelMember;
  type: ChannelMessageType;
}

/** Structured representation of what is serialized in ChannelMessage.metadata */
export interface ChannelMessageMetadata {
  media?: UploadedAttachment[];
  status?: ChannelMessageStatus; // This is typically only set locally, but allows us to package up failed sent status with the message object. Keeping as a string for potential read receipts, etc
}

export type ChannelMessageStatus = 'FAILURE';

export interface ChannelMembership {
  channelArn: string;
  invitedByArn: string;
  createdTimestamp: string;
  lastUpdatedTimestamp: string;
  member: {
    id: string;
    arn: string;
    name: string;
  };
}
