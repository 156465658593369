import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { BrandingService } from '@app/core/services/branding.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private brandingService: BrandingService) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const token = await this.authService.getRefreshedAccessToken();
    if (token === '') {
      window.location.href = this.brandingService.fusionauthUrl;
      return false;
    }
    return true;
  }
}
