import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlSegment } from '@angular/router';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { EventName } from '@app/core/models/event-bus.models';
import { BaseStateService } from '@app/core/services/base.state.service';
import { EventBusService } from '@app/core/services/event-bus.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { WsService } from '@app/core/services/ws.service';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import { ShowError } from '@app/shared/interceptors/error-interceptor';
import { PhoneNumberPipe } from '@app/shared/pipes/phone-number.pipe';
import normalizePhoneNumber from '@app/shared/utils/phone.util';
import { environment } from '@environment/environment';
import * as moment from 'moment';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  firstValueFrom,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  Subject,
  tap,
  throwError,
} from 'rxjs';

import {
  AllLocalNumber,
  DraftMessageContent,
  ListSMSNumbersResponse,
  LocalNumber,
  OptStatus,
  SMSConversation,
  SMSMessage,
  SMSMessageDispositionUpdatedPayload,
  STREAM_UPDATE_TYPE,
} from '../models/sms.models';
import { SMSMessageService } from './sms-message.service';

export type DataLoadState = 'initial' | 'loading' | 'loaded';
type SocketMessageEvent = 'SMSMessageSent' | 'SMSMessageReceived';

export interface LinkedSMSContact {
  number: string;
  contact?: Contact;
}

@Injectable({
  providedIn: 'root',
})
export class SMSService extends BaseStateService<SMSConversation> {
  protected override baseUrl = environment.messageHubGateway + '/sms';
  protected path = '';

  public readonly dataLoadStateSubject = new BehaviorSubject<DataLoadState>('initial');
  public readonly localNumbersSubject = new BehaviorSubject<LocalNumber[] | null>(null);
  public readonly hasLocalNumbersSubject = new BehaviorSubject<boolean>(false);
  private readonly optStatusSubject = new Subject<OptStatus & { conversationId: string }>();

  public readonly dataLoadState$ = this.dataLoadStateSubject.asObservable();
  public readonly localNumbers$ = this.localNumbersSubject.asObservable();
  public readonly hasLocalNumbers$ = this.hasLocalNumbersSubject.asObservable();
  public readonly optStatus$ = this.optStatusSubject.asObservable();

  private currentUrl: string;

  private messageSendRefCount = 0;
  private bufferedSocketMessages: Array<{ eventName: SocketMessageEvent; message: SMSMessage }> = [];

  /**
   * Every time our URL changes (i.e. when we receive a `NavigationEnd` event),
   * update the selectedLocalNumber based on whatever is in the url. If the url contains
   * a number that doesn't belong to the user, set the value to undefined.
   */
  public readonly selectedLocalNumberSubject = new BehaviorSubject<LocalNumber | null>(null);
  public readonly selectedLocalNumber$ = this.selectedLocalNumberSubject.asObservable();

  // Maps conversation id to conversation object.
  private conversationMap: Map<string, SMSConversation> = new Map();
  private messageServices: Map<string, SMSMessageService> = new Map();

  // ========== Getters / Setters ==========

  public get allLocalNumber(): LocalNumber {
    return AllLocalNumber;
  }

  // ========== Lifecycle ===========

  constructor(
    http: HttpClient,
    private contactService: ContactService,
    private wsService: WsService,
    eventBusService: EventBusService,
    private router: Router,
    private notificationService: NotificationService,
    private storage: LocalStorageService
  ) {
    super(http);

    eventBusService.on(EventName.Logout, () => {
      this.dataLoadStateSubject.next('initial');
      this.localNumbersSubject.next(null);
      this.selectedLocalNumberSubject.next(null);
    });

    const selectedLocalNumberStorageKey = 'selectedLocalNumberStorageKey';
    const lastStoredLocalNumber = this.storage.get<LocalNumber>(selectedLocalNumberStorageKey);
    if (lastStoredLocalNumber) {
      this.selectedLocalNumberSubject.next(lastStoredLocalNumber);
    }

    // Always persist the selectedLocalNumber to localStorage
    this.selectedLocalNumber$.subscribe((value) => {
      if (value === null) {
        this.storage.delete(selectedLocalNumberStorageKey);
      } else {
        this.storage.set(selectedLocalNumberStorageKey, value);
      }
    });

    this.localNumbers$.subscribe((localNumbers) => {
      const hasLocalNumbers = (localNumbers && localNumbers.length > 0) || false;
      this.hasLocalNumbersSubject.next(hasLocalNumbers);
    });

    this.wsService.socket.on('SMSConversationCreated', (conversation: SMSConversation) => {
      if (this.conversationMap.get(conversation.conversationId)) {
        return;
      }

      const normalizedConversation: SMSConversation = {
        ...conversation,
        local: normalizePhoneNumber(conversation.local),
        remotes: conversation.remotes.map((remote) => normalizePhoneNumber(remote)),
      };
      this.conversationMap.set(normalizedConversation.conversationId, normalizedConversation);
      this.setData([...this.conversationMap.values()]);
    });

    // Observe outgoing messages and add them to the corresponding message service. This may be necessary
    // if a message was sent by the current user but from a different device
    this.wsService.socket.on('SMSMessageSent', (message: SMSMessage) => {
      if (this.messageSendRefCount > 0) {
        this.bufferedSocketMessages.push({ message, eventName: 'SMSMessageSent' });
      } else {
        this.processSocketMessage(message, 'SMSMessageSent');
      }
    });

    this.wsService.socket.on('SMSMessageDispositionUpdated', (payload: SMSMessageDispositionUpdatedPayload) => {
      console.log(`sms: SMSMessageDispositionUpdated ${payload.conversationId} ${payload.messageId} ${payload.status}`);

      const messageService = this.getMessageService(payload.conversationId);
      const message: SMSMessage | undefined = messageService.messageWithId(payload.messageId);
      if (message) {
        messageService.upsertMessage(message.messageId, {
          ...message,
          disposition: payload.status,
        });
      }
    });

    // Observe incoming messages and add them to the corresponding message service.
    this.wsService.socket.on('SMSMessageReceived', (message: SMSMessage) => {
      // Ignore notifications that aren't stream updates. Standard messages won't have the `notification`
      // prop set and when it is set, only stream updates should be processed.
      if (message.notification?.type && message.notification.type !== STREAM_UPDATE_TYPE) {
        return;
      }
      // No need to buffer these since only messages from other users should be emitted with this event
      this.processSocketMessage(message, 'SMSMessageReceived');
    });

    // Observe opt status changes and update in-memory conversation data
    this.wsService.socket.on('SMSConversationOptStatus', (optStatus: OptStatus & { conversationId: string }) => {
      const conversation = this.getCachedConversationWithId(optStatus.conversationId);
      if (conversation) {
        this.conversationMap.set(optStatus.conversationId, {
          ...conversation,
          optStatus,
        });
        this.setData([...this.conversationMap.values()]);
      }

      this.optStatusSubject.next(optStatus);
    });

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => event as NavigationEnd)
      )
      .subscribe((navigationEndEvent) => {
        this.currentUrl = navigationEndEvent.url;
        this.setLocalNumberFromUrl();
      });
  }

  private processSocketMessage(message: SMSMessage, eventName: SocketMessageEvent) {
    // Normalize the message's local and remote numbers
    console.log(`sms: process ${eventName} ${message.messageId} ${(message.content ?? '').slice(0, 4)} `);
    const normalizedMessage: SMSMessage = {
      ...message,
      sender: normalizePhoneNumber(message.sender),
      recipients: message.recipients.map((recipient) => normalizePhoneNumber(recipient)),
    };
    const messageService = this.getMessageService(normalizedMessage.conversationId);
    messageService.updateLatestMessageIfNecessary(normalizedMessage);

    const upsertResult = messageService.upsertMessage(normalizedMessage.messageId, normalizedMessage);

    const conversation = this.conversationMap.get(normalizedMessage.conversationId);
    if (conversation) {
      this.conversationMap.set(conversation.conversationId, {
        ...conversation,
        lastMessage: normalizedMessage,
        lastMessageTimestamp: normalizedMessage.createdTimestamp,
      });

      this.setData([...this.conversationMap.values()]);

      if (upsertResult === 'inserted' && eventName === 'SMSMessageReceived') {
        this.notificationService.sendNotification(
          'New SMS Message',
          `${normalizedMessage.content}`,
          NotificationKeyBackend.SMS
        );
      }
    }
  }

  private incrementMessageSendRefCount() {
    this.messageSendRefCount += 1;
  }

  private decrementMessageSendRefCount() {
    const shouldProcessBuffer = this.messageSendRefCount === 1;
    this.messageSendRefCount = Math.max(0, this.messageSendRefCount - 1);

    if (shouldProcessBuffer) {
      for (const { message, eventName } of this.bufferedSocketMessages) {
        this.processSocketMessage(message, eventName);
      }
      this.bufferedSocketMessages = [];
    }
  }

  override getHttpData(): Observable<SMSConversation[]> {
    this.dataLoadStateSubject.next('loading');

    // A 403 error from this call is a valid scenario that should be handled.
    // See https://skyswitch.atlassian.net/browse/CUC-788?focusedCommentId=177107 for more detail.
    return forkJoin([
      this.get<{ conversations: SMSConversation[] }>('conversations', {
        context: new HttpContext().set(ShowError, (err) => err.status != 403),
        params: { 'max-results': '100' },
      }).pipe(
        map(({ conversations }) => {
          return conversations.map((conversation) => {
            return {
              ...conversation,
              id: conversation.conversationId,
              local: normalizePhoneNumber(conversation.local),
              remotes: conversation.remotes.map((remote) => normalizePhoneNumber(remote)),
            };
          }) as SMSConversation[];
        }),
        catchError((error: HttpErrorResponse) => {
          return error.status === 403 ? of([]) : throwError(() => error);
        })
      ),
      this.get<ListSMSNumbersResponse>('numbers', {
        context: new HttpContext().set(ShowError, (err) => err.status != 403),
      }).pipe(
        catchError((error: HttpErrorResponse) => {
          return error.status === 403 ? of({ numbers: [], sharedNumbers: [] }) : throwError(() => error);
        })
      ),
    ]).pipe(
      tap(([conversations, numbersResponse]) => {
        console.log(`sms: got ${conversations.length} conversations`);
        const allLocalNumbers = [
          ...numbersResponse.numbers,
          ...numbersResponse.sharedNumbers.map((number) => {
            return { ...number, shared: true };
          }),
        ];
        this.localNumbersSubject.next(allLocalNumbers);
        // in case page is reloaded with url contains sms number set this number as selected
        this.setLocalNumberFromUrl();
      }),
      map(([conversations]) => {
        return conversations;
      }),
      tap((conversations) => {
        this.setData(conversations);
        this.dataLoadStateSubject.next('loaded');
      })
    );
  }

  protected override setData(data: SMSConversation[]): void {
    const sorted = data.sort((lhs, rhs) => (lhs.lastMessageTimestamp < rhs.lastMessageTimestamp ? 1 : -1));

    const conversationMap = new Map();
    for (const conversation of sorted) {
      conversationMap.set(conversation.conversationId, conversation);

      if (conversation.lastMessage) {
        const messageService = this.getMessageService(conversation.conversationId);
        messageService.updateLatestMessageIfNecessary(conversation.lastMessage);
      }
    }
    this.conversationMap = conversationMap;
    this.source.next(sorted);
  }

  private async createConversation(local: string, remotes: string[]): Promise<SMSConversation> {
    console.log(
      `sms: creating conversation with local: ${local.slice(-4)}, remotes: ${remotes.map((r) => r.slice(-4)).join(',')}`
    );

    const { conversationId } = await firstValueFrom(
      this.post<{ conversationId: string }>(
        'conversations',
        {
          local,
          remotes,
        },
        {
          method: 'PUT',
        }
      )
    );

    // If the conversation doesn't exist already, ensure it is in the array.
    let conversation = this.conversationMap.get(conversationId);
    if (!conversation) {
      // Create a new conversation object.
      conversation = {
        id: conversationId,
        conversationId: conversationId,
        local, // no need to normalize the numbers here since they have to be normalized correctly to work with the API
        remotes,
        unreadMessageCount: 0,
        lastMessageTimestamp: '',
        lastReadTimestamp: '',
      };
      this.setData([...this.getData(), conversation]); // will cause conversations to be sorted again. Not the most efficient, but should work just fine for the amount of data we're dealing with
    }

    return conversation;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected override updateField(id: string, field: string, state: string | number | boolean): void {
    // Do nothing. We don't want behavior from the base class.
    // TODO: Remove reliance on base class for this service
  }

  private setLocalNumberFromUrl() {
    const tree = this.router.parseUrl(this.currentUrl);
    const urlSegments: UrlSegment[] | undefined = tree.root.children[PRIMARY_OUTLET]?.segments;
    if (!urlSegments) {
      this.selectedLocalNumberSubject.next(null);
      return;
    }

    const smsSegmentIndex = urlSegments.findIndex((segment) => segment.path === 'sms');
    if (smsSegmentIndex !== -1 && smsSegmentIndex + 1 < urlSegments.length) {
      const numberInPath = urlSegments[smsSegmentIndex + 1];
      const localNumber = [this.allLocalNumber, ...(this.localNumbersSubject.value || [])].find(
        (number) => number.number === numberInPath.path
      );

      // Update our subject if its value has changed
      const number = localNumber || this.allLocalNumber;
      if (JSON.stringify(number) !== JSON.stringify(this.selectedLocalNumberSubject.value)) {
        console.log(`sms: setting local number with value ${number?.number}`);
        this.selectedLocalNumberSubject.next(number);
      }
    } else {
      this.selectedLocalNumberSubject.next(null);
    }
  }

  // ========== Messages ==========

  public getMessageService(conversationId: string): SMSMessageService {
    if (!this.messageServices.has(conversationId)) {
      this.messageServices.set(conversationId, new SMSMessageService(this.httpClient, conversationId));
    }

    return this.messageServices.get(conversationId)!;
  }

  public retrySendMessage(message: SMSMessage, conversation: SMSConversation) {
    const draft: DraftMessageContent = {
      content: message.content ?? '',
      media: message.media || [],
    };
    // Remove the message and try sending it again.
    const messageService = this.getMessageService(conversation.conversationId);
    messageService.removeMessage(message.messageId);
    return this.sendMessage(draft, conversation, message.messageId);
  }

  /**
   * Send an SMS message to a conversation
   * @param draft The draft message to send
   * @param conversation The conversation the message belongs to
   * @param referenceId A optional reference id for the message. Use this when attempting to send a message that failed previously.
   * @returns An observable containing the conversationId and messageId of the sent message, or undefined if the message failed to send.
   */
  public sendMessage(
    draft: DraftMessageContent,
    conversation: SMSConversation,
    referenceId?: string
  ): Observable<{ conversationId: string; messageId: string } | undefined> {
    const messageService = this.getMessageService(conversation.conversationId);
    const userCreatedMessage = messageService.insertUserCreatedMessage(
      conversation.conversationId,
      draft,
      conversation.local,
      conversation.remotes,
      referenceId
    );

    // Strip the type and size from media before POSTing.
    const mediaUrls =
      userCreatedMessage.media?.map((item) => {
        return { url: item.url, fileName: item.fileName };
      }) || [];
    const body: Partial<SMSMessage> | { application: string } = {
      referenceId: userCreatedMessage.messageId,
      sender: conversation.local,
      recipients: conversation.remotes,
      content: userCreatedMessage.content,
      metadata: userCreatedMessage.metadata,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      media: mediaUrls as any,
      application: 'connectuc',
    };

    this.incrementMessageSendRefCount();
    return this.post('messages', body).pipe(
      map((value) => value as { conversationId: string; messageId: string }),
      tap((value) => {
        console.log(
          `chat: sent messageId: ${value.messageId} content: ${(userCreatedMessage.content ?? '').slice(0, 4)}`
        );
        messageService.upsertMessage(userCreatedMessage.messageId, {
          ...userCreatedMessage,
          conversationId: value.conversationId,
          messageId: value.messageId,
        });
      }),
      catchError(() => {
        const message = SMSMessageService.messageWithAddedMetadata(userCreatedMessage, { status: 'FAILURE' });
        messageService.upsertMessage(message.messageId, message);
        return of();
      }),
      finalize(() => {
        this.decrementMessageSendRefCount();
      })
    );
  }

  // ========== Opt Status ==========
  /**
   * Returns an observable for the opt status with the given conversation id. Observable
   * starts with the current value of the opt status and emits new values when the opt status changes.
   */
  public observeOptStatus(conversationId: string): Observable<OptStatus | undefined> {
    return this.optStatus$.pipe(
      filter((status) => status.conversationId === conversationId),
      startWith(this.getCachedConversationWithId(conversationId)?.optStatus)
    );
  }

  // ========== Helpers ==========

  public async findOrCreateConversation(local: string, remotes: string[]): Promise<SMSConversation> {
    let conversation = this.getCachedConversationWithNumbers(local, remotes);
    if (!conversation) {
      conversation = await this.createConversation(local, remotes);
    }
    return conversation;
  }

  public getContactNamesForDisplay(conversation: SMSConversation, truncateAfter?: number, numbersOnly = false): string {
    if (!conversation.remotes) {
      return '';
    }

    const linkedContactsWithoutLocalUser = this.getLinkedContactsExcludingLocalUser(conversation);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getFirstWord = (string_: string): string => {
      return string_.split(' ').find((value) => value.length > 0) || string_;
    };

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const displayNameForLinkedContact = (contact: LinkedSMSContact, firstNamesOnly: boolean): string => {
      if (contact.contact && !numbersOnly) {
        return firstNamesOnly ? getFirstWord(contact.contact.fullName) : contact.contact.fullName;
      } else {
        return new PhoneNumberPipe().transform(contact.number);
      }
    };

    if (linkedContactsWithoutLocalUser.length === 1) {
      return displayNameForLinkedContact(linkedContactsWithoutLocalUser[0], false);
    } else if (linkedContactsWithoutLocalUser.length === 2) {
      return linkedContactsWithoutLocalUser.map((contact) => displayNameForLinkedContact(contact, true)).join(' and ');
    } else {
      const remainder = truncateAfter ? Math.min(truncateAfter, linkedContactsWithoutLocalUser.length) : undefined;
      // eslint-disable-next-line unicorn/prefer-ternary
      if (remainder) {
        // Append +N-truncateAfter if applicable
        return (
          linkedContactsWithoutLocalUser
            .slice(0, remainder)
            .map((contact) => displayNameForLinkedContact(contact, true))
            .join(', ') +
          (remainder < linkedContactsWithoutLocalUser.length ? `, +${linkedContactsWithoutLocalUser.length - 2}` : '')
        );
      } else {
        return linkedContactsWithoutLocalUser.map((contact) => displayNameForLinkedContact(contact, true)).join(', ');
      }
    }
  }

  public getLinkedContactsExcludingLocalUser(conversation?: SMSConversation): LinkedSMSContact[] {
    if (!conversation) {
      return [];
    }

    const remotesToDisplay = conversation.remotes?.map((remote) => normalizePhoneNumber(remote));
    return remotesToDisplay.map((remote) => {
      return { number: remote, contact: this.contactService.contactByTel(remote) };
    });
  }

  /**
   * Get an already-fetched conversation from memory.
   * @param local
   * @param remotes The phone number of each member of the conversation.
   * @returns The corresponding conversation or null otherwise.
   */
  public getCachedConversationWithNumbers(local: string, remotes: string[]): SMSConversation | null {
    const hashKey = this.hashKeyForNumbers(local, remotes);
    for (const conversation of this.conversationMap.values()) {
      if (hashKey === this.hashKeyForNumbers(conversation.local, conversation.remotes)) {
        return conversation;
      }
    }
    return null;
  }

  public getCachedConversationWithId(conversationId: string): SMSConversation | null {
    return this.conversationMap.get(conversationId) || null;
  }

  private hashKeyForNumbers(local: string, remotes: string[]): string {
    // Convert the list of remotes into a string we'll use for comparison.
    return [local, ...remotes]
      .map((number) => normalizePhoneNumber(number))
      .sort()
      .join(':');
  }

  public isAllLocalNumber(number: string | LocalNumber | null | undefined) {
    if (!number) {
      return false;
    }

    return typeof number === 'string'
      ? number === this.allLocalNumber.number
      : number.number === this.allLocalNumber.number;
  }

  private downloadSms(smsMessages: SMSMessage[]) {
    const content: string[] = [];
    smsMessages?.forEach((message) => {
      const senders: string[] = message.sender ? message.sender.split(' ') : [];
      let name = '';
      senders.forEach((sender) => {
        // check if its local user
        const number = this.isLocalNumber(sender);
        number
          ? (name += this.contactService.currentUser?.fullName ?? sender + ' ')
          : // look on contacts sms numbers to find the name of contact, otherwise use number
            (name += this.contactService.contactByTel(message.sender)?.fullName ?? sender + ' ');
      });
      let line = `${moment(message.updatedTimestamp).format('llll')} ${name}: ${message.content}`;
      //check for media attached
      message.media?.forEach((media) => {
        line += ` <file={${media.url} }>`;
      });
      line += '\n';
      content.push(line);
    });

    const blob = new Blob(content, { type: 'text/plain', endings: 'native' });
    const element = window.document.createElement('a');
    element.setAttribute('href', URL.createObjectURL(blob));
    element.setAttribute('download', '');
    element.style.display = 'none';
    window.document.body.append(element);
    element.click();
    element.remove();
    URL.revokeObjectURL(element.href);
  }

  public exportSms(id: string) {
    const messageService = this.getMessageService(id);
    messageService.fetchMessageHistory('PREVIOUS').subscribe((messages) => {
      messages.length === 0 ? this.downloadSms(messageService.messages) : this.exportSms(id);
    });
  }

  public deleteConversation(conversationId: string): Observable<void> {
    return this.getMessageService(conversationId).deleteConversation(conversationId);
  }

  public conversationCanBeClosed(conversationId: string): boolean {
    const conversation = this.conversationMap.get(conversationId);
    if (conversation && this.localNumbersSubject.value) {
      return this.localNumbersSubject.value.some((number) => number.number === conversation.local);
    }
    return false;
  }

  public isLocalNumber(number: string): boolean {
    return this.localNumbersSubject.value?.some((localNumber) => localNumber.number === number) || false;
  }

  public conversationLocalNumberIsShared(conversationId: string): boolean {
    const conversation = this.conversationMap.get(conversationId);
    if (!conversation) {
      return false;
    }

    const sharedNumbers =
      this.localNumbersSubject.value?.filter((number) => number.shared).map((number) => number.number) || [];
    return sharedNumbers.includes(conversation.local);
  }
}
