import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { environment } from '@environment/environment';
import { Emitter } from '@socket.io/component-emitter';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class SocketUC extends Emitter<any, any> {
  ws?: WebSocket;
  heartbeat$ = interval(1000 * 60 * 6);
  heartbeatSub?: Subscription;

  /**
   * From https://dev.to/jeroendk/how-to-implement-a-random-exponential-backoff-algorithm-in-javascript-18n6
   */
  initialReconnectDelay = 1000;
  currentReconnectDelay = this.initialReconnectDelay;
  maxReconnectDelay = 16_000;
  connectionStatus: 'Disconnected' | 'Connecting' | 'Connected' | 'Disconnecting' | 'Reconnecting' = 'Disconnected';
  retryCounterConnection = 0;

  private cachedMessagesToSend: Array<{ event: string; payload: unknown }> = [];

  constructor(private authService: AuthService) {
    super();
  }

  heartbeat() {
    this.send('ping', {});
  }

  disconnect() {
    this.ws?.close();
  }

  async reconnectToWebsocket() {
    if (this.currentReconnectDelay < this.maxReconnectDelay) {
      this.currentReconnectDelay *= 2;
    }
    this.connectionStatus = 'Reconnecting';
    this.retryCounterConnection++;
    if (this.retryCounterConnection < 10) {
      await this.connect();
    }
  }

  async connect(resetCounter = false) {
    if (resetCounter) {
      this.retryCounterConnection = 0;
    }
    const token = await this.authService.getRefreshedAccessToken();
    this.connectionStatus = 'Connecting';

    const readyState = this.ws?.readyState;
    if (readyState === WebSocket.CONNECTING || readyState === WebSocket.OPEN) {
      // NOTE: This return does not effect connection status of this class or the websocket class.
      // possible fix would be to throw an error here but not sure if that would cause any other issues
      console.log('websocket: ignoring connect() called while already connecting or open');
      return;
    }
    this.ws = new WebSocket(`${environment.websocketUrlWeb}?token=${token}&appType=web`);
    this.ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.hasOwnProperty('event') && data.hasOwnProperty('payload')) {
        this.emit(data.event, data.payload);
      }
    });

    this.ws.addEventListener('open', (event) => {
      this.currentReconnectDelay = this.initialReconnectDelay;
      this.connectionStatus = 'Connected';

      // Send all cached messages
      this.cachedMessagesToSend.forEach((message) => this.send(message.event, message.payload));
      this.cachedMessagesToSend = [];

      this.emit('connect', event);
      this.heartbeatSub = this.heartbeat$.subscribe(() => {
        this.heartbeat();
      });
    });

    this.ws.addEventListener('error', (event) => {
      this.emit('connect_error', event);
      this.heartbeatSub?.unsubscribe();
    });

    this.ws.addEventListener('close', (event) => {
      this.emit('disconnect', event);
      this.heartbeatSub?.unsubscribe();
      if (this.connectionStatus === 'Disconnecting') {
        this.connectionStatus = 'Disconnected';
      } else {
        setTimeout(async () => {
          await this.reconnectToWebsocket();
        }, this.currentReconnectDelay + Math.floor(Math.random() * 3000));
      }
    });
  }

  send(event: string, payload: unknown) {
    if (this.connectionStatus !== 'Connected') {
      this.cachedMessagesToSend.push({ event, payload });
      return;
    }

    this.ws?.send(JSON.stringify({ event, payload }));
  }
}
