import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gravatarUrl',
  standalone: true,
})
export class GravatarUrlPipe implements PipeTransform {
  /*
    In some cases the SS API provides contact urls of the format:
    https://www.gravatar.com/avatar/{:user-email-hash}?d=https%3A%2F%2Fui-avatars.com%2Fapi/{:user-name}

    We want to append a size parameter for ui-avatars.com AND gravatars.com. Fortunately the format of the url
    is such that all we need to append is `/{:2xsize}&s={:size}`. The 2x size is a recommendation by ui-avatars.com
    to ensure on hdpi screens the image looks good.

    If the url doesn't come from gravatar, don't append any resizing parameters.
  */
  transform(url: string, size: number): string {
    return url.includes('gravatar.com') ? url + `%2f${size * 2}&s=${size}` : url;
  }
}
