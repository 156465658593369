import { ThemePalette } from '@angular/material/core';

export interface DialogData {
  title: string | null;
  icon: string | null;
  description: string | null;
  buttons: DialogButton[];
  rememberId: string | null;
}

export interface DialogButton {
  label: string;
  color: ThemePalette;
  type: ButtonType;
  action: VoidFunction;
  default?: boolean;
}

export enum ButtonType {
  matButton = 'mat-button',
  matRaisedButton = 'mat-raised-button',
  matFlatButton = 'mat-flat-button',
  matStrokedButton = 'mat-stroked-button',
}
