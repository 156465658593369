import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AudioUtil } from '@app/shared/utils/audio.util';
import { firstValueFrom } from 'rxjs';

import { NotificationItems, NotificationType } from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';
import { NotificationService } from '../../services/notification.service';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends SettingsPage implements OnInit, OnDestroy {
  notificationItems: NotificationItems[];
  audioUtil: AudioUtil;
  formGroup: FormGroup;

  private removedTypes: NotificationType[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private notificationService: NotificationService,
    private configService: AppConfigService
  ) {
    super();
    this.formGroup = this.formBuilder.group({
      formItems: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    if (!this.configService.features[AppFeature.Chat]) {
      this.removedTypes.push(
        NotificationType.Chat,
        NotificationType.GroupMeetingStarted,
        NotificationType.MeetingJoined
      );
    }
    if (!this.configService.features[AppFeature.Fax]) {
      this.removedTypes.push(NotificationType.IncomingFax);
    }
    this.notificationItems = Object.values(this.notificationService.getNotificationItems()).filter(
      (item) => !this.removedTypes.includes(item.title)
    );
    this.notificationItems.forEach((_item) => {
      this.formItems.push(this.createItem(_item));
    });
  }

  get formItems(): FormArray {
    return this.formGroup.get('formItems') as FormArray;
  }

  private createItem(item: NotificationItems): FormGroup {
    return this.formBuilder.group({
      enableSound: item.notificationSound,
      enableNotification: item.showNotification,
      enableForegroundNotification: item.showInForeground,
      soundSelect: item.ringtone,
    });
  }

  async save() {
    this.notificationItems.forEach((item, index) => {
      item.ringtone = this.formItems.controls[index].value.soundSelect;
      item.notificationSound = this.formItems.controls[index].value.enableSound;
      item.showNotification = this.formItems.controls[index].value.enableNotification;
      item.showInForeground = this.formItems.controls[index].value.enableForegroundNotification;
    });
    const transformedItems = this.notificationService.transformToBackendFormat(this.notificationItems);
    await firstValueFrom(this.notificationService.updateNotificationSettings(transformedItems));
  }

  onCheckChange() {
    this.disabled = false;
  }

  async onRingtoneChanged(index: number, option: string) {
    this.formItems.controls[index].patchValue({ select: option });
    this.disabled = false;
    await this.play(option);
  }

  async play(select: string, e?: MouseEvent) {
    e?.stopPropagation();
    this.audioUtil?.pause();
    this.audioUtil = new Audio(`../../../assets/sounds/${select}.wav`) as AudioUtil;
    await this.settingsService.playAudio(this.audioUtil);
  }

  ngOnDestroy(): void {
    this.audioUtil?.pause();
  }

  getItemName(index: number): string {
    return this.notificationItems[index].title === 'SMS'
      ? this.notificationItems[index].title
      : this.notificationItems[index].title.toLowerCase();
  }
}
