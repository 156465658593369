import { Injectable } from '@angular/core';
import { ChimeMeetingService } from '@app/meetings/services/chime-meeting.service';
import { AudioUtil } from '@app/shared/utils/audio.util';

import { NotificationKeyBackend, SoundAssets } from '../models/settings.models';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class SoundEffectService {
  private audio: AudioUtil | null;

  constructor(private settingsService: SettingsService, private chimeMeetingService: ChimeMeetingService) {}

  public async play(assetName: string, notificationType: NotificationKeyBackend) {
    if (
      [NotificationKeyBackend['Incoming Call'], NotificationKeyBackend['Incoming Second Call']].includes(
        notificationType
      )
    ) {
      if (!this.audio) {
        this.audio = this.createAudioElement(assetName, true);
        await this.playAudio(
          this.audio,
          this.settingsService.getDefaultRingingVolume(
            this.chimeMeetingService.isMeetingActive,
            NotificationKeyBackend['Incoming Second Call'] === notificationType
          ),
          this.settingsService.audioSettings.notificationSpeakerDevice
        );
      }
    } else {
      const audio = this.createAudioElement(assetName, false);
      await this.playAudio(
        audio,
        this.settingsService.audioSettings.notificationSoundVolume,
        this.settingsService.audioSettings.notificationSpeakerDevice
      );
    }
  }

  async playAudio(audio: AudioUtil, volume: number, speakerDevice: string) {
    try {
      await this.settingsService.playAudio(audio, volume, speakerDevice);
    } catch (error) {
      console.error(error);
    }
  }

  private createAudioElement(assetName: string, loop = false): AudioUtil {
    const audio = new Audio() as AudioUtil;
    audio.src = (assetName as SoundAssets)
      ? `../../assets/sounds/${assetName}.wav`
      : `../../assets/sounds/${loop ? SoundAssets.RingtoneIncomingCall : SoundAssets.NotificationSimple1}.wav`;
    audio.loop = loop;
    return audio;
  }

  public stop() {
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }
}
