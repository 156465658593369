import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VoicemailSettingPageType } from '@app/preferences/models/voicemail.model';

@Component({
  selector: 'app-settings-voicemail-change-pin',
  templateUrl: './voicemail-change-pin.component.html',
  styleUrls: ['../../voicemail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicemailChangePinComponent {
  @Output() selectedPageChanged = new EventEmitter<VoicemailSettingPageType>();
  @Output() submitForm = new EventEmitter<string>();

  pinForm: FormGroup;
  processing = false;
  isPinHide = true;

  constructor(private formBuilder: FormBuilder) {
    this.pinForm = formBuilder.group(
      {
        pin: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(10)]),
        confirmPin: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(10)]),
      },
      {
        validator: this.ConfirmedValidator('pin', 'confirmPin'),
      }
    );
  }

  setSelectedPage(page: VoicemailSettingPageType) {
    this.selectedPageChanged.emit(page);
  }

  toggleIsPinHide() {
    this.isPinHide = !this.isPinHide;
  }

  ConfirmedValidator(controlName: string, matchingControlName: string): object {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value === matchingControl.value) {
        matchingControl.setErrors(null);
      } else {
        matchingControl.setErrors({ confirmedValidator: true });
      }
    };
  }

  save() {
    this.processing = true;
    if (this.pinForm.valid) {
      this.submitForm.emit(this.pinForm.controls['pin'].value);
    }
  }
}
