import { HttpClient } from '@angular/common/http';
import {
  ChannelMessage,
  ChannelMessageMetadata,
  ChannelMessageStatus,
  DeletedChannelMember,
  ListMessagesResponse,
} from '@app/chat/models/channel.models';
import { ChannelService } from '@app/chat/services/channel.service';
import { ApiService } from '@app/core/services/api.service';
import { getFormattedDateWithDecimalZulu } from '@app/shared/utils/date.util';
import { DraftMessageContent } from '@app/sms/models/sms.models';
import { BehaviorSubject, catchError, combineLatest, finalize, map, Observable, of, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

interface FetchMessageOptions {
  not_before?: Date | string;
  not_after?: Date | string;
  limit?: number;
  next_token?: string;
  sort_order?: 'ASCENDING' | 'DESCENDING';
  saveResults?: boolean; // defaults to true
}

interface PagingToken {
  token?: string;
  hasMore: boolean;
  initialRequestOptions: FetchMessageOptions;
}

export type PageDirection = 'PREVIOUS' | 'NEXT';

/**
 * Non-injectable service used for fetching messages for a given channel. Instances are instantiated
 * and managed by ChannelService which provides an interface for getting a channel's messages.
 *
 * This logic could have lived in ChannelService, but making it its own class made sense given how
 * closely its functionality mimics other services (even though this class isn't injectable).
 */
export class MessageService extends ApiService {
  private readonly messagesSubject: BehaviorSubject<ChannelMessage[]> = new BehaviorSubject<ChannelMessage[]>([]);
  public readonly messages$ = this.messagesSubject.asObservable();

  // Latest message is stored separately from the rest of the message array since it may come from a separate source
  private readonly latestMessageSubject: BehaviorSubject<ChannelMessage | null> =
    new BehaviorSubject<ChannelMessage | null>(null);
  public readonly lastestMessage$ = this.latestMessageSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private errorSubject = new BehaviorSubject<boolean>(false);
  public hasError$ = this.errorSubject.asObservable();
  public pageTokens?: {
    previous: PagingToken;
    next: PagingToken;
  };

  constructor(http: HttpClient, private channelArn: string) {
    super(http);
    // subscribe to messages to ensure we're always updating latest message correctly
    this.messagesSubject.subscribe((messages) => {
      if (messages.length === 0) {
        return;
      }
      // Update latest message if what we have as the last item in the list is newer than what's currently stored
      const lastMessageInList = messages[messages.length - 1];
      this.updateLatestMessageIfNecessary(lastMessageInList);
    });
  }

  private set messages(data: ChannelMessage[]) {
    this.messagesSubject.next([...data]);
  }

  private get messages(): ChannelMessage[] {
    return this.messagesSubject.getValue();
  }

  private get latestMessage(): ChannelMessage | null {
    return this.latestMessageSubject.getValue();
  }

  private set latestMessage(message: ChannelMessage | null) {
    this.latestMessageSubject.next(message);
  }

  public updateLatestMessageIfNecessary(toMessage: ChannelMessage) {
    if (!this.latestMessage || toMessage.createdTimestamp > this.latestMessage.createdTimestamp) {
      this.latestMessage = toMessage;
    }
  }

  // =========== API ===========

  resetData() {
    this.pageTokens = undefined;
    this.messages = [];
  }

  /**
   * Fetches the next page of messages from the history. Messages will be stored as a prop on this service,
   * but the observable is returned to make reacting to stream emissions more convenient.
   * @returns An observable which emits the newly fetched message payload once the messages have been retrieved.
   */
  fetchMessageHistory(pageDirection: PageDirection): Observable<ChannelMessage[]> {
    if (!this.pageTokens || !this.hasMoreMessagesInDirection(pageDirection)) {
      return of([]);
    }

    const pageToken = pageDirection === 'PREVIOUS' ? this.pageTokens?.previous : this.pageTokens.next;
    const sortOrder = pageDirection === 'PREVIOUS' ? 'DESCENDING' : 'ASCENDING';
    const options: FetchMessageOptions = {
      ...pageToken.initialRequestOptions,
      next_token: pageToken.token,
      sort_order: sortOrder,
    };

    return this.fetchMessages(options).pipe(
      tap((response) => {
        if (!this.pageTokens) {
          return;
        }

        const token: PagingToken = {
          token: response?.nextToken,
          hasMore: response?.hasMore || false,
          initialRequestOptions: pageToken.initialRequestOptions,
        };

        if (pageDirection === 'PREVIOUS') {
          this.pageTokens.previous = token;
        } else {
          this.pageTokens.next = token;
        }
      }),
      map((response) => {
        return [...(response?.messages || [])];
      }),
      tap((messages) => {
        this.mergeMessageSubsetWithCached(messages);
      })
    );
  }

  fetchPagesAroundTimestamp(lastReadMessageTimestamp?: string): Observable<ChannelMessage[]> {
    // If timestamp is undefined, just fetch the latest messages and don't worry about the "next" page token
    const fetchObservables = lastReadMessageTimestamp
      ? [
          this.fetchMessages({ not_after: lastReadMessageTimestamp }),
          this.fetchMessages({ not_before: lastReadMessageTimestamp, sort_order: 'ASCENDING' }),
        ]
      : [this.fetchMessages()];

    return combineLatest(fetchObservables).pipe(
      tap((responses) => {
        const previousMessagesResponse = responses[0];
        this.pageTokens = {
          previous: {
            token: previousMessagesResponse?.nextToken,
            hasMore: previousMessagesResponse?.hasMore || false,
            initialRequestOptions: { not_after: lastReadMessageTimestamp },
          },
          next: { token: undefined, hasMore: false, initialRequestOptions: {} },
        };

        if (responses.length > 1) {
          const nextMessagesResponse = responses[1];
          this.pageTokens.next = {
            token: nextMessagesResponse?.nextToken,
            hasMore: nextMessagesResponse?.hasMore || false,
            initialRequestOptions: { not_before: lastReadMessageTimestamp },
          };
        }
      }),
      map((responses) => {
        return responses.flatMap((response) => response?.messages || []);
      }),
      tap((messages) => {
        this.mergeMessageSubsetWithCached(messages);
      })
    );
  }

  private fetchMessages(
    options: {
      not_before?: Date | string;
      not_after?: Date | string;
      limit?: number;
      next_token?: string;
      sort_order?: 'ASCENDING' | 'DESCENDING';
      saveResults?: boolean; // defaults to true
    } = {}
  ): Observable<ListMessagesResponse | null> {
    const body = {
      channel_arn: this.channelArn,
      sort_order: 'DESCENDING',
      ...options,
    };

    this.loadingSubject.next(true);

    // This is a POST request so we don't have to mess with escaping the channel_arn as a query parameter
    return this.post<ListMessagesResponse>('users/{me}/messages/list', body).pipe(
      map((response) => {
        const mappedResponse = response;
        mappedResponse.messages.forEach((message) => {
          message.content = message.content === ChannelService.EmptyContentKeyword ? '' : message.content;
          if (message.sender.id === null) {
            // user was deleted
            message.sender = DeletedChannelMember;
          }
        });
        return mappedResponse;
      }),
      catchError((error) => {
        console.log(`fetchMessages error: ${error}`);
        this.errorSubject.next(true);
        return of(null);
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  public mergeMessageSubsetWithCached(subset: ChannelMessage[]) {
    // Determine where to insert the newly fetched messages into our array.
    // Typically messages fetched should be on one of the edges of our current array (i.e. all messages
    // should be timestamp before or after the current set). Since contents are ordered we can
    // simply look at the first and last element.
    // If for some reason there is overlap, favor the new data replacing what is stored locally.

    // O(n^2) filter any messages in the subset with the same id as a message in the existing set
    const filteredSubset = subset.filter(
      (subsetMessage) => !this.messages.some((existingMessage) => existingMessage.messageId === subsetMessage.messageId)
    );

    if (filteredSubset.length === 0) {
      return;
    }

    if (this.messages.length === 0) {
      this.messages = filteredSubset;
    } else if (this.messages[0].createdTimestamp > filteredSubset.slice(-1)[0].createdTimestamp) {
      // Subset messages are earlier and should appear before what is currently cached
      this.messages = [...filteredSubset, ...this.messages];
    } else if (this.messages[this.messages.length - 1].createdTimestamp < filteredSubset[0].createdTimestamp) {
      // subset messages are all later and should appear after what is currently cached
      this.messages = [...this.messages, ...filteredSubset];
    } else {
      // We've managed to get some message overlap. Concat the two arrays and sort. We've already filtered
      // duplicates so we shouldn't need to worry about that here.
      this.messages = this.sortMessages([...this.messages, ...filteredSubset]);
    }
  }

  // ========== Message Management ==========

  /**
   * Inserts (via upsert) a message with the provided content to the service's message list.
   * @param draft The drafted message
   * @param senderUserId UserId of the sender of the message
   * @param senderProfileName Display name of the user sending the message. This is what will be displayed as the sender's name in the UI
   * @returns The created message object.
   */
  public insertUserCreatedMessage(
    draft: DraftMessageContent,
    senderUserId: string,
    senderProfileName: string
  ): ChannelMessage {
    const dateString = getFormattedDateWithDecimalZulu(new Date());

    const message: ChannelMessage = {
      messageId: `temp-${uuidv4()}`,
      content: draft.content,
      createdTimestamp: dateString,
      lastUpdatedTimestamp: dateString,
      redacted: false,
      metadata: MessageService.serializeMessageMetadata(draft),
      sender: {
        id: senderUserId,
        arn: '',
        name: senderProfileName,
      },
      type: 'STANDARD',
    };
    this.upsertMessage(message.messageId, message);
    return message;
  }

  public removeMessage(messageId: string) {
    this.messages = this.messages.filter((message) => message.messageId !== messageId);
  }

  public hasMoreMessagesInDirection(pageDirection: PageDirection): boolean {
    if (!this.pageTokens) {
      return true;
    }

    const token = pageDirection === 'PREVIOUS' ? this.pageTokens.previous : this.pageTokens.next;
    return token.hasMore;
  }

  /**
   * Peform an upsert with the given payload. If the created date has been modified, the message
   * will be sorted into its correct position.
   * @param messageId The messageId to use for searching for an existing message
   * @param payload The message payload to be upserted
   */
  public upsertMessage(messageId: string, payload: ChannelMessage): 'inserted' | 'updated' {
    console.log(
      `chat: upserting message ${payload.messageId} content: ${payload.content.slice(0, 4)} ${payload.createdTimestamp}`
    );
    const messages = [...this.messagesSubject.value];
    const existingMessageIndex = messages.findIndex((m) => m.messageId === messageId);

    // If the message already exists, remove it.
    if (existingMessageIndex > -1) {
      console.log(
        `chat: removing existing message ${messageId} for ${payload.messageId} with content: ${payload.content.slice(
          0,
          4
        )}`
      );
      messages.splice(existingMessageIndex, 1);
    }

    // Add the new message by finding its insertion index. `messages` should already be sorted
    // so we only need to find the first index where the comparison result is > 0.
    let insertionIndex = messages.findIndex((m) => this.comparisonResultForMessages(m, payload) > 0);
    insertionIndex = insertionIndex > -1 ? insertionIndex : messages.length;

    console.log(`chat: pushing message ${payload.messageId} content: ${payload.content.slice(0, 4)}`);
    messages.splice(insertionIndex, 0, payload);
    this.messagesSubject.next(messages);
    return existingMessageIndex > -1 ? 'updated' : 'inserted';
  }

  /**
   * Adds the passed metadata object to the ChannelMessage's metadata. This requires deserializing the current
   * `metadata` prop (if it is set), merging the objects, and then stringifying the resulting object before
   * assigning it back to the channel.
   * @param message The message to append the metadata field to
   * @param metadata The metadata object to add
   * @returns A new ChannelMessage with the metadata argument appended to the existing metadata
   */
  public static messageWithAddedMetadata(message: ChannelMessage, metadata: object): ChannelMessage {
    const metadataObject = message.metadata ? JSON.parse(message.metadata) : {};
    const newMetadata = { ...metadataObject, ...metadata };
    return { ...message, metadata: JSON.stringify(newMetadata) };
  }

  private sortMessages(messages: ChannelMessage[]): ChannelMessage[] {
    return messages.sort((lhs, rhs) => {
      return this.comparisonResultForMessages(lhs, rhs);
    });
  }

  private comparisonResultForMessages(lhs: ChannelMessage, rhs: ChannelMessage): number {
    let comparisonResult = lhs.createdTimestamp.localeCompare(rhs.createdTimestamp);

    // If the created timestamp is the same, sort by last updated timestamp if it exists.
    // Favor the object that has a timestamp if both objects don't have the value set
    if (comparisonResult === 0) {
      if (lhs.lastUpdatedTimestamp && rhs.lastUpdatedTimestamp) {
        comparisonResult = lhs.lastUpdatedTimestamp?.localeCompare(rhs.lastUpdatedTimestamp);
      } else if (lhs.lastUpdatedTimestamp) {
        comparisonResult = -1;
      } else {
        comparisonResult = 1;
      }
    }
    return comparisonResult;
  }

  // ========== Helpers ==========

  /**
   * Returns the message currently stored in memory that is closest to the provided timestamp.
   *
   * @param timestamp The timestamp used for comparison when looking for a message
   * @param filter Limit the results to only messages before or after the current timestamp. Results are inclusive of the current timestamp
   * @returns The closest message or undefined if no message exists.
   */
  public messageClosestToTimestamp(
    timestamp: string,
    filter?: 'past_only' | 'future_only'
  ): ChannelMessage | undefined {
    const inputTime = new Date(timestamp).getTime();

    let messages = this.messages;
    if (filter) {
      messages = messages.filter((message) => {
        const messageTime = new Date(message.createdTimestamp).getTime();
        const difference = messageTime - inputTime;
        return filter === 'past_only' ? difference <= 0 : difference >= 0;
      });
    }

    if (messages.length === 0) {
      return undefined;
    }

    // Convert each timestamp to a date and compare to find the message closest to the given timestamp.
    let currentClosest = { message: messages[0], time: new Date(messages[0].createdTimestamp).getTime() };
    for (const message of this.messages) {
      const messageTime = new Date(message.createdTimestamp).getTime();
      if (Math.abs(messageTime - inputTime) < Math.abs(currentClosest.time - inputTime)) {
        currentClosest = { message, time: messageTime };
      }
    }

    return currentClosest.message;
  }

  public static serializeMessageMetadata(
    draft: DraftMessageContent,
    status?: ChannelMessageStatus
  ): string | undefined {
    if (draft.media.length === 0 && status === undefined) {
      return undefined;
    }

    const metadata: ChannelMessageMetadata = {
      media: draft.media,
      status: status,
    };
    return JSON.stringify(metadata);
  }

  public static deserializeMessageMetadata(metadata?: string): ChannelMessageMetadata | undefined {
    const parsed = metadata ? (JSON.parse(metadata) as ChannelMessageMetadata) : undefined;
    parsed?.media?.forEach((media) => {
      // If the filename is not provided, extract the last path component from the URL.
      // This is for backwards compatibility with older messages that don't have the filename field.
      if (!media.fileName) {
        media.fileName = decodeURI(media.url.split('/').pop() || '');
      }
    });
    return parsed;
  }
}
