import { Contact } from '@app/contacts/models/contact';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Session, SessionState } from 'sip.js';
import { SessionDescriptionHandler } from 'sip.js/lib/platform/web';

import { SipjsService } from '../services/sipjs.service';
import { Call } from './call.model';
import { CallTerminationCause, ConferenceStatus, SessionStatus } from './phone.models';

/**
 * Nominal type used to distinguish CallId and ConferenceIds. This ensures when calling
 * methods that take a call id, that we don't accidentally mix up conference and call id.
 *
 * See more info on nominal types here: https://www.typescriptlang.org/play#example/nominal-typing
 */
export type ConferenceId = string & { readonly __brand: unique symbol };

/**
 * Delegate methods for conference call events. These methods are called when the conference
 * call changes state.
 */
export interface ConferenceCallDelegate {
  onCallsAdded?(calls: Call[]): void;
  onCallsRemoved?(calls: Array<{ call: Call; status: ConferenceStatus.Split | ConferenceStatus.Left }>): void;
  onConferenceFinished?(): void;
}

/**
 * Implementation of ConferenceCall which is similar to the `Call` class in that it possess many of the same
 * properties. This class is also responsible for managing the audio streams for calls that are in the conference.
 *
 * This class is also responsible for setting `call.conferenceId` whenever calls are added and removed. To know when the property
 * has changed, use the delegate methods. This approach was chosen since it is similar to how SIP.js SessionManager reports events to SipjsService.
 *
 * Audio stream logic has been ported from the previous calling feature implementation.
 */
export class ConferenceCall {
  public readonly id: ConferenceId = Date.now().toString() as ConferenceId;
  private _calls: Call[] = [];
  private clonedSenderTrackMap = new Map<string, MediaStreamTrack>(); // [callId: senderTrack]
  private originalSenderTrackMap = new Map<string, MediaStreamTrack>(); // [callId: senderTrack]

  /** Always returns undefined for conference call */
  private contactSubject = new BehaviorSubject<Contact | undefined>(undefined);
  public readonly contact$ = this.contactSubject.asObservable();

  private mergedAudioStreamSubject = new BehaviorSubject<MediaStream | undefined>(undefined);
  public readonly mergedAudioStream$ = this.mergedAudioStreamSubject.asObservable();

  /** This property should only be assigned by PhoneService. Assigning anywhere else will result in broken behavior */
  public delegate: ConferenceCallDelegate | undefined;

  private sessionStatusSubscription: Subscription | undefined;
  private started = false;

  // ========== Getters ==========

  public get held(): boolean {
    if (this._calls.length === 0) {
      return false;
    }

    // If all calls are held, then the conference is held.
    // Every call should be in the same state. If there are different values, then something is wrong.
    const allCallsHeld = [...new Set(this._calls.map((c) => c.held))];
    if (allCallsHeld.length > 1) {
      console.error(`Calls in conference ${this.id} are in different held states.`);
      return false;
    }
    return allCallsHeld[0];
  }

  /** Returns the collective muted state of each call in the conference. If all calls are muted, then the conference is muted */
  public get muted(): boolean {
    if (this._calls.length === 0) {
      return false;
    }

    // Every call should be in the same state. If there are different values, then something is wrong.
    const allCallsMuted = [...new Set(this._calls.map((c) => this.clonedSenderTrackMap.get(c.id)?.enabled !== true))];
    if (allCallsMuted.length > 1) {
      console.error(`Calls in conference ${this.id} are in different states.`);
      return false;
    }
    return allCallsMuted[0];
  }

  /**
   * Returns the status of the conference. If all calls are in the same state, then the conference is in that state.
   * If the calls are in different states, then the conference is in the state of the call that is "most" active. This means
   * that if any call is answered, the conference is answered, answered takes precedence over hold, hold over hangup, etc.
   */
  public get status(): SessionStatus | undefined {
    if (this._calls.length === 0) {
      return undefined;
    }

    const allCallStatuses = new Set(this._calls.map((c) => c.status)); // lazy way to get unique values
    // If all statuses are the same, return that status
    if (allCallStatuses.size === 1) {
      return allCallStatuses.values().next().value;
    }

    // Look at the status of each call. If there are any that are established, then the state of the conference is
    // that status. Otherwise, the status is either hangup or destroy (depending on the status of the call).
    if (allCallStatuses.has(SessionStatus.Answered)) {
      return SessionStatus.Answered;
    } else if (allCallStatuses.has(SessionStatus.Hold)) {
      return SessionStatus.Hold;
    } else if (allCallStatuses.has(SessionStatus.Hangup)) {
      return SessionStatus.Hangup;
    } else if (allCallStatuses.has(SessionStatus.Destroy)) {
      return SessionStatus.Destroy;
    } else {
      console.error(`Calls in conference ${this.id} are in unhandled configuration ${allCallStatuses}.`);
      return undefined;
    }
  }

  /** The earliest established timestamp of all calls when the conference is created */
  private _establishedTimestamp: Date | undefined;
  public get establishedTimestamp(): Date | undefined {
    return this._establishedTimestamp;
  }

  /** The latest terminated timestamp of all calls when the conference ends. Returns undefined if there are calls that aren't terminated. */
  private _terminatedTimestamp: Date | undefined;
  public get terminatedTimestamp(): Date | undefined {
    return this._terminatedTimestamp;
  }

  /**
   * Termination cause always returns undefined for now since we don't care about this value for conference calls.
   */
  public get terminationCause(): CallTerminationCause | undefined {
    return undefined;
  }

  public get remoteUriUser(): string {
    return '';
  }

  public get calls(): Call[] {
    return this._calls;
  }

  private get sessions(): Session[] {
    return this._calls.map((call) => call.session);
  }

  private get peerConnections(): Array<{ session: Session; peerConnection: RTCPeerConnection }> {
    const peerConnections: Array<{ session: Session; peerConnection: RTCPeerConnection }> = [];
    for (const session of this.sessions) {
      if (session && [SessionState.Establishing, SessionState.Established].includes(session.state)) {
        const sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
          throw new Error('Invalid session description handler.');
        }

        const peerConnection = sessionDescriptionHandler.peerConnection;
        if (!peerConnection || !(peerConnection instanceof RTCPeerConnection)) {
          throw new Error('Invalid peer connection.');
        }
        peerConnections.push({ session, peerConnection });
      }
    }
    return peerConnections;
  }

  // ========== Lifecycle ==========

  constructor(private sipjsService: SipjsService, private audioContext: AudioContext) {
    // Observe status changes and handle mutations to the conference as needed
    this.sessionStatusSubscription = this.sipjsService.sessionStatus$.subscribe(async ({ call, status }) => {
      const isCallInConference = this._calls.some((c) => c.id === call.id);
      if (!this.started || !isCallInConference) {
        return;
      }

      // Remove calls from the conference when they're destroyed. Technically, they can be removed
      // once the call has hung up, but by waiting until the call is destroyed, the point where
      // SessionStatus.Hangup event is emitted will still contain the call's conference information.
      if (status === SessionStatus.Destroy) {
        await this.removeCalls([{ call, status: ConferenceStatus.Left }]);
      }
    });
  }

  public async destroy() {
    this.sessionStatusSubscription?.unsubscribe();
    this.started = false;
    await this.removeCalls(this._calls.map((call) => ({ call, status: ConferenceStatus.Left })));
    this.originalSenderTrackMap.forEach((track) => track.stop());
    this.clonedSenderTrackMap.forEach((track) => track.stop());
  }

  // ========== Individual Call Management ==========

  public async addCall(call: Call) {
    if (this._calls.some((c) => c.id === call.id)) {
      return;
    }

    call.conferenceId = this.id;

    this._calls.push(call);
    await this.mergeCallAudioStreams();
    this.delegate?.onCallsAdded?.([call]);
  }

  /**
   * Removes the call from the conference. If after the removal there would be only once call left in the conference, that call
   * is also removed and the conference is considered empty and will call the `onConferenceFinished` delegate method.
   * @param callsToRemoveWithReason The calls to remove from the conference and the reason for their removal (will get passed to delegate).
   */
  public async removeCalls(
    callsToRemoveWithReason: Array<{ call: Call; status: ConferenceStatus.Split | ConferenceStatus.Left }>
  ) {
    if (callsToRemoveWithReason.length === 0) {
      return;
    }

    // If after removing all the provided calls there would only be one call remaining, that call
    // should also be removed. Check for this case and add the call to the list of calls to remove if needed.
    const remainingCallsAfterRemoval: Call[] = [];
    for (const call of this._calls) {
      const willRemoveCall = callsToRemoveWithReason.some((c) => c.call.id === call.id);
      if (!willRemoveCall) {
        remainingCallsAfterRemoval.push(call);
      }
    }

    if (remainingCallsAfterRemoval.length === 1) {
      const call = remainingCallsAfterRemoval.pop()!;
      callsToRemoveWithReason.push({ call, status: ConferenceStatus.Left });
    }

    // For each call, restore its sender track and receiver track to their original state before merging again below.
    for (const { session, peerConnection } of this.peerConnections) {
      const senderTrack = this.originalSenderTrackMap.get(session.id);
      if (!senderTrack) {
        throw new Error(`Cannot restore sender track for call: ${session.id}`);
      }
      await peerConnection.getSenders()[0].replaceTrack(senderTrack);
    }

    // Filter out all calls that should be removed
    this._calls = remainingCallsAfterRemoval;

    // Sanity check that we didn't leave a single call in the conference
    if (this._calls.length === 1) {
      console.error('Conference only has one call remaining. This should never happen.');
    } else if (this._calls.length > 1) {
      // Merge the remaining calls if we have them
      await this.mergeCallAudioStreams();
    }

    // At this point the new audio stream should have been merged and we can update the calls and let the delegate know they were removed
    callsToRemoveWithReason.forEach((c) => (c.call.conferenceId = undefined));
    this.delegate?.onCallsRemoved?.(callsToRemoveWithReason);

    // Finally, if there are no more calls, let the delegate know the conference is empty after updating the termination timestamp. Leave
    // the termination cause as undefined since we don't know what caused the conference to end.
    if (this._calls.length === 0) {
      this._terminatedTimestamp = new Date();
      this.delegate?.onConferenceFinished?.();
    }
  }

  // ========== Audio Management ==========

  public setMuted(muted: boolean) {
    muted ? this.muteAudioStreams() : this.unmuteAudioStreams();
  }

  /**
   * Starts (or restarts) the conference by remixing the audio streams from each call in the conference
   * and emitting the merged audio stream.
   *
   * Will emit to mergedAudioStreamSubject once the audio streams are merged.
   */
  public async start(calls: Call[]) {
    if (this.started) {
      console.error('Calling start on a conference that has already started.');
      return;
    }

    this.started = true;
    this._calls = calls;

    // Set the established timestamp here insteand of determining every time in the getter since the earliest established call
    // may be removed, but that shouldn't affect the established timestamp.
    this._establishedTimestamp = this.firstEstablishedCall()?.establishedTimestamp;

    await this.mergeCallAudioStreams();

    this._calls.forEach((call) => (call.conferenceId = this.id));
    this.delegate?.onCallsAdded?.(this._calls);
  }

  private async mergeCallAudioStreams() {
    this.clonedSenderTrackMap.forEach((track) => track.stop());
    this.clonedSenderTrackMap.clear();

    const receivedAudioTracks: MediaStreamTrack[] = [];

    const peerConnections = this.peerConnections;

    for (const { session, peerConnection } of peerConnections) {
      // Keep the original receiver tracks to use for unmute
      const receivers = peerConnection.getReceivers().map((r) => r.track);
      receivedAudioTracks.push(...receivers);

      // Keep the original sender tracks to use for restoration when removing calls.
      // Clone the sender track to use for mixing audio.
      const senders = peerConnection.getSenders().flatMap((s) => s.track ?? []);
      senders.forEach((track) => {
        this.originalSenderTrackMap.set(session.id, track);
        this.clonedSenderTrackMap.set(session.id, track.clone());
      });
    }

    // Mix the received tracks from each session
    const allReceivedMediaStreams = new MediaStream();
    for (const { peerConnection } of peerConnections) {
      const audioDestination = this.audioContext.createMediaStreamDestination();

      for (const receiver of peerConnection.getReceivers()) {
        for (const track of receivedAudioTracks) {
          if (receiver.track.id !== track.id) {
            //Add each receiver track
            allReceivedMediaStreams.addTrack(receiver.track);

            const audioSources = this.audioContext.createMediaStreamSource(new MediaStream([track]));
            audioSources.connect(audioDestination);
          }
        }

        //Get senders - agent audio
        const senderTracks = this.clonedSenderTrackMap.values();
        for (const track of senderTracks) {
          const audioSources = this.audioContext.createMediaStreamSource(new MediaStream([track]));
          audioSources.connect(audioDestination);
        }

        // Stop the original sender track and replace it with the new stream track otherwise the
        // replaced track will not release the microphone and the browser will indicate the mic is active
        // even after the call ends
        const currentSender = peerConnection.getSenders()[0];
        // currentSender.track?.stop();
        await currentSender.replaceTrack(audioDestination.stream.getTracks()[0]);
      }
    }

    this.mergedAudioStreamSubject.next(allReceivedMediaStreams);
  }

  private muteAudioStreams() {
    console.log('ConferenceCall --> muteAudioStreams() ');
    this.clonedSenderTrackMap.forEach((track) => (track.enabled = false));
  }

  private unmuteAudioStreams() {
    console.log('ConferenceCall --> getUnmutedAudioStreams() ');
    this.clonedSenderTrackMap.forEach((track) => (track.enabled = true));
  }

  // ========== Helpers ==========

  /**
   * Returns true if the conference is finished or will be finished soon. This is determined
   * by looking at the state of each Call in the conference.
   *
   * If there is at most one call in the conference with a state of SessionState.Established, we know that this
   * Conference will be finished soon if it hasn't already.
   */
  public isReadyToFinish(): boolean {
    return (
      this._calls.filter((c) =>
        [SessionState.Initial, SessionState.Establishing, SessionState.Established].includes(c.state)
      ).length <= 1
    );
  }

  /**
   * Returns the first call that was established in the conference.
   */
  public firstEstablishedCall(): Call | undefined {
    return this._calls
      .filter((call) => call.establishedTimestamp !== undefined)
      .sort((a, b) => a.establishedTimestamp!.getTime() - b.establishedTimestamp!.getTime())[0];
  }
}
