import { DateListItem } from '@app/shared/models/date-list-item';

export interface CallHistory extends DateListItem {
  toLabel: string;
  toNumber: string;
  missed: boolean;
  direction: CallDirection;
  origCallid: string;
  termCallid: string;
  fromLabel: string;
  fromNumber: string;
  recordingId: string;
  recordingType: RecordingType;
  disposition?: string;
  reason?: string;
  notes?: string;

  /** The ID of the call recording's transcription. If no transcription exists, this property will not be set. */
  transcriptionId?: string;

  /**
   * The ID of the call recording transcription's AI-generated summary.
   * If no summary exists, this property will not be set.
   *
   * Summaries are paywalled and it's possible a transcription exists without a summary.
   */
  summaryId?: string;
  /** The cancel reason is being set when someone else answer the call from ring group. For all other cases, this property will not be set. */
  cancelReason?: string;
  /**Device parameter is being set on CallCompletedElsewhere event to recognize for which device is message sent(web/mobile) */
  device?: string;
  /** Queue parameter is being set on MissedCallFromQueue event to recognize for which queue was called */
  queue?: string;
  /**  it contains the information who pick up the call when cancel reason is someone else answered*/
  termToUri?: string;
}

export enum CallDirection {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export enum CallHistoryType {
  outgoing,
  incoming,
  missed,
}

export enum CallMissedReason {
  MissedQueue = 'Missed Queue call',
  CompletedElsewhere = 'Call completed elsewhere',
}

export interface CallTranscription {
  id: string;
  callId: string;
  comments: CallTranscriptionComment[];
}

export interface CallTranscriptionComment {
  speaker: string;
  comment: string;
  created: string;
  startTime: number; // seconds offset from the start of the call
  endTime: number; // seconds offset from the start of the call
}

export interface CallServiceResponse {
  cdrs: CallHistory[];
  nextPage: string | null;
}

export class CallHistoryTypeFilter {
  type: CallHistoryType;
  name: string;
  icon: string;
  status: boolean;
}

export enum RecordingType {
  Audio = 'audio',
  Video = 'video',
}

export enum CallsTab {
  History = 'History',
  Recordings = 'Recordings',
}

export interface MissedCallFromQueueActivated {
  id: string;
  orig_callid: string;
  term_sub: string;
  termToUri?: string;
  cancelReason?: string;
}
