<h1>Integrations</h1>
<h4>Browser Integration</h4>
<mat-divider></mat-divider>
<p>
  Browser integrations allow the user to install ConnectUC as a Progressive Web App (PWA) and Register ConnectUC as the
  handler for 'tel' (telephone) links to provide one-click calling.
</p>
<div class="btn-group">
  <!--    (click)="save(currentPage)"-->
  <button
    color="primary"
    mat-raised-button
    class="m-l-12"
    type="button"
    [disabled]="pwaInstalled"
    (click)="installConnectUCPwa()"
    [matTooltip]="'Installs ConnectUC as a Progressive Web App and then Register it to open phone number links'">
    <mat-icon style="transform: rotate(180deg)"> publish</mat-icon>
    <span>Install ConnectUC</span>
  </button>
  <button
    color="primary"
    mat-stroked-button
    class="m-l-12"
    type="button"
    [disabled]="!pwaInstalled || protocolHandlerService.isRegistered('tel')"
    (click)="registerTelHandler()"
    [matTooltip]="
      'ConnectUC is installed, Register ConnectUC so it can make calls when you click on phone number links'
    ">
    <span>Register for One-Click Calling</span>
  </button>
</div>
<div
  class="web-pop-section"
  *ngIf="this.appConfigService.features[AppFeature.WebPopIntegration]">
  <h4>ConnectUC Link</h4>
  <mat-divider></mat-divider>
  <p>
    ConnectUC Link is a companion application to ConnectUC. It adds the ability to support headset device call control
    and will enable ConnectUC’s WebPop feature to work. For enhanced functionality in ConnectUC, install our standalone
    app linked below and experience a smarter, more informed communications workflow.
  </p>
  <div class="btn-group">
    <button
      class="connect-button"
      [disabled]="!environment.ucLinkMacURL"
      (click)="openInstallationUCLinkApp(environment.ucLinkMacURL)">
      <img
        src="assets/macIcon.png"
        alt="Mac" />Install for Mac
    </button>
    <button
      class="connect-button"
      [disabled]="!environment.ucLinkWindowsURL"
      (click)="openInstallationUCLinkApp(environment.ucLinkWindowsURL)">
      <img
        alt="Windows"
        src="assets/windowsIcon.png" />Install for Windows
    </button>
  </div>
</div>
