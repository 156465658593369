import { Injectable } from '@angular/core';
import { CallHistory } from '@app/call-history/models/call-history.models';
import Dexie, { liveQuery, Table } from 'dexie';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CallHistoryStorageService {
  private db = new CallHistoryDB();

  public async updateCallHistoryItem(id: string, fieldsToUpdate: Partial<CallHistory>): Promise<void> {
    await this.db.callHistory.update(id, { ...fieldsToUpdate });
  }

  public async updateCallHistoryItemByOrigCallId(
    origCallid: string,
    fieldsToUpdate: Partial<CallHistory>
  ): Promise<void> {
    // origCallid is unique (but not the primary index) so query for it, but only care about the first result
    const results = await this.db.callHistory.where('origCallid').equals(origCallid).toArray();
    if (results.length > 0) {
      await this.db.callHistory.update(results[0].id, { ...fieldsToUpdate });
    }
  }

  public async upsertCallHistory(items: CallHistory[]): Promise<{
    lastContiguousTimestamp: string | null;
  }> {
    if (items.length === 0) {
      const lastItem = await this.getLastItem();
      return { lastContiguousTimestamp: lastItem?.dateTime ?? null };
    }

    // Extract the keys from the items
    const keys = items.map((item) => item.id);

    // Fetch existing items by keys
    const existingItems = await this.db.callHistory.where('id').anyOf(keys).toArray();

    // Perform bulk put operation for new items
    await this.db.callHistory.bulkPut(items);

    // If there are existing items, we can assume this insert is contiguous with the existing data and the last contiguous
    // timestamp is the last item's timestamp. Otherwise, the last contiguous timestamp is the last item's timestamp for the given insert.
    if (existingItems.length > 0) {
      const lastItem = (await this.getLastItem()) ?? items[items.length - 1];
      return { lastContiguousTimestamp: lastItem.dateTime };
    } else {
      return { lastContiguousTimestamp: items[items.length - 1].dateTime };
    }
  }

  public liveQuery(filter?: (CallHistory) => boolean): Observable<CallHistory[]> {
    const dexieObservable = liveQuery(() => {
      let collection = this.db.callHistory.orderBy('dateTime').reverse();

      if (filter) {
        collection = collection.filter((item) => filter(item));
      }

      return collection.toArray();
    });

    return from(dexieObservable);
  }

  public async getLastItem(): Promise<CallHistory | undefined> {
    return await this.db.callHistory.orderBy('dateTime').first();
  }

  public drop() {
    this.db.resetDatabase();
  }
}

class CallHistoryDB extends Dexie {
  public callHistory!: Table<CallHistory, string>;

  constructor() {
    super('skySwitchCallHistory');
    this.version(2)
      .stores({
        // v2 is the same as v1, but with the id field designated as the primary key
        callHistory:
          '&id,contactId,dateTime,direction,disposition,duration,fromLabel,fromNumber,missed,notes,origCallid,reason,recordingId,recordingType,termCallid,toLabel,toNumber,voicemailId',
      })
      .upgrade(() => {
        // v2: No need for data migration since we are just designating an existing field (id) as the primary key
        return Promise.resolve();
      });
  }

  public async resetDatabase() {
    await this.transaction('rw', 'callHistory', () => {
      this.callHistory.clear();
    });
  }
}
