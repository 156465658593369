<section>
  <div class="container">
    <h1>Contacts</h1>
    <mat-divider class="m-t-16"></mat-divider>
    <div class="call-options flex">
      <div class="slider-item">
        <mat-slide-toggle
          class="slider"
          [disabled]="contactMatchingLoading"
          [formControl]="contactMatching"
          (change)="onChangeContactMatching()">
        </mat-slide-toggle>
        <div class="text">
          <span>Enable Contact Matching</span>
        </div>
      </div>
      <span class="help-text">
        When disabled, the contact lookups for incoming calls will not be performed. The incoming Caller Name will be displayed instead.
      </span>
    </div>
    <div class="import-export-header">Export Contacts</div>
    <mat-form-field
      appearance="outline"
      class="contact-type-form-field">
      <mat-label>Contacts to export</mat-label>
      <div class="flex">
        <mat-select [(value)]="selectedContactOption">
          <mat-option
            *ngIf="appConfigService.hasOfficeManagerRole$ | async"
            [value]="ContactType.Shared"
          >{{ ContactType.Shared | contactTypeLabel }}
          </mat-option
          >
          <mat-option [value]="ContactType.Personal">{{ ContactType.Personal | contactTypeLabel }}</mat-option>
        </mat-select>
      </div>
    </mat-form-field>
    <div class="export-btn">
      <button
        color="primary"
        mat-raised-button
        type="button"
        (click)="exportContacts()">
        <span>Export</span>
      </button>
    </div>
    <div class="import-export-header">Import Contacts</div>
    <p class="import-text">
      The proper format for the CSV file can be obtained by exporting the contacts above. There is a 2MB limit for the
      import file.
    </p>
    <button
      class="action-button"
      (click)="handleClickOnImportContact()">
      <span class="action">Select a File</span>
      <mat-icon color="primary">upload</mat-icon>
    </button>
  </div>
</section>
