import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, forwardRef, Input, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface LayoutItem {
  /** Ordered index of the layout data item */
  index: number;

  /** The height of the element */
  height: number;

  /**
   * The scroll offset of the item. We store this value instead of deriving it since it may
   * need to be accessed while scrolling and we want O(1) instead of O(n) access.
   */
  scrollOffset: number;
}

/**
 * Virtual scrolling strategy for lists with items of known variable size.
 *
 * Inspired by Angular's FixedSize variant here: https://github.com/angular/components/blob/main/src/cdk/scrolling/fixed-size-virtual-scroll.ts
 */
export class VariableSizeVirtualScrollStrategy implements VirtualScrollStrategy {
  private readonly _scrolledIndexChange = new Subject<number>();

  /** @docs-private Implemented as part of VirtualScrollStrategy. */
  scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());

  /** The attached viewport. */
  private viewport: CdkVirtualScrollViewport | null = null;

  /** Array of objects with a height property. */
  private itemHeights: Array<{ height: number }> = [];

  /** The amount of buffer rendered beyond the viewport (in pixels). */
  private bufferPx: number;

  private layoutItems: LayoutItem[] = [];

  /**
   * @param itemHeights The height values of each item in the virtually scrolling list.
   * @param bufferPx The amount of buffer (in pixels) to render when rendering more.
   */
  constructor(itemHeights: Array<{ height: number }>, bufferPx = 200) {
    this.itemHeights = itemHeights;
    this.bufferPx = bufferPx;
  }

  /**
   * Attaches this scroll strategy to a viewport.
   * @param viewport The viewport to attach this strategy to.
   */
  attach(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  /** Detaches this scroll strategy from the currently attached viewport. */
  detach() {
    this._scrolledIndexChange.complete();
    this.viewport = null;
  }

  /**
   * Update the item size and buffer size.
   * @param itemSize The size of the items in the virtually scrolling list.
   * @param bufferPx The amount of buffer (in pixels) to render when rendering more.
   */
  updateItemAndBufferSize(itemHeights: Array<{ height: number }>, bufferPx: number) {
    if (bufferPx < 0) {
      throw new Error('CDK virtual scroll: maxBufferPx must be greater than or equal to zero');
    }
    this.itemHeights = itemHeights;
    this.bufferPx = bufferPx;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  /** @docs-private Implemented as part of VirtualScrollStrategy. */
  onContentScrolled() {
    this.updateRenderedRange();
  }

  /** @docs-private Implemented as part of VirtualScrollStrategy. */
  onDataLengthChanged() {
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }

  /** @docs-private Implemented as part of VirtualScrollStrategy. */
  onContentRendered() {
    /* no-op */
  }

  /** @docs-private Implemented as part of VirtualScrollStrategy. */
  onRenderedOffsetChanged() {
    /* no-op */
  }

  /**
   * Scroll to the offset for the given index.
   * @param index The index of the element to scroll to.
   * @param behavior The ScrollBehavior to use when scrolling.
   */
  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (this.viewport) {
      const layoutData = this.layoutItems[index];
      this.viewport.scrollToOffset(layoutData.scrollOffset, behavior);
    }
  }

  /** Update the viewport's total content size. */
  private updateTotalContentSize() {
    if (!this.viewport) {
      return;
    }

    this.layoutItems = [];

    // Iterate over our entire data set querying for the size of every item
    let scrollOffset = 0;
    for (let i = 0; i < this.itemHeights.length; i++) {
      const height = this.itemHeights[i].height;
      this.layoutItems.push({
        index: i,
        height,
        scrollOffset,
      });
      scrollOffset += height;
    }

    this.viewport.setTotalContentSize(scrollOffset);
  }

  /** Update the viewport's rendered range. */
  private updateRenderedRange() {
    if (!this.viewport) {
      return;
    }

    const renderedRange = this.viewport.getRenderedRange();
    const newRange = { start: renderedRange.start, end: renderedRange.end };
    const viewportSize = this.viewport.getViewportSize();
    const scrollOffset = this.viewport.measureScrollOffset();

    // Adjust start and end range values need to be adjusted based on the buffer size.
    const startVisibleItem = this.findLayoutItemIntersectingScrollOffset(scrollOffset - this.bufferPx);
    const endVisibleItem = this.findLayoutItemIntersectingScrollOffset(scrollOffset + viewportSize + this.bufferPx);
    newRange.start = startVisibleItem.index;
    newRange.end = endVisibleItem.index + 1;

    this.viewport.setRenderedRange(newRange);
    this.viewport.setRenderedContentOffset(startVisibleItem.scrollOffset);
    this._scrolledIndexChange.next(startVisibleItem.index);
  }

  private findLayoutItemIntersectingScrollOffset(scrollOffset: number): LayoutItem {
    if (this.layoutItems.length === 0) {
      return { height: 0, scrollOffset: 0, index: 0 };
    }

    // We can assume our layout items are sequential (no gaps between them). If a layout item isn't
    // found, return the item either at the upper or lower bound.
    let intersectingItem = this.layoutItems.find((item) => {
      return scrollOffset >= item.scrollOffset && scrollOffset < item.scrollOffset + item.height;
    });

    if (!intersectingItem) {
      intersectingItem =
        scrollOffset < this.layoutItems[0].scrollOffset ? this.layoutItems[0] : this.layoutItems.slice(-1)[0];
    }

    return intersectingItem;
  }
}

function factory(dir: VariableSizeVirtualScrollStrategyDirective) {
  return dir.scrollStrategy;
}

/** A virtual scroll strategy that supports items of variable (but known) height. */
@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'cdk-virtual-scroll-viewport[variableSizeStrategy]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: factory,
      deps: [forwardRef(() => VariableSizeVirtualScrollStrategyDirective)],
    },
  ],
})
export class VariableSizeVirtualScrollStrategyDirective implements OnChanges {
  /** The data source which provides height information for our layout. */
  @Input() itemHeights: Array<{ height: number }> = [];

  /**
   * The number of pixels worth of buffer to render for when rendering new items. Defaults to 100px.
   */
  @Input()
  get bufferPx(): number {
    return this._bufferPx;
  }

  set bufferPx(value: NumberInput) {
    this._bufferPx = coerceNumberProperty(value);
  }

  private _bufferPx = 100;

  /** The scroll strategy used by this directive. */
  scrollStrategy = new VariableSizeVirtualScrollStrategy(this.itemHeights, this.bufferPx);

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges() {
    this.scrollStrategy.updateItemAndBufferSize(this.itemHeights, this.bufferPx);
    this.cd.detectChanges();
  }
}
