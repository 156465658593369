import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms the time elapsed between two dates into a
 * human-readable string. If no toDate is provided, the
 * current date is used.
 */
@Pipe({
  name: 'timeElapsed',
  standalone: true,
})
export class TimeElapsedPipe implements PipeTransform {
  transform(fromDate: Date | string | number, toDate = new Date()): string {
    if (!fromDate) {
      return '';
    }

    const creationDate = new Date(fromDate);
    const elapsed = toDate.getTime() - creationDate.getTime();

    // Calculate elapsed hours, minutes, and seconds
    const hours = Math.floor(elapsed / (1000 * 60 * 60));
    const minutes = Math.floor((elapsed / (1000 * 60)) % 60);
    const seconds = Math.floor((elapsed / 1000) % 60);

    // Format mm:ss for less than an hour, otherwise hh:mm:ss
    return hours < 1
      ? this.pad(minutes) + ':' + this.pad(seconds)
      : this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
  }

  // Helper function to format numbers to 2 digits
  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
