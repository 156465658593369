import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar) {}

  open(message: string, action?: string, config?: MatSnackBarConfig) {
    return this.matSnackBar.open(message, action, config);
  }
}
