<ng-template [ngIf]="!edit && !image && !isChangingPassword">
  <div class="flex space-between" *ngIf="profile">
    <div class="details">
      <div class="account-info">
        <h1>Profile</h1>
        <h4>
          My Information
          <i
            data-cy="profile-edit"
            (click)="edit = !edit"
            class="link edit">Edit</i>
        </h4>
        <div class="info">
          <span class="label">Name</span>
          <p class="profile-name">{{ profile.fullName }}</p>
        </div>
        <div
          *ngIf="profile.company"
          class="info">
          <span class="label">Company</span>
          <p class="company-name">{{ profile.company }}</p>
        </div>
        <div class="info" *ngIf="profile.title">
          <span class="label">Title</span>
          <p class="title-name">{{ profile.title }}</p>
        </div>
        <div class="info">
          <span class="label">Email</span>
          <div *ngFor="let email of profile.emails" class="info-grid" data-cy="profile-emails">
            <span>
              {{ email.value }}
            </span>
            <span>{{ email.type | titlecase }}</span>
          </div>
        </div>
        <div class="info">
          <span class="label">Extension</span>
          <div class="info-grid">
            <span>
              {{ profile.ext }}
            </span>
          </div>
        </div>
        <div class="info">
          <span class="label">Phone Number</span>
          <div *ngFor="let DID of DIDNumbers" class="info-grid" data-cy="profile-phone-number">
            <span>
              {{ DID | phoneNumber }}
            </span>
            <span>DID</span>
          </div>

          <div *ngFor="let phone of profile.tels" class="info-grid" data-cy="profile-phone-number">
            <span>
              {{ phone.number | phoneNumber }}
            </span>
            <span
              *ngIf="phone.type===ExtensionTypes.SMS || phone.type===ExtensionTypes.PbxSms; else noneSMS">{{ phone.type | uppercase }}</span>
            <ng-template #noneSMS>
              <span>{{ phone.type | titlecase }}</span>
            </ng-template>
          </div>
        </div>
        <div class="info">
          <span class="label">Address</span>
          <div class="info-grid" *ngFor="let address of profile.addresses" data-cy="profile-address">
            <span>
              {{ address.addressLine1 }} {{ address.city }} {{ address.state }} {{ address.postalCode }}
            </span>
            <span>{{ address.type | titlecase }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="avatar">
      <ng-template [ngIf]="(!profile.avatar)" [ngIfElse]="showAvatarBlock">
        <div class="bigCoin">
          <span data-cy="profile-icon-text">{{ getCoin() }}</span>
        </div>
        <h2 class="full-name">{{ profile.fullName }}</h2>
        <span
          data-cy="profile-choose-photo"
          class="link"
          (click)="file.click()">Choose Profile Photo</span>
      </ng-template>
      <ng-template #showAvatarBlock>
        <mat-spinner class="avatar-spinner" *ngIf="avatarLoading" />
        <div class="bigCoin" *ngIf="!avatarLoading">
          <img
            alt="avatar"
            [src]="profile.avatar" />
          <div
            (click)="file.click()"
            class="overlay">
            <mat-icon>add_a_photo</mat-icon>
          </div>
        </div>
        <button
          data-cy="profile-choose-photo"
          (click)="file.click()"
          color="primary"
          mat-raised-button>
          Choose Photo
        </button>
        <button
          data-cy="profile-remove-photo"
          (click)="removeAvatar()"
          *ngIf="!profile?.avatar?.startsWith(defaultAvatarsBaseUrl)"
          mat-button>
          Remove Photo
        </button>
      </ng-template>
      <div class="img-format">
        <div class="file-types">
          File types: jpg,png,jpeg,gif,svg
        </div>
        <div class="file-types">
          Size: up to 1024 KB
        </div>
      </div>
    </div>
    <input
      data-cy="profile-input-file"
      #file
      appAvatar
      (changeEvent)="fileChangeEvent($event)" />
  </div>
  <div class="account-info account-time-format">
    <h4>Time Format</h4>
    <div class="info">
      <div class="flex align-items-center space-between m-b-8">
        <div class="label">Hour Format</div>
        <div class="label timezone">
          Timezone (Computer Default)
        </div>
      </div>
      <div class="flex space-between hour-format">
        <mat-radio-group color="primary" [(ngModel)]="selectedHourFormat" (change)="onHourFormatChange()">
          <mat-radio-button value="12">12 hour</mat-radio-button>
          <mat-radio-button value="24">24 hour</mat-radio-button>
        </mat-radio-group>
        <div class="fs-12 timezone m-t-8">{{ localTimezone }}</div>
      </div>
    </div>
  </div>
  <div class="account-settings account-info">
    <h4>Account Settings</h4>
    <span class="label">Sign-in Password
        <i
          data-cy="profile-change-password"
          (click)="isChangingPassword = true"
          class="edit">Change</i></span>
  </div>
</ng-template>

<ng-template [ngIf]="edit">
  <app-contact
    (cancelAction)="onEditCanceled()"
    (saveAction)="onDataSubmitted($event)"
    [loading]="editProfileLoading"
    [contact]="profile"
    [showSharedContact]="false"
    [title]="'Edit Profile'"></app-contact>
</ng-template>

<ng-template [ngIf]="image">
  <app-transform-image [title]="'Set Profile Photo'" [imageChangedEvent]="imageChangedEvent"
                       (cancelEvent)="image = false"
                       (saveEvent)="save($event)" />
</ng-template>

<app-change-password
  (passwordChange)="onPasswordChange($event)"
  (cancel)="onPasswordChangeCanceled()"
  *ngIf="isChangingPassword"></app-change-password>
