<div class="header">
  <h1 class="thumbnail-title">
    <mat-icon (click)="onCanceled()">chevron_left</mat-icon>
    {{ title }}
  </h1>
</div>
<div class="image-cropper">
  <image-cropper
    (imageCropped)="imageCropped($event)"
    [cropperMinHeight]="150"
    [cropperMinWidth]="150"
    [imageChangedEvent]="imageChangedEvent"
    [canvasRotation]="rotation"
    [maintainAspectRatio]="true"
    [roundCropper]="true"
    [resizeToWidth]="640"
    [resizeToHeight]="640"
    [onlyScaleDown]="true"
    format="png"></image-cropper>
  <div
    class="icon-background"
    (click)="changeRotation()">
    <mat-icon>rotate_90_degrees_ccw</mat-icon>
  </div>
</div>

<div class="flex justify-content-end align-center bottom-bar static-bottom">
  <button
    class="cancel-button"
    (click)="onCanceled()"
    mat-button
    type="button">
    Cancel
  </button>
  <button
    data-cy="save-upload-image"
    color="primary"
    mat-raised-button
    class="m-l-12 save-button"
    (click)="onSaved()"
    type="button">
    Save
  </button>
</div>
