import { Injectable } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { Config } from '@app/core/models/config.models';
import { ConnectionStatus } from '@app/core/models/connection-status';
import { UserConnectedEvent } from '@app/core/models/link.model';
import { AppConfigService } from '@app/core/services/app-config.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { WsService } from '@app/core/services/ws.service';
import { CallParkService } from '@app/parking/services/call-park.service';
import { Preferences } from '@app/preferences/models/preferences.model';
import { SettingsService } from '@app/preferences/services/settings.service';
import { detectDevice } from '@app/shared/utils/detect-device';
import { formatInternationalPhoneNumber } from '@app/shared/utils/phone.util';
import { environment } from '@environment/environment';
import { BehaviorSubject, map, Subject, takeWhile, timer } from 'rxjs';
import {
  Invitation,
  InvitationRejectOptions,
  Inviter,
  Message,
  RegistererOptions,
  Session,
  SessionState,
  URI,
  UserAgent,
  UserAgentOptions,
  UserAgentState,
} from 'sip.js';
import { SessionInviteOptions } from 'sip.js/lib/api/session-invite-options';
import { LogLevel } from 'sip.js/lib/api/user-agent-options';
import { IncomingResponse } from 'sip.js/lib/core';
import { IncomingRequestMessage } from 'sip.js/lib/core/messages/incoming-request-message';
import {
  ManagedSessionFactory,
  SessionDescriptionHandler,
  SessionDescriptionHandlerOptions,
  SessionManager,
  SessionManagerOptions,
} from 'sip.js/lib/platform/web';

import { Call, CallId } from '../models/call.model';
import { CallTerminationCause, SessionStatus } from '../models/phone.models';
import { LoggerService } from './logger.service';

export const TerminatedCallPersistenceDuration = 5000;

@Injectable({
  providedIn: 'root',
})
export class SipjsService {
  static readonly maxSimulataneousSessions = 50;

  /**
   * Updated any time a session is added or removed by sip.js. Sessions are removed from this list
   * after `TerminatedCallPersistenceDuration` milliseconds have elapsed. Holding onto these calls for the extended
   * duration is useful for cleanup.
   */
  private callsSubject = new BehaviorSubject<Call[]>([]);
  private sessionStatusSubject = new Subject<{ call: Call; status: SessionStatus }>();
  private connectionStatusSubject = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Connecting);
  private rtcCallQualitySubject = new BehaviorSubject<string>('Green');

  public readonly newSessionConnected = new BehaviorSubject<boolean>(false);

  /**
   * List of Call objects currently being managed by sip.js _as well as_ calls that have been terminated for a certain period of time.
   * When a Session is terminated, sip.js removes it from its internal list immediately. This service persists said Calls (i.e.
   * the wrapper around the session) for a short period of time since it has proven useful in the application to have a reference to
   * them for UI purposes (i.e. displaying a terminated state).
   *
   * If additional functionality is needed beyond the default persistence duration, that functionality should be added by the
   * consumer of this subject.
   */
  public readonly calls$ = this.callsSubject.asObservable();
  public readonly sessionStatus$ = this.sessionStatusSubject.asObservable();
  public readonly connectionStatus$ = this.connectionStatusSubject.asObservable();
  public readonly rtcCallQuality$ = this.rtcCallQualitySubject.asObservable();
  private rtcSnapshot = '';

  private host: string = '';
  private preferences?: Preferences;
  private sessionManager?: SessionManager;

  get userAgent(): UserAgent | undefined {
    return this.sessionManager?.userAgent;
  }

  get calls(): Call[] {
    return this.callsSubject.value;
  }

  get connectionStatus(): ConnectionStatus {
    return this.connectionStatusSubject.value;
  }

  private _maxSimultaneousSessions = SipjsService.maxSimulataneousSessions;
  get maxSimultaneousSessions(): number {
    // Adjust the value before returning since it is stored as one less than the actual value.
    return this._maxSimultaneousSessions <= 1 ? this._maxSimultaneousSessions : this._maxSimultaneousSessions + 1;
  }

  /**
   * Sets the maximum number of simultaneous sessions that can be active at any given time.
   * @param maxSimultaneousSessions The maximum number of simultaneous sessions that can be active at any given time. This value will be clamped to
   * the range [0, SipjsService.maximumSimulataneousSessions].
   */
  set maxSimultaneousSessions(value: number) {
    /**
     * Technically this property is private in sip.js, but it's the most concise and correct way to set the value while ensuring
     * the library will properly limit calls.
     *
     * The library uses this value to limit outgoing calls here: https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/platform/web/session-manager/session-manager.ts#L528
     * and incoming calls here: https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/platform/web/session-manager/session-manager.ts#L202
     *
     * Notice from the library implementation that the max value is compared using the greater than operator. NOT the greater than or equal to operator.
     * This is odd and not what we intuitively expect. This means that the library will allow for one MORE than the maximum number of calls to be active.
     * Reduce the provided value by one before setting it to account for this (unless the value is already 0, in which case we don't want to reduce it further).
     * This also means at minimum we allow for two calls to be active since the library will allow for one more than the maximum and zero means there is no limit.
     */
    const clampedValue = Math.min(Math.max(value, 0), SipjsService.maxSimulataneousSessions);
    const adjustedValue = clampedValue > 1 ? value - 1 : clampedValue;
    this._maxSimultaneousSessions = adjustedValue;
    if (this.sessionManager) {
      (this.sessionManager['options'] as Required<SessionManagerOptions>).maxSimultaneousSessions = adjustedValue;
    }
  }

  constructor(
    private settingsService: SettingsService,
    private appConfig: AppConfigService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private wsService: WsService,
    private loggerService: LoggerService,
    private callParkService: CallParkService,
    private contactService: ContactService
  ) {
    this.appConfig.data$.subscribe((config: Config) => {
      this.preferences = config?.settings?.preferences;
    });

    // setup connectivity change handler
    this.checkUserConnectionStateToServer();

    this.settingsService.audioSettings$.subscribe(async () => {
      // Refresh the audio device for any call that's in the answered state
      this.getCallsWithStatus(SessionStatus.Answered).forEach(async (call) => {
        await this.refreshAudioDevice(call.id);
      });
    });
  }

  private checkUserConnectionStateToServer() {
    this.wsService.socket.on('UserConnected', (data: UserConnectedEvent) => {
      const deviceType = detectDevice(data.userAgent);
      if (deviceType === this.appConfig.deviceType) {
        this.newSessionConnected.next(true);
        this.wsService.disconnect();
        this.sessionManager?.disconnect();
      }
    });
  }

  async dispose() {
    if (this.userAgent?.state === UserAgentState.Started) {
      await this.sessionManager?.unregister();
      await this.sessionManager?.disconnect();
    }
  }

  //create authenticated user agent
  public async createUserAgent(extension: string, registeredDomain: string, password: string, host: string) {
    this.host = registeredDomain;
    // Create user agent instance (caller)
    if (this.userAgent) {
      console.log('SipjsService: User agent already created');
      return;
    }
    const uri = new URI('sip', extension, registeredDomain);
    if (!uri) {
      throw new Error('Failed to create URI');
    }

    const userAgentOptions: UserAgentOptions = {
      uri: uri as URI,
      contactName: extension,
      viaHost: registeredDomain,
      authorizationUsername: extension,
      authorizationPassword: password,
      transportOptions: {
        server: `wss://${host}`,
        rtcConfiguration: {
          iceTransportPolicy: 'all',
        },
      },
      // add contact instance id to make it unique for multi-device support
      contactParams: { rinstance: crypto.getRandomValues(new Uint32Array(1))[0].toString(16) },
      sessionDescriptionHandlerFactoryOptions: {
        iceGatheringTimeout: 500, // Smallest allowed value
        iceCandidatePoolSize: 0, // Disable ICE gathering
        peerConnectionConfiguration: {
          iceServers: [], // Resetting all values
        },
        peerIdentity: undefined,
        rtcpMuxPolicy: 'require',
        stunServers: [],
      },
      logConnector: (level: LogLevel, category: string, label: string | undefined, content: string) => {
        this.logConnector(level, category, label, content);
      },
      userAgentString: 'ConnectUC Web',
    };
    // Create UserAgent
    const registererOptions: RegistererOptions = {
      registrar: new URI('sip', '', registeredDomain),
      expires: 500,
      refreshFrequency: 90,
    };
    this.sessionManager = this.createSessionManager(userAgentOptions, registererOptions);

    // Start the user agent
    await this.connectUserAgent();
  }

  private createSessionManager(userAgentOptions: UserAgentOptions, registererOptions: RegistererOptions) {
    const { websocketUrlSip } = environment;

    return new SessionManager(websocketUrlSip, {
      userAgentOptions,
      registererOptions,
      reconnectionAttempts: 10_000,
      // Maximum number of simultaneous sessions we allow.
      maxSimultaneousSessions: this._maxSimultaneousSessions,
      // Number of seconds to wait between reconnection attempts
      reconnectionDelay: 10,
      registrationRetry: true,
      // Number of seconds to wait between re-register attempts
      registrationRetryInterval: 10,
      managedSessionFactory: this.createManagedSessionToCallFactory(),
      delegate: {
        onCallCreated: (invitation: Session) => {
          // Once this callback is called, the session has been added to the manager's list of
          // managed sessions. See [initSession](https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/platform/web/session-manager/session-manager.ts#L1080)
          console.log(`call: onCallCreated: ${invitation.id}`);

          // Add a listener for session state changes so we can track a timestamp of when
          // the session was established. This will allow interested entites to calculate
          // call durations. This function is defined in a separate variable so it can be
          // self-referenced when removing the listener.
          const handleStateChange = (newState: SessionState) => {
            const call = this.getCallById(invitation.id as CallId);

            switch (newState) {
              case SessionState.Established: {
                if (call) {
                  call.establishedTimestamp = new Date();
                }
                break;
              }
              case SessionState.Terminated: {
                invitation.stateChange.removeListener(handleStateChange);
                break;
              }
            }
          };
          invitation.stateChange.addListener(handleStateChange);

          // We should always have an instance of the call here since it was just created.
          const call = (this.sessionManager!.managedSessions as Call[]).find((c) => c.session.id === invitation.id)!;

          // Update the calls subject first here so it is stored in the subject.
          this.callsSubject.next((this.sessionManager?.managedSessions as Call[]) ?? []);

          // Issue a status update. We do this after the calls subject update so if any subsequent call to `getCallById` is made,
          // from a status update, the call will exist in the calls subject.
          this.updateCallStatus(call, SessionStatus.Created);
        },
        onCallAnswered: (session: Session) => {
          console.log(`call: onCallAnswered: ${session.id}`);
          const call = this.getCallById(session.id as CallId);
          if (call) {
            this.updateCallStatus(call, SessionStatus.Answered);
          }
        },
        onCallReceived: async (receivedSession: Session) => {
          console.log(`call: onCallReceived: ${receivedSession.id}`);
          console.log('SIPJS onInvite --> invitation:', receivedSession, this.preferences?.disableCalls ?? false);

          const didCallSelf = this.sessionRemoteIdentityMatchesCurrentUser(receivedSession);
          if (didCallSelf) {
            return;
          }

          // Handle outgoing session state changes
          this.setupStateChangeListener(receivedSession as Invitation);

          // Immediately reject calls if either the user has disabled calls or DND is enabled.
          // or if the user has enabled auto-answering rules for returning busy when on the call.
          if (
            (this.preferences && this.preferences.disableCalls) ||
            this.appConfig.isDNDEnabled() ||
            (this.appConfig.autoAnsweringRulesSettings?.returnBusy && this.calls.length > 1)
          ) {
            console.log('SIPJS onInvite --> rejecting disableCalls');
            await this.rejectCall(receivedSession.id as CallId);
            return;
          }

          // Immediately answer the call if the call header indicates it should be automatically answered
          // https://skyswitch.atlassian.net/browse/CUC-2395
          const invitation = receivedSession as Invitation;
          if (invitation.request.data.includes('<>;answer-after=0')) {
            console.log('SIPJS onInvite --> automatically answering call with answer-after=0');
            await this.acceptCall(invitation.id as CallId);
            return;
          }

          console.log('NEW INVITE:', receivedSession);
          const call = this.getCallById(receivedSession.id as CallId);
          if (call) {
            this.updateCallStatus(call, SessionStatus.Received);
          }
        },
        onCallHangup: (session: Session) => {
          console.log(`call: onCallHangup: ${session.id}`);
          // At this point SessionManager has removed the session from `managedSessions`. Update the
          // subject here. See [SessionManager.initSession](https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/platform/web/session-manager/session-manager.ts#L1108)
          // for more info.
          const call = this.getCallById(session.id as CallId);
          if (call) {
            call.terminatedTimestamp = new Date();
            this.updateCallStatus(call, SessionStatus.Hangup);
          }

          // Remove the call from the subject after a delay. We do this here because most of the time the UI wants to have the terminated
          // call for UI cleanup at least for a few seconds after the call is terminated.
          setTimeout(() => {
            const calls = this.calls.filter((c) => c.session.id !== session.id);
            if (call) {
              this.updateCallStatus(call, SessionStatus.Destroy);
            }

            this.callsSubject.next(calls);
          }, TerminatedCallPersistenceDuration);
        },
        onCallHold: (session: Session, held: boolean) => {
          console.log(`call: onCallHold ${held} - ${session.id}`);
          const call = this.getCallById(session.id as CallId);
          if (call) {
            this.updateCallStatus(call, held ? SessionStatus.Hold : SessionStatus.Answered);
          }
        },
        onMessageReceived: (message: Message) => {
          console.log(`call: onMessageReceived: ${message.request.method} ${message.request.callId}`);
        },
        onRegistered: () => {
          console.log('key register');
          this.connectionStatusSubject.next(ConnectionStatus.Connected);
        },
        onUnregistered: async () => {
          this.connectionStatusSubject.next(ConnectionStatus.Disconnected);
          console.log('key unRegister');
        },
        onServerConnect: () => {
          console.log('key Connected');
          console.log('*** UserAgent Connected');
          this.connectionStatusSubject.next(ConnectionStatus.Connected);
          this.sessionManager?.register();
        },
        onServerDisconnect: async () => {
          this.connectionStatusSubject.next(ConnectionStatus.Disconnected);
          console.log('key Disconnect');
        },
      },
    });
  }

  private createManagedSessionToCallFactory(): ManagedSessionFactory {
    return (sessionManager: SessionManager, session: Session): Call => {
      let contact: Contact | undefined;
      if (!this.appConfig.isContactMatchingDisabled()) {
        // Perform contact lookup when session is created and attach to object.
        // Do this here since contacts should have already been loaded and there's no need to perform
        // the lookup everywhere.
        // Attempt to lookup using the remote identity's user part. If it's not found, it's possible
        // the value isn't present.
        contact = this.contactService.contactByTel(session.remoteIdentity.uri.user ?? '');
      }

      console.log(
        `call: creating new Call from session: ${session.remoteIdentity.displayName}, ${session.remoteIdentity.uri.user}, ${contact?.id}`
      );
      return new Call(session, contact);
    };
  }

  public getCallsWithStatus(status: SessionStatus): Call[] {
    return this.calls.filter((call: Call) => call.status === status);
  }

  public getNonEstablishedCalls(): Call[] {
    return this.calls.filter((call: Call) => [SessionState.Initial, SessionState.Establishing].includes(call.state));
  }

  public getNonTerminatedCalls(): Call[] {
    return this.calls.filter(
      (call: Call) => call.state !== SessionState.Terminated && call.state !== SessionState.Terminating
    );
  }

  public getRemoteMediaStream(callId: CallId): MediaStream | undefined {
    const call = this.getCallById(callId);
    return call ? this.sessionManager?.getRemoteMediaStream(call.session) : undefined;
  }

  public getLocalMediaStream(callId: CallId): MediaStream | undefined {
    const call = this.getCallById(callId);
    return call ? this.sessionManager?.getLocalMediaStream(call.session) : undefined;
  }

  public getCallById(callId: CallId): Call | undefined {
    return this.calls.find((call) => call.id === callId);
  }

  /**
   * Addresses issue where a user calls their own extension and picks up on mobile.
   * https://skyswitch.atlassian.net/browse/CUC-1737.
   * In an earlier version the solution looked for an existing call with the
   * same remote identity. That doesn't work because we support calling CUC web from the same
   * remote number multiple times (https://skyswitch.atlassian.net/browse/CUC-2413)
   * This solution targets _only_ the user's extension since that's the only case that we actually care about.
   */
  private sessionRemoteIdentityMatchesCurrentUser(session: Session): boolean {
    return session.remoteIdentity.uri.user === this.contactService.currentUser?.ext;
  }

  logConnector(level: LogLevel, category: string, label: string | undefined, content: string) {
    if (this.calls.length > 0) {
      this.loggerService.pushSipLog(this.calls[0].session.id, level, category, label, content);
    } else if (this.loggerService.sipLogs.length > 0) {
      this.loggerService.saveCallReport(this.rtcSnapshot, new Date());
    }
  }

  async connectUserAgent() {
    this.connectionStatusSubject.next(ConnectionStatus.Connecting);
    this.newSessionConnected.next(false);
    try {
      await this.sessionManager?.connect();
    } catch (error: unknown) {
      this.connectionStatusSubject.next(ConnectionStatus.Disconnected);
      throw error;
    }
  }

  private handleConnectionStateChange(
    event: Event,
    state: RTCPeerConnectionState,
    sessionDescriptionHandler: SessionDescriptionHandler
  ) {
    console.log('key On Connection State Change:', event);
    console.log('*** peerConnection.connectionState: ' + state);

    switch (state) {
      case 'connected': {
        console.log('*** Media CONNECTED', event);
        break;
      }
      case 'disconnected':
      case 'failed': {
        console.log(`*** Media ${state.toUpperCase()}`, event);
        console.log('*** Restarting media negotiation');
        if (sessionDescriptionHandler.peerConnection) {
          sessionDescriptionHandler.peerConnection.restartIce();
        }
        break;
      }
    }
  }

  private async handleNegotiationNeeded(invitation: Invitation | Inviter) {
    if (this.connectionStatusSubject.value !== ConnectionStatus.Connected) {
      console.log('*** Negotiation delayed due to browser offline');
      return;
    }
    console.log('*** Performing re-invite for media negotiation');
    invitation.sessionDescriptionHandlerOptionsReInvite = {
      iceGatheringTimeout: 500,
      offerOptions: {
        iceRestart: true,
      },
    } as SessionDescriptionHandlerOptions;
    await invitation.invite();
  }

  private handleEstablishedState(invitation: Invitation | Inviter) {
    console.log('call: SIPJS onInvite --> inviter state change - Established:', invitation);

    const sessionDescriptionHandler = invitation.sessionDescriptionHandler;
    if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error('Invalid session description handler.');
    }
    this.recordRtcStats(invitation);

    sessionDescriptionHandler.peerConnectionDelegate = {
      oniceconnectionstatechange: (event) => {
        console.log('On Ice Connection State Change:', event);
      },
      onconnectionstatechange: (event: Event) => {
        const state = sessionDescriptionHandler.peerConnection?.connectionState;
        if (state) {
          this.handleConnectionStateChange(event, state, sessionDescriptionHandler);
        }
      },
    };
  }

  /** This method is executed when a Call has been received and dialed */
  setupStateChangeListener(invitation: Invitation | Inviter): void {
    if (invitation.delegate) {
      invitation.delegate.onInvite = (info: IncomingRequestMessage) => {
        if (
          info.from.uri.user &&
          (invitation.remoteIdentity.uri.user?.startsWith('*') ||
            this.callParkService.getData().some((x) => x.coin === invitation.remoteIdentity.uri.user))
        ) {
          const call = this.getCallById(invitation.id as CallId);
          if (call) {
            // Update properties on call after incoming request message received
            call.session.remoteIdentity.uri.user = info.from.uri.user;
            call.updateContact(this.contactService.contactByTel(info.from.uri.user));
          }
        }
      };
    }
    invitation.stateChange.addListener((newState: SessionState) => {
      switch (newState) {
        case SessionState.Establishing: {
          console.log('call: SIPJS onInvite --> inviter state change - Establishing:', invitation);
          break;
        }
        case SessionState.Established: {
          this.handleEstablishedState(invitation);
          break;
        }
        case SessionState.Terminated: {
          console.log('call: SIPJS onInvite --> inviter state change - Terminated:', invitation);
          break;
        }
      }
    });
  }

  private recordRtcStats(session: Session) {
    this.rtcSnapshot = '';
    timer(0, 1000)
      .pipe(
        map(() => session.sessionDescriptionHandler),
        takeWhile((handler) => handler instanceof SessionDescriptionHandler && handler.peerConnection != null)
      )
      .subscribe(async (handler: SessionDescriptionHandler) => {
        const { jitter, latency, packetLoss } = await this.extractStats(handler);
        this.updateCallQuality(jitter, latency, packetLoss);
        await this.buildRtcSnapshot(handler);
      });
  }

  /**
   * Calls the provided number placing any currently answered
   * calls on hold in the process.
   */
  async call(number: string): Promise<string | undefined> {
    if (!this.sessionManager) {
      return undefined;
    }

    const answeredCalls = this.getCallsWithStatus(SessionStatus.Answered);
    await Promise.all(answeredCalls.map((call) => this.setCallHold(call.id, true)));

    console.log('SIPJS dialTheCall --> numberToDial: ' + number + ', host: ' + this.host);
    const target = this.createTargetUri(number, this.host);
    const inviter = await this.sessionManager.call(
      target.toString(),
      {
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: {
              deviceId: this.settingsService.getMicrophone(),
            },
            video: false,
          },
        },
      },
      {
        requestDelegate: {
          onAccept: (response: IncomingResponse) => {
            console.log('call: SIPJS dialTheCall --> onAccept', response.message.callId);
          },
          onProgress: (response: IncomingResponse) => {
            console.log('call: SIPJS dialTheCall --> onProgress', response.message.callId);
            if (response.message.statusCode === 180 || response.message.statusCode === 183) {
              const call = this.getCallById(inviter.id as CallId);
              if (call) {
                this.updateCallStatus(call, SessionStatus.Ringing);
              }
            }
          },
          onReject: (response: IncomingResponse) => {
            console.log('call: SIPJS dialTheCall --> onReject', response.message.callId);
            const call = this.getCallById(inviter.id as CallId);
            if (call) {
              this.updateCallStatus(call, SessionStatus.Reject);
            }
          },
          onTrying: (response: IncomingResponse) => {
            console.log('call: SIPJS dialTheCall --> onTrying', response.message.callId);
            if (response.message.statusCode === 100) {
              const call = this.getCallById(inviter.id as CallId);
              if (call) {
                this.updateCallStatus(call, SessionStatus.Trying);
              }
            }
          },
        },
      }
    );

    this.setupStateChangeListener(inviter);

    this.googleAnalyticsService.voiceCallOutbound();
    return inviter.id;
  }

  private updateCallStatus(call: Call, status: SessionStatus) {
    console.log(`call: update session state transition: ${status} for session: ${call.session.id}`);
    call.statusHistory.push(status);
    this.sessionStatusSubject.next({ call, status });
  }

  public async acceptCall(callId: CallId): Promise<void> {
    console.log(`SIPJS --> acceptCall ${callId}`);
    const call = this.getCallById(callId);
    if (!call || !(call.session instanceof Invitation)) {
      throw new TypeError('Session does not exist or has invalid type.');
    }

    return await (call.session as Invitation).accept();
  }

  public async rejectCall(callId: CallId, rejectOptions?: InvitationRejectOptions): Promise<void> {
    console.log(`SIPJS --> rejectCall: ${callId} with options: ${rejectOptions}`);
    const call = this.getCallById(callId);
    if (!call || !(call.session instanceof Invitation)) {
      throw new TypeError('Session does not exist or has invalid type.');
    }

    this.updateCallStatus(call, SessionStatus.Reject);
    await call.session.reject({ ...rejectOptions, statusCode: 486 });
  }

  public async endCall(callId: CallId): Promise<void> {
    const call = this.getCallById(callId);
    if (!call) {
      throw new Error('Session does not exist.');
    }

    // Add this here since there isn't a good way to know from sip.js when a session has been cancelled by the
    // local user.
    call.terminationCause = CallTerminationCause.LocalUserHangup;

    const session = call.session;
    console.log(`SIPJS endCall --> ${session.state}...`);

    // Logic derived from https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/api/session.ts#L343C10-L343C14
    switch (session.state) {
      case SessionState.Initial:
      case SessionState.Establishing: {
        if (session instanceof Inviter) {
          await session.cancel();
        } else if (session instanceof Invitation) {
          await this.rejectCall(callId);
        }
        break;
      }
      case SessionState.Established: {
        await session.bye();
        break;
      }
      case SessionState.Terminating:
      case SessionState.Terminated: {
        break;
      }
    }
  }

  public async endAllSessions(): Promise<void> {
    console.log('SIPJS endAllSessions');
    for (const call of this.calls) {
      await this.endCall(call.id);
    }
  }

  public setCallMuted(callId: CallId, shouldMute: boolean) {
    if (!this.sessionManager) {
      throw new Error('SessionManager not initialized.');
    }

    const call = this.getCallById(callId);
    if (!call) {
      throw new Error('Call does not exist.');
    }

    shouldMute ? this.sessionManager.mute(call.session) : this.sessionManager.unmute(call.session);
  }

  public async setCallHold(callId: CallId, shouldHold: boolean): Promise<void> {
    if (!this.sessionManager) {
      throw new Error('SessionManager not initialized.');
    }

    const call = this.getCallById(callId);
    if (!call) {
      throw new Error('Session does not exist.');
    }

    const holdPromise = shouldHold ? this.sessionManager.hold(call.session) : this.sessionManager.unhold(call.session);
    await holdPromise;
  }

  public sendDtmf(callId: CallId, digit: string): void {
    const call = this.getCallById(callId);
    if (call) {
      const sessionDescriptionHandler = call.session.sessionDescriptionHandler;
      if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
        throw new Error('Invalid session description handler.');
      }
      sessionDescriptionHandler.sendDtmf(digit, { duration: 200, interToneGap: 30 });
    }
  }

  async transfer(transferDestinationNumber: string, callIdToTransfer: CallId, host: string = this.host): Promise<void> {
    await this.transferUnsupervised(transferDestinationNumber, host, callIdToTransfer);
  }

  private async transferUnsupervised(transferDestinationNumber: string, host: string, callIdToTransfer: CallId) {
    const call = this.getCallById(callIdToTransfer);
    if (!call) {
      throw new Error('Session does not exist.');
    }

    const transferTarget = this.createTargetUri(transferDestinationNumber, host);
    try {
      call.terminationCause = CallTerminationCause.Transferred;
      await this.referSession(call.session, transferTarget);
      this.googleAnalyticsService.callTransferred(false);
    } catch {
      call.terminationCause = undefined;
    }
  }

  private async referSession(session: Session, transferTarget: URI): Promise<void> {
    await session.refer(transferTarget, {
      requestDelegate: {
        onAccept(): boolean {
          console.log('refer request ACCEPTED!!');
          return true;
        },
      },
    });
  }

  async attendedTransfer(callToTransferId: CallId, destinationCallId: CallId): Promise<void> {
    const callToTransfer = this.getCallById(callToTransferId);
    const destinationCall = this.getCallById(destinationCallId);

    if (!callToTransfer || !destinationCall) {
      return;
    }

    try {
      callToTransfer.terminationCause = CallTerminationCause.Transferred;
      await callToTransfer.session.refer(destinationCall.session);
      this.googleAnalyticsService.callTransferred(true);
    } catch {
      callToTransfer.terminationCause = undefined;
    }
  }

  public getEstablishedSessionCount(): number {
    const establishedSessions = this.sessionManager?.managedSessions.filter(
      (s) => s.session.state === SessionState.Established
    );
    return establishedSessions?.length ?? 0;
  }

  /**
   * Returns the number of sessions currently being managed by SIP.js SessionManager. Calls are added when created
   * and removed when terminated.
   */
  public getManagedSessionsCount(): number {
    return this.sessionManager?.managedSessions.length ?? 0;
  }

  public maxSimultaneousSessionsReached(): boolean {
    return this.maxSimultaneousSessions !== 0 && this.getManagedSessionsCount() >= this.maxSimultaneousSessions;
  }

  public getNormalizedCallId(callId: CallId): string {
    // SIP.js appends the tag value that has been set in "From" or "To" to the Call-ID; we have to remove the tag from Call-ID
    const call = this.getCallById(callId);
    const tag = call?.session?.localIdentity.getParam('tag') ?? call?.session?.remoteIdentity.getParam('tag') ?? '';

    return callId.endsWith(tag) ? callId.slice(0, -tag.length) : callId;
  }

  // =========== RTC Stats ===========

  private async extractStats(handler: SessionDescriptionHandler) {
    let jitter = 0;
    let latency = 0;
    let packetLoss = 0;
    if (!handler.peerConnection) {
      // Handle the case where peerConnection is not available
      return { jitter, latency, packetLoss };
    }
    const stats = await handler.peerConnection.getStats(null);
    let inboundRtpPacketsLost = 0;
    let remoteOutboundRtpPacketsSent = 0;
    let remoteInboundRtpPacketsLost = 0;
    let outboundRtpPacketsSent = 0;
    stats.forEach((report) => {
      switch (report.type) {
        case 'inbound-rtp': {
          jitter = report.jitter * 1000;
          inboundRtpPacketsLost = report.packetsLost;
          break;
        }
        case 'remote-inbound-rtp': {
          latency = report.roundTripTime * 1000;
          remoteInboundRtpPacketsLost = report.packetsLost;
          break;
        }
        case 'remote-outbound-rtp': {
          remoteOutboundRtpPacketsSent = report.packetsSent;
          break;
        }
        case 'outbound-rtp': {
          outboundRtpPacketsSent = report.packetsSent;
          break;
        }
      }
    });
    const localPacketLoss = (inboundRtpPacketsLost / remoteOutboundRtpPacketsSent) * 100;
    const remotePacketLoss = (remoteInboundRtpPacketsLost / outboundRtpPacketsSent) * 100;
    packetLoss = (localPacketLoss + remotePacketLoss) / 2;
    if (Number.isNaN(packetLoss)) {
      packetLoss = 0;
    }
    return { jitter, latency, packetLoss };
  }

  private updateCallQuality(jitter: number, latency: number, packetLoss: number) {
    if (jitter <= 30 && latency <= 300 && packetLoss < 1) {
      this.rtcCallQualitySubject.next('Green');
    } else if (jitter <= 80 && latency <= 600 && packetLoss <= 4) {
      this.rtcCallQualitySubject.next('Yellow');
    } else {
      this.rtcCallQualitySubject.next('Red');
    }
  }

  private async buildRtcSnapshot(handler: SessionDescriptionHandler) {
    this.rtcSnapshot = '';
    if (!handler.peerConnection) {
      return;
    }
    const stats = await handler.peerConnection.getStats(null);
    stats.forEach((report) => {
      this.rtcSnapshot += `\n\nReport: ${report.type}\n\ID: ${report.id}\nTimestamp: ${report.timestamp}\n`;
      Object.keys(report)
        .filter((statName) => !['id', 'timestamp', 'type'].includes(statName))
        .forEach((statName) => {
          this.rtcSnapshot += `${statName}: ${report[statName]}\n`;
        });
    });
  }

  // =========== Helpers ==========

  private async refreshAudioDevice(callId: CallId): Promise<void> {
    if (!callId) {
      return;
    }
    // TODO: Is it necessary to reinvite? Instead, could we either make a custom SDH or modify the existing options?
    /**
     const deviceId = this.settingsService.getMicrophone();
     const session = this.getCallById(sessionId);
     if (session && session.session.state === SessionState.Established) {
     const constraints = { ...session.session.sessionDescriptionHandlerOptions.constraints, audio: { deviceId } };
     session.session.sessionDescriptionHandlerOptions.constraints = constraints;
     }
     */
    const options: SessionInviteOptions = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: {
            deviceId: this.settingsService.getMicrophone(),
          },
          video: false,
        },
      },
    };

    const call = this.getCallById(callId);
    if (call && call.session.state === SessionState.Established) {
      await call.session.invite(options);
    }
  }

  private createTargetUri(numberToDial: string, host: string): URI {
    const target = new URI('sip', formatInternationalPhoneNumber(numberToDial), host);
    if (!target) {
      throw new Error('Failed to create URI');
    }
    return target;
  }
}
