import { DialogModule } from '@angular/cdk/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthService } from '@app/auth/services/auth.service';
import { InitialLoadingComponent } from '@app/shared/components/initial-loading/initial-loading.component';
import { AuthGuard } from '@app/shared/guards/auth-guard';
import { AuthTokenInterceptor } from '@app/shared/interceptors/auth-token-interceptor';
import { ErrorInterceptor } from '@app/shared/interceptors/error-interceptor';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { environment } from '@environment/environment';
import * as Sentry from '@sentry/angular-ivy';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppInitializerService } from './core/services/app-initializer.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    DialogModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    LayoutModule,
    MatSelectModule,
    MatAutocompleteModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionFactor,
        deps: [AuthService],
      },
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('../service-worker.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatIconModule,
    MatInputModule,
    TextFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatRadioModule,
    MatStepperModule,
    InitialLoadingComponent,
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerService.init,
      deps: [Injector],
      multi: true,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    provideEnvironmentNgxMask(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(_: Sentry.TraceService) {}
}

export function jwtOptionFactor(authService: AuthService) {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tokenGetter: async (request?: HttpRequest<any>) => {
      if (request && AuthTokenInterceptor.shouldSkipAuthenticationForUrl(request.url)) {
        return null;
      }
      return await authService.getRefreshedAccessToken(!request?.url.includes('provision'));
    },
    allowedDomains: [
      'auth.uc-technologies.com',
      'api.connectuc.io',
      'staging.connectuc.engineering',
      'dev.connectuc.engineering',
    ],
  };
}
