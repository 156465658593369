import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { PARK_LOT_EXT_PREFIX } from '@app/parking/models/parking-lot.model';
import { CallParkService } from '@app/parking/services/call-park.service';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import { SettingsService } from '@app/preferences/services/settings.service';
import { SoundEffectService } from '@app/preferences/services/sound-effect.service';
import { CallDisplayNamePipe } from '@app/shared/pipes/call-display-name.pipe';
import { TimeElapsedPipe } from '@app/shared/pipes/time-elapsed.pipe';
import { AudioUtil, microphonePermission } from '@app/shared/utils/audio.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  startWith,
  Subject,
  Subscription,
} from 'rxjs';
import { SessionState } from 'sip.js';

import { Call, CallId } from '../models/call.model';
import { CallStatus } from '../models/call-details.models';
import { ConferenceCall, ConferenceId } from '../models/conference-call.model';
import { CallTerminationCause, ConferenceStatus, SessionStatus } from '../models/phone.models';
import { AudioService } from './audio.service';
import { SipjsService } from './sipjs.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  private readonly incomingCallsSubject = new BehaviorSubject<Call[]>([]);
  private readonly visibleCallsSubject = new BehaviorSubject<Call[]>([]);
  private readonly currentVisibleCallSubject = new BehaviorSubject<Call | ConferenceCall | undefined>(undefined);
  private readonly hasVisibleCallsSubject = new BehaviorSubject<boolean>(false);
  private readonly hasEstablishedCallsSubject = new BehaviorSubject<boolean>(false);
  private readonly hasNotEstablishedCallsSubject = new BehaviorSubject<boolean>(false);
  private readonly callWindowFocusedSubject = new BehaviorSubject<boolean>(false);

  /** Subject which emits every time a call is added, leaves, or is split from a conference */
  private conferenceStatusSubject = new Subject<{
    call: Call;
    status: ConferenceStatus;
  }>();

  /**
   * List of incoming calls. If a call is missed, it will be removed after a delay to allow for the UI to display missed status.
   *
   * Emits only when the array changes.
   */
  public readonly incomingCalls$ = this.incomingCallsSubject
    .asObservable()
    .pipe(this.createDistinctUntilCallArrayChangedHandler());

  /**
   * List of visible calls. Visible calls typically are any calls that are currently managed by sip.js. If a call is terminated by the local
   * user, it will be removed from this list immediately since we do not want to display it in the UI (i.e. should no longer be visible). \
   * Otherwise, it will be removed after a delay to allow for the UI to display the call as terminated.
   *
   * Note this list will only contain `Call` objects. No `ConferenceCall` objects will be present here.
   *
   * Emits only when the array changes.
   */
  public readonly visibleCalls$ = this.visibleCallsSubject
    .asObservable()
    .pipe(this.createDistinctUntilCallArrayChangedHandler());

  /**
   * The currently visible call. A Call in the `SessionStatus.Answered` state will typically be set here. If
   * all calls are on hold, this value will be equal to the last call that was Answered. This observable will only
   * emit when the current visible call changes.
   */
  public readonly currentVisibleCall$ = this.currentVisibleCallSubject
    .asObservable()
    .pipe(distinctUntilChanged((prevCall, currentCall) => prevCall?.id === currentCall?.id));

  /** True whenever the visibleCallsSubject has items. */
  public readonly hasVisibleCalls$ = this.hasVisibleCallsSubject.asObservable();

  /** True whenver the visibleCallsSubject has sessions with SessionState.Established. This value only emits when it changes.  */
  public readonly hasEstablishedCalls$ = this.hasEstablishedCallsSubject.asObservable().pipe(distinctUntilChanged());

  /**
   * True whenver the visibleCallsSubject has sessions that have not yet been established. Determined by
   * checking if any calls don't have an established timestamp. This value only emits when it changes.
   */
  public readonly hasNotEstablishedCalls$ = this.hasNotEstablishedCallsSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public readonly callWindowFocused$ = this.callWindowFocusedSubject.asObservable();

  public readonly conferenceStatus$ = this.conferenceStatusSubject.asObservable();

  public callNotificationsSilenced = false;

  // The audio stream received from the other call party
  private remoteAudioElement = new Audio() as AudioUtil;
  private ringtoneOutgoingCall = new Audio('../../../assets/sounds/ringtone-outgoing-call.wav') as AudioUtil;

  private conferenceCallsMap = new Map<ConferenceId, ConferenceCall>(); // [conferenceId : ConferenceCall]

  constructor(
    private appConfigService: AppConfigService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private sipJsService: SipjsService,
    private parkService: CallParkService,
    private notificationService: NotificationService,
    private audioService: AudioService,
    private soundEffectService: SoundEffectService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    // Moved to helper method to make testing easier
    this.setUpAllObservers();
  }

  private setUpAllObservers() {
    this.initializeAudioElements();
    this.subscribeToAudioSettings();
    this.subscribeToJwtClaims();
    this.subscribeToConfigSettings();

    // Collection of methods that observe sip.js events and update
    // the state of the PhoneService so that UI components may display
    // calling information.
    this.setUpIncomingCallsObserver();
    this.setUpVisibleCallsObserver();
    this.setUpCurrentVisibleCallObserver();
    this.setUpHasVisibleCallObserver();
    this.setUpHasEstablishedCallsObserver();
    this.setUpHasNotEstablishedCallsObserver();
    this.setUpRingtoneHandlers();
    this.setUpNotificationPresentationObserver();
    this.setUpAudioStreamHandler();
    this.setUpAutoAnsweringObserver();
  }

  /**
   * Incoming calls:
   * Observes session status changes filtering for incoming calls
   * (i.e. calls with status `SessionStatus.Received`) and republish them
   * for consumption by the UI. Missed calls will also be contained within this list for a short period of time for UI display.
   */
  private setUpIncomingCallsObserver() {
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(({ call, status }) => {
      if (status === SessionStatus.Received) {
        this.incomingCallsSubject.next([...this.incomingCallsSubject.value, call]);
        return;
      }

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const callsWithSessionIdFiltered = (sessionId: string): Call[] => {
        return this.incomingCallsSubject.value.filter((c) => c.id !== sessionId);
      };

      // Missed calls should only be removed once they have been destroyed. All others should be removed immediately.
      if (this.isCallMissed(call)) {
        if (status === SessionStatus.Destroy) {
          this.incomingCallsSubject.next(callsWithSessionIdFiltered(call.id));
        }
      } else {
        this.incomingCallsSubject.next(callsWithSessionIdFiltered(call.id));
      }
    });
  }

  /**
   * Visible calls:
   * Observe sessions provided by sip.js and keep list of calls that should be visible to the user in the list.
   * The list is mutated as status changes occur by observing status changes emitted by SipJsService.
   *
   * When a session terminates, it will be removed from the list immediately if the local user hung up the call.
   * Otherwise, it will be removed after a delay to allow for the UI to display the call as terminated.
   */
  private setUpVisibleCallsObserver() {
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(({ call, status }) => {
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const callsByFilteringId = (callId: string): Call[] => {
        return this.visibleCallsSubject.value.filter((c) => c.id !== callId);
      };

      /**
       * Incoming calls should only be added to the visible call list once they have been answered. Outgoing calls should be added to the list
       * as soon as they are created.
       *
       *
       * It's important to check if the call already exists since the `Answered` status can be emitted multiple times for a given call and also
       * it may have been added during the created stage.
       */
      switch (status) {
        case SessionStatus.Created: {
          if (call.direction === 'outgoing') {
            this.visibleCallsSubject.next([...this.visibleCallsSubject.value, call]);
          }
          break;
        }

        case SessionStatus.Answered: {
          if (!this.visibleCallsSubject.value.some((c) => c.id === call.id)) {
            this.visibleCallsSubject.next([...this.visibleCallsSubject.value, call]);
          }
          break;
        }

        case SessionStatus.Hangup: {
          // Remove calls that are hung up immediately. If a call is parked and there are other calls
          // visible, the parked call should be removed immediately as well.
          if (
            call.terminationCause === CallTerminationCause.LocalUserHangup ||
            (call.terminationCause === CallTerminationCause.Parked && this.hasNonTerminatedCalls())
          ) {
            this.visibleCallsSubject.next(callsByFilteringId(call.id));
          }
          break;
        }

        case SessionStatus.Destroy: {
          this.visibleCallsSubject.next(callsByFilteringId(call.id));
          break;
        }
      }
    });
  }

  /**
   * Current visible call:
   * Observe session and conference status updates list and set the current visible call based on those values.
   */
  private setUpCurrentVisibleCallObserver() {
    merge(this.sipJsService.sessionStatus$, this.conferenceStatus$)
      .pipe(untilDestroyed(this))
      .subscribe(({ call, status }) => {
        switch (status) {
          case SessionStatus.Created: {
            // If an outgoing call is made set it as the visible call.
            if (call.direction === 'outgoing') {
              this.currentVisibleCallSubject.next(call);
            }
            break;
          }

          case SessionStatus.Answered: {
            // Any time a call enters the `Answered` state, it should be set as the current visible call.
            const nextCall = call.conferenceId ? this.getConferenceCallById(call.conferenceId) : call;
            this.currentVisibleCallSubject.next(nextCall);
            break;
          }

          case ConferenceStatus.Joined: {
            // If a call has joined a conference, the call's conference should be the visible call.
            if (!call.conferenceId) {
              throw new Error('ConferenceStatus.Joined emitted without a conference id.');
            }

            const conference = this.getConferenceCallById(call.conferenceId);
            this.currentVisibleCallSubject.next(conference);
            break;
          }

          case ConferenceStatus.Split: {
            // If a call has split from a conference, assume it should be set as the current visible call
            this.currentVisibleCallSubject.next(call);
            break;
          }

          case SessionStatus.Hangup: {
            const currentVisibleCallId = this.currentVisibleCallSubject.value?.id;
            const wasVisibleCall = call.id === currentVisibleCallId;
            const wasInVisibleConference =
              call.conferenceId !== undefined && call.conferenceId === currentVisibleCallId;
            const conference = call.conferenceId ? this.getConferenceCallById(call.conferenceId) : undefined;

            // If the call that was hung up was part of a conference that is visible and about to finish, switch to
            // another call. Otherwise, perform the normal logic for when a call hangs up.
            if (wasInVisibleConference && conference?.isReadyToFinish()) {
              // Find a visible call. Make sure to check if that call is conferenced
              // so that we can set the current visible call to the conference if it is, but only if the
              // conference is not the one we're currently in.
              const nextCall = this.visibleCallsSubject.value.find((c) => c.id !== call.id);
              if (nextCall && nextCall.conferenceId && nextCall.conferenceId !== conference.id) {
                const nextConference = this.getConferenceCallById(nextCall.conferenceId);
                if (!nextConference) {
                  throw new Error('Visible call is conferenced but the conference does not exist.');
                }
                this.currentVisibleCallSubject.next(conference);
              } else {
                this.currentVisibleCallSubject.next(nextCall);
              }
            }
            // If we aren't dealing with a call that was conferenced,
            // replace current visible call with another when the local user hangs up the call. If the user parks the call,
            // the current visible call should only be replaced if another call exists. Otherwise, let the parked call be removed
            // when destroyed.
            else if (
              wasVisibleCall &&
              (call.terminationCause === CallTerminationCause.LocalUserHangup ||
                (call.terminationCause === CallTerminationCause.Parked && this.hasNonTerminatedCalls()))
            ) {
              const nextCall = this.visibleCallsSubject.value.find((c) => c.id !== call.id);
              this.currentVisibleCallSubject.next(nextCall);
            }

            break;
          }

          case SessionStatus.Destroy: {
            // On Destroy, replace the current visible call with another if there is one. Call conference information will have been
            // removed by this point so it doesn't need to be considered.
            const currentVisibleCallId = this.currentVisibleCallSubject.value?.id;
            const wasVisibleCall =
              currentVisibleCallId !== undefined && [call.id, call.conferenceId].includes(currentVisibleCallId);
            if (wasVisibleCall) {
              const nextCall = this.visibleCallsSubject.value.find((c) => c.id !== call.id);
              this.currentVisibleCallSubject.next(nextCall);
            }
            break;
          }
        }
      });

    this.currentVisibleCall$.pipe(untilDestroyed(this)).subscribe((call) => {
      console.log('call: current visible call changed', call?.id);
    });
  }

  private setUpHasVisibleCallObserver() {
    this.visibleCalls$.pipe(untilDestroyed(this)).subscribe((calls) => {
      // Calls are ongoing when the current calls array has at least one item in it.
      const hasVisibleCalls = calls.length > 0;
      this.hasVisibleCallsSubject.next(hasVisibleCalls);
    });
  }

  private setUpHasEstablishedCallsObserver() {
    this.visibleCalls$.pipe(untilDestroyed(this)).subscribe((calls) => {
      // Calls are ongoing when the current calls array has at least one item in it.
      const hasEstablishedCalls = calls.some((call) => call.session.state === SessionState.Established);
      this.hasEstablishedCallsSubject.next(hasEstablishedCalls);
    });
  }

  private setUpHasNotEstablishedCallsObserver() {
    combineLatest([this.sipJsService.calls$, this.sipJsService.sessionStatus$])
      .pipe(untilDestroyed(this))
      .subscribe(([calls]) => {
        const hasNotEstablishedCalls = calls.some((call) => !this.isCallEstablished(call));
        this.hasNotEstablishedCallsSubject.next(hasNotEstablishedCalls);
      });
  }

  /**
   * Ringtone handling
   * Play a ringtone when the session status changes to `SessionStatus.Ringing` or `SessionStatus.Received`
   */
  private setUpRingtoneHandlers() {
    this.ringtoneOutgoingCall.loop = true;

    combineLatest([this.sipJsService.calls$, this.sipJsService.sessionStatus$])
      .pipe(untilDestroyed(this))
      .subscribe(async () => {
        const incomingCount = this.sipJsService.getCallsWithStatus(SessionStatus.Received).length;
        const callCount = this.sipJsService.calls.length;
        const hasRinging = this.sipJsService.getCallsWithStatus(SessionStatus.Ringing).length > 0;
        const notificationSettings = this.appConfigService.getNotificationSettings();

        const incomingKey = callCount > 1 ? 'Incoming Second Call' : 'Incoming Call';
        if (incomingCount > 0 && notificationSettings[NotificationKeyBackend[incomingKey]]?.notificationSound) {
          await this.playIncomingRingtone(incomingKey);
        } else {
          this.pauseIncomingRingtone();
        }

        if (hasRinging) {
          await this.playOutgoingRingtone();
        } else {
          this.pauseOutgoingRingtone();
        }
      });

    // Play a disconnect tone whenever an established call is hung up without a termination reason (i.e. the local
    // user did not hang up the call.)
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(async ({ call, status }) => {
      if (status === SessionStatus.Hangup && call.establishedTimestamp && !call.terminationCause) {
        await this.playDisconnectCallRingtone();
      }
    });
  }

  /**
   * AutoAnswering handling
   * Auto answer the call when its enabled and after time period from settings
   */
  private setUpAutoAnsweringObserver() {
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(({ call, status }) => {
      if (status === SessionStatus.Received) {
        const autoAnswer = this.appConfigService.autoAnsweringRulesSettings?.autoAnswerIncomingCalls ?? false;
        const autoAnswerTime = this.appConfigService.autoAnsweringRulesSettings?.autoAnswerTime ?? 4;
        if (autoAnswer && autoAnswerTime >= 0) {
          setTimeout(() => {
            // answer the call only in case it is ringing.
            if (call.status == SessionStatus.Received) {
              this.answerIncomingCall(call.id);
            }
          }, autoAnswerTime * 1000);
        }
      }
    });
  }

  /**
   * Notification presentation:
   * For presenting notifications, we want to hook into the session status event stream. Any time
   * a session status changes to `SessionStatus.Received`, we want to present a notification to the user.
   */
  private setUpNotificationPresentationObserver() {
    this.sipJsService.sessionStatus$.pipe(untilDestroyed(this)).subscribe(({ call, status }) => {
      if (this.callNotificationsSilenced) {
        return;
      }

      if (status === SessionStatus.Received) {
        this.sendIncomingCallNotification(call);
      }

      if (status === SessionStatus.Hangup) {
        const history = call.statusHistory ?? [];
        if (
          history.includes(SessionStatus.Received) &&
          !history.includes(SessionStatus.Answered) &&
          !history.includes(SessionStatus.Reject)
        ) {
          this.sendMissedCallNotification(call);
        }
      }
    });
  }

  /**
   * Audio stream management:
   * Whatever call is currently visible should be played through the remote audio element. Since a call may be visible when
   * its remote stream is unavailable, we need to observe the session status changes to know when to play the remote stream.
   */
  private setUpAudioStreamHandler() {
    let sessionStatusSubscription: Subscription | undefined;

    this.currentVisibleCall$.pipe(untilDestroyed(this)).subscribe((call) => {
      sessionStatusSubscription?.unsubscribe();
      if (!call) {
        this.pauseRemoteAudio();
        return;
      }

      // Wait for a stream to be available before playing once the visible call changes. We can do this
      // by observing the session status changes and just checking each time. Once a stream is available,
      // we can play it.
      // Be sure to check if a call is conferenced since the audio stream will be different.
      const mediaStreamObservable =
        call instanceof ConferenceCall
          ? call.mergedAudioStream$
          : this.observeCallStatusChanges(call.id).pipe(
              untilDestroyed(this),
              map(() => this.sipJsService.getRemoteMediaStream(call.id))
            );

      sessionStatusSubscription = mediaStreamObservable
        .pipe(
          untilDestroyed(this),
          filter((stream) => stream !== undefined)
        )
        .subscribe((stream) => {
          this.playCallMediaStream(stream!);
        });
    });
  }

  /**
   * Creates an operator function that will diff two Call arrays returning true if each element in both arrays is the same.
   * This is useful for performance since it means we don't have to re-render the UI if the calls array haven't changed after a status change.
   */
  private createDistinctUntilCallArrayChangedHandler(): MonoTypeOperatorFunction<Call[]> {
    return distinctUntilChanged((prevCalls: Call[], currentCalls: Call[]) => {
      if (prevCalls.length !== currentCalls.length) {
        return false;
      }

      for (const [i, prevCall] of prevCalls.entries()) {
        if (prevCall.id !== currentCalls[i].id) {
          return false;
        }
      }

      return true;
    });
  }

  private initializeAudioElements(): void {
    this.ringtoneOutgoingCall.loop = true;
    this.remoteAudioElement.autoplay = true;
  }

  private subscribeToAudioSettings(): void {
    this.settingsService.audioSettings$.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.settingsService.setSpeaker(this.remoteAudioElement);
    });
  }

  private sendIncomingCallNotification(call: Call) {
    const suspended = this.audioService.isAudioSuspend();
    const numberOfCalls = this.visibleCallsSubject.value.length;
    if (suspended) {
      this.googleAnalyticsService.voiceCallNoClickRinging();
    }

    const displayName = new CallDisplayNamePipe().determineCallDisplayName(call, call.contact);
    this.notificationService.sendNotification(
      `${displayName} is calling you`,
      `${call.remoteUriUser}`,
      numberOfCalls === 1 ? NotificationKeyBackend['Incoming Second Call'] : NotificationKeyBackend['Incoming Call'],
      suspended,
      undefined,
      call.id
    );
  }

  private sendMissedCallNotification(call: Call) {
    console.log('call: Sending missed call notification');

    const displayName = new CallDisplayNamePipe().determineCallDisplayName(call, call.contact);
    this.notificationService.sendNotification(
      'Call Missed',
      `Call from ${displayName} was missed.`,
      NotificationKeyBackend['Missed Call']
    );
  }

  private subscribeToConfigSettings() {
    combineLatest([this.appConfigService.features$, this.appConfigService.data$])
      .pipe(untilDestroyed(this))
      .subscribe(([_, data]) => {
        const isAttendantConsoleEnabled = this.appConfigService.isFeatureEnabled(AppFeature.AttendantConsole);
        this.sipJsService.maxSimultaneousSessions =
          isAttendantConsoleEnabled && data?.settings.preferences.attendantConsole.isOnline === true
            ? SipjsService.maxSimulataneousSessions
            : 4;
      });
  }

  private subscribeToJwtClaims(): void {
    this.authService.jwtClaims.subscribe(async (next) => {
      if (next === null) {
        await this.sipJsService.dispose();
      }
    });
  }

  private async playIncomingRingtone(notificationType: 'Incoming Call' | 'Incoming Second Call') {
    const notificationKey = NotificationKeyBackend[notificationType];
    const notificationSettings = this.appConfigService.getNotificationSettings();
    await this.soundEffectService.play(notificationSettings[notificationKey].ringtone, notificationKey);
  }

  private pauseIncomingRingtone() {
    this.soundEffectService.stop();
  }

  // Only if early media is missing
  private async playOutgoingRingtone() {
    await this.play(this.ringtoneOutgoingCall);
  }

  private pauseOutgoingRingtone() {
    this.ringtoneOutgoingCall.pause();
  }

  private async playDisconnectCallRingtone() {
    await this.settingsService.playAudio(
      new Audio('../../../assets/sounds/phone_disconnect_tone.wav') as AudioUtil,
      undefined,
      this.settingsService.audioSettings.speakerDevice
    );
  }

  private playCallMediaStream(mediaStream: MediaStream) {
    if (
      this.remoteAudioElement.srcObject instanceof MediaStream &&
      mediaStream.id === this.remoteAudioElement.srcObject.id
    ) {
      console.debug('call: Provided media stream is already playing. Ignoring call to play media stream.');
      return;
    }

    console.log(`call: playing media stream: ${mediaStream.id}`);
    this.remoteAudioElement.srcObject = mediaStream;
    this.remoteAudioElement.load();
    this.remoteAudioElement.addEventListener('loadedmetadata', async () => {
      await this.play(this.remoteAudioElement);
    });
  }

  private pauseRemoteAudio() {
    this.remoteAudioElement.pause();
  }

  private async play(audioElement: AudioUtil) {
    try {
      await this.settingsService.playAudio(audioElement, undefined, this.settingsService.getSpeaker());
    } catch (error) {
      console.error('Failed to play media!', error);
    }
  }

  //this method call while logout
  async dispose() {
    this.remoteAudioElement.pause();
    this.incomingCallsSubject.next([]);
    await this.sipJsService.dispose();
  }

  public setCallWindowFocused(focused: boolean) {
    this.callWindowFocusedSubject.next(focused);
  }

  public getCallById(callId: CallId): Call | undefined {
    return this.sipJsService.getCallById(callId);
  }

  public getConferenceCallById(conferenceId: ConferenceId): ConferenceCall | undefined {
    return this.conferenceCallsMap.get(conferenceId);
  }

  /**
   * Returns an observable that emits the current status of the call/conference with the given ID
   *
   * @param callId The call ID to observe
   * @returns An observable that emits the current status of the call/conference
   * with the given ID, or undefined if the session does not exist
   */
  public observeStatusChanges(callId: CallId | ConferenceId): Observable<SessionStatus | undefined> {
    // Cast the callId to a string and look for a call. If we don't have a call, look for a conference. If neither is found, throw an error
    const callIdAsString = callId as string;
    const call = this.getCallById(callIdAsString as CallId);
    if (call) {
      return this.observeCallStatusChanges(call.id);
    }

    const conference = this.getConferenceCallById(callIdAsString as ConferenceId);
    if (conference) {
      return this.observeConferenceStatusChanges(conference.id);
    }

    throw new Error(`Could not find call or conference with id: ${callId}.`);
  }

  /**
   * Returns an observable that emits the current status of the call with the given ID
   *
   * @param callId The call ID to observe
   * @returns An observable that emits the current status of the session
   * with the given ID, or undefined if the session does not exist
   */
  public observeCallStatusChanges(callId: CallId): Observable<SessionStatus | undefined> {
    const callToObserve = this.getCallById(callId);
    if (!callToObserve) {
      throw new Error('Session does not exist.');
    }

    return this.sipJsService.sessionStatus$.pipe(
      startWith({ call: callToObserve, status: callToObserve.status }),
      filter(({ call }) => call.id === callId),
      distinctUntilChanged((a, b) => a.status === b.status),
      map(({ status }) => status)
    );
  }

  /**
   * Returns an observable that emits the current status of the conference with the given ID
   *
   * @param conferenceId The conference ID to observe
   * @returns An observable that emits the current status of the session
   * with the given ID, or undefined if the session does not exist
   *
   * @remarks The `ConferenceCall` object does not have its own status, but rather derives its status
   * from the status of the calls that are part of the conference. This observable uses the underlying calls
   * to determine when the conference status changes.
   */
  public observeConferenceStatusChanges(conferenceId: ConferenceId): Observable<SessionStatus | undefined> {
    const conferenceToObserve = this.getConferenceCallById(conferenceId);
    if (!conferenceToObserve) {
      throw new Error('Conference does not exist.');
    }

    return this.sipJsService.sessionStatus$.pipe(
      startWith({ call: undefined, status: conferenceToObserve.status }),
      map(() => conferenceToObserve.status), // This is a derived status so we can just call the getter every time
      distinctUntilChanged()
    );
  }

  // =========== Call Handling ===========

  public async answerIncomingCall(callId: CallId) {
    if (await microphonePermission.check()) {
      // Place any visible calls on hold before answering the incoming call.
      await this.holdAllAnsweredCalls();
      await this.sipJsService.acceptCall(callId);
    }
  }

  public async declineIncomingCall(callId: CallId) {
    await this.sipJsService.rejectCall(callId);
  }

  /** Call the provided number placing any currently visible calls on hold in the process. */
  public async call(number: string): Promise<CallId | undefined> {
    if (!(await microphonePermission.check())) {
      return undefined;
    }

    return (await this.sipJsService.call(number)) as CallId;
  }

  public async swapCall(callIdToSwap: CallId) {
    const callToSwap = this.getCallById(callIdToSwap);
    if (!callToSwap) {
      return;
    }

    // End any outgoing calls that haven't been established yet since they can't be swapped.
    const outgoingNonEstablishedCalls = this.sipJsService
      .getNonEstablishedCalls()
      .filter((call) => call.direction === 'outgoing');
    await Promise.all(outgoingNonEstablishedCalls.map((call) => this.endCall(call.id)));

    // Put any answered calls on hold.
    await this.holdAllAnsweredCalls();

    // If it's established already, we can just unhold the call to swap. Otherwise, try and accept it.
    await (callToSwap.status === SessionStatus.Received
      ? this.answerIncomingCall(callIdToSwap)
      : this.setCallHold(callIdToSwap, false));
  }

  private async holdAllAnsweredCalls(): Promise<void> {
    const answeredCalls = this.sipJsService.getCallsWithStatus(SessionStatus.Answered);
    await Promise.all(answeredCalls.map((call) => this.setCallHold(call.id, true)));
  }

  /**
   * Parks the call using the call parking API. If the call is successfully parked,
   * the call's termination cause will be set to `CallTerminationCause.Parked`.
   * @param call The call to park.
   * @param lot The parking lot to park the call in. If undefined, the call will be autoparked.
   * @returns The lot ID the call was parked in.
   */
  public async parkCall(call: Call, lot?: string): Promise<string> {
    const normalizedCallId = this.sipJsService.getNormalizedCallId(call.id);

    try {
      // Set the terminationCause before calling the API call to avoid race conditions where
      // the session terminates before returning from this call.
      call.terminationCause = CallTerminationCause.Parked;
      const result = await firstValueFrom(this.parkService.parkCall(normalizedCallId, lot));
      //If lot is undefined, send callParkedAutoPark else callParkedDirect
      lot === undefined
        ? this.googleAnalyticsService.callParkedAutoPark()
        : this.googleAnalyticsService.callParkedDirect();
      return result.lotId;
    } catch (error) {
      // Reset termination cause if the call fails to park
      call.terminationCause = undefined;
      throw error;
    }
  }

  public async pickupParkedCall(lotId: string) {
    await this.call(`${PARK_LOT_EXT_PREFIX}${lotId}`);
    this.googleAnalyticsService.callParkedRetrieved();
  }

  public async transfer(transferDestinationNumber: string, callIdToTransfer: CallId): Promise<void> {
    await this.sipJsService.transfer(transferDestinationNumber, callIdToTransfer);
  }

  public async attendedTransfer(callToTransferId: CallId, destinationCallId: CallId): Promise<void> {
    await this.sipJsService.attendedTransfer(callToTransferId, destinationCallId);
  }

  public async endAllCalls(): Promise<void> {
    const callIds = this.sipJsService.calls.map((call) => call.id);
    await Promise.all(callIds.map((callId) => this.endCall(callId)));
  }

  public async endAllAnsweredCalls(): Promise<void> {
    const answeredCalls = this.sipJsService.getCallsWithStatus(SessionStatus.Answered);
    await Promise.all(answeredCalls.map((call) => this.endCall(call.id)));
  }

  public async endCall(callId: CallId): Promise<void> {
    await this.sipJsService.endCall(callId);
  }

  public async toggleCallHold(callId: CallId | ConferenceId) {
    const call = this.getCallOrConferenceById(callId);
    if (call) {
      await this.setCallHold(callId, !call.held);
    }
  }

  private async setCallHold(callId: CallId | ConferenceId, hold: boolean) {
    const call = this.getCallOrConferenceById(callId);
    if (!call) {
      return;
    }

    if (call instanceof Call) {
      await this.sipJsService.setCallHold(call.id, hold);
    } else {
      const conferencedCalls = this.getCallsWithConferenceId(call.id);
      await Promise.all(conferencedCalls.map((c) => this.sipJsService.setCallHold(c.id, hold)));
    }
  }

  /** Toggle the muted state of a call. */
  public toggleCallMuted(callId: CallId | ConferenceId) {
    const call = this.getCallOrConferenceById(callId);
    if (call) {
      this.setCallMuted(callId, !call.muted);
    }
  }

  private setCallMuted(callId: CallId | ConferenceId, muted: boolean) {
    const call = this.getCallOrConferenceById(callId);
    if (!call) {
      return;
    }

    if (call instanceof Call) {
      this.sipJsService.setCallMuted(call.id, muted);
    } else {
      this.conferenceCallsMap.get(call.id)?.setMuted(muted);
    }
  }

  public sendDtmfToAnsweredCalls(digit: string): void {
    this.sipJsService.getCallsWithStatus(SessionStatus.Answered).forEach((call) => {
      this.sipJsService.sendDtmf(call.id, digit);
    });
  }

  // ========== Conference Calls ==========

  public async mergeVisibleCalls() {
    const calls = this.visibleCallsSubject.value.map((call) => call.id);
    await this.mergeCalls(calls);
  }

  /**
   * Merges the provided call IDs into a single merged call. For our purposes, this only entails
   * merging the audio streams of the calls together. The call IDs must be in the `SessionState.Established` state.
   *
   * @param callIds The call IDs to merge.
   */
  public async mergeCalls(callIds: CallId[]) {
    const calls = callIds
      .map((id) => this.getCallById(id))
      .filter((call) => call !== undefined && call.state === SessionState.Established) as Call[];

    try {
      const conference = new ConferenceCall(this.sipJsService, this.audioService.audioContext);
      conference.delegate = {
        onCallsAdded: (addedCalls) => {
          addedCalls.forEach((call) => this.conferenceStatusSubject.next({ call, status: ConferenceStatus.Joined }));
        },
        onCallsRemoved: (removedCalls) => {
          removedCalls.forEach(({ call, status }) => this.conferenceStatusSubject.next({ call, status }));
        },
        onConferenceFinished: () => {
          conference.destroy();
          this.conferenceCallsMap.delete(conference.id);
        },
      };

      // Update hold state for all calls
      await Promise.all(calls.map((call) => this.setCallHold(call.id, false)));

      // Unmute all calls. This needs to happen after the held state is set since unmuting a held call doesn't work:
      // See https://github.com/onsip/SIP.js/blob/2e1c525279c8d6deebb6ecaf3d14477ab7b63310/src/platform/web/session-manager/session-manager.ts#L1481
      calls.forEach((call) => this.setCallMuted(call.id, false));

      await conference.start(calls);
      conference.setMuted(false);

      // Now update the call map with our data
      this.conferenceCallsMap.set(conference.id, conference);
    } catch (error) {
      console.error('Failed to merge calls:', error);
      calls.forEach((call) => (call.conferenceId = undefined));
    }
  }

  /**
   * Splits the call from its merged state. If the call is not merged, an exception will be thrown.
   */
  public async splitCall(callId: CallId) {
    const call = this.getCallById(callId);
    if (!call) {
      return;
    }

    const conference = this.getConferenceForCall(call);
    if (!conference) {
      throw new Error(`Conference ${call.conferenceId} does not exist.`);
    }

    console.log('call: splitting call from conference', callId, conference.id);
    const callsToHold = this.getCallsWithConferenceId(conference.id).filter((c) => c.id !== callId);
    await conference.removeCalls([{ call, status: ConferenceStatus.Split }]);

    // The call that is split should not be held. All other calls in the conference should be held.
    await this.setCallHold(callId, false);

    // Hold all other answered calls.
    await Promise.all(callsToHold.map((c) => this.setCallHold(c.id, true)));
  }

  public async endConferenceCall(conferenceId: ConferenceId) {
    const calls = this.getCallsWithConferenceId(conferenceId);
    await Promise.all(calls.map((call) => this.endCall(call.id)));
    this.conferenceCallsMap.delete(conferenceId);
  }

  private getCallsWithConferenceId(conferenceId: ConferenceId) {
    return this.sipJsService.calls.filter((call) => call.conferenceId === conferenceId);
  }

  private getConferenceForCall(call: Call): ConferenceCall | undefined {
    if (!call.conferenceId) {
      return undefined;
    }

    return this.conferenceCallsMap.get(call.conferenceId);
  }

  // ========== Helpers ==========

  private getCallOrConferenceById(callId: CallId | ConferenceId): Call | ConferenceCall | undefined {
    return this.getCallById(callId as CallId) ?? this.getConferenceCallById(callId as ConferenceId);
  }

  public getStatusDescriptionForCall(call: Call | ConferenceCall) {
    switch (call.status) {
      case SessionStatus.Created:
      case SessionStatus.Trying: {
        if (call instanceof Call) {
          return call.direction === 'outgoing' ? CallStatus.Calling : CallStatus.Connecting;
        } else {
          return CallStatus.Connecting;
        }
      }
      case SessionStatus.Ringing: {
        return CallStatus.Ringing;
      }
      case SessionStatus.Received: {
        return CallStatus.Connecting;
      }
      case SessionStatus.Hold: {
        return 'ON HOLD';
      }
      case SessionStatus.Reject: {
        return CallStatus.Rejected;
      }
      case SessionStatus.Hangup:
      case SessionStatus.Destroy: {
        return CallStatus.CallEnded;
      }
      default: {
        return call.establishedTimestamp ? new TimeElapsedPipe().transform(call.establishedTimestamp) : '';
      }
    }
  }

  /**
   * Returns true if there are any calls with a transitioning state (i.e. not established or terminated).
   */
  public hasCallsWithTransitioningState(): boolean {
    return this.sipJsService.calls.some((call) => {
      call.state !== SessionState.Established && call.state !== SessionState.Terminated;
    });
  }

  public hasNonTerminatedCalls(): boolean {
    return this.sipJsService.getNonTerminatedCalls().length > 0;
  }

  public isCallEstablished(call: Call | ConferenceCall): boolean {
    return call.establishedTimestamp !== undefined;
  }

  /** Returns true if the Hangup state is right next to Received state. */
  public isCallMissed(call: Call | ConferenceCall): boolean {
    // Conference calls cannot be missed
    if (call instanceof ConferenceCall) {
      return false;
    }

    const receivedHistoryIndex = call.statusHistory.indexOf(SessionStatus.Received);
    return receivedHistoryIndex !== -1 && call.statusHistory[receivedHistoryIndex + 1] === SessionStatus.Hangup;
  }

  public isCallConferenced(call: Call): boolean {
    return call.conferenceId !== undefined;
  }

  public formatNumberForVoicemail(number: number | string): string {
    return `7${number}`;
  }
}
