<div
  class="import-contact-container"
  [formGroup]="importContactFormGroup">
  <mat-icon
    class="close-btn"
    (click)="close()"
    >close
  </mat-icon>
  <h1 class="header">Import contacts</h1>
  <div class="files">
    <div>
      <mat-form-field
        class="file input-40"
        appearance="outline"
        floatLabel="always">
        <mat-label>File Name</mat-label>
        <input
          autocomplete="off"
          readonly="true"
          matInput
          value="{{ contactFile.name }}"
          placeholder=""
          (click)="openFileSelector()" />
      </mat-form-field>
      <span
        (click)="removeFaxFile()"
        class="remove">
        <mat-icon>delete_outline</mat-icon>
      </span>
    </div>
  </div>
  <div class="add-file-wrapper">
    <div
      class="add-file"
      (click)="openFileSelector()">
      Select a Different File
      <mat-icon>file_upload_outline</mat-icon>
    </div>
  </div>
  <input
    type="file"
    #fileInput
    accept="{{ allowedFiles.join(',') }}"
    (change)="addFile($event)"
    [style.display]="'none'" />
  <mat-form-field
    class="file input-40 m-t-30"
    appearance="outline">
    <mat-label>Add to contact list</mat-label>
    <div class="flex">
      <mat-select formControlName="type">
        <mat-option
          *ngIf="appConfigService.hasOfficeManagerRole$ | async"
          [value]="ContactType.Shared"
          >{{ ContactType.Shared | contactTypeLabel }}</mat-option
        >
        <mat-option [value]="ContactType.Personal">{{ ContactType.Personal | contactTypeLabel }}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
  <mat-form-field
    class="file input-40 m-t-30"
    appearance="outline">
    <mat-label>Conflict resolution</mat-label>
    <div class="flex">
      <mat-select formControlName="merge">
        <mat-option [value]="1">Merge</mat-option>
        <mat-option [value]="0">Duplicate</mat-option>
      </mat-select>
    </div>
  </mat-form-field>
  <div class="button-container">
    <mat-divider></mat-divider>
    <button
      class="btn btn-cancel"
      (click)="close()"
      type="button">
      Cancel
    </button>
    <button
      (click)="submit()"
      class="btn-blue"
      [ngClass]="{ loading: loading }"
      color="primary"
      type="submit">
      <mat-spinner
        [diameter]="20"
        *ngIf="loading"></mat-spinner>
      <span *ngIf="!loading">Save</span>
    </button>
  </div>
</div>
