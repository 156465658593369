import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import { AudioType } from '@app/preferences/models/audio-type.enum';
import {
  TTSLanguage,
  TTSVoice,
  VoicemailFileGreeting,
  VoicemailGreeting,
  VoicemailGreetingAudioItem,
  VoicemailRecordingMethodType,
  VoicemailSettingPageType,
  VoicemailTTSGreeting,
} from '@app/preferences/models/voicemail.model';
import { Track } from '@app/shared/models/track.model';

@Component({
  selector: 'app-settings-voicemail-manage-greeting',
  templateUrl: './voicemail-manage-greeting.component.html',
  styleUrls: ['../../voicemail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicemailManageGreetingComponent implements OnChanges {
  @ViewChild('file') fileInput: ElementRef;
  @Input() ttsLanguages: TTSLanguage[] = [];
  @Input() ttsVoices: TTSVoice[] = [];
  @Input() index: number | string;
  @Input() audioType: AudioType = AudioType.greeting;
  @Input() audioItem: VoicemailGreeting | undefined;
  @Input() isRecording: boolean = false;
  @Input() recordingSeconds: number = 10;
  @Input() recordedFile: File | null = null;
  @Input() recordingTrack: Track | undefined;
  @Input() playingAudioId: string | null;
  @Input() user: Contact | undefined;
  @Input() audio: VoicemailGreetingAudioItem | null;
  @Output() selectedPageChanged = new EventEmitter<VoicemailSettingPageType>();
  @Output() selectedTTSLanguageChanged = new EventEmitter<TTSLanguage>();
  @Output() saveClickedTTS = new EventEmitter<VoicemailTTSGreeting>();
  @Output() saveClickedFile = new EventEmitter<VoicemailFileGreeting>();
  @Output() saveClickedRecord = new EventEmitter<VoicemailFileGreeting>();
  @Output() recorderClicked = new EventEmitter<AudioType>();
  @Output() playPreviewTtsClicked = new EventEmitter<{
    voice: TTSVoice;
    text: string;
  }>();
  @Output() stopPreview = new EventEmitter<null>();
  @Output() togglePlayRecordedFile = new EventEmitter<null>();
  @Output() togglePlayAudio = new EventEmitter<null>();
  @Output() deleteAudioClicked = new EventEmitter<null>();

  recordingMethod: VoicemailRecordingMethodType = 'TextToSpeech';
  fileToUpload: File | undefined;
  greetingName: string = '';
  isValid: boolean;
  isProcessing: boolean;
  recorderIsDisabled: boolean = false;
  showRecorder: boolean = false;

  isLoadingVoices = true;
  selectedTTSLanguage: TTSLanguage | undefined = undefined;
  selectedTTSVoice: TTSVoice | undefined = undefined;
  ttsScript: string = 'Hello, {firstname} {lastname} is unavailable. Please leave a message.';

  protected readonly AudioType = AudioType;
  protected readonly Number = Number;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['audioItem'] && this.audioItem) {
      this.greetingName = this.audioItem.name;
      this.recordingMethod = this.audioItem.recordingMethod;
      if (this.recordingMethod === 'TextToSpeech') {
        this.selectedTTSLanguage = this.ttsLanguages.find((lang) => lang.id === this.audioItem?.language);
        this.ttsScript = this.audioItem.message;
      }
    }
    if (changes['ttsVoices'] && this.ttsVoices.length > 0 && this.recordingMethod === 'TextToSpeech') {
      this.selectedTTSVoice =
        this.audioItem && this.audioItem.voice?.length > 0
          ? this.ttsVoices.find((voice) => voice.id === this.audioItem?.voice)
          : this.ttsVoices[0];
      this.isLoadingVoices = false;
    }
    if (
      changes['ttsLanguages'] &&
      changes['ttsLanguages'].currentValue.length > 0 &&
      this.recordingMethod === 'TextToSpeech'
    ) {
      const preferredLanguage = this.ttsLanguages.find((lang) => lang.id.includes(navigator.language));
      this.selectedTTSLanguage =
        this.audioItem && this.audioItem.language.length > 0
          ? this.ttsLanguages.find((lang) => lang.id === this.audioItem?.language)
          : preferredLanguage || this.ttsLanguages[0];
      this.selectedTTSLanguageChanged.emit(this.selectedTTSLanguage);
    }
    if (changes['isRecording']) {
      this.recorderIsDisabled = false;
    }
    if (changes['recordingTrack']) {
      this.changeDetector.detectChanges();
    }
    if (changes['user'] && !this.audioItem && this.user && this.audioType === AudioType.greeting) {
      this.ttsScript = `Hello, ${this.user.firstName} ${this.user.lastName} is unavailable. Please leave a message.`;
    }
    if (
      changes['audioType'] &&
      (!this.audioItem || !(this.audioItem.message?.length > 0)) &&
      this.audioType === AudioType.name
    ) {
      this.ttsScript = ``;
    }
    this.validate();
  }

  setSelectedPage(page: VoicemailSettingPageType) {
    this.selectedPageChanged.emit(page);
  }

  onFileSelect(event) {
    this.fileToUpload = event.target.files[0];
    this.validate();
  }

  onTtsLanguageChanged(event) {
    this.validate();
    this.isLoadingVoices = true;
    this.selectedTTSLanguageChanged.emit(event);
  }

  validate() {
    switch (this.recordingMethod) {
      case 'TextToSpeech': {
        this.validateTextToSpeech();
        break;
      }
      case 'Upload': {
        this.validateUpload();
        break;
      }
    }
  }

  validateTextToSpeech() {
    this.isValid =
      this.greetingName.length > 0 &&
      this.ttsScript.length > 0 &&
      this.selectedTTSVoice !== undefined &&
      this.selectedTTSLanguage !== undefined;
  }

  validateUpload() {
    this.isValid = this.greetingName.length > 0 && (this.fileToUpload !== undefined || this.recordedFile !== null);
  }

  handleSaveClicked() {
    if (this.recordingMethod === 'TextToSpeech') {
      if (this.selectedTTSVoice && this.selectedTTSLanguage) {
        this.isProcessing = true;
        this.saveClickedTTS.emit({
          description: this.greetingName,
          voice_id: this.selectedTTSVoice.id,
          source_type: 'tts',
          tts_script: this.ttsScript,
          voice_language: this.selectedTTSLanguage.id,
        });
      }
    } else if (this.recordingMethod === 'Upload' && this.fileToUpload) {
      this.isProcessing = true;
      this.saveClickedFile.emit({
        description: this.greetingName,
        file: this.fileToUpload,
        name: this.fileToUpload.name,
        index: this.index,
      });
    } else if (this.recordingMethod === 'Upload' && this.recordedFile) {
      this.isProcessing = true;
      this.saveClickedRecord.emit({
        description: this.greetingName,
        file: this.recordedFile,
        name: this.recordedFile.name,
        index: this.index,
      });
    }
  }

  deleteRecordedFile() {
    this.recordingSeconds = 0;
    this.recordingTrack = undefined;
    this.recordedFile = null;
    this.validate();
  }

  deleteAudioToEdit() {
    this.deleteAudioClicked.emit();
  }

  recordClickHandler() {
    this.recorderIsDisabled = true;
    this.recorderClicked.emit(this.audioType);
  }

  onRecordedFilePlayClicked() {
    this.togglePlayRecordedFile.emit();
  }

  playTtsPreview() {
    if (this.playingAudioId === 'voice') {
      this.stopPreview.emit();
    } else if (this.selectedTTSVoice) {
      this.playPreviewTtsClicked.emit({ voice: this.selectedTTSVoice, text: this.ttsScript });
    }
  }

  onRecordingMethodChanged() {
    if (this.recordingMethod === 'TextToSpeech' && !this.selectedTTSLanguage && this.ttsLanguages.length > 0) {
      this.selectedTTSLanguage = this.ttsLanguages[0];
      this.onTtsLanguageChanged(this.selectedTTSLanguage);
    }
    this.validate();
  }

  handleClickOnShowRecorder() {
    this.showRecorder = true;
  }

  handleClickOnUpload() {
    this.showRecorder = false;
    this.fileInput.nativeElement.click();
  }
}
