import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, tokenStorageKey } from '@app/auth/services/auth.service';
import { Channel, ChannelMember, ChannelMessage, DeletedChannelMember } from '@app/chat/models/channel.models';
import { ChimeSocketService } from '@app/chat/services/chime-socket.service';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { BaseStateService } from '@app/core/services/base.state.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { NotificationKeyBackend } from '@app/preferences/models/settings.models';
import { NotificationService } from '@app/preferences/services/notification.service';
import bufferDebounce from '@app/shared/utils/buffer-debounce';
import { DraftMessageContent } from '@app/sms/models/sms.models';
import { MessageService } from '@app/sms/services/message.service';
import { UnreadMessageService } from '@app/sms/services/unread-message.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defer,
  filter,
  finalize,
  firstValueFrom,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';

/**
 *
 *
 * @export
 * @class ChannelService
 * @extends {BaseStateService<Channel>}
 */
@Injectable({
  providedIn: 'root',
})
export class ChannelService extends BaseStateService<Channel> {
  /**
   * Set when a message contains no content (i.e. only attachments). Limitation of Chime
   * which doesn't allow messages with empty content
   */
  public static EmptyContentKeyword = '[attachment_only]';

  protected path: string = 'users/{me}/user/summary';

  /**
   * Logically, the API (and AWS Chime) treat all channels the same way. The SS API is what
   * distinguishes between Direct Messages and other Channels. Given this, to make consumption easier
   * for the rest of the application, create subjects for each type of message which can then
   * be subscribed to for updates to only that data type.
   *
   * This may be something we want to change on the API so there are separate endpoints for
   * Direct Messages and Chat Rooms as I can't think if a use case where we'd want to request
   * both at once?
   */
  private directMessageSubject = new BehaviorSubject<Channel[]>([]);
  private chatRoomSubject = new BehaviorSubject<Channel[]>([]);
  private messageServices = new Map<string, MessageService>();
  private directMessagesLookupByContactsHash = new Map<string, Channel>();
  private visibleChannelsSubject = new BehaviorSubject<Channel[]>([]);

  private messageSendRefCount = 0;
  private bufferedSocketMessages: Array<{ channelArn: string; message: ChannelMessage }> = [];

  public readonly directMessages$ = this.directMessageSubject.asObservable();
  public readonly visibleChannels$ = this.visibleChannelsSubject.asObservable();

  private profile: Contact | undefined;
  private currentUserId!: string;
  private refreshingChannelsFromSocketEvent = false;

  constructor(
    http: HttpClient,
    private authService: AuthService,
    private chimeSocketService: ChimeSocketService,
    private contactService: ContactService,
    private unreadMessageService: UnreadMessageService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {
    super(http);
    this.data$.subscribe((allChannels) => {
      const directMessages = allChannels.filter((c) => c?.type === 'Direct Message');
      const channels = allChannels.filter((c) => c?.type === 'Chat Room');

      // Update our lookup table for direct messages
      // TODO: We should look into having the API provide a hash key for each channel we can use for lookup instead
      // of constructing on our end.
      this.directMessagesLookupByContactsHash = new Map();
      for (const channel of directMessages) {
        if (!channel.members || channel.members.length === 0) {
          continue;
        }
        const hashKey = this.hashKeyForContacts(channel.members);
        this.directMessagesLookupByContactsHash.set(hashKey, channel);
      }

      this.directMessageSubject.next(directMessages);
      this.chatRoomSubject.next(channels);

      this.updateVisibleChannelsList();
    });

    this.contactService.currentUser$.subscribe((contact) => {
      this.profile = contact;
    });

    this.authService.jwtClaims.subscribe((value) => {
      if (value) {
        this.currentUserId = value.sub;
      } else {
        this.clearUserData();
      }
    });

    // Observe incoming channel details events and add them to our channels array
    this.chimeSocketService.channelDetailsSubject$.pipe(bufferDebounce(500)).subscribe((details) => {
      // TODO: If we receive 50 or more channels we probably need to make a call to /user/summary to get the rest of the channels
      const bufferedChannels = details.map((detail) => detail.channel);
      const channels: Channel[] = [...this.getData()];

      // Upsert each channel we've received from the socket
      for (const bufferedChannel of bufferedChannels) {
        const existingChannelIndex = channels.findIndex((channel) => channel.id === bufferedChannel.id);
        if (existingChannelIndex === -1) {
          channels.push(bufferedChannel);
        } else {
          channels.splice(existingChannelIndex, 1, bufferedChannel);
        }
      }
      this.setData(channels);

      for (const { channel, messages } of details) {
        if (messages?.length > 0) {
          const messageService = this.getMessageService(channel.channelArn, false);
          messageService.updateLatestMessageIfNecessary(messages[0]);
        }
      }
    });

    this.chimeSocketService.createChannelSubject$.subscribe(() => {
      if (this.refreshingChannelsFromSocketEvent) {
        return;
      }

      this.refreshingChannelsFromSocketEvent = true;
      super.getHttpData().subscribe((channels) => {
        this.refreshingChannelsFromSocketEvent = false;

        const channelsWithIdAndType: Channel[] = channels.map((channel) => {
          const type: 'Chat Room' | 'Direct Message' =
            channel.metadata && channel.metadata.length > 0 ? JSON.parse(channel.metadata)['type'] : 'Direct Message';
          return { ...channel, id: channel.channelArn.split('/').pop()!, type };
        });
        this.setData(channelsWithIdAndType);
      });
    });

    // Observe incoming channel messages (filtering out messages sent by this current user) and add them
    // to the corresponding message service.
    this.chimeSocketService.channelMessageSubject$
      .pipe(filter(({ message }) => message?.type === 'STANDARD'))
      .subscribe(({ channelArn, message }) => {
        if (this.messageSendRefCount > 0) {
          this.bufferedSocketMessages.push({ channelArn, message });
        } else {
          this.processChimeSocketMessage(channelArn, message);
        }
      });
  }

  private processChimeSocketMessage(channelArn: string, message: ChannelMessage) {
    console.log(`chat: chime socket message received ${message.messageId} content: ${message.content.slice(0, 4)}`);

    const channels = [...this.getData()];
    const channelIndex = channels.findIndex((channel) => channel.channelArn === channelArn);
    const messageService = this.getMessageService(channelArn, false);
    messageService.updateLatestMessageIfNecessary(message);

    // Don't do anything with the message if we don't yet have a channel for it.
    if (channelIndex === -1) {
      return;
    }
    const channel: Channel = channels[channelIndex];
    if (!channel.mute) {
      // Add the message to the proper channel if it doesn't already exist.
      const result = messageService.upsertMessage(message.messageId, message);
      if (result === 'inserted') {
        this.notificationService.sendNotification(
          `New Chat Message from ${message.sender.name}`,
          message.content,
          NotificationKeyBackend.Chat
        );
      }
    }

    channels[channelIndex] = { ...channels[channelIndex], lastMessageTimestamp: message.createdTimestamp };
    this.setData(channels);
  }

  private incrementMessageSendRefCount() {
    this.messageSendRefCount += 1;
  }

  private decrementMessageSendRefCount() {
    const shouldProcessBuffer = this.messageSendRefCount === 1;
    this.messageSendRefCount = Math.max(0, this.messageSendRefCount - 1);

    if (shouldProcessBuffer) {
      for (const { channelArn, message } of this.bufferedSocketMessages) {
        this.processChimeSocketMessage(channelArn, message);
      }
      this.bufferedSocketMessages = [];
    }
  }

  clearUserData() {
    this.setData([]);
    this.messageServices.clear();
    this.directMessageSubject.next([]);
    this.chatRoomSubject.next([]);
    this.directMessagesLookupByContactsHash.clear();
  }

  override getHttpData(): Observable<Channel[]> {
    // We rely on prefetch now via the chime socket so just return whatever we have currently here. If we need to fetch
    // from the API, call `super` explicitly.
    // Use `defer` so value provided to the subscriber is the most up-to-date value. Without defer, `this.source.value` is captured
    // when the function is called and it may change elsewhere before the observable is subscribed to.
    return defer(() => of(this.source.value));
  }

  protected override setData(data: Channel[]): void {
    const sortedChannels = data.sort((lhs, rhs) => {
      if (lhs.lastMessageTimestamp && rhs.lastMessageTimestamp) {
        return rhs.lastMessageTimestamp.localeCompare(lhs.lastMessageTimestamp);
      } else if (lhs.lastMessageTimestamp || rhs.lastMessageTimestamp) {
        return lhs.lastMessageTimestamp ? -1 : 1;
      } else {
        // This sort doesn't provide any semantic value to the user, but it will result in a deterministic sort across
        // channels with no messages (which really shouldn't be happening anyway).
        return lhs.channelArn.localeCompare(rhs.channelArn);
      }
    });
    super.setData(sortedChannels);
  }

  public createChannel(memberIds: string[]): Observable<{ channelArn: string }> {
    console.log(`chat: creating channel with members: ${memberIds.join(',')}`);

    // Ensure none of the memberIds correspond to contacts with a scope of 'No Portal' and also virtual 'Deleted User' contact
    const noPortalContact = memberIds
      .filter(
        (contactId) =>
          contactId !== DeletedChannelMember.id && this.contactService.isContactForVoiceCallsOnly(contactId)
      )
      .map((contactId) => this.contactService.contactById(contactId));

    if (noPortalContact[0]) {
      const contactName = noPortalContact[0].fullName;
      return throwError(
        () => new Error(`Cannot start a Chat with ${contactName}. This contact is configured for voice calls only.`)
      );
    }

    return this.post<{ channelArn: string }>('users/{me}/channels', {
      direct_message: true,
      members: memberIds,
    }).pipe(
      mergeMap((value) => combineLatest([of(value), super.getHttpData()])), // fetch using super since we don't want the side effects of this class's overridden implementation of the method
      tap(([{ channelArn }, channels]) => {
        // Inject the channel into our channel list
        const allChannels = [...this.source.getValue()];
        const addedChannel = channels.find((channel) => channel.channelArn === channelArn);
        if (addedChannel) {
          addedChannel.id = channelArn.split('/').pop()!;
          addedChannel.type = 'Direct Message';
          if (!allChannels.some((channel) => channel.id === addedChannel.id)) {
            allChannels.push(addedChannel);
          }
        }
        this.setData(allChannels); // will cause channels to be sorted again. Not the most efficient, but should work just fine for the amount of data we're dealing with
      }),
      map(([value]) => value)
    );
  }

  public setFavoriteStatus(id: string, state: boolean): void {
    this.updateField(id, 'favorite', state);
  }

  public setMuteStatus(id: string, state: boolean): void {
    this.updateField(id, 'mute', state);
  }

  /**
   * Returns a channel in cache contains exactly the memberIds (if it exists)
   * @param members
   */
  public getCachedChannelWithMembers(members: ChannelMember[] | Contact[]): Channel | null {
    const channels = this.getData();
    const inputHashKey = this.hashKeyForContacts(members);
    return (
      channels.find((channel) => channel.members && this.hashKeyForContacts(channel.members) === inputHashKey) || null
    );
  }

  public getCachedChannelWithArn(channelArn: string): Channel | null {
    return this.getData().find((channel) => channel.channelArn === channelArn) || null;
  }

  public getCachedChannelWithId(channelId: string): Channel | null {
    return this.getData().find((channel) => channel.id === channelId) || null;
  }

  // Messages
  public getMessageService(channelArn: string, shouldRefreshData = false): MessageService {
    if (!this.messageServices.has(channelArn)) {
      this.messageServices.set(channelArn, new MessageService(this.httpClient, channelArn));
    }

    const service = this.messageServices.get(channelArn)!;
    if (shouldRefreshData) {
      service.fetchMessageHistory('NEXT');
    }

    return service;
  }

  public toggleFavorite(channel: Channel): Observable<unknown> {
    this.setFavoriteStatus(channel.id, !channel.favorite);
    return this.post(
      'users/{me}/channels',
      {
        channelArn: channel.channelArn,
        favorite: !channel.favorite,
      },
      { method: 'PUT' }
    ).pipe(
      catchError(() => {
        this.setFavoriteStatus(channel.id, channel.favorite);
        const error = new Error('API call failed');
        return throwError(() => error);
      })
    );
  }

  public toggleMute(channel: Channel): Observable<unknown> {
    this.setMuteStatus(channel.id, !channel.mute);
    return this.post(
      'users/{me}/channels',
      {
        channelArn: channel.channelArn,
        mute: !channel.mute,
      },
      { method: 'PUT' }
    ).pipe(
      catchError(() => {
        this.setMuteStatus(channel.id, channel.mute);
        const error = new Error('API call failed');
        return throwError(() => error);
      })
    );
  }

  public getContactsForChannel(channel: Channel): Contact[] {
    const members = this.getMembersExcludingLocalUser(channel);
    return members.flatMap((member) => {
      const contact = this.contactService.contactById(member.id);
      return contact ? [contact] : [];
    });
  }

  public getMembersExcludingLocalUser(channel?: Channel, sorted = true): ChannelMember[] {
    if (!channel || !channel.members) {
      return [];
    }

    const localUserArn = this.authService.jwtClaims.getValue()?.chimeArn;
    const members = channel.members.filter((member) => member.arn !== localUserArn);
    return sorted
      ? members.sort((lhs, rhs) => {
          // put deleted users last
          if (lhs.id === DeletedChannelMember.id) {
            return 1;
          } else if (rhs.id === DeletedChannelMember.id) {
            return -1;
          } else {
            return lhs.name.localeCompare(rhs.name);
          }
        })
      : members;
  }

  public getDisplayNameForChannel(channel?: Channel | null): string {
    if (!channel) {
      return '';
    }
    return channel.type === 'Chat Room' ? channel.name : this.getMemberNamesForDisplay(channel);
  }

  /**
   *
   * @param channel - The channel whose members we should display
   * @param truncateAfter - The number of members we should show names before truncating with a "+N-trncateAfter" string (Only valid for channels with more than 2 total members)
   * @returns Concatenated string of member names
   */
  public getMemberNamesForDisplay(channel: Channel, truncateAfter?: number): string {
    if (!channel.members) {
      return '';
    }

    const sortedMembersWithoutLocalUser = this.getMembersExcludingLocalUser(channel);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getFirstWord = (string_: string): string => {
      return string_.split(' ').find((value) => value.length > 0) || string_;
    };

    if (sortedMembersWithoutLocalUser.length === 1) {
      return sortedMembersWithoutLocalUser[0].name;
    } else if (sortedMembersWithoutLocalUser.length === 2) {
      return sortedMembersWithoutLocalUser.map((member) => getFirstWord(member.name)).join(' and ');
    } else {
      const remainder = truncateAfter ? Math.min(truncateAfter, sortedMembersWithoutLocalUser.length) : undefined;

      // eslint-disable-next-line unicorn/prefer-ternary
      if (remainder) {
        // Append +N-truncateAfter if applicable
        return (
          sortedMembersWithoutLocalUser
            .slice(0, remainder)
            .map((member) => getFirstWord(member.name))
            .join(', ') +
          (remainder < sortedMembersWithoutLocalUser.length ? `, +${sortedMembersWithoutLocalUser.length - 2}` : '')
        );
      } else {
        return sortedMembersWithoutLocalUser.map((member) => getFirstWord(member.name)).join(', ');
      }
    }
  }

  public sendDirectMessage(
    draft: DraftMessageContent,
    channelArn: string
  ): Observable<{ channelArn: string; messageId: string } | undefined> {
    /**
     * Insert the message into the Message service's list optimistically. If a failure occurs
     * for whatever reason, the message will have its status updated to indicate it failed to send.
     *
     * MessageServices are indexed by channelArn. Since we send direct messages based on contacts, iterate over all the direct message channels
     * and look for one where the list of members matches the passed in list of contacts. If we don't find anything, it means this is a new direct message
     * and the UI will redirect to the channel at which point it should load correctly. If we _do_ find something, add the message content optimistically.
     */

    console.log(`chat: sending message content: ${draft.content.slice(0, 4)}`);
    const messageService = this.getMessageService(channelArn, false);
    const userCreatedMessage = messageService.insertUserCreatedMessage(
      draft,
      this.currentUserId,
      this.profile!.fullName
    );

    const body = {
      channel_arn: channelArn,
      content: draft.content,
      metadata: userCreatedMessage.metadata,
    };

    this.incrementMessageSendRefCount();
    return this.post('users/{me}/messages/send', body).pipe(
      map((value) => value as { channelArn: string; messageId: string }),
      tap((value) => {
        console.log(`chat: sent messageId: ${value.messageId} content: ${draft.content.slice(0, 4)}`);
        messageService.upsertMessage(userCreatedMessage.messageId, {
          ...userCreatedMessage,
          messageId: value.messageId,
        });
        this.unreadMessageService.updateReadMarker(value.channelArn);
      }),
      catchError(() => {
        const metadata = MessageService.serializeMessageMetadata(draft, 'FAILURE');
        const message: ChannelMessage = { ...userCreatedMessage, metadata };
        messageService.upsertMessage(message.messageId, message);
        return of();
      }),
      finalize(() => {
        this.decrementMessageSendRefCount();
      })
    );
  }

  public retrySendMessage(message: ChannelMessage, channel: Channel) {
    // Remove the message and try sending it again.
    const metadata = MessageService.deserializeMessageMetadata(message.metadata);
    const draft: DraftMessageContent = {
      content: message.content,
      media: metadata?.media || [],
    };

    const messageService = this.getMessageService(channel.channelArn, false);
    messageService.removeMessage(message.messageId);

    return this.sendDirectMessage(draft, channel.channelArn);
  }

  private hashKeyForContacts(contacts: ChannelMember[] | Contact[]): string {
    // Convert the list of contacts into a string of contact ids we'll use for comparison.
    return contacts
      .map((contact) => contact.id)
      .filter((id) => id !== this.currentUserId) // filter out existing user
      .sort()
      .join(':');
  }

  public findChannelByMemberId(id: string): Channel | null {
    const privateChannelList = this.source.value.filter((channel) => channel.members && channel.members.length === 2);
    for (const channel of privateChannelList) {
      const list = channel.members?.filter((member: ChannelMember) => member.id === id) || [];
      if (list.length > 0) {
        return channel;
      }
    }
    return null;
  }

  public async findOrCreateChannelByMemberId(memberId: string): Promise<{ channelArn: string }> {
    const currentChannel = this.findChannelByMemberId(memberId);
    let channelArn = currentChannel?.channelArn;
    if (!channelArn) {
      const result = await firstValueFrom(this.createChannel([memberId]));
      channelArn = result.channelArn;
    }
    return { channelArn };
  }

  public deleteChannel(channelArn: string) {
    return this.delete(`users/{me}/channels`, { channel_arn: channelArn });
  }

  public archiveChannel(channelArn: string) {
    return this.archiveChannelAPI(channelArn, true);
  }

  private archiveChannelAPI(channelArn: string, withUpdate: boolean) {
    return this.post(`users/{me}/channels`, { channelArn: channelArn, close: true }, { method: 'PUT' }).pipe(
      tap(() => {
        if (withUpdate) {
          this.updateVisibleChannelsList();
        }
      })
    );
  }

  public getChannels(): Observable<Channel[]> {
    return this.get(`users/{me}/user/summary`);
  }

  public renameChannelName(channelInfo: { channelArn: string; name: string }) {
    return this.post(
      `users/{me}/channels`,
      { channelArn: channelInfo.channelArn, name: channelInfo.name },
      { method: 'PUT' }
    );
  }

  public archiveChannels(channels: Channel[]) {
    return forkJoin(channels.map((channel) => this.archiveChannelAPI(channel.channelArn, false))).pipe(
      catchError((error) => {
        console.log('Chat conversation can not be closed, there was an error');
        return error;
      }),
      finalize(() => {
        this.updateVisibleChannelsList();
      })
    );
  }

  private async updateVisibleChannelsList() {
    const currentUser: { userId: string } | null = this.localStorageService.get(tokenStorageKey);
    const channels = this.getData();

    if (channels.length > 0 && currentUser) {
      try {
        const response = await firstValueFrom(this.getChannels());
        if (response) {
          response.forEach((c) => {
            const index = channels.findIndex((ch) => ch.channelArn === c.channelArn);
            if (channels[index]) {
              channels[index].name = c.name;
              channels[index].metadata = c.metadata;

              if (c.metadata) {
                const deletedUsers = JSON.parse(c.metadata).deleted_users;
                this.updateDeletedUsers(channels[index], deletedUsers);
              }
            }
          });

          const archivedChannelsArn = new Set(
            response
              .filter((c) => (JSON.parse(c.metadata!).closed_users ?? []).includes(currentUser.userId))
              .map((c) => c.channelArn)
          );

          const filteredChannels = channels.filter((ch) => !archivedChannelsArn.has(ch.channelArn));

          this.visibleChannelsSubject.next(filteredChannels);
        }
      } catch (error) {
        console.log('update visible channels error:', error);
      }
    }
  }

  private updateDeletedUsers(channel: Channel, deletedUsers: string[] | undefined) {
    if (deletedUsers?.length) {
      channel.members = channel.members ?? []; // make sure it is defined
      const existingDeletedUsers = channel.members.filter((member) => member.arn === DeletedChannelMember.arn);
      for (let index = existingDeletedUsers.length; index < deletedUsers.length; index++) {
        channel.members.push(DeletedChannelMember);
      }
    }
  }
}
