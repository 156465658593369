import { Contact } from '@app/contacts/models/contact';

export interface CallData {
  id: string;
  phone: string;
  displayName: string;
  time: Date;
}

export interface ContactParams {
  contact: Contact;
  time: Date;
  callId: string;
  isOngoingOpen: boolean;
}

export enum CallStatus {
  Connecting = 'Connecting',
  Active = 'Active',
  CallEnded = 'Call Ended',
  Calling = 'Calling',
  Held = 'Held',
  Ringing = 'Ringing',
  Merge = 'Merge',
  Rejected = 'Rejected',
}

export enum CallAction {
  Bye = 'bye',
  Dial = 'dial',
  Established = 'Established',
  HoldCall = 'holdCall',
  OnInvite = 'OnInvite',
  OnAnswer = 'OnAnswer',
  Reject = 'reject',
  RetrieveCall = 'retrieveCall',
  SupervisedTransfer = 'supervisedTransfer',
  Terminated = 'Terminated',
  Transfer = 'transfer',
  TransferComplete = 'transferComplete',
  Merge = 'Merge',
  Trying = 'trying',
  Ringing = 'ringing',
  changePhoneNumber = 'ChangePhoneNumber',
}
