import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { AnsweringRulesService } from '@app/preferences/services/answering-rules.service';
import { firstValueFrom } from 'rxjs';

import { AnsweringRule, AnsweringRuleType } from '../../../models/answering-rules.models';

@Component({
  selector: 'app-answering-rule',
  templateUrl: './answering-rule.component.html',
  styleUrls: ['./answering-rule.component.scss'],
})
export class AnsweringRuleComponent implements OnInit {
  @Input() answeringRule: AnsweringRule;

  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  protected readonly AppFeature = AppFeature;

  protected disabled = false;
  protected ruleType: AnsweringRuleType;
  protected ruleTypeString: string;

  protected editable = false;

  constructor(
    protected answeringRuleService: AnsweringRulesService,
    protected appConfigService: AppConfigService,
    private snackBar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.editable = this.appConfigService.features[AppFeature.ModifyAnsweringRules];
    this.answeringRule.simRing.otherDestinations.sort((a, b) => a.delay - b.delay);
    this.ruleType = this.answeringRuleService.answeringRuleType(this.answeringRule);
    switch (this.ruleType) {
      case AnsweringRuleType.DoNotDisturb: {
        this.ruleTypeString = 'Do Not Disturb';
        break;
      }
      case AnsweringRuleType.ForwardAllCalls: {
        this.ruleTypeString = 'Forward All Calls';
        break;
      }
      case AnsweringRuleType.SimultaneousRing: {
        this.ruleTypeString = 'Simultaneous Ring';
        break;
      }
      case AnsweringRuleType.OnlyPrimaryDeskphone: {
        this.ruleTypeString = 'Only Primary Deskphone';
      }
    }
  }

  protected stateChanged(event: MatSlideToggleChange) {
    this.updateState(event.checked);
  }

  protected editRule(): void {
    this.edit.emit();
  }

  protected deleteRule(): void {
    this.delete.emit();
  }

  private async updateState(newState: boolean) {
    this.disabled = true;
    this.answeringRule.enabled = newState;
    const rule = { ...this.answeringRule };
    delete rule.timeFrame;
    delete rule.priority;

    try {
      await firstValueFrom(this.answeringRuleService.updateRule(this.answeringRule.timeFrame!, rule, false));
      this.snackBar.open('New rule state was saved.', 'OK');
    } catch (error) {
      console.error('Answering rule state change error:', error);
      this.answeringRule.enabled = !newState; // revert state
      this.snackBar.open('New rule state was not saved.', 'OK');
    }

    this.disabled = false;
  }
}
