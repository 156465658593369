import { Injectable } from '@angular/core';
import { ChannelService } from '@app/chat/services/channel.service';
import { ChimeSocketService } from '@app/chat/services/chime-socket.service';
import { ContactService } from '@app/contacts/services/contact.service';
import { SMSMessage } from '@app/sms/models/sms.models';

import { WsService } from './ws.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    chimeSocketService: ChimeSocketService,
    contactService: ContactService,
    private channelService: ChannelService,
    private wsService: WsService
  ) {
    chimeSocketService.channelMessageSubject$.subscribe(({ channelArn, message }) => {
      if (!contactService.currentUser) {
        return;
      }

      const type = message.sender.id === contactService.currentUser.id ? 'sent' : 'received';
      this.messageEvent(channelArn, type);
    });

    /* SMS */
    // Observe incoming sms messages
    this.wsService.socket.on('SMSMessageReceived', (message: SMSMessage) => {
      //Determine if this was a group message by looking at the number of recipients
      const eventName = message.recipients.length > 1 ? 'group_sms_received' : 'sms_received';
      gtag('event', eventName, {});
    });

    // Observe outgoing sms messages
    this.wsService.socket.on('SMSMessageSent', (message: SMSMessage) => {
      //Determine if this was a group message by looking at the number of recipients
      const eventName = message.recipients.length > 1 ? 'group_sms_sent' : 'sms_sent';
      gtag('event', eventName, {});
    });

    // Observe outbound Fax messages
    this.wsService.socket.on('FaxSent', () => {
      gtag('event', 'fax_sent', {});
    });

    // Observe inbound Fax messages
    this.wsService.socket.on('FaxReceived', () => {
      gtag('event', 'fax_received', {});
    });
  }

  /* Inbound call */
  voiceCallInbound() {
    gtag('event', 'voice_call_inbound', {});
  }

  /* Outbound call */
  voiceCallOutbound() {
    gtag('event', 'voice_call_outbound', {});
  }

  voiceCallNoClickRinging() {
    console.log("googleAnalyticsService --> sending 'no_click_phone_ringing' event");
    gtag('event', 'no_click_phone_ringing', {});
  }

  dialSelectedContact() {
    gtag('event', 'dial_selected_contact', {});
  }

  dialFromDialPad() {
    gtag('event', 'dial_from_dial_pad', {});
  }

  /* Call transferred */
  callTransferred(assisted: boolean) {
    if (assisted) {
      gtag('event', 'transfer_assisted', {});
    } else {
      gtag('event', 'transfer_unassisted', {});
    }
  }

  /* Voicemail Events */
  voicemailViewed() {
    gtag('event', 'voicemail_viewed', {});
  }

  voicemailPlayed() {
    gtag('event', 'voicemail_played', {});
  }

  voicemailDeleted() {
    gtag('event', 'voicemail_delete_detail', {});
  }

  voicemailCallBackFromList() {
    gtag('event', 'voicemail_call_back_vmlist', {});
  }

  voicemailDeleteFromList() {
    gtag('event', 'voicemail_delete_vmlist', {});
  }

  voicemailDownloaded() {
    gtag('event', 'voicemail_download', {});
  }

  voicemailMarkUnread() {
    gtag('event', 'voicemail_mark_unread_detail', {});
  }

  voicemailCallback() {
    gtag('event', 'voicemail_call_back_detail', {});
  }

  voicemailCallbackNumber() {
    gtag('event', 'voicemail_call_back_number_detail', {});
  }

  voicemailSmsDetail() {
    gtag('event', 'voicemail_sms_detail', {});
  }

  voicemailChatDetail() {
    gtag('event', 'voicemail_chat_detail', {});
  }

  /* Team Messaging Events */
  private messageEvent(channelArn: string, type: 'received' | 'sent') {
    const channel = this.channelService.getCachedChannelWithArn(channelArn);
    if (!channel) {
      return;
    }

    const isGroupMessage = (channel.members || []).length > 2;
    let eventName: string;
    if (type === 'received') {
      eventName = isGroupMessage ? 'group_message_received' : 'message_received';
    } else {
      eventName = isGroupMessage ? 'group_message_sent' : 'message_sent';
    }

    gtag('event', eventName, {});
    console.log(`googleAnalyticsService --> ${eventName}`);
  }

  /* Contact Events */
  contactsViewed() {
    gtag('event', 'contacts_viewed', {});
  }

  contactsSearched() {
    gtag('event', 'contacts_searched', {});
  }

  //Clean up the page title so we can tell if the page was refreshed or the nav menu was clicked on
  pageView(page) {
    //Replace initial /
    let title = page.url.replace('/', '');

    //Check if the url is callHistory/history (url when callHistory refreshed)
    if (title.includes('callHistory/history')) {
      title = 'callHistory auto redirect';
    } else if (title.includes('/home')) {
      //URL contains /home when page is refreshed
      //Remove all / and replace '/home' with 'auto redirect' for the title so we know this is not from clicking in the nav menu
      title = title.replace('/home', ' auto redirect').replaceAll('/', ' ');
    } else {
      title = title.replaceAll('/', ' ');
    }

    gtag('event', 'page_view', {
      page_path: page.urlAfterRedirects,
      page_title: title,
    });
  }

  /* Call Recording Events */
  callRecording() {
    gtag('event', 'call_recording', {});
  }

  /* Fax Events */
  faxDownloaded() {
    gtag('event', 'fax_downloaded', {});
  }

  faxNew() {
    gtag('event', 'fax_new', {});
  }

  faxPrinted() {
    gtag('event', 'fax_printed', {});
  }

  faxCoverPageSet(page) {
    gtag('event', 'fax_cover_page', { cover_page_option: page });
  }

  faxFileNameSelected() {
    gtag('event', 'fax_file_name_selected', {});
  }

  faxMultipleFileNamesUsed(count) {
    gtag('event', 'fax_multiple_file_names_selected', { count: count });
  }

  faxDragAndDropUsed() {
    gtag('event', 'fax_drag_and_drop', {});
  }

  faxDIDUsed() {
    gtag('event', 'fax_DID', {});
  }

  faxSharedDIDUsed() {
    gtag('event', 'fax_shared_DID', {});
  }

  faxOpenedInSent() {
    gtag('event', 'fax_sent_opened', {});
  }

  faxOpenedInInbox() {
    gtag('event', 'fax_inbox_opened', {});
  }

  faxSentTabSelected() {
    gtag('event', 'fax_sent_tab_selected', {});
  }

  callParkedDirect() {
    gtag('event', 'parked_call_direct', {});
  }

  callParkedAutoPark() {
    gtag('event', 'parked_call_autopark', {});
  }

  callParkedRetrieved() {
    gtag('event', 'parked_call_retrieved', {});
  }

  //New Dialing Events
  newCallButton() {
    gtag('event', 'new_call_button', {});
  }

  newButton() {
    gtag('event', 'new_button', {});
  }

  newButtonNewCall() {
    gtag('event', 'new_button_new_call', {});
  }

  newButtonNewChat() {
    gtag('event', 'new_button_new_chat', {});
  }

  newButtonNewSms() {
    gtag('event', 'new_button_new_sms', {});
  }

  newButtonNewFax() {
    gtag('event', 'new_button_new_fax', {});
  }

  dialPadButton() {
    gtag('event', 'dial_pad_button', {});
  }

  //Preferences
  preferencesMenuOpen() {
    gtag('event', 'preferences_menu_open', {});
  }

  leftMenuMinimized() {
    gtag('event', 'left_menu_minimized', {});
  }

  leftMenuMaximized() {
    gtag('event', 'left_menu_maximized', {});
  }

  userStatusSet() {
    gtag('event', 'user_status_set', {});
  }

  userStatusCleared() {
    gtag('event', 'user_status_cleared', {});
  }

  dndEnabled() {
    gtag('event', 'dnd_enabled', {});
  }

  dndDisabled() {
    gtag('event', 'dnd_disabled', {});
  }

  disableCallsEnabled() {
    gtag('event', 'disable_calls_enabled', {});
  }

  disabledCallsDisabled() {
    gtag('event', 'disabled_calls_disabled', {});
  }

  disableNotifEnabled() {
    gtag('event', 'disable_not_if_enabled', {});
  }

  disabledNotifDisabled() {
    gtag('event', 'disabled_not_if_disabled', {});
  }

  preferencesSelected() {
    gtag('event', 'preferences_selected', {});
  }

  reportIssueSelected() {
    gtag('event', 'report_issue_selected', {});
  }

  pbxPortalSelected() {
    gtag('event', 'pbx_portal_selected', {});
  }

  signOutSelected() {
    gtag('event', 'sign_out_selected', {});
  }
}
