import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { AudioType } from '@app/preferences/models/audio-type.enum';
import {
  TTSLanguage,
  TTSVoice,
  VoicemailFileGreeting,
  VoicemailGreeting,
  VoicemailGreetingAudioItem,
  VoicemailSettingPageType,
  VoicemailSettingsModel,
  VoicemailTTSGreeting,
} from '@app/preferences/models/voicemail.model';
import { VoicemailSettingsFacadeService } from '@app/preferences/services/voicemail-settings-facade.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import { Track } from '@app/shared/models/track.model';
import { AudioUtil, microphonePermission } from '@app/shared/utils/audio.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay } from 'rxjs';
import { getWaveBlob } from 'webm-to-wav-converter';

@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss'],
})
@UntilDestroy()
export class VoicemailComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollArea') scrollArea: ElementRef;
  @ViewChild('greyMask') greyMask?: ElementRef<HTMLDivElement>;

  @Output() voicemailSettingsSaved = new EventEmitter();

  selectedPage: VoicemailSettingPageType = 'Main';
  voicemailSettings?: VoicemailSettingsModel;
  ttsLanguages: TTSLanguage[] = [];
  voicesOfSelectedLanguage: TTSVoice[] = [];
  greetings: VoicemailGreetingAudioItem[] = [];
  recordedName: VoicemailGreetingAudioItem | undefined = undefined;
  indexToEdit: number | string = -1;
  editAudioType: AudioType = AudioType.greeting;
  itemToEdit: VoicemailGreeting | undefined = undefined;
  audioToEdit: VoicemailGreetingAudioItem | null = null;
  playingAudio: AudioUtil | undefined;
  playingAudioId: string | null = null;
  recorder: MediaRecorder;
  stream: MediaStream | null = null;
  track: Track | undefined = { title: '', link: '' };
  isLoading = true;
  currentUser: Contact | undefined;
  recordingSeconds: number = 10;
  recordedFile: File | null = null;
  AudioType = AudioType;
  isRecording: boolean = false;
  recordingAudio: AudioUtil = new Audio() as AudioUtil;
  recordingIntervalId: NodeJS.Timer;

  protected editingDisabled = true;

  constructor(
    private facade: VoicemailSettingsFacadeService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private appConfigService: AppConfigService,
    protected contactService: ContactService
  ) {}

  ngOnInit() {
    this.editingDisabled = !this.appConfigService.features[AppFeature.ModifyVoicemail];
    this.isLoading = true;
    this.facade
      .getVoicemailSettings$()
      .pipe(untilDestroyed(this))
      .subscribe((settings: VoicemailSettingsModel | undefined) => {
        this.isLoading = false;
        this.voicemailSettings = settings;
      });
    this.facade.getVoicemailSettings();
    this.facade
      .getVoicemailSettingsSaved$()
      .pipe(untilDestroyed(this))
      .subscribe((success) => {
        if (success) {
          this.voicemailSettingsSaved.emit();
        }
      });
    this.facade
      .getVoicemailPinUpdated$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setSelectedPage('Main');
      });
    this.facade
      .getTTSLanguages$()
      .pipe(untilDestroyed(this))
      .subscribe((ttsLanguages: TTSLanguage[]) => {
        this.ttsLanguages = ttsLanguages;
      });
    this.facade
      .getTTSVoices$()
      .pipe(untilDestroyed(this))
      .subscribe((voices) => {
        this.voicesOfSelectedLanguage = voices;
      });
    this.facade
      .getAudioUploaded$()
      .pipe(untilDestroyed(this), delay(4500))
      .subscribe(() => {
        this.setSelectedPage('Main');
        this.voicemailSettings = undefined;
        this.facade.getVoicemailSettings();
      });
    this.facade
      .getGreetingList$()
      .pipe(untilDestroyed(this))
      .subscribe((greetings) => {
        this.greetings = greetings;
      });
    this.facade
      .getRecordedName$()
      .pipe(untilDestroyed(this))
      .subscribe((recordedName) => {
        this.recordedName = recordedName;
      });
    this.facade
      .getPlayingAudio$()
      .pipe(untilDestroyed(this))
      .subscribe((audio) => {
        this.playingAudio = audio;
        if (!this.playingAudio) {
          this.playingAudioId = null;
          this.recordingSeconds = 0;
          clearInterval(this.recordingIntervalId);
        }
      });
    this.facade
      .getCurrentUser$()
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.currentUser = user;
      });
    this.facade.getTTSLanguages();
  }

  ngAfterViewInit(): void {
    if (this.editingDisabled) {
      // pass scroll events from grey mask to main container
      this.greyMask?.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
        this.scrollArea.nativeElement.scrollBy(event.deltaX, event.deltaY);
      });

      // block mouse and keyboard events except Escape key and click on Cancel button
      this.scrollArea.nativeElement.addEventListener(
        'click',
        (event) => {
          if (event.target.parentElement.id !== 'cancel-button') {
            this.stopAndPreventDefault(event);
          }
        },
        true
      );
      this.scrollArea.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key !== 'Escape') {
          this.stopAndPreventDefault(event);
        }
      });
    }
  }

  private stopAndPreventDefault(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  setSelectedPage(page: VoicemailSettingPageType) {
    this.selectedPage = page;
    this.stopAudio();
    if (this.selectedPage === 'Main') {
      this.itemToEdit = undefined;
      this.audioToEdit = null;
      this.recordingSeconds = 0;
      this.recordingAudio = new Audio() as AudioUtil;
    }
    this.scrollArea.nativeElement.scrollTop = 0;
  }

  goToManageGreetingPage(audioType: AudioType) {
    this.editAudioType = audioType;
    if (this.itemToEdit) {
      this.indexToEdit = audioType === AudioType.name ? 0 : Number(this.itemToEdit.index);
    } else {
      if (this.editAudioType === AudioType.name) {
        this.indexToEdit = 0;
      } else {
        this.indexToEdit =
          this.greetings.length > 0
            ? Number(Math.max(...this.greetings.map((greeting) => Number(greeting.index)))) + 1
            : 1;
      }
    }
    this.track = undefined;
    this.recordedFile = null;
    this.setSelectedPage('ManageGreeting');
  }

  updatePin(pin: string) {
    this.facade.updatePin(pin);
  }

  uploadTTS(greeting: VoicemailTTSGreeting) {
    this.facade.uploadTTS(greeting, this.indexToEdit, this.editAudioType);
  }

  uploadAudioFile(greeting: VoicemailFileGreeting) {
    this.facade.uploadAudioFile(greeting, this.editAudioType);
  }

  downloadAudioFile(greeting: VoicemailGreetingAudioItem) {
    this.facade.downloadAudioFile(
      greeting.index,
      greeting.type === 'greeting' ? AudioType.greeting : AudioType.name,
      greeting.description
    );
  }

  async toggleRecording(audioType: AudioType) {
    if (this.isRecording) {
      this.stopInputSound();
    } else {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const { source, destination } = await this.setupAudioContextAndNodes(this.stream);
      await this.setupAndStartRecorder(destination, audioType === AudioType.greeting ? 20_000 : 10_000);
      source.connect(destination);
    }
  }

  async setupAndStartRecorder(destination: MediaStreamAudioDestinationNode, timeout: number) {
    if (await microphonePermission.check()) {
      this.recordingSeconds = 0;
      this.recorder = new MediaRecorder(destination.stream);
      this.recordingIntervalId = setInterval(() => this.recordingSeconds++, 1000);
      const chunks: Blob[] = [];
      this.isRecording = true;
      this.recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      this.recorder.onstop = async () => {
        clearInterval(this.recordingIntervalId);
        this.recordingAudio.pause();
        const audioBlob = new Blob(chunks, { type: 'audio/webm' });
        try {
          const wavBlob = await getWaveBlob(audioBlob, false);
          this.recordedFile = new File([wavBlob], 'UploadedGreeting.wav', { type: 'audio/wav' });
          this.isRecording = false;
        } catch {
          this.snackbarService.open('Error in audio record!');
          this.isRecording = false;
        }

        this.recordingAudio.src = URL.createObjectURL(audioBlob);
        this.track = {
          link: this.recordingAudio.src,
          title: 'RecordedGreeting.wav',
          duration: this.recordingSeconds,
        };
        this.recordingSeconds = 0;
      };

      this.recorder.start();

      setTimeout(() => {
        this.stopInputSound();
      }, timeout);
    }
  }

  private async setupAudioContextAndNodes(audio: MediaStream | HTMLAudioElement) {
    const audioContext = new AudioContext();
    const source =
      audio instanceof HTMLAudioElement
        ? audioContext.createMediaElementSource(audio)
        : audioContext.createMediaStreamSource(audio);
    const destination = audioContext.createMediaStreamDestination();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    source.connect(analyser);
    return { source, destination, analyser };
  }

  saveSettings(settings: VoicemailSettingsModel): void {
    if (!this.editingDisabled) {
      this.facade.saveVoicemailSettings(settings);
    }
  }

  getVoicesOfLanguage(language: TTSLanguage): void {
    this.facade.getTTSVoiceOfLanguage(language.id);
  }

  onAudioDeleteClicked(audioItem: VoicemailGreetingAudioItem): void {
    if (audioItem.type === 'greeting') {
      if (this.voicemailSettings?.confirmDeletion) {
        this.confirmDeleteGreeting(audioItem);
      } else {
        this.deleteGreeting(audioItem);
      }
    } else {
      this.deleteRecordedName();
    }
  }

  onGreetingEditClicked(greetingAudioItem: VoicemailGreetingAudioItem): void {
    this.audioToEdit = greetingAudioItem;
    this.itemToEdit = this.voicemailSettings?.greetings.find(
      (greeting) => greeting.index === greetingAudioItem.index.toString()
    );
    this.goToManageGreetingPage(AudioType.greeting);
  }

  onManageRecorderNameClicked(): void {
    if (this.recordedName) {
      this.audioToEdit = this.recordedName;
      this.itemToEdit = this.voicemailSettings?.recordedNames;
    }
    this.goToManageGreetingPage(AudioType.name);
  }

  confirmDeleteGreeting(toBeDeleted: VoicemailGreetingAudioItem): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Voicemail Greeting',
        description: 'Are you sure you want to delete this greeting?',
        buttons: [
          {
            label: 'Cancel',
            color: 'primary',
            action: () => null,
            type: ButtonType.matStrokedButton,
          },
          {
            label: 'Delete',
            color: 'warn',
            action: () => this.deleteGreeting(toBeDeleted),
            type: ButtonType.matRaisedButton,
            default: true,
          },
        ],
      },
    });
  }

  deleteGreeting(greetingAudioItem: VoicemailGreetingAudioItem): void {
    this.facade.deleteGreetingAudioItem(greetingAudioItem);
  }

  deleteRecordedName(): void {
    this.facade.deleteRecordedNameAudioItem();
  }

  async playAudio(audio: VoicemailGreetingAudioItem | null) {
    if (audio === null) {
      return;
    }
    this.playingAudioId = audio.type === 'greeting' ? audio.index : 'name';
    const audioUtil: AudioUtil = new Audio(audio.mediaUrl) as AudioUtil;
    await this.facade.playAudio(audioUtil);
  }

  stopAudio(): void {
    this.facade.stopAudio();
    this.recordingSeconds = 0;
    clearInterval(this.recordingIntervalId);
  }

  playTtsPreview(data: { voice: TTSVoice; text: string }): void {
    this.playingAudioId = 'voice';
    this.facade.previewTts(data.voice, data.text);
  }

  async playRecordedFile() {
    if (this.track && this.playingAudioId != 'recorded') {
      const audioUtil: AudioUtil = new Audio(this.track.link) as AudioUtil;
      await this.facade.playAudio(audioUtil);
      this.recordingSeconds = 0;
      this.recordingIntervalId = setInterval(() => this.recordingSeconds++, 1000);
      this.playingAudioId = 'recorded';
    } else {
      this.playingAudioId = null;
      this.recordingSeconds = 0;
      clearInterval(this.recordingIntervalId);
      this.facade.stopAudio();
    }
  }

  stopInputSound() {
    this.recordingAudio.pause();
    if (this.isRecording) {
      this.recorder.stop();
    }
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
      this.stream = null;
    }
  }

  async playAudioToEdit() {
    if (this.audioToEdit && this.playingAudioId != this.audioToEdit.index) {
      const audioUtil: AudioUtil = new Audio(this.audioToEdit.mediaUrl) as AudioUtil;
      await this.facade.playAudio(audioUtil);
      this.recordingSeconds = 0;
      this.recordingIntervalId = setInterval(() => this.recordingSeconds++, 1000);
      this.playingAudioId = this.audioToEdit.index;
    } else {
      this.playingAudioId = null;
      this.recordingSeconds = 0;
      clearInterval(this.recordingIntervalId);
      this.facade.stopAudio();
    }
  }
}
