import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@app/auth/services/user.service';
import { Contact, ContactUpdateResult, ExtensionTypes } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { SnackbarService } from '@app/core/services/snack-bar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { base64ToFile } from 'ngx-image-cropper';
import { catchError, firstValueFrom, throwError } from 'rxjs';

import { ApiChangePassword } from '../../models/profile';

@UntilDestroy()
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  isChangingPassword = false;
  edit = false;
  image = false;
  @Input() profile: Contact | undefined;
  imageChangedEvent!: Event;
  editProfileLoading = false;
  avatarLoading = false;
  selectedHourFormat: string | null;
  localTimezone: string;
  DIDNumbers: string[] = [];
  readonly defaultAvatarsBaseUrl = 'https://www.gravatar.com';
  @ViewChild('file') fileInput!: ElementRef;

  constructor(
    private snackBar: SnackbarService,
    private contactService: ContactService,
    private userService: UserService,
    private configService: AppConfigService
  ) {}

  ngOnInit(): void {
    this.localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.configService.data$.subscribe((config) => {
      if (config && config.settings.preferences) {
        this.selectedHourFormat = config.settings.preferences.hourFormat;
      }
    });
    this.DIDNumbers = this.configService.getDIDs();
  }

  fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
    this.image = true;
  }

  save(resultImage: string) {
    this.avatarLoading = true;
    this.profile!.avatar = resultImage;
    this.saveProfilePhoto(base64ToFile(resultImage));
    this.image = false;
  }

  removeAvatar() {
    this.avatarLoading = true;
    this.contactService
      .removeAvatar(this.profile!.id)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.onFailedProfileEdit(error))
      )
      .subscribe(() => {
        this.onSuccessProfileEdit();
        if (!this.profile?.firstName && !this.profile?.lastName) {
          this.profile!.avatar = 'assets/company.svg';
        } else {
          this.profile!.avatar = null;
        }
      });
  }

  onPasswordChange($event: ApiChangePassword) {
    this.userService
      .changePassword($event)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.snackBar.open('Password has been updated', 'ok');
        this.isChangingPassword = false;
      });
  }

  onPasswordChangeCanceled() {
    this.isChangingPassword = false;
  }

  getCoin(): string {
    return this.profile!.firstName?.charAt(0).toUpperCase() + this.profile!.lastName?.charAt(0).toUpperCase();
  }

  onDataSubmitted(result: ContactUpdateResult) {
    this.editProfileLoading = true;
    this.contactService
      .update(result.contact)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.onFailedProfileEdit(error))
      )
      .subscribe((response: Contact) => {
        this.edit = false;
        this.contactService.currentUserSubject.next(response);
        this.onSuccessProfileEdit();
      });
  }

  private saveProfilePhoto(avatar: Blob) {
    const formData = new FormData();
    formData.append('avatar', avatar);
    this.contactService
      .uploadContactAvatar(this.profile!, formData)
      .pipe(
        untilDestroyed(this),
        catchError((error) => this.onFailedProfileEdit(error))
      )
      .subscribe(() => {
        this.onSuccessProfileEdit();
      });
  }

  private onFailedProfileEdit(error) {
    this.editProfileLoading = false;
    this.avatarLoading = false;
    return throwError(error);
  }

  private onSuccessProfileEdit() {
    this.editProfileLoading = false;
    this.avatarLoading = false;
    this.snackBar.open('Profile has been updated', 'Ok');
  }

  onEditCanceled() {
    this.edit = false;
  }

  async onHourFormatChange() {
    await firstValueFrom(
      this.configService.updatePreferences({
        hourFormat: this.selectedHourFormat!,
        timezone: this.localTimezone,
      })
    );
  }

  protected readonly ExtensionTypes = ExtensionTypes;
}
