import { Injectable } from '@angular/core';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AudioType } from '@app/preferences/models/audio-type.enum';
import {
  TTSLanguage,
  TTSLanguagesDto,
  TTSVoice,
  VoicemailFileGreeting,
  VoicemailGreetingAudioItem,
  VoicemailSettingsModel,
  VoicemailTTSGreeting,
} from '@app/preferences/models/voicemail.model';
import { SettingsService } from '@app/preferences/services/settings.service';
import { AudioUtil } from '@app/shared/utils/audio.util';
import { saveAs } from 'file-saver-es';
import { Observable } from 'rxjs';

import { VoicemailSettingsApiService } from './voicemail-settings-api.service';
import { VoicemailSettingsStateService } from './voicemail-settings-state.service';

@Injectable()
export class VoicemailSettingsFacadeService {
  constructor(
    private api: VoicemailSettingsApiService,
    private state: VoicemailSettingsStateService,
    private settingsService: SettingsService,
    private contactService: ContactService
  ) {}

  getVoicemailSettings(): void {
    this.getCurrentUser();
    this.api.getVoicemailSettings().subscribe((response: VoicemailSettingsModel) => {
      this.state.updateVoiceMailSettings(response);
      this.getTTSLanguages();
      this.getGreetingsList();
      this.loadRecordedName();
    });
  }

  getVoicemailSettings$(): Observable<VoicemailSettingsModel | undefined> {
    return this.state.getVoiceMailSettings$();
  }

  getGreetingList$(): Observable<VoicemailGreetingAudioItem[]> {
    return this.state.getGreetingsList$();
  }

  deleteGreetingAudioItem(greetingAudioItem: VoicemailGreetingAudioItem) {
    this.api.deleteAudioFile(AudioType.greeting, greetingAudioItem.index).subscribe(() => {
      this.state.deleteGreetingAudioItem(greetingAudioItem);
    });
  }

  deleteRecordedNameAudioItem() {
    this.api.deleteAudioFile(AudioType.name, '1').subscribe(() => {
      this.state.setRecordedName();
    });
  }

  saveVoicemailSettings(settings: VoicemailSettingsModel): void {
    this.api.saveVoicemailSettings(settings).subscribe(() => {
      this.state.updateVoiceMailSettings(settings);
      this.updateVoicemailSettingsSaved(true);
    });
  }

  getVoicemailSettingsSaved$(): Observable<boolean> {
    return this.state.getVoicemailSettingsSaved$();
  }

  updateVoicemailSettingsSaved(successful: boolean): void {
    this.state.updateVoicemailSettingsSaved(successful);
  }

  getVoicemailPinUpdated$(): Observable<boolean> {
    return this.state.getVoicemailPinUpdated$();
  }

  updatePin(pin: string): void {
    this.api.updatePin(pin).subscribe(() => {
      this.state.setVoicemailPinUpdated();
    });
  }

  getTTSLanguages(): void {
    this.api.getTTSLanguages().subscribe((response: TTSLanguagesDto) => {
      this.state.setTTSLanguages$(response.languages);
    });
  }

  getTTSLanguages$(): Observable<TTSLanguage[]> {
    return this.state.getTTSLanguages$();
  }

  getTTSVoices$(): Observable<TTSVoice[]> {
    return this.state.getTTSVoices$();
  }

  getTTSVoiceOfLanguage(languageId: string): void {
    if (this.state.isVoiceOfLanguageLoaded(languageId)) {
      this.state.setTTSVoices(languageId);
    } else {
      this.api.getTTSVoices(languageId).subscribe((voices: TTSVoice[]) => {
        this.state.setTTSVoicesOfLanguage({ language: languageId, voices });
        this.state.setTTSVoices(languageId);
      });
    }
  }

  getCurrentUser() {
    this.contactService.currentUser$.subscribe((contact) => this.state.setCurrentUser(contact));
  }

  getCurrentUser$(): Observable<Contact | undefined> {
    return this.state.getCurrentUser$();
  }

  getAudioUploaded$(): Observable<boolean> {
    return this.state.getAudioUploaded$();
  }

  getRecordedName$(): Observable<VoicemailGreetingAudioItem | undefined> {
    return this.state.getRecordedName$();
  }

  setRecordedName(recordedName: VoicemailGreetingAudioItem | undefined) {
    this.state.setRecordedName(recordedName);
  }

  loadRecordedName(): void {
    this.api.getListOfRecordedNames().subscribe((recordedNames) => {
      this.setRecordedName(recordedNames.length > 0 ? recordedNames[0] : undefined);
    });
  }

  uploadTTS(audio: VoicemailTTSGreeting, index: number | string, audioType: AudioType) {
    this.api.uploadTTS(audio, index, audioType).subscribe(() => {
      this.state.setAudioUploaded();
    });
  }

  uploadAudioFile(greeting: VoicemailFileGreeting, audioType: AudioType) {
    if (greeting.file) {
      this.api
        .uploadAudio(
          greeting.name,
          greeting.description,
          greeting.index,
          audioType,
          greeting.file.name.split('.').pop() || 'wav'
        )
        .subscribe((res) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(greeting.file!);
          reader.addEventListener('load', () => {
            const blob = new Blob([reader.result!], { type: greeting.file!.type });
            this.api.uploadFileGreeting(blob, res.url).subscribe(() => {
              this.state.setAudioUploaded();
            });
          });
        });
    }
  }

  downloadAudioFile(index: string, type: AudioType, description: string) {
    this.api.downloadAudioFile(index, type).subscribe((response) => {
      saveAs(response, `${type}-${description}.wav`);
    });
  }

  getGreetingsList() {
    this.api.getListOfGreetings().subscribe((res) => {
      this.state.updateGreetingsList(res);
    });
  }

  previewTts(voice: TTSVoice, text: string) {
    this.api.previewTTS(voice, text).subscribe((response) => {
      const audioUtil: AudioUtil = new Audio(URL.createObjectURL(new Blob([response]))) as AudioUtil;
      void this.playAudio(audioUtil);
    });
  }

  getPlayingAudio$(): Observable<AudioUtil | undefined> {
    return this.state.getPlayingAudio$();
  }

  async playAudio(audio: AudioUtil) {
    audio.addEventListener('play', () => {
      this.state.setPlayingAudio(audio);
    });
    audio.addEventListener('ended', () => {
      this.state.setPlayingAudio();
    });
    await this.settingsService.playAudio(audio);
  }

  stopAudio() {
    this.state.setPlayingAudio();
  }
}
