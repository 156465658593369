import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, Observable, of, tap } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export abstract class BaseStateService<T extends { id?: string }> extends ApiService {
  protected abstract path: string;
  public readonly source: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public readonly data$ = this.source.asObservable();

  protected loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  protected errorSubject = new BehaviorSubject<boolean>(false);
  public hasError$ = this.errorSubject.asObservable();
  protected isLoaded = false;

  protected constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   *  Should only be called once in the main component somewhere
   */
  init(): void | Error {
    if (this.isLoaded) {
      return new Error('Should only ever be loaded once');
    }
    this.isLoaded = true;
  }

  refreshData(): Observable<T[]> {
    this.loadingSubject.next(true);
    return this.getHttpData().pipe(
      catchError(() => {
        this.errorSubject.next(true);
        return of([]);
      }),
      tap((data) => this.setData(data)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  /**
   * http request for voicemail
   */
  getHttpData(): Observable<T[]> {
    return this.get<T[]>(this.path);
  }

  public updateRead(id: string, state: boolean): void {
    this.updateField(id, 'read', state);
  }

  /**
   *
   * Delete an Item from the collection
   * @param {string} id
   */
  public removeItem(id: string): void {
    const newState = this.getData().filter((p) => p.id !== id);
    console.log(newState);
    this.setData(newState);
  }

  public addItemUnshift(item: T): void {
    const newState = [item, ...this.getData()];
    this.setData(newState);
  }

  getData(): T[] {
    return this.source.getValue();
  }

  protected setData(data: T[]): void {
    this.source.next(data);
  }

  protected updateField(id: string, field: string, state: number | string | boolean | []): void {
    const newState = this.getData().map((object) => {
      return undefined !== object.id && object.id == id ? { ...object, [field]: state } : object;
    });
    this.setData(newState);
  }
}
