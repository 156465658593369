import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudioType } from '@app/preferences/models/audio-type.enum';
import {
  TTSLanguagesDto,
  TTSVoice,
  VoicemailGreetingAudioItem,
  VoicemailSettingsModel,
  VoicemailTTSGreeting,
} from '@app/preferences/models/voicemail.model';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable()
export class VoicemailSettingsApiService {
  private _baseUrl: string = environment.gateway;
  constructor(private httpClient: HttpClient) {}

  getVoicemailSettings(): Observable<VoicemailSettingsModel> {
    return this.httpClient.get<VoicemailSettingsModel>(`${this._baseUrl}/users/{me}/voicemail/settings`);
  }

  saveVoicemailSettings(settings: VoicemailSettingsModel): Observable<{ success: boolean }> {
    return this.httpClient.put<{ success: boolean }>(`${this._baseUrl}/users/{me}/voicemail/settings`, settings);
  }

  updatePin(pin: string): Observable<{ success: boolean }> {
    const body = {
      pin,
    };
    return this.httpClient.post<{ success: boolean }>(`${this._baseUrl}/users/{me}/update-pin`, body);
  }

  getTTSLanguages(): Observable<TTSLanguagesDto> {
    return this.httpClient.get<TTSLanguagesDto>(`${this._baseUrl}/users/{me}/tts/languages`);
  }

  getTTSVoices(languageId: string): Observable<TTSVoice[]> {
    return this.httpClient.get<TTSVoice[]>(`${this._baseUrl}/users/{me}/tts/voices/${languageId}`);
  }

  uploadTTS(greeting: VoicemailTTSGreeting, index: number | string, audioType: AudioType): Observable<{ url: string }> {
    return this.httpClient.post<{ url: string }>(
      `${this._baseUrl}/users/{me}/audio/${audioType}/${index}/upload`,
      greeting
    );
  }

  previewTTS(voice: TTSVoice, text: string): Observable<Blob> {
    return this.httpClient.post(
      `${this._baseUrl}/users/{me}/tts/preview`,
      { text: text, voice: voice },
      { responseType: 'blob' }
    );
  }

  uploadAudio(
    name: string,
    description: string,
    index: number | string,
    audioType: AudioType,
    format: string
  ): Observable<{ url: string }> {
    return this.httpClient.post<{ url: string }>(`${this._baseUrl}/users/{me}/audio/${audioType}/${index}/upload`, {
      description,
      name,
      format: `audio/${format}`,
    });
  }

  uploadFileGreeting(blobfile: Blob, url: string): Observable<unknown> {
    return this.httpClient.put<unknown>(url, blobfile);
  }

  getListOfGreetings(): Observable<VoicemailGreetingAudioItem[]> {
    return this.httpClient.get<VoicemailGreetingAudioItem[]>(`${this._baseUrl}/users/{me}/audio/greeting/list`);
  }

  getListOfRecordedNames(): Observable<VoicemailGreetingAudioItem[]> {
    return this.httpClient.get<VoicemailGreetingAudioItem[]>(`${this._baseUrl}/users/{me}/audio/name/list`);
  }

  deleteAudioFile(audioType: AudioType, index: string): Observable<unknown> {
    return this.httpClient.delete(`${this._baseUrl}/users/{me}/audio/${audioType}/${index}`);
  }

  downloadAudioFile(index: string, type: AudioType): Observable<Blob> {
    return this.httpClient.get<Blob>(`${this._baseUrl}/users/{me}/audio/${type}/${index}/download`, {
      responseType: 'blob' as 'json',
    });
  }
}
