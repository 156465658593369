import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallHistoryStorageService } from '@app/call-history/services/call-history-storage.service';
import { EventData, EventName } from '@app/core/models/event-bus.models';
import { ApiService } from '@app/core/services/api.service';
import { EventBusService } from '@app/core/services/event-bus.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { LoggerService } from '@app/phone/services/logger.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '@environment/environment';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';

import { Jwt } from '../models/jwt';
import { TokenModel } from '../models/token-model';

const currentURL = new URL(window.location.href);
const authRedirectURL = `${currentURL.protocol}//${currentURL.host}/auth/login`;
export const tokenStorageKey = 'tokens';
export const authUrl = `${environment.authUrl}?scope=offline_access&client_id=${environment.applicationId}&response_type=code&redirect_uri=${authRedirectURL}`;
export const authLogoutUrl = `${environment.authLogoutUrl}?client_id=${environment.applicationId}&post_logout_redirect_uri=${currentURL.protocol}//${currentURL.host}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ApiService {
  protected override baseUrl = environment.jwtGateway;
  private isRefreshing = false;
  private refreshPromise: Promise<string> | null = null;

  jwtClaims = new BehaviorSubject<Jwt | null>(null);
  jwtService: JwtHelperService = new JwtHelperService();
  isAuthenticated$ = new ReplaySubject<boolean>(1);

  constructor(
    httpClient: HttpClient,
    private eventBusService: EventBusService,
    private storage: LocalStorageService,
    private callHistoryStorageService: CallHistoryStorageService,
    private loggerService: LoggerService
  ) {
    super(httpClient);
    merge(
      new Observable<boolean>((obs) => this.eventBusService.on(EventName.Login, () => obs.next(true))),
      new Observable<boolean>((obs) => this.eventBusService.on(EventName.Logout, () => obs.next(false)))
    ).subscribe((isAuthenticated) => this.isAuthenticated$.next(isAuthenticated));

    this.jwtClaims
      .pipe(
        distinctUntilChanged((previous, current) => previous?.iat === current?.iat),
        filter((claims) => !!claims)
      )
      .subscribe(() => {
        this.eventBusService.emit(new EventData(EventName.Login));
      });
  }

  userLogin(username: string, password: string): Observable<boolean> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('client_id', environment.applicationId)
      .set('client_secret', environment.applicationSecret)
      .set('scope', 'offline_access');

    return this.post<TokenModel>('token', body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    }).pipe(
      map((data) => {
        this.setAccessTokens(data);
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  async getRefreshedAccessToken(updateClaims = true): Promise<string> {
    let tokens = this.getAccessTokens();
    if (tokens) {
      const isExpired = this.jwtService.isTokenExpired(tokens.access_token);
      if (isExpired) {
        if (this.isRefreshing) {
          // If a refresh operation is already in progress, return the promise for that operation
          return this.refreshPromise ?? '';
        }

        this.isRefreshing = true;
        this.refreshPromise = new Promise(async (resolve, reject) => {
          try {
            if (!tokens) {
              throw new Error('No tokens found');
            }
            const refreshedTokens = await this.refreshToken(tokens).toPromise();
            if (!refreshedTokens) {
              throw new Error('Could not refresh token');
            }
            this.setAccessTokens(refreshedTokens);
            if (updateClaims) {
              this.updateClaims(refreshedTokens);
            }
            tokens = refreshedTokens;
            resolve(tokens.access_token);
          } catch (error) {
            this.clearClaims();
            this.clearAccess();
            this.eventBusService.emit(new EventData(EventName.Logout));
            reject(error);
          }
        });
      } else {
        if (updateClaims) {
          this.updateClaims(tokens);
        }
        return tokens.access_token;
      }
    } else {
      this.clearClaims();
      this.eventBusService.emit(new EventData(EventName.Logout));
      return '';
    }

    try {
      return await this.refreshPromise;
    } catch (error) {
      throw error;
    } finally {
      this.isRefreshing = false;
      this.refreshPromise = null;
    }
  }

  refreshToken(payload: TokenModel): Observable<TokenModel> {
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', environment.applicationId)
      .set('client_secret', environment.applicationSecret)
      .set('refresh_token', payload.refresh_token)
      .set('scope', 'offline_access');

    return this.post<TokenModel>('token', body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    }).pipe(
      map((data) => {
        data.refresh_token = payload.refresh_token;
        return data;
      })
    );
  }

  exchangeCode(code: string): Observable<boolean> {
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', environment.applicationId)
      .set('client_secret', environment.applicationSecret)
      .set('redirect_uri', authRedirectURL)
      .set('code', code);

    return this.post<TokenModel>('token', body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    }).pipe(
      map((data) => {
        this.setAccessTokens(data);
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  updateClaims(token: TokenModel): void {
    const decodedToken = this.jwtService.decodeToken(token.access_token);
    if (decodedToken === null || typeof decodedToken === 'object') {
      this.jwtClaims.next(decodedToken);
    }
  }

  clearClaims(): void {
    this.jwtClaims.next(null);
  }

  getAccessTokens(): TokenModel | null {
    const tokenModel = this.storage.get<TokenModel>(tokenStorageKey);
    if (!tokenModel) {
      return null;
    }
    /**
     * Ensure token properties are set. If not, return null. Normally we don't need a check like this, but
     * the shape of this model is important since an incorrect model can brick launch of the app.
     * See https://skyswitch.atlassian.net/browse/CUC-717 and https://bcm-one.slack.com/archives/C03QNKG88K1/p1683061948746719
     */
    if (tokenModel.access_token && tokenModel.refresh_token) {
      return tokenModel;
    }

    console.error('Invalid token model in local storage.');
    return null;
  }

  clearAccess(): void {
    this.storage.delete(tokenStorageKey);
    this.callHistoryStorageService.drop();
    this.loggerService.drop();
    this.clearClaims();
  }

  setAccessTokens(token: TokenModel): void {
    this.storage.set(tokenStorageKey, token);
  }
}
