<div
  #container
  class="container">
  <div class="add-rule-container">
    <h1>
      <mat-icon (click)="close()">chevron_left</mat-icon>
      {{ pageTitle }}
    </h1>

    <form
      class="form-container"
      [formGroup]="ruleForm">
      <div class="section">
        <div class="subheader line">
          Enable Rule
          <mat-slide-toggle formControlName="enabled"></mat-slide-toggle>
        </div>
      </div>

      <div class="section">
        <div class="subheader line">
          Time Frame
          <mat-radio-group
            *ngIf="answeringRule === undefined && appConfigService.features[AppFeature.ModifyTimeFrames]"
            color="primary"
            [formControl]="filterCtrl"
            (change)="filterTimeFrames()">
            <mat-radio-button
              disableRipple
              [value]="false">
              All
            </mat-radio-button>
            <mat-radio-button
              disableRipple
              [value]="true"
              [style]="'margin-left: 16px'">
              Personal
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field
          class="time-frame-select input-40"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always">
          <mat-label *ngIf="answeringRule === undefined"> Select a Time Frame </mat-label>
          <mat-select
            #timeFrameSelect
            formControlName="timeFrame"
            placeholder="Select">
            <mat-option [value]="newTimeFrameOptionString">New Time Frame</mat-option>
            <mat-option
              *ngFor="let tf of filteredTimeFrames"
              [disabled]="timeFramesUsedByMe.includes(tf.time_frame)"
              [value]="tf.time_frame">
              {{ tf.time_frame }}
              <mat-icon
                style="position: absolute; right: 22px"
                [ngClass]="{
                  disabled:
                    (tf.in_use && !timeFramesUsedByMe.includes(tf.time_frame)) ||
                    (tf.owner === '*' && !answeringRulesService.isCurrentUserOfficeManager)
                }"
                (click)="deleteTimeFrame($event, tf.time_frame)">
                delete_outline
              </mat-icon>
            </mat-option>
            <mat-option
              *ngIf="defaultRule"
              value="*">
              Default
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-add-timeframe
          *ngIf="timeFrameSelect.value === newTimeFrameOptionString"
          #timeFrame
          [standalone]="false"
          [(invalid)]="invalidTimeFrame">
        </app-add-timeframe>
      </div>

      <div class="section">
        <div class="subheader line">
          Call Screening
          <mat-slide-toggle formControlName="callScreening"></mat-slide-toggle>
        </div>
        <div class="text">
          When enabled, the caller is prompted to say their name, and you are able to screen the call before accepting.
        </div>
      </div>

      <div class="section">
        <div class="subheader line">Ringing behaviour</div>
        <div class="text">This setting is to configure the ringing behavior for when someone calls your extension.</div>
        <mat-form-field
          class="behaviour-select input-40"
          hideRequiredMarker
          appearance="outline"
          floatLabel="always">
          <mat-label>Select a ringing behaviour</mat-label>
          <mat-select
            #behaviourSelection
            placeholder="Select"
            [formControl]="ruleType"
            (selectionChange)="behaviourSelectionChange($event)">
            <mat-option [value]="AnsweringRuleType.ForwardAllCalls">Forward All Calls</mat-option>
            <mat-option [value]="AnsweringRuleType.DoNotDisturb">Do Not Disturb</mat-option>
            <mat-option [value]="AnsweringRuleType.OnlyPrimaryDeskphone">Only Ring Primary Deskphone</mat-option>
            <mat-option [value]="AnsweringRuleType.SimultaneousRing">Simultaneous Ring</mat-option>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.ForwardAllCalls">
          <div class="text">Calls will ring only the primary deskphone.</div>
          <mat-form-field
            class="forward-field input-40"
            hideRequiredMarker
            appearance="outline"
            floatLabel="always">
            <mat-label>Start typing a contact name or number</mat-label>
            <input
              matInput
              type="text"
              placeholder="Type an extension, contact name, or number"
              formControlName="forwardAlwaysDestination"
              [matAutocomplete]="autoAlways"
              (focus)="onInputFieldFocus(AutocompleteSource.Always)" />
            <mat-autocomplete #autoAlways="matAutocomplete">
              <mat-option
                *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                [value]="destination.description">
                {{ destination.description }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.DoNotDisturb">
          <div class="dnd-text text">No phone will ring. Calls will go directly to voicemail if available.</div>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.OnlyPrimaryDeskphone">
          <div class="text">Calls will ring only the primary deskphone.</div>
        </ng-container>

        <ng-container *ngIf="behaviourSelection.value === AnsweringRuleType.SimultaneousRing">
          <div class="text">Calls will ring multiple devices at once.</div>
          <div class="ring-controls">
            <div class="ring-options">
              <mat-checkbox
                formControlName="simRingUsersExtension"
                [disableRipple]="true"
                [color]="'primary'">
                Include user’s extension
              </mat-checkbox>
              <mat-checkbox
                formControlName="simRingAllDevices"
                [disableRipple]="true"
                [color]="'primary'">
                Ring all user’s phones (all registered devices of this current user)
              </mat-checkbox>
              <mat-checkbox
                formControlName="simRingConfirmOffnet"
                [disableRipple]="true"
                [color]="'primary'"
                >Answer confirmation for offnet numbers</mat-checkbox
              >
            </div>
            <div class="ring-rules">
              <ng-container formArrayName="simRingOtherDestinations">
                <div
                  class="ring-rule"
                  [ngClass]="{ editing: editSimRingDestIndex === i }"
                  *ngFor="let _ of otherDestinations.controls; index as i">
                  <ng-container [formGroupName]="i">
                    <mat-form-field
                      class="number input-40"
                      appearance="outline">
                      <input
                        matInput
                        [readonly]="editSimRingDestIndex !== i"
                        type="text"
                        formControlName="destination" />
                    </mat-form-field>
                    <mat-icon class="clock-icon">schedule</mat-icon>
                    <mat-form-field
                      class="delay input-40"
                      appearance="outline">
                      <input
                        #delayInput
                        matInput
                        [readonly]="editSimRingDestIndex !== i"
                        type="number"
                        min="0"
                        max="60"
                        formControlName="delay" />
                      <span
                        [style]="'position: relative; left: ' + (-47 + 8 * delayInput.value.length) + 'px;'"
                        matTextSuffix>
                        sec
                      </span>
                    </mat-form-field>
                    <mat-icon
                      class="edit-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i }"
                      (click)="editSimRingDestination(i)">
                      edit_outline
                    </mat-icon>
                    <mat-icon
                      class="delete-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i }"
                      (click)="deleteSimRingDestination(i)">
                      delete_outline
                    </mat-icon>
                    <mat-icon
                      class="done-icon"
                      [ngClass]="{ editing: editSimRingDestIndex === i, disabled: otherDestinations.at(i).invalid }"
                      (click)="editSimRingDestinationDone()">
                      done_outline
                    </mat-icon>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <div
              class="new-ring-rule"
              [ngClass]="{ space: otherDestinations.length > 0 }">
              <mat-form-field
                class="number input-40"
                hideRequiredMarker
                appearance="outline"
                floatLabel="always">
                <mat-label>Add a Number to Simultaneously Ring</mat-label>
                <input
                  matInput
                  #simRingDestination
                  type="text" />
              </mat-form-field>
              <mat-form-field
                class="delay input-40"
                hideRequiredMarker
                appearance="outline"
                floatLabel="always">
                <mat-label>Ring Delay</mat-label>
                <input
                  matInput
                  #simRingDelay
                  value="0"
                  min="0"
                  max="60"
                  type="number" />
              </mat-form-field>
              <mat-icon
                [ngClass]="{
                  disabled:
                    simRingDestination.value.length === 0 ||
                    simRingDelay.value.length === 0 ||
                    Number(simRingDelay.value) < 0 ||
                    Number(simRingDelay.value) > 60
                }"
                (click)="addSimRingDestination()"
                >add_box</mat-icon
              >
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container
        *ngIf="
          behaviourSelection.value === AnsweringRuleType.SimultaneousRing ||
          behaviourSelection.value === AnsweringRuleType.OnlyPrimaryDeskphone
        ">
        <div class="section">
          <div class="subheader line">Call Forwarding</div>
          <div class="text">If you want to forward all calls, select “Forward all calls” from the dropdown above.</div>
          <div class="forwarding-options">
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardOnActiveEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardOnActiveDestination'], $event.checked)">
                  Active
                </mat-checkbox>
                <mat-icon
                  matTooltip="Forward calls to the number specified when you have one or more calls active."
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="Start typing contact name or number"
                  formControlName="forwardOnActiveDestination"
                  [matAutocomplete]="autoActive"
                  (focus)="onInputFieldFocus(AutocompleteSource.OnActive)" />
                <mat-autocomplete #autoActive="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div
              *ngIf="behaviourSelection.value !== AnsweringRuleType.SimultaneousRing"
              class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardBusyEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardBusyDestination'], $event.checked)">
                  When Busy
                </mat-checkbox>
                <mat-icon
                  matTooltip="Forwards calls to the number specified when the terminating device returns a busy response code."
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="Start typing contact name or number"
                  formControlName="forwardBusyDestination"
                  [matAutocomplete]="autoBusy"
                  (focus)="onInputFieldFocus(AutocompleteSource.Busy)" />
                <mat-autocomplete #autoBusy="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardNoAnswerEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardNoAnswerDestination'], $event.checked)">
                  When Unanswered
                </mat-checkbox>
                <mat-icon
                  matTooltip="Forwards calls to the number specified if the call is not answered after the specified ring timeout."
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="Start typing contact name or number"
                  formControlName="forwardNoAnswerDestination"
                  [matAutocomplete]="autoNoAnswer"
                  (focus)="onInputFieldFocus(AutocompleteSource.NoAnswer)" />
                <mat-autocomplete #autoNoAnswer="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="item">
              <div>
                <mat-checkbox
                  formControlName="forwardOfflineEnabled"
                  [color]="'primary'"
                  [disableRipple]="true"
                  (change)="setCallForwardInputsState(['forwardOfflineDestination'], $event.checked)">
                  When Offline
                </mat-checkbox>
                <mat-icon
                  matTooltip="Automatically forwards if your desk phone loses communication (such as during a power outage)."
                  matTooltipPosition="above">
                  info_outline
                </mat-icon>
              </div>
              <mat-form-field
                class="input-40"
                hideRequiredMarker
                appearance="outline">
                <input
                  matInput
                  type="text"
                  placeholder="Start typing contact name or number"
                  formControlName="forwardOfflineDestination"
                  [matAutocomplete]="autoOffline"
                  (focus)="onInputFieldFocus(AutocompleteSource.Offline)" />
                <mat-autocomplete #autoOffline="matAutocomplete">
                  <mat-option
                    *ngFor="let destination of filteredDestinations; trackBy: trackByValue"
                    [value]="destination.description">
                    {{ destination.description }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div
    *ngIf="editingDisabled"
    class="grey-mask"
    #greyMask></div>
</div>

<div class="main-buttons">
  <button
    mat-button
    type="button"
    color="primary"
    (click)="cancelEvent()">
    Cancel
  </button>
  <button
    mat-raised-button
    type="button"
    color="primary"
    [disabled]="ruleForm.invalid || ruleForm.pristine || editSimRingDestIndex !== -1 || invalidTimeFrame || saving"
    (click)="saveEvent()">
    <mat-spinner
      diameter="24"
      *ngIf="saving"></mat-spinner>
    <span *ngIf="!saving">Save</span>
  </button>
</div>
