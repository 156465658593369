import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { AudioSettings, VideoSettings } from '@app/preferences/models/settings.models';
import { SettingsService } from '@app/preferences/services/settings.service';
import {
  AudioVideoObserver,
  BackgroundBlurProcessor,
  BackgroundBlurVideoFrameProcessor,
  ClientMetricReport,
  ConnectionHealthData,
  ConsoleLogger,
  ContentShareObserver,
  DefaultActiveSpeakerPolicy,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultVideoTransformDevice,
  Device,
  DeviceChangeObserver,
  DeviceController,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
  MeetingSessionStatus,
  MeetingSessionVideoAvailability,
  RealtimeAttendeePositionInFrame,
  SimulcastLayers,
  VideoFrameProcessor,
  VideoPriorityBasedPolicy,
  VideoSource,
  VideoTileState,
  VolumeIndicatorCallback,
} from 'amazon-chime-sdk-js';
import {
  auditTime,
  BehaviorSubject,
  catchError,
  combineLatest,
  from,
  map,
  mergeMap,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';

import { AttendeePresence, AttendeeVolume, MeetingResponse, MeetingState } from '../models/chime.models';
import meetingConfig from '../shared/react/meetingConfig';

@Injectable({
  providedIn: 'root',
})
export class ChimeMeetingService
  extends ApiService
  implements DeviceChangeObserver, AudioVideoObserver, ContentShareObserver
{
  protected path = 'users/{me}/meetings';

  public readonly meetingStateSubject = new BehaviorSubject<MeetingState>({ name: 'stopped', reason: undefined });
  public readonly attendeePresenceSubject = new BehaviorSubject<Map<string, AttendeePresence>>(new Map());
  public readonly attendeeVolumeSubject = new BehaviorSubject<Map<string, AttendeeVolume>>(new Map());
  public readonly attendeeSpeakingSubject = new BehaviorSubject<Map<string, boolean>>(new Map());
  public readonly activeSpeakerIdsSubject = new BehaviorSubject<string[]>([]);
  public readonly attendeeMutedStateSubject = new BehaviorSubject<Map<string, boolean>>(new Map());
  public readonly localAudioMutedSubject = new BehaviorSubject<boolean>(false);
  public readonly currentMeetingChannelArnSubject = new BehaviorSubject<string | null>(null); // Tracks channel arn for meeting the user is either joining or has joined
  public readonly contentShareActiveSubject = new BehaviorSubject<boolean>(false);
  public readonly audioInputDevicesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  public readonly audioOutputDevicesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  public readonly videoInputDevicesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);

  /** Payload will be tile's state, an id (if removed), or undefined for initial state */
  private videoTileUpdateSubject = new BehaviorSubject<VideoTileState | number | undefined>(undefined);

  public readonly meetingState$ = this.meetingStateSubject.asObservable();
  public readonly attendeePresence$ = this.attendeePresenceSubject.asObservable();
  public readonly attendeeVolume$ = this.attendeeVolumeSubject.asObservable();
  public readonly attendeeSpeaking$ = this.attendeeSpeakingSubject.asObservable();
  public readonly activeSpeakersIds$ = this.activeSpeakerIdsSubject.asObservable();
  public readonly localAudioMuted$ = this.localAudioMutedSubject.asObservable();
  public readonly attendeeMutedState$ = this.attendeeMutedStateSubject.asObservable();
  public readonly videoTileUpdateData$ = this.videoTileUpdateSubject.asObservable();
  public readonly currentMeetingChannelArn$ = this.currentMeetingChannelArnSubject.asObservable();
  public readonly contentShareActive$ = this.contentShareActiveSubject.asObservable();
  public readonly audioInputDevices$ = this.audioInputDevicesSubject.asObservable();
  public readonly audioOutputDevices$ = this.audioOutputDevicesSubject.asObservable();
  public readonly videoInputDevices$ = this.videoInputDevicesSubject.asObservable();

  public currentMeetingSession?: MeetingSession;
  public currentActiveMediaDevices: {
    videoInput?: MediaDeviceInfo;
    audioOutput?: MediaDeviceInfo;
    audioInput?: MediaDeviceInfo;
  } = {};

  get isMeetingActive(): boolean {
    return !!this.currentMeetingChannelArnSubject.value;
  }

  // Meeting Props
  private priorityBasedPolicy?: VideoPriorityBasedPolicy;
  private enableSimulcast = false;
  private keepLastFrameWhenPaused = false;
  private audioSetting: AudioSettings;
  private videoSetting: VideoSettings;

  constructor(http: HttpClient, private settingsService: SettingsService, private ngZone: NgZone) {
    super(http);
    this.settingsService.audioSettings$.subscribe((audioSettings) => {
      this.audioSetting = audioSettings;
    });
    this.settingsService.videoSettings$.subscribe((videoSettings) => {
      this.videoSetting = videoSettings;
    });
    /**
     * The ChimeSDK will provide volume updates for a given attendee where some props may be null if they are unchanged
     * from the previous update. While understandable, it's annoying for tracking a single prop (muted) because we
     * only care about the true and false cases. This subscription handles diffing the update with what is stored
     * currently and will only update if a muted state has changed for an attendee.
     */
    this.attendeeVolume$.subscribe((attendeesVolumeMap) => {
      let shouldUpdateSubject = false;
      const mutedAttendeeIds = this.attendeeMutedStateSubject.getValue();
      for (const [attendeeId, attendee] of attendeesVolumeMap.entries()) {
        if (attendee.muted !== null && mutedAttendeeIds.get(attendeeId) !== attendee.muted) {
          shouldUpdateSubject = true;
          mutedAttendeeIds.set(attendeeId, attendee.muted);
        }
      }

      if (shouldUpdateSubject) {
        this.attendeeMutedStateSubject.next(mutedAttendeeIds);
      }
    });

    this.attendeeVolume$.pipe(auditTime(100)).subscribe((attendeesVolumeMap) => {
      let shouldUpdateSubject = false;
      const speakingAttendeeMap = this.attendeeSpeakingSubject.getValue();
      for (const [attendeeId, attendee] of attendeesVolumeMap.entries()) {
        if (attendee.volume !== null) {
          const isAttendeeSpeaking = attendee.volume > 0;
          if (speakingAttendeeMap.get(attendeeId) !== isAttendeeSpeaking) {
            shouldUpdateSubject = true;
            speakingAttendeeMap.set(attendeeId, isAttendeeSpeaking);
          }
        }
      }

      if (shouldUpdateSubject) {
        this.attendeeSpeakingSubject.next(speakingAttendeeMap);
      }
    });
  }

  // ========== Convenience ==========

  public async listAudioInputDevices(): Promise<MediaDeviceInfo[]> {
    if (!this.currentMeetingSession) {
      return [];
    }

    const devices = await this.currentMeetingSession?.audioVideo.listAudioInputDevices();
    console.log(`meeting: list audio input devices: ${JSON.stringify(devices)}`);
    return devices;
  }

  public async listAudioOutputDevices(): Promise<MediaDeviceInfo[]> {
    if (!this.currentMeetingSession) {
      return [];
    }

    const devices = await this.currentMeetingSession?.audioVideo.listAudioOutputDevices();
    console.log(`meeting: list audio output devices: ${JSON.stringify(devices)}`);
    return devices;
  }

  public async listVideoInputDevices(): Promise<MediaDeviceInfo[]> {
    if (!this.currentMeetingSession) {
      return [];
    }

    const devices = await this.currentMeetingSession.audioVideo.listVideoInputDevices();
    console.log(`meeting: list video input devices: ${JSON.stringify(devices)}`);
    return devices;
  }

  private async updateSelectedDeviceAfterDeviceChangeIfNecessary(
    deviceList: MediaDeviceInfo[],
    type: 'speaker' | 'microphone'
  ) {
    // Confirm the currently selected audio input device is still in the list. If not, select the default device
    // based on preferences.
    const preferredDeviceId =
      type === 'microphone' ? this.audioSetting.microphoneDevice : this.audioSetting.speakerDevice;
    const currentActiveDevice =
      type === 'microphone' ? this.currentActiveMediaDevices.audioInput : this.currentActiveMediaDevices.audioOutput;

    /**
     * Chrome has a unique behavior where it will sometimes change a device's id to literally 'default'.
     * This value appears to be whatever device the OS current has selected and may change as devices are
     * added and removed.
     *
     * When a user changes their audio device, this can confuse the Chime SDK's logic for automatically selecting
     * a device.
     *
     * Our solution:
     * Confirm the currently active device still exists in the list. If it does not OR another device
     * has a deviceId of 'default' (chrome only), select said device.
     *
     * We have to do this manually, because audio switching
     * doesn't work when switching between devices when both have the label 'default' (which Chrome does internally)
     */
    const devices = this.devicesSortedWithDefaultFirst(preferredDeviceId, deviceList);
    const currentActiveDeviceExists = devices.some((device) => device.deviceId === currentActiveDevice?.deviceId);
    const chromeDefaultDevice = devices.find((device) => device.deviceId === 'default');

    // `true` if the chrome device exists and it has a different deviceId _or_ groupId from whatever device is currently active
    const isChromeDefaultDeviceDifferentFromActive =
      chromeDefaultDevice &&
      (chromeDefaultDevice.deviceId !== currentActiveDevice?.deviceId ||
        chromeDefaultDevice.groupId !== currentActiveDevice.groupId);

    if (!currentActiveDeviceExists || isChromeDefaultDeviceDifferentFromActive) {
      await this.selectMediaDevice(chromeDefaultDevice ?? devices[0], type);
    }
  }

  public async selectMediaDevice(device: MediaDeviceInfo, type: 'video' | 'speaker' | 'microphone') {
    console.log(`meeting: selecting media device: ${device.deviceId}`);
    if (!this.currentMeetingSession) {
      return;
    }

    try {
      switch (type) {
        case 'video': {
          await this.startVideoInput(this.currentMeetingSession, [device]);
          this.currentActiveMediaDevices.videoInput = device;
          break;
        }
        case 'microphone': {
          await this.startAudioInput(this.currentMeetingSession, [device]);
          this.currentActiveMediaDevices.audioInput = device;
          break;
        }
        case 'speaker': {
          await this.chooseAudioOutput(this.currentMeetingSession, [device]);
          this.currentActiveMediaDevices.audioOutput = device;
          break;
        }
      }
    } catch (error) {
      console.log(`meeting: error selecting media device: ${error}`);
    }
  }

  // ========== Meeting Lifecycle ==========

  /**
   * Starts and joins (creating if necessary) a meeting with the given id.
   *
   * @param channelArn The ID of the meeting to join
   */
  public async startMeeting(channelArn: string) {
    const meetingStateName = this.meetingStateSubject.value.name;
    if (
      this.currentMeetingChannelArnSubject.value === channelArn ||
      meetingStateName === 'willConnect' ||
      meetingStateName === 'connecting' ||
      meetingStateName === 'stopping'
    ) {
      return;
    }

    this.meetingStateSubject.next({ name: 'willConnect' });
    if (meetingStateName === 'started' && this.currentMeetingChannelArnSubject.value !== channelArn) {
      await this.leaveMeeting();
    }

    this.localAudioMutedSubject.next(false);
    this.contentShareActiveSubject.next(false);

    this.ngZone.runOutsideAngular(() => {
      this.createAndConfigureMeetingSession(channelArn).subscribe(({ meetingSession }) => {
        this.currentMeetingSession = meetingSession;
        this.currentMeetingChannelArnSubject.next(channelArn);
        meetingSession.audioVideo.start();
      });
    });
  }

  public async leaveMeeting() {
    this.meetingStateSubject.next({ name: 'stopping' });
    this.currentMeetingChannelArnSubject.next(null);
    this.attendeePresenceSubject.next(new Map());
    this.attendeeVolumeSubject.next(new Map());
    this.attendeeSpeakingSubject.next(new Map());
    this.activeSpeakerIdsSubject.next([]);
    this.attendeeMutedStateSubject.next(new Map());
    this.contentShareActiveSubject.next(false);
    this.audioInputDevicesSubject.next([]);
    this.audioOutputDevicesSubject.next([]);
    this.videoInputDevicesSubject.next([]);

    // Chime API Overview docs are incomplete when it comes to tearing down
    // an audioVideo session. See https://github.com/aws/amazon-chime-sdk-js/issues/833
    await this.currentMeetingSession?.audioVideo.stopVideoInput();
    this.currentMeetingSession?.audioVideo.stopLocalVideoTile();
    this.currentMeetingSession?.audioVideo.removeAllVideoTiles();
    await this.currentMeetingSession?.audioVideo.stopAudioInput();
    this.currentMeetingSession?.audioVideo.stopContentShare();
    this.currentMeetingSession?.audioVideo.stop();
  }

  private removeMeetingObservers() {
    this.currentMeetingSession?.audioVideo.removeDeviceChangeObserver(this);
    this.currentMeetingSession?.audioVideo.removeObserver(this);
    this.currentMeetingSession?.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(
      this.realtimeSubscribeToAttendeeIdPresenceCallback
    );
    this.currentMeetingSession?.audioVideo.unsubscribeFromActiveSpeakerDetector(this.activeSpeakerDetectorCallback);
    this.currentMeetingSession?.audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(
      this.localAudioMutedAndUnmutedCallback
    );
  }

  private createAndConfigureMeetingSession(channelArn: string): Observable<{
    meetingSession: MeetingSession;
    audioInputStream: MediaStream | undefined;
    videoInputStream: MediaStream | undefined;
  }> {
    return this.createMeetingSession(channelArn).pipe(
      tap(({ meetingSession, deviceController }) => {
        // Add ourselves as a device change observer. This is a side-effect which is why we don't include it in the map methods further
        // along the chain.
        deviceController.addDeviceChangeObserver(this);

        // Invoke devices.
        // See: https://aws.github.io/amazon-chime-sdk-js/modules/apioverview.html#implement-a-view-onlyobserverspectator-experience
        meetingSession.audioVideo.setDeviceLabelTrigger(async () => {
          return await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        });
      }),
      mergeMap(({ meetingSession }) => {
        const audioInputDevices = from(meetingSession.audioVideo.listAudioInputDevices());
        // Note: Firefox does not support selecting audio output device by default. See https://github.com/aws/amazon-chime-sdk-js/issues/657
        const audioOutputDevices = from(meetingSession.audioVideo.listAudioOutputDevices());
        const videoInputDevices = from(meetingSession.audioVideo.listVideoInputDevices());
        return combineLatest([of(meetingSession), audioInputDevices, audioOutputDevices, videoInputDevices]);
      }),
      tap(([_meetingSession, audioInputDevices, audioOutputDevices, videoInputDevices]) => {
        this.audioInputDevicesSubject.next(audioInputDevices);
        this.audioOutputDevicesSubject.next(audioOutputDevices);
        this.videoInputDevicesSubject.next(videoInputDevices);
      }),
      mergeMap(([meetingSession, audioInputDevices, audioOutputDevices, videoInputDevices]) => {
        // Sort devices so default option is first
        return combineLatest([
          of(meetingSession),
          of(this.devicesSortedWithDefaultFirst(this.audioSetting.microphoneDevice, audioInputDevices)),
          of(this.devicesSortedWithDefaultFirst(this.audioSetting.speakerDevice, audioOutputDevices)),
          of(this.devicesSortedWithDefaultFirst(this.videoSetting.cameraDevice, videoInputDevices)),
        ]);
      }),
      mergeMap(([meetingSession, audioInputDevices, audioOutputDevices, videoInputDevices]) => {
        // Start each device
        return combineLatest([
          of(meetingSession),
          from(this.startAudioInput(meetingSession, audioInputDevices)),
          from(this.chooseAudioOutput(meetingSession, audioOutputDevices)),
          from(this.startVideoInput(meetingSession, videoInputDevices)),
        ]);
      }),
      mergeMap(([meetingSession, audioInputResult, audioOutputResult, videoInputResult]) => {
        // If we got here we should have all the devices ids available. We can continue on
        const activeDeviceIds: typeof this.currentActiveMediaDevices = {
          audioInput: audioInputResult.device,
          audioOutput: audioOutputResult.device,
          videoInput: videoInputResult.device,
        };
        return combineLatest([
          of(meetingSession),
          of(audioInputResult.mediaStream),
          of(videoInputResult.mediaStream),
          of(activeDeviceIds),
        ]);
      }),
      catchError((error) => {
        console.error(error);
        return throwError(error);
      }),
      tap((parts) => {
        this.currentActiveMediaDevices = parts[3];
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([meetingSession, audioInputStream, videoInputStream]) => {
        return {
          meetingSession,
          audioInputStream,
          videoInputStream,
        };
      }),
      tap(({ meetingSession }) => {
        // Add ourselves as an audio observer on the session
        meetingSession.audioVideo.addObserver(this);
        meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
          this.realtimeSubscribeToAttendeeIdPresenceCallback
        );
        meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
          new DefaultActiveSpeakerPolicy(),
          this.activeSpeakerDetectorCallback
        );

        meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(this.localAudioMutedAndUnmutedCallback);
        meetingSession.audioVideo.addContentShareObserver(this);
      })
      // Need to cast here otherwise TypeScript gets mad because it can't infer the type
    ) as Observable<{
      meetingSession: MeetingSession;
      audioInputStream: MediaStream | undefined;
      videoInputStream: MediaStream | undefined;
    }>;
  }

  private createMeetingSession(
    channelArn: string
  ): Observable<{ meetingSession: MeetingSession; deviceController: DeviceController }> {
    return this.joinMeeting(channelArn).pipe(
      map((meetingResponse) => {
        const { Meeting, Attendee } = meetingResponse;
        const meetingSessionConfiguration = new MeetingSessionConfiguration(Meeting, Attendee);
        if (
          meetingConfig.postLogger &&
          meetingSessionConfiguration.meetingId &&
          meetingSessionConfiguration.credentials &&
          meetingSessionConfiguration.credentials.attendeeId
        ) {
          const existingMetadata = meetingConfig.postLogger.metadata;
          meetingConfig.postLogger.metadata = {
            ...existingMetadata,
            meetingId: meetingSessionConfiguration.meetingId,
            attendeeId: meetingSessionConfiguration.credentials.attendeeId,
          };
        }
        meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = this.enableSimulcast;
        if (this.priorityBasedPolicy) {
          meetingSessionConfiguration.videoDownlinkBandwidthPolicy = this.priorityBasedPolicy;
        }
        meetingSessionConfiguration.keepLastFrameWhenPaused = this.keepLastFrameWhenPaused;

        return meetingSessionConfiguration;
      }),
      map((meetingSessionConfiguration) => {
        const deviceController = new DefaultDeviceController(meetingConfig.logger);
        const meetingSession = new DefaultMeetingSession(
          meetingSessionConfiguration,
          meetingConfig.logger,
          deviceController
        );
        return { meetingSession, deviceController };
      })
    );
  }

  private joinMeeting(channelArn: string): Observable<MeetingResponse> {
    // This is a POST request so we don't have to mess with escaping the channel_arn as a query parameter
    return this.post<MeetingResponse>(`${this.path}/join`, {
      channel_arn: channelArn,
    }).pipe(map((response) => this.capitalizeObjectKeys(response) as MeetingResponse));
  }

  // ======== Device Selection ==========
  /**
   * Order our devices such that if there's a default device, it is first in the list. Otherwise, just
   * iterate over the list of devices attempting to start each one until one succeeds. If all fails,
   * throw an error that we couldn't start a device.
   *
   * Making these async functions instead of observables because it's easier to execute in a loop and stop early using async/await than using
   * rxjs.
   *
   * The behavior of each device type is different enough to warrant separate functions instead of abstracting into one.
   */
  private async startAudioInput(
    meetingSession: MeetingSession,
    devices: MediaDeviceInfo[]
  ): Promise<{
    deviceId: string | undefined;
    device: MediaDeviceInfo | undefined;
    mediaStream: MediaStream | undefined;
  }> {
    for (const device of devices) {
      try {
        const mediaStream = await meetingSession.audioVideo.startAudioInput(device);
        console.log(`meeting: start audio input device: ${JSON.stringify(device)}`);
        return { deviceId: device.deviceId, device, mediaStream };
      } catch (error) {
        console.error(`meeting: could not start audio device. ${error}`);
      }
    }

    console.log(`meeting: start default audio input device`);
    const mediaStream = await meetingSession.audioVideo.startAudioInput(null);
    if (!mediaStream) {
      throw new Error(`meeting: could not find an available audio input device to start`);
    }
    return { deviceId: undefined, device: undefined, mediaStream };
  }

  private async chooseAudioOutput(
    meetingSession: MeetingSession,
    devices: MediaDeviceInfo[]
  ): Promise<{ deviceId: string | undefined; device: MediaDeviceInfo | undefined }> {
    for (const device of devices) {
      try {
        await meetingSession.audioVideo.chooseAudioOutput(device.deviceId);
        console.log(`meeting: choose audio output device ${JSON.stringify(device)}`);
        return { deviceId: device.groupId, device };
      } catch (error) {
        console.error(`meeting: could not start device. ${error}`);
      }
    }

    console.log(`meeting: choosing default audio output device`);
    await meetingSession.audioVideo.chooseAudioOutput(null);
    return { deviceId: undefined, device: undefined };
  }

  private async startVideoInput(
    meetingSession: MeetingSession,
    devices: MediaDeviceInfo[]
  ): Promise<{
    deviceId: string | undefined;
    device: MediaDeviceInfo | undefined;
    mediaStream: MediaStream | undefined;
  }> {
    for (const device of devices) {
      try {
        const transformDevice = await this.getVideoTransformDevice(
          device.deviceId,
          this.settingsService.videoSettings.blurBackground
        );
        const mediaStream = await meetingSession.audioVideo.startVideoInput(transformDevice);
        console.log(`meeting: choose video input device ${JSON.stringify(device)}`);
        return { deviceId: device.deviceId, device, mediaStream };
      } catch (error) {
        console.error(`meeting: could not start video device. ${error}`);
      }
    }

    console.log(`meeting: skipping starting video input. no device available.`);
    return { deviceId: undefined, device: undefined, mediaStream: undefined };
  }

  /** Order devices so the default device id is the first in the list. */
  private devicesSortedWithDefaultFirst(
    defaultDevice: MediaDeviceInfo | string | undefined,
    availableDevices: MediaDeviceInfo[]
  ): MediaDeviceInfo[] {
    const sortedDevices = availableDevices;
    const defaultDeviceIndex = availableDevices.findIndex((device) => {
      return typeof defaultDevice === 'string'
        ? device.deviceId === defaultDevice
        : device.deviceId === defaultDevice?.deviceId;
    });

    if (defaultDeviceIndex !== -1) {
      const device = sortedDevices.splice(defaultDeviceIndex, 1);
      sortedDevices.unshift(...device);
    }
    return sortedDevices;
  }

  /**
   * Note this function does not work on arrays of nested objects
   * @param object Object to convert
   * @returns Object where all properties keys are capitalized.
   */
  private capitalizeObjectKeys(object: object) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: Record<string, any> = {};
    for (const [key, value] of Object.entries(object)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      result[capitalizedKey] = typeof value === 'object' ? this.capitalizeObjectKeys(value) : value;
    }
    return result;
  }

  // ========== Meeting Controls ==========

  public setLocalAudioMuted(muted: boolean) {
    muted
      ? this.currentMeetingSession?.audioVideo.realtimeMuteLocalAudio()
      : this.currentMeetingSession?.audioVideo.realtimeUnmuteLocalAudio();
  }

  public isLocalAudioMuted(): boolean {
    return this.currentMeetingSession?.audioVideo.realtimeIsLocalAudioMuted() || false;
  }

  public isSharingScreen(): boolean {
    return this.contentShareActiveSubject.value;
  }

  public async setSharingLocalVideo(sharing: boolean) {
    // Wrap in a try-catch otherwise if an exception is thrown future attempts to toggle video will not work.
    if (!this.currentMeetingSession) {
      return;
    }

    try {
      if (sharing) {
        const videoDevices = await this.currentMeetingSession.audioVideo.listVideoInputDevices();
        const lastKnownVideoDeviceId =
          this.currentActiveMediaDevices.videoInput?.deviceId || this.settingsService.videoSettings.cameraDevice;
        const videoDevice =
          videoDevices.find((device) => device.deviceId === lastKnownVideoDeviceId) ?? videoDevices[0];

        if (videoDevice) {
          await this.startVideoInput(this.currentMeetingSession, [videoDevice]);
          this.currentMeetingSession?.audioVideo.startLocalVideoTile();
        }
      } else {
        this.currentMeetingSession.audioVideo.stopLocalVideoTile();
        this.currentMeetingSession.audioVideo.stopVideoInput();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public hasStartedLocalVideo(): boolean {
    return this.currentMeetingSession?.audioVideo.hasStartedLocalVideoTile() || false;
  }

  public async setSharingScreen(sharing: boolean) {
    console.log(`meeting: set screen sharing: ${sharing}`);
    const constraints = {
      video: true,
      audio: false,
    };

    if (sharing) {
      const stream = await (window?.navigator ?? navigator).mediaDevices.getDisplayMedia(constraints);
      await this.currentMeetingSession?.contentShare.startContentShare(stream);
    } else {
      this.currentMeetingSession?.audioVideo.stopContentShare();
    }
  }

  // ========== Callbacks ==========
  // (Why? Some of the observer APIs require a callback argument for subscription to events. Keep them here so we
  // have a reference which we can use for unscribing when necessary)

  private realtimeSubscribeToAttendeeIdPresenceCallback = (
    attendeeId: string,
    present: boolean,
    externalUserId: string | undefined,
    dropped: boolean | undefined,
    posInFrame: RealtimeAttendeePositionInFrame | undefined | null
  ) => {
    // If the attendee is present, subscribe to volume updates
    if (present) {
      this.currentMeetingSession?.audioVideo.realtimeSubscribeToVolumeIndicator(
        attendeeId,
        this.realtimeVolumeIndicatorCallback
      );
    } else {
      // Remove the attendee volume info from our data structure
      const volumes = this.attendeeVolumeSubject.getValue();
      volumes.delete(attendeeId);

      this.attendeeVolumeSubject.next(volumes);
      this.currentMeetingSession?.audioVideo.realtimeUnsubscribeFromVolumeIndicator(
        attendeeId,
        this.realtimeVolumeIndicatorCallback
      );
    }

    // Sometimes Chime may change an attendee's id even though their externalUserId is the same. For that case we want to replace the
    // old entry with the new.
    const attendees = this.attendeePresenceSubject.value;
    if (!attendeeId.endsWith('#content')) {
      const duplicateAttendees = [...attendees.values()].filter(
        (attendee) => attendee.externalUserId === externalUserId
      );
      for (const attendee of duplicateAttendees) {
        attendees.delete(attendee.attendeeId);
      }
    }

    attendees.set(attendeeId, { attendeeId, present, externalUserId, dropped, posInFrame });
    this.attendeePresenceSubject.next(attendees);
  };

  private realtimeVolumeIndicatorCallback: VolumeIndicatorCallback = (
    volumeAttendeeId,
    volume,
    muted,
    signalStrength,
    volumeExternalUserId
  ) => {
    /**
     * From the docs:
     * https://github.com/aws/amazon-chime-sdk-js/blob/7283401c6c00166a7ffaf22a4de1bd4ddd6ffeff/src/realtimecontroller/RealtimeController.ts#L140
     * Subscribes to volume indicator changes for a specific attendee id with a
     * callback. Volume is between 0.0 (min volume) and 1.0 (max volume).
     * Signal strength can be 0 (no signal), 0.5 (weak signal), or 1 (good signal).
     * A null value for any field means that it has not changed.
     */
    const volumes = this.attendeeVolumeSubject.getValue();
    volumes.set(volumeAttendeeId, {
      attendeeId: volumeAttendeeId,
      volume,
      muted,
      signalStrength,
      externalUserId: volumeExternalUserId,
    });
    this.attendeeVolumeSubject.next(volumes);
  };

  private activeSpeakerDetectorCallback = (activeSpeakerIds: string[]) => {
    this.activeSpeakerIdsSubject.next(activeSpeakerIds);
  };

  private localAudioMutedAndUnmutedCallback = (muted: boolean) => {
    this.localAudioMutedSubject.next(muted);
  };

  // ========== DeviceChangeObserver ==========

  audioInputsChanged?(freshAudioInputDeviceList?: MediaDeviceInfo[]) {
    meetingConfig.logger.info(`deviceChangeObserver: audioInputsChanged: ${freshAudioInputDeviceList}`);

    const deviceList = freshAudioInputDeviceList || [];
    this.audioInputDevicesSubject.next(deviceList);

    // The `update*` call below is an async method. The enclosing function is called by the Chime SDK and does not expect this method
    // to return a promise. Given this, returning a Promise wouldn't have any effect and we can call this method without `await`.
    this.updateSelectedDeviceAfterDeviceChangeIfNecessary(deviceList, 'microphone');
  }

  audioOutputsChanged?(freshAudioOutputDeviceList?: MediaDeviceInfo[]) {
    meetingConfig.logger.info(`deviceChangeObserver: audioOutputsChanged: ${freshAudioOutputDeviceList}`);

    const deviceList = freshAudioOutputDeviceList || [];
    this.audioOutputDevicesSubject.next(freshAudioOutputDeviceList || []);

    // The `update*` call below is an async method. The enclosing function is called by the Chime SDK and does not expect this method
    // to return a promise. Given this, returning a Promise wouldn't have any effect and we can call this method without `await`.
    this.updateSelectedDeviceAfterDeviceChangeIfNecessary(deviceList, 'speaker');
  }

  videoInputsChanged?(freshVideoInputDeviceList?: MediaDeviceInfo[]) {
    meetingConfig.logger.info(`deviceChangeObserver: videoInputsChanged: ${freshVideoInputDeviceList}`);
    this.videoInputDevicesSubject.next(freshVideoInputDeviceList || []);
  }

  audioInputMuteStateChanged?(device: string | MediaStream, muteState: boolean) {
    meetingConfig.logger.info(`deviceChangeObserver: audioInputMuteStateChanged: ${device} ${muteState}`);
  }

  audioInputStreamEnded?(deviceId?: string) {
    meetingConfig.logger.info(`deviceChangeObserver: audioInputStreamEnded: ${deviceId}`);
  }

  videoInputStreamEnded?(deviceId?: string) {
    meetingConfig.logger.info(`deviceChangeObserver: videoInputStreamEnded: ${deviceId}`);
  }

  // ========== AudioVideoObserver ==========

  /**
   * See interface definition for original docs on methods. Most of the docs aren't very helpful so comments above each method
   * below are pulled from the API overview (unless the overview isn't helpful): https://aws.github.io/amazon-chime-sdk-js/modules/apioverview.html
   */

  /**
   * Occurs when the audio-video session is in the process of connecting or reconnecting.
   */
  audioVideoDidStartConnecting?(reconnecting: boolean) {
    meetingConfig.logger.info(`audioVideoObserver: audioVideoDidStartConnecting: ${reconnecting}`);
    this.meetingStateSubject.next({ name: 'connecting', reconnecting });
  }

  /**
   * Occurs when the audio-video session finishes connecting
   */
  audioVideoDidStart?() {
    meetingConfig.logger.info(`audioVideoObserver: audioVideoDidStart`);
    this.meetingStateSubject.next({ name: 'started' });
  }

  /**
   * Occurs when the audio-video session has disconnected.
   * Use the provided MeetingSessionStatus to determine why the session disconnected.
   */
  audioVideoDidStop?(sessionStatus: MeetingSessionStatus) {
    meetingConfig.logger.info(`audioVideoObserver: audioVideoDidStop: ${sessionStatus}`);
    this.meetingStateSubject.next({ name: 'stopped', reason: sessionStatus });
    this.removeMeetingObservers();
  }

  /**
   * Occurs when either a video stream is started or updated.
   * Use the provided VideoTileState to determine the tile ID and the attendee ID of the video stream.
   */
  videoTileDidUpdate?(tileState: VideoTileState) {
    meetingConfig.logger.info(`videoTileDidUpdate: ${tileState.boundAttendeeId} - ${tileState.tileId}`);
    this.videoTileUpdateSubject.next(tileState);
  }

  /**
   * Occurs when a video stream stops and the reference to the tile (the tile ID) is deleted.
   */
  videoTileWasRemoved?(tileId: number) {
    meetingConfig.logger.info(`audioVideoObserver: videoTileWasRemoved: ${tileId}`);
    this.videoTileUpdateSubject.next(tileId);
  }

  /**
   * Occurs video availability state has changed such as whether the attendee can start local video or whether
   * remote video is available. See MeetingSessionVideoAvailability for more information.
   */
  videoAvailabilityDidChange?(availability: MeetingSessionVideoAvailability) {
    meetingConfig.logger.info(`audioVideoObserver: videoAvailabilityDidChange: ${availability}`);
  }

  /**
   * Occurs when attendee tries to start video but the maximum video limit of 25 tiles has
   * already been reached by other attendees sharing their video.
   */
  videoSendDidBecomeUnavailable?() {
    meetingConfig.logger.info(`audioVideoObserver: videoSendDidBecomeUnavailable`);
  }

  /**
   * Occurs periodically when WebRTC media stats are available
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  metricsDidReceive?(clientMetricReport: ClientMetricReport) {
    // meetingConfig.logger.info(`metricsDidReceive: ${clientMetricReport}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connectionHealthDidChange?(connectionHealthData: ConnectionHealthData) {
    // meetingConfig.logger.info(`connectionHealthDidChange: ${connectionHealthData}`);
  }

  connectionDidBecomePoor?() {
    meetingConfig.logger.info(`connectionDidBecomePoor`);
  }

  connectionDidSuggestStopVideo?() {
    meetingConfig.logger.info(`connectionDidSuggestStopVideo`);
  }

  connectionDidBecomeGood?() {
    meetingConfig.logger.info(`connectionDidBecomeGood`);
  }

  remoteVideoSourcesDidChange?(videoSources: VideoSource[]) {
    meetingConfig.logger.info(`remoteVideoSourcesDidChange: ${videoSources}`);
  }

  encodingSimulcastLayersDidChange?(simulcastLayers: SimulcastLayers) {
    meetingConfig.logger.info(`encodingSimulcastLayersDidChange: ${simulcastLayers}`);
  }

  audioVideoWasDemotedFromPrimaryMeeting?(status: MeetingSessionStatus) {
    meetingConfig.logger.info(`audioVideoWasDemotedFromPrimaryMeeting: ${status}`);
  }

  // =========== ContentShareObserver ==========

  contentShareDidStart() {
    meetingConfig.logger.info(`contentShareObserver: contentShareDidStart`);
    this.contentShareActiveSubject.next(true);
  }

  contentShareDidStop() {
    meetingConfig.logger.info(`contentShareObserver: contentShareDidStop`);
    this.contentShareActiveSubject.next(false);
  }

  contentShareDidPause() {
    meetingConfig.logger.info(`contentShareObserver: contentShareDidPause`);
    this.contentShareActiveSubject.next(false);
  }

  contentShareDidUnpause() {
    meetingConfig.logger.info(`contentShareObserver: contentShareDidUnpause`);
    this.contentShareActiveSubject.next(true);
  }

  async getVideoTransformDevice(device: Device, shouldBlurBackground: boolean): Promise<DefaultVideoTransformDevice> {
    const logger = new ConsoleLogger('uc-web-v2 Video Transform Logger', LogLevel.INFO);
    const processors: VideoFrameProcessor[] = [];

    if (shouldBlurBackground) {
      const processor = await this.getBackgroundBlurProcessor();
      if (processor) {
        processors.push(processor);
      }
    }

    return new DefaultVideoTransformDevice(logger, device, processors);
  }

  async getBackgroundBlurProcessor(): Promise<BackgroundBlurProcessor | undefined> {
    if (!(await BackgroundBlurVideoFrameProcessor.isSupported())) {
      return undefined;
    }
    const blurProcessor = await BackgroundBlurVideoFrameProcessor.create();
    if (!blurProcessor) {
      return undefined;
    }

    return blurProcessor;
  }
}
