import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AudioUtil, microphonePermission, sanitizeAudioContextSinkId } from '@app/shared/utils/audio.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, firstValueFrom } from 'rxjs';

import { AudioSettings } from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';
import { SettingsService } from '../../services/settings.service';

type Type = 'Call' | 'Notification';

@UntilDestroy()
@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent extends SettingsPage implements OnInit, OnDestroy {
  ringtoneIncomingCall = '../../../assets/sounds/ringtone-incoming-call.wav';
  notificationSound = '../../../assets/sounds/notification_simple-01.wav';
  audioElement = new Audio() as AudioUtil;
  inputSound = new Audio() as AudioUtil;
  isRecording: boolean | null = false; //null means playing back the recorded sound
  recorder: MediaRecorder;
  counter = 0;
  currentMicrophoneVolume: number = 0;
  currentSpeakerVolume: number = 0;
  canceled = false;
  stream: MediaStream | null = null;
  private audioContext = new AudioContext();
  private audioSource: MediaElementAudioSourceNode | MediaStreamAudioSourceNode | null = null;
  audioForm: FormGroup;
  @Input() displayedInPopupWindow = false;
  protected activeTest?: Type;

  get microphoneDevice(): string {
    return this.audioForm.get('microphoneDevice')!.value;
  }

  get speakerDevice(): string {
    return this.audioForm.get('speakerDevice')!.value;
  }

  get notificationSoundVolume(): number {
    return this.audioForm.get('notificationSoundVolume')!.value;
  }

  get ringingSoundVolume(): number {
    return this.audioForm.get('ringingSoundVolume')!.value;
  }

  get notificationSpeakerDevice() {
    return this.audioForm.get('notificationSpeakerDevice')!.value;
  }

  constructor(
    public settingsService: SettingsService,
    private appConfigService: AppConfigService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.audioForm = this.createFormGroup(this.settingsService.getDefaultAudioSettings());
  }

  ngOnInit() {
    this.settingsService.audioSettings$.pipe(untilDestroyed(this)).subscribe(async (settings: AudioSettings) => {
      settings.quietRinging = false; // TODO: remove this when we return to the quietRinging option
      settings = this.settingsService.audioReplaceNullsWithDefaults(settings);
      this.audioForm = this.createFormGroup(settings);
      await this.scanDevices();

      if (!this.settingsService.isSetSinkIdSupported || this.settingsService.outputDevices.length === 0) {
        this.audioForm.controls['speakerDevice'].disable();
      }
    });
  }

  private createFormGroup(audioSettings: AudioSettings): FormGroup {
    return this.formBuilder.group({
      notificationSoundVolume: [audioSettings.notificationSoundVolume],
      ringingSoundVolume: [audioSettings.ringingSoundVolume],
      quietRinging: [audioSettings.quietRinging],
      speakerDevice: [audioSettings.speakerDevice],
      notificationSpeakerDevice: [audioSettings.notificationSpeakerDevice],
      microphoneDevice: [audioSettings.microphoneDevice],
    });
  }

  async scanDevices() {
    try {
      if (await microphonePermission.check()) {
        await this.settingsService.scanForAudioDevices();
        this.setDefaultDevices();
      }
    } catch (error) {
      console.error('Error enumerating devices:', error);
    }
  }

  private setDefaultDevices(): void {
    const inputDevices = this.settingsService.inputDevices;
    if (inputDevices.length > 0) {
      const defaultMicrophoneDevice = inputDevices[0].deviceId;
      const isDefaultMicrophoneAvailable = inputDevices.some((device) => device.deviceId === this.microphoneDevice);
      this.audioForm.patchValue({
        microphoneDevice: isDefaultMicrophoneAvailable ? this.microphoneDevice : defaultMicrophoneDevice,
      });
    } else {
      this.audioForm.controls['microphoneDevice'].disable();
    }

    const outputDevices = this.settingsService.outputDevices;
    if (outputDevices.length > 0) {
      const defaultSpeakerDevice = outputDevices[0].deviceId;
      const isDefaultSpeakerAvailable = outputDevices.some((device) => device.deviceId === this.speakerDevice);
      const isDefaultNotificationSpeakerAvailable = outputDevices.some(
        (device) => device.deviceId === this.notificationSpeakerDevice
      );
      this.audioForm.patchValue({
        speakerDevice: isDefaultSpeakerAvailable ? this.speakerDevice : defaultSpeakerDevice,
        notificationSpeakerDevice: isDefaultNotificationSpeakerAvailable
          ? this.notificationSpeakerDevice
          : defaultSpeakerDevice,
      });
    }
  }

  async setupAudioContextAndNodes(
    audio: MediaStream | HTMLAudioElement,
    device?: {
      deviceId: string;
      isOutputDevice: boolean;
    }
  ) {
    this.audioSource =
      audio instanceof HTMLAudioElement
        ? this.audioContext.createMediaElementSource(audio)
        : this.audioContext.createMediaStreamSource(audio);

    const destination = this.audioContext.createMediaStreamDestination();
    const analyser = this.audioContext.createAnalyser();
    analyser.fftSize = 2048;

    // When the audio graph is set up using AudioContext, we need to call
    // setSinkId on it otherwise audio gets piped through the OS-default audio device.
    if ('setSinkId' in AudioContext.prototype && device && device.isOutputDevice) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (this.audioContext as any).setSinkId(sanitizeAudioContextSinkId(device.deviceId));
    }
    this.audioSource?.connect(analyser);
    audio instanceof HTMLAudioElement && analyser.connect(this.audioContext.destination);

    return { destination, analyser };
  }

  drawAudioMeter(analyser: AnalyserNode, updateVolumeCallback: (volume: number) => void) {
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const drawMeter = () => {
      requestAnimationFrame(drawMeter);
      analyser.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((a, b) => a + b) / bufferLength;
      const percentage = Math.min(100, average);
      const volume = Math.floor(percentage / 10);
      updateVolumeCallback(volume);
    };
    drawMeter();
  }

  async setupAndStartRecorder(destination: MediaStreamAudioDestinationNode) {
    this.recorder = new MediaRecorder(destination.stream);
    const chunks: Blob[] = [];
    this.counter = 1;
    let intervalId = setInterval(() => {
      this.counter++;
    }, 1000);
    this.isRecording = true;
    this.recorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };
    this.recorder.onstop = async () => {
      clearInterval(intervalId);
      const audioBlob = new Blob(chunks, { type: 'audio/webm' });
      this.inputSound.src = URL.createObjectURL(audioBlob);
      this.isRecording = null;
      intervalId = setInterval(() => {
        this.counter--;
        if (this.counter === 0) {
          this.isRecording = false;
          clearInterval(intervalId);
        }
      }, 1000);
      if (!this.canceled) {
        await this.settingsService.playAudio(this.inputSound, this.ringingSoundVolume, this.speakerDevice);
      }
    };

    this.recorder.start();

    setTimeout(() => {
      this.stopInputTest();
    }, 10_000);
  }

  async testInputSound() {
    try {
      this.stopInputTest();
      this.stopOutputTest();

      this.audioElement = new Audio() as AudioUtil;
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: this.microphoneDevice } });
      const { destination, analyser } = await this.setupAudioContextAndNodes(this.stream, {
        deviceId: this.microphoneDevice,
        isOutputDevice: false,
      });
      this.drawAudioMeter(analyser, (volume) => (this.currentMicrophoneVolume = volume));
      await this.setupAndStartRecorder(destination);
      this.audioSource?.connect(destination);
    } catch (error) {
      console.log(error);
    }
  }

  async testOutputDevice(device: string, type: Type) {
    this.stopInputTest();
    this.stopOutputTest();

    this.audioElement = new Audio(this.ringtoneIncomingCall) as AudioUtil;
    this.activeTest = type;
    this.audioElement.loop = true;
    const soundVolume = type === 'Call' ? this.ringingSoundVolume : this.notificationSoundVolume;
    await this.settingsService.playAudio(this.audioElement, soundVolume, device);
    const { analyser } = await this.setupAudioContextAndNodes(this.audioElement, {
      deviceId: device,
      isOutputDevice: true,
    });
    this.drawAudioMeter(analyser, (volume) => (this.currentSpeakerVolume = volume));
  }

  stopOutputTest() {
    this.activeTest = undefined;
    this.audioElement.pause();
  }

  stopInputTest() {
    this.activeTest = undefined;
    this.inputSound.pause();
    if (this.isRecording) {
      this.recorder.stop();
    }
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
      this.stream = null;
    }
  }

  protected async onChange(type: Type) {
    this.stopInputTest();
    this.stopOutputTest();

    const src = type === 'Call' ? this.ringtoneIncomingCall : this.notificationSound;
    const volume = type === 'Call' ? this.ringingSoundVolume : this.notificationSoundVolume;

    this.audioElement = new Audio(src) as AudioUtil;
    this.audioElement.loop = false;
    await this.settingsService.playAudio(this.audioElement, volume, this.notificationSpeakerDevice);

    this.disabled = false;
  }

  async save() {
    this.stopInputTest();
    this.stopOutputTest();
    this.loading = true;
    await firstValueFrom(
      this.appConfigService.saveAudioSettings(this.audioForm.value).pipe(finalize(() => (this.loading = false)))
    );
  }

  ngOnDestroy() {
    this.canceled = true;
    this.disabled = true;
    this.stopInputTest();
    this.stopOutputTest();
  }
}
