import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProtocolHandlerService implements NavigatorContentUtils {
  static readonly localStorageKey = 'registeredProtocolHandlers';

  constructor(private storage: LocalStorageService) {}

  isRegistered(protocol: string): boolean {
    const registeredProtocolHandlers = this.storage.get<string[]>(ProtocolHandlerService.localStorageKey) ?? [];
    return registeredProtocolHandlers.includes(protocol);
  }

  registerProtocolHandler(scheme: string, url: string | URL): void {
    navigator.registerProtocolHandler(scheme, url);
    const registeredProtocolHandlers = this.storage.get<string[]>(ProtocolHandlerService.localStorageKey) ?? [];
    if (registeredProtocolHandlers.length === 0) {
      this.storage.set(ProtocolHandlerService.localStorageKey, ['tel']);
    } else {
      registeredProtocolHandlers.push('tel');
      this.storage.set(ProtocolHandlerService.localStorageKey, registeredProtocolHandlers);
    }
  }
}
