export type eventCallback = (data: EventData) => void;

export class EventData {
  name: EventName;
  message: string;
  count: number;

  constructor(name: EventName, message = '', count = 0) {
    this.name = name;
    this.message = message;
    this.count = count;
  }
}

export enum EventName {
  FocusSelectContact = 'focus_select_contact',
  Logout = 'logout',
  Login = 'login',
  PopoutWindowResize = 'popout_window_resize',
  DialerInputTabKeydown = 'dialer_input_tab_keydown',
  ContactListHeaderInputTabKeydown = 'contact_list_header_input_tab_keydown',
  PopoutWindowFailedToOpen = 'popout_window_failed_to_open',
}
