import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactService } from '@app/contacts/services/contact.service';
import { BaseStateService } from '@app/core/services/base.state.service';
import { WsService } from '@app/core/services/ws.service';
import { catchError, finalize, map, Observable, of, tap } from 'rxjs';

import { PARK_LOT_EXT_PREFIX, ParkedCall, ParkingLot, ParkingLotDto } from '../models/parking-lot.model';

@Injectable({
  providedIn: 'root',
})
export class CallParkService extends BaseStateService<ParkingLot> {
  protected override readonly baseUrl = 'users/{me}/call';
  path = '';

  constructor(httpClient: HttpClient, private wsService: WsService, private contactService: ContactService) {
    super(httpClient);
    this.bindSocket();

    this.contactService.currentUser$.subscribe(() => {
      /**
       *  Filter parking queues based on user pbxSite
       */
      this.setData(this.getData());
    });
  }

  override refreshData(): Observable<ParkingLot[]> {
    return this.getLots().pipe(
      catchError(() => {
        this.loadingSubject.next(false);
        this.errorSubject.next(true);
        return of([]);
      }),
      map((response) => {
        const parkingLots: ParkingLot[] = response.map((parkingLotDto: ParkingLotDto) => {
          return {
            id: parkingLotDto.lotId,
            coin: parkingLotDto.coin,
            title: parkingLotDto.title,
            department: parkingLotDto.department,
            site: parkingLotDto.site,
            parkedCalls: parkingLotDto.parkedCalls,
          };
        });
        return parkingLots;
      }),
      tap((data) => this.setData(data)),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  override setData(data: ParkingLot[]) {
    /**
     *  Filter based on user pbxSite, empty park queue site should not be filtered.
     *  Do not use filtering in case user has pbxSite an empty string
     */
    const pbxSite = this.contactService.currentUser?.pbxSite ?? '';
    let filteredLots = data;
    if (pbxSite !== '') {
      filteredLots = filteredLots.filter((lot) => [pbxSite, ''].includes(lot.site));
    }
    super.setData(filteredLots);
  }

  getLots(): Observable<ParkingLotDto[]> {
    return this.get<ParkingLotDto[]>('parking-lots');
  }

  getLot(phoneNumber: string): ParkingLot | undefined {
    if (!phoneNumber.startsWith(PARK_LOT_EXT_PREFIX)) {
      return;
    }
    const lotId = phoneNumber.replace(PARK_LOT_EXT_PREFIX, '');
    return this.getData().find((x) => x.id == lotId);
  }

  parkCall(id: string, lot?: string) {
    return this.post<ParkingLotDto>('park', {
      call_id: id,
      ...(lot != null && { lots: [lot] }),
    });
  }

  park(id: string, parkCall: ParkedCall[]) {
    this.updateField(id, 'parkedCalls', parkCall as []);
  }

  pickup(lot: string | ParkingLot) {
    this.updateField(typeof lot === 'string' ? lot : lot.id, 'parkedCalls', []);
  }

  /**
   * Determines if any lots current have actively parked calls.
   * @param lots The lots to check. If not provided, all lots will be checked.
   * @returns True if any lots have parked calls, false otherwise.
   */
  hasParkedCalls(lots?: ParkingLot[]): boolean {
    return (lots ?? this.getData()).some((lot) => lot.parkedCalls.length > 0);
  }

  private bindSocket() {
    this.wsService.socket.on('CallParked', (data: ParkingLotDto) => {
      this.park(data.lotId, data.parkedCalls);
    });

    this.wsService.socket.on('ParkedCallRetrieved', (data: ParkingLotDto) => {
      this.pickup(data.lotId);
    });
  }
}
