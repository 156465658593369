import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProtocolHandlerService } from '@app/core/services/protocol-handler.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'app-register-tel-handler-dialog',
  templateUrl: './register-tel-handler-dialog.component.html',
  styleUrls: ['./register-tel-handler-dialog.component.scss'],
})
export class RegisterTelHandlerDialogComponent {
  protocolUrl: string;

  constructor(
    private protocolHandlerService: ProtocolHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: { protocolUrl: string },
    private dialog: MatDialog
  ) {
    this.protocolUrl = data.protocolUrl;

    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'ConntecUC has been installed.',
          description: 'Click on Register to have ConnectUC handle phone number links.',
          buttons: [
            {
              label: 'Cancel',
              action: () => null,
              type: ButtonType.matButton,
              color: 'primary',
            },
            {
              label: 'Register',
              action: () => this.emitRegister(),
              type: ButtonType.matRaisedButton,
              color: 'primary',
            },
          ],
        },
      })
      ?.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => dialog.closeAll());
  }

  emitRegister() {
    this.dialog.closeAll();
    this.protocolHandlerService.registerProtocolHandler('tel', this.protocolUrl);
  }
}
