import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@environment/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  environment: environment.name,
  dsn: environment.sentryDSN,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
  release: environment.awsCommitId,
  debug: false,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.sentrySampleRate,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://beta.connectuc.io/', 'https://app.connectuc.io/'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Register the service worker.
window.addEventListener('load', function () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then(function (registration) {
      if (!registration) {
        // Register the service worker
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(function (registration) {
            console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch(function (error) {
            console.log('Service Worker registration failed:', error);
          });
      } else {
        console.log('Service Worker already registered.');
      }
    });
  }
});
