<div class="alert-box">
  <div
    class="title"
    *ngIf="data.title">
    {{ data.title }}
  </div>
  <div class="gray-text">
    <div>{{ data.description }}</div>
    <div
      *ngIf="data.rememberId"
      class="remembered">
      <mat-checkbox
        tabindex="-1"
        [disableRipple]="true"
        color="primary"
        (change)="onCheckChange()"
        >Don’t show this again
      </mat-checkbox>
    </div>
  </div>
  <div class="buttons">
    <ng-container *ngFor="let button of data.buttons">
      <ng-container [ngSwitch]="button.type">
        <button
          *ngSwitchCase="ButtonType.matRaisedButton"
          mat-raised-button
          [color]="button.color"
          (click)="onButtonClick(button)">
          {{ button.label }}
        </button>
        <button
          *ngSwitchCase="ButtonType.matFlatButton"
          mat-flat-button
          [color]="button.color"
          (click)="onButtonClick(button)">
          {{ button.label }}
        </button>
        <button
          *ngSwitchCase="ButtonType.matStrokedButton"
          mat-stroked-button
          [color]="button.color"
          (click)="onButtonClick(button)">
          {{ button.label }}
        </button>
        <button
          *ngSwitchDefault
          mat-button
          [color]="button.color"
          (click)="onButtonClick(button)">
          {{ button.label }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
