import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { DeletedChannelMember } from '@app/chat/models/channel.models';
import { BaseStateService } from '@app/core/services/base.state.service';
import { WsService } from '@app/core/services/ws.service';
import { AttendeeEvent } from '@app/meetings/models/chime.models';
import normalizePhoneNumber from '@app/shared/utils/phone.util';
import { LinkedSMSContact } from '@app/sms/services/sms.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  expand,
  finalize,
  map,
  Observable,
  of,
  scan,
  Subject,
  tap,
  throttleTime,
  throwError,
} from 'rxjs';

import {
  Contact,
  CONTACT_GROUP_ALL_KEY,
  CONTACT_GROUP_FAVORITE_KEY,
  ContactFilterOptions,
  ContactGrouping,
  ContactObject,
  ContactsAndGroup,
  ContactScope,
  ContactsGroup,
  ContactSort,
  ContactType,
  PresenceType,
} from '../models/contact';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends BaseStateService<Contact> {
  flatContacts: Contact[] = [];
  protected path = '';
  protected override readonly baseUrl = 'users/{me}/contacts';
  protected usersInMeetingsSubject = new BehaviorSubject<string[]>([]);
  protected usersInMeetings$ = this.usersInMeetingsSubject.asObservable();
  public readonly currentUserSubject = new BehaviorSubject<Contact | undefined>(undefined);
  public readonly currentUser$ = this.currentUserSubject.asObservable();
  contactsById: { [key: string]: Contact } = {};
  protected contactTelsToContactId: { [key: string]: string } = {};
  protected lastPresenceForContacts: { [key: string]: PresenceType } = {};
  protected lastInMeetingForContacts: { [key: string]: boolean } = {};
  private userPresenceSubject = new BehaviorSubject<PresenceType>(PresenceType.Inactive);
  private refreshContactsSubject = new Subject<void>();

  public readonly userPresence$ = this.userPresenceSubject.asObservable();

  protected statuses = {
    [PresenceType.Open]: 'Available',
    [PresenceType.Inuse]: 'On a Call',
    [PresenceType.Ringing]: 'Ringing',
    [PresenceType.Closed]: 'Do not Disturb',
    [PresenceType.Inactive]: 'Offline',
  };

  public get currentUser(): Contact | undefined {
    return this.currentUserSubject.getValue();
  }

  public get allPBXSites(): string[] {
    const sites = new Set<string>();
    this.source.value.forEach((contact) => {
      if (contact.pbxSite) {
        sites.add(contact.pbxSite);
      }
    });

    return [...sites].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  public get allPBXDepartments(): string[] {
    const departments = new Set<string>();
    this.source.value.forEach((contact) => {
      if (contact.pbxDepartment) {
        departments.add(contact.pbxDepartment);
      }
    });

    return [...departments].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  public readonly defaultContact = ContactObject();

  public readonly deletedContact = Object.assign(ContactObject(), <Partial<Contact>>{
    id: DeletedChannelMember.id,
    firstName: 'User Not Found',
    fullName: 'User Not Found',
    directoryListing: false,
    avatar: 'assets/clear.svg',
    tels: [],
  });

  constructor(private authService: AuthService, private wsService: WsService, httpClient: HttpClient) {
    super(httpClient);
    this.data$
      .pipe(
        tap((contacts) => {
          this.flatContacts = this.createContactFromNumber(contacts);
        })
      )
      .subscribe();
    /**
     * CURRENT USER
     */
    combineLatest([
      this.authService.jwtClaims.pipe(distinctUntilChanged((previous, current) => previous?.sub === current?.sub)),
      this.data$,
      this.usersInMeetings$,
    ]).subscribe(([claim, contacts, usersInMeetings]) => {
      let currentUser: Contact | undefined;
      if (claim?.pbxConnectorUuid) {
        currentUser = contacts.find((contact) => contact.id === claim.pbxConnectorUuid);
      } else if (claim?.sub) {
        currentUser = contacts.find((contact) => contact.id === claim.sub);
      }
      this.currentUserSubject.next(currentUser);

      /** Update users in meetings */
      for (const userId of usersInMeetings) {
        if (this.contactsById.hasOwnProperty(userId)) {
          this.contactsById[userId].inMeeting = true;
          this.setStatus(userId);
        } else {
          this.lastInMeetingForContacts[userId] = true;
        }
      }
    });

    this.refreshContactsSubject.pipe(throttleTime(5000)).subscribe(() => {
      this.refreshData().subscribe();
    });
  }

  /**
   *  Should only be called once in the main component somewhere
   *
   * @returns {*}  {(void | Error)}
   */
  override init(): void | Error {
    if (this.isLoaded) {
      return new Error('Should only ever be loaded once');
    }

    /**
     * Listen to presence
     */

    this.wsService.socket.on('PresenceChanged', (event) => {
      if (this.contactsById.hasOwnProperty(event.userId)) {
        this.contactsById[event.userId].presence = event.presence;
        if (this.statuses.hasOwnProperty(event.presence)) {
          this.setStatus(event.userId);
        }
      }
      this.lastPresenceForContacts[event.userId] = event.presence;
    });

    this.wsService.socket.on('StatusChanged', (event) => {
      if (this.contactsById.hasOwnProperty(event.userId)) {
        this.contactsById[event.userId].serverEmoji = event.emoji;
        this.contactsById[event.userId].serverStatus = event.status;
        this.setStatus(event.userId);
      }
    });

    this.wsService.socket.on('AttendeeJoined', ({ userId }: AttendeeEvent) => {
      if (this.contactsById.hasOwnProperty(userId)) {
        this.contactsById[userId].inMeeting = true;
        console.log('coming from AttendeeJoined');
        this.setStatus(userId);
      }
    });

    this.wsService.socket.on('AttendeeLeft', ({ userId }: AttendeeEvent) => {
      if (this.contactsById.hasOwnProperty(userId)) {
        this.contactsById[userId].inMeeting = false;
        this.setStatus(userId);
      }
    });

    this.wsService.socket.on('UsersInMeeting', ({ userIds }: { channelId: string; userIds: string[] }) => {
      this.usersInMeetingsSubject.next(userIds);
    });

    this.wsService.socket.on('RefreshContacts', () => {
      this.refreshContactsSubject.next();
    });
    this.wsService.socket.on('ShareContactCreated', (contact: Contact) => {
      this.upsertLocalContact(contact);
    });
    this.wsService.socket.on('ShareContactUpdated', (contact: Contact) => {
      this.upsertLocalContact(contact);
    });
    this.wsService.socket.on('ShareContactDeleted', (data: { id: string }) => {
      this.deleteLocalContact(data.id);
    });
    this.wsService.socket.on('CompanyNewUser', (contact: Contact) => {
      this.upsertLocalContact(contact);
    });
    this.wsService.socket.on('CompanyUserUpdated', (contact: Contact) => {
      this.upsertLocalContact(contact);
    });
    this.wsService.socket.on('CompanyUserDeleted', (data: { id: string }) => {
      this.deleteLocalContact(data.id);
    });
    this.isLoaded = true;
  }

  getStatus(contact: Contact): string {
    const hasCustomStatus = contact.serverStatus && contact.serverStatus.trim() !== '';

    let status: string;
    // because call is handled by sip and meeting is handled by chime.
    // a user can both be in a call and in a meeting.
    if (contact.inMeeting) {
      if (contact.presence) {
        status =
          contact.presence === PresenceType.Open
            ? 'In a Meeting' + (hasCustomStatus ? ` • ${contact.serverStatus!}` : '')
            : `${this.statuses[contact.presence]} • In a Meeting`;
      } else {
        status = 'In a Meeting' + (hasCustomStatus ? ` • ${contact.serverStatus!}` : '');
      }
    } else {
      if (contact.presence) {
        if (contact.presence === PresenceType.Open) {
          status = hasCustomStatus ? contact.serverStatus! : this.statuses[PresenceType.Open];
        } else {
          status = this.statuses[contact.presence] + (hasCustomStatus ? ` • ${contact.serverStatus}` : '');
        }
      } else {
        status = hasCustomStatus ? contact.serverStatus! : '';
      }
    }

    return status;
  }

  setStatus(userId: string) {
    const contact = this.contactsById[userId];
    if (userId === this.currentUser?.id) {
      this.userPresenceSubject.next(contact?.presence ?? PresenceType.Inactive);
    }
    if (contact === undefined || contact.type != ContactType.Company) {
      return;
    }
    /**
     * Based on https://www.figma.com/file/0L95UO8uVHY502jXC7nhQM/SkySwitch-Desktop?node-id=18271%3A268777&t=0TOTz8oD1ntfQy9O-1
     */

    contact.status = this.getStatus(contact);

    /**
     * Handle Emoji
     */
    let emoji = contact.serverEmoji === null ? '' : contact.serverEmoji;

    if (contact.inMeeting) {
      emoji = `🎧️`;
    }
    if (contact.presence == PresenceType.Ringing) {
      emoji = `🔔`;
    }
    if (contact.presence == PresenceType.Inuse) {
      emoji = `📞`;
    }

    contact.emoji = emoji;
  }

  /**
   * http request for voicemail
   */
  getHttpDataContacts(params: HttpParams): Observable<{ data: Contact[]; nextCursor: string }> {
    return this.get<{ data: Contact[]; nextCursor: string }>(this.path, {
      params: params,
    }).pipe(
      map((response) => {
        // Ensure the tels property is always an array
        response.data.forEach((contact) => (contact.tels = contact.tels ?? []));
        return response;
      })
    );
  }

  private createContactFromNumber(contacts: Contact[]) {
    const contactsList: Contact[] = [];
    for (const c of contacts.filter((x) => x.tels.length > 0 || x.ext)) {
      if (c.ext) {
        contactsList.push({ ...c, tel: { number: c.ext, type: 'Work' } });
      }
      for (const t of c.tels) {
        contactsList.push({ ...c, tel: { number: t.number, type: '' } });
      }
    }
    return contactsList;
  }

  /**
   * Override of refreshData because
   * I don't want to refresh just keep state
   * and based on WS Events update the information
   *
   */
  override refreshData(): Observable<Contact[]> {
    this.loadingSubject.next(true);
    return this.fetchAllContacts(100).pipe(
      catchError(() => {
        this.errorSubject.next(true);
        return of([]);
      }),
      tap((data) => {
        this.setData(data);
      }),
      finalize(() => this.loadingSubject.next(false))
    );
  }

  // the reason we are fetching all contacts in this way is because we need to have all contacts
  // https://skyswitch.atlassian.net/browse/CUC-2380?focusedCommentId=190188
  private fetchAllContacts(pageSize: number): Observable<Contact[]> {
    const params = new HttpParams()
      .append('type', 'all')
      .append('per_page', pageSize.toString())
      .append('paginate', '1')
      .append('pagination_type', 'cursor');

    return this.loadNextPage(params).pipe(
      expand((response) =>
        response.nextCursor ? this.loadNextPage(params.set('cursor', response.nextCursor)) : EMPTY
      ),
      scan((acc, response) => [...acc, ...response.data], [] as Contact[]),
      catchError(() => {
        this.errorSubject.next(true);
        return of([]);
      })
    );
  }

  private loadNextPage(params: HttpParams): Observable<{ data: Contact[]; nextCursor: string }> {
    return this.getHttpDataContacts(params);
  }

  protected override setData(data: Contact[]) {
    const enhancedContacts = [...data, this.deletedContact]
      .sort((a, b) => {
        return a.fullName?.localeCompare(b.fullName);
      })
      .map((contact: Contact) => {
        contact.serverEmoji = contact.emoji;
        contact.serverStatus = contact.status || '';
        contact.inMeeting = false;

        if (this.lastPresenceForContacts[contact.id] != null) {
          contact.presence = this.lastPresenceForContacts[contact.id];
        }

        if (this.lastInMeetingForContacts[contact.id] != null) {
          contact.inMeeting = this.lastInMeetingForContacts[contact.id];
        }

        contact.tels = contact.tels.map((tel) => ({
          ...tel,
          number: normalizePhoneNumber(tel.number),
        }));

        if (!contact.firstName && !contact.lastName) {
          contact.fullName = contact.company;
          if (!contact.avatar) {
            contact.avatar = 'assets/company.svg';
          }
        }

        this.contactsById[contact.id] = contact;
        if (contact.ext) {
          this.contactTelsToContactId[contact.ext] = contact.id;
        }
        contact.tels.forEach((tel) => (this.contactTelsToContactId[normalizePhoneNumber(tel.number)] = contact.id));

        this.setStatus(contact.id);
        return contact;
      });
    super.setData(enhancedContacts);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateFavorite(id: string, state: boolean): Observable<any> {
    this.setFavoriteStatus(id, state);
    return this.post(id, { is_favorite: state }, { method: 'PUT' }).pipe(
      catchError(() => {
        this.setFavoriteStatus(id, !state);
        const error = new Error('API call failed');
        return throwError(() => error);
      })
    );
  }

  public setFavoriteStatus(id: string, state: boolean) {
    const contacts = [...this.source.value];
    contacts.filter((c) => c.id === id).forEach((c) => (c.favorite = state));
    this.source.next(contacts);
  }

  /**
   * Returns an Observable which will emit every time the contact data is updated (even if the observed contact has not changed).
   * This method is useful for instances where a data structure has a contactId and needs a contact, but may need to request
   * said contact _before_ the contact data has been loaded.
   */
  public observeContactById(id: string): Observable<Contact | undefined> {
    return this.data$.pipe(map((contacts) => contacts.find((contact) => contact.id === id)));
  }

  public contactById(id: string): Contact | undefined {
    return this.contactsById[id] ?? undefined;
  }

  public isContactForVoiceCallsOnly(id: string): boolean {
    return this.contactById(id)?.scope === ContactScope.NoPortal;
  }

  public contactByTel(number: string): Contact | undefined {
    const contactId = this.contactTelsToContactId[normalizePhoneNumber(number)];
    return contactId ? this.contactById(contactId) : undefined;
  }

  update(contact: Contact) {
    const body = this.createBodyRequestFromContact(contact);
    return this.post<object>(contact.id, body, { method: 'PUT' }).pipe(
      tap((response) => {
        if (response['result'] === 'success') {
          this.upsertLocalContact(contact);
        }
      })
    );
  }

  public upsertLocalContact(contact: Contact) {
    const currentIndex = this.source.getValue().findIndex((c) => c.id === contact.id);
    const updatedContacts =
      currentIndex > -1
        ? this.source.getValue().map((c, index) => (index === currentIndex ? contact : c))
        : [...this.source.getValue(), contact];
    this.setData(updatedContacts);
  }

  create(contactData: Contact) {
    return this.post('', contactData).pipe(
      tap((response: Contact) => {
        this.upsertLocalContact(response);
      })
    );
  }

  deleteContact(contact: Contact) {
    return this.delete(contact.id).pipe(
      tap((response) => {
        if (response['result'] === 'success') {
          this.deleteLocalContact(contact.id);
        }
      })
    );
  }

  private deleteLocalContact(id: string) {
    const contacts = [...this.source.getValue()].filter((contact) => contact.id !== id);
    this.setData(contacts);
  }

  uploadContactAvatar(contact: Contact, formData: FormData) {
    return this.post(`${contact.id}/avatar?_method=PUT`, formData);
  }

  removeAvatar(contactId: string) {
    const body = { avatar: null };
    return this.post<object>(contactId, body, { method: 'PUT' }).pipe(
      tap((response) => {
        if (response['result'] === 'success') {
          const contacts = this.source.getValue();
          const index = contacts.findIndex((c) => c.id === contactId);
          if (index > -1) {
            contacts[index].avatar = null;
            this.setData(contacts);
          }
        }
      })
    );
  }

  createBodyRequestFromContact(contact: Contact) {
    return {
      first_name: contact.firstName,
      last_name: contact.lastName,
      tels: [...contact.tels],
      title: contact.title || null,
      emails: [...contact.emails],
      company: contact.company || null,
      addresses: [...contact.addresses],
    };
  }

  filterSortAndGroupContacts(
    contactFilterOptions: ContactFilterOptions,
    contactSort: ContactSort,
    contactGrouping: ContactGrouping,
    contacts: Contact[] = this.source.value
  ): ContactsAndGroup {
    const filteredContacts = contacts.filter((contact) =>
      this.contactPassesFilterOptions(contact, contactFilterOptions)
    );

    const sortedContacts = this.sortContactsBy(contactSort, filteredContacts);

    // Group remaining contacts using our grouping strategy.
    const groupedContacts = this.groupContactsBy(contactGrouping, contactSort, sortedContacts);
    return {
      contacts: filteredContacts,
      groups: groupedContacts,
    };
  }

  contactPassesFilterOptions(contact: Contact, contactFilterOptions: ContactFilterOptions): boolean {
    let passed = true;
    // Filter by contact type. If contactType === ContactType.All, we don't need to filter since that
    // covers all contacts.
    if (contactFilterOptions.contactType && contactFilterOptions.contactType !== ContactType.All) {
      passed = passed && contact.type === contactFilterOptions.contactType;
    }

    // Filter by pbxSite, if set.
    if (contactFilterOptions.pbxSite) {
      passed = passed && contact.pbxSite === contactFilterOptions.pbxSite;
    }

    // Filter by pbxDepartment, if set.
    if (contactFilterOptions.pbxDepartment) {
      passed = passed && contact.pbxDepartment === contactFilterOptions.pbxDepartment;
    }

    return passed;
  }

  sortContactsBy(sort: ContactSort, contacts: Contact[]): Contact[] {
    console.log(`contact filter: sorting by ${sort}`);
    return contacts.sort((lhs, rhs) => {
      const lhsContactName = this.contactNameForSort(sort, lhs);
      const rhsContactName = this.contactNameForSort(sort, rhs);
      return lhsContactName.localeCompare(rhsContactName);
    });
  }

  groupContactsBy(grouping: ContactGrouping, sort: ContactSort, contacts: Contact[]): ContactsGroup {
    console.log(`contact filter: grouping by ${grouping} sort: ${sort}`);
    const contactsGroup: ContactsGroup = {};
    for (const contact of contacts) {
      let key: string;
      if (contact.favorite) {
        key = CONTACT_GROUP_FAVORITE_KEY;
      } else {
        switch (grouping) {
          case ContactGrouping.Name: {
            key = this.contactNameForSort(sort, contact).charAt(0).toUpperCase();
            break;
          }
          case ContactGrouping.Site: {
            key = contact.pbxSite || 'No Site';
            break;
          }
          case ContactGrouping.Department: {
            key = contact.pbxDepartment || 'No Department';
            break;
          }
          case ContactGrouping.Company: {
            key = contact.company || 'No Company';
            break;
          }
          case ContactGrouping.FavoritesOnly: {
            key = CONTACT_GROUP_ALL_KEY;
            break;
          }
        }
      }
      (contactsGroup[key] = contactsGroup[key] || []).push(contact);
    }

    return contactsGroup;
  }

  private contactNameForSort(sort: ContactSort, contact: Contact): string {
    return sort === ContactSort.FirstName ? contact.fullName : `${contact.lastName} ${contact.firstName}`;
  }

  contactHasMoreThanOneNumber(contact: Contact | undefined): boolean {
    if (contact) {
      if (contact.tels.length > 1) {
        return true;
      } else if (contact.tels.length > 0 && contact.ext) {
        return true;
      }
    }
    return false;
  }

  getContactNumber(contact: Contact): string | null {
    if (contact) {
      if (contact.ext) {
        return contact.ext;
      } else if (contact.tels.length > 0) {
        return contact.tels[0].number;
      }
    }
    return null;
  }

  getContactByNumber(number: string): Contact {
    const contact = ContactObject();
    contact.fullName = number;
    contact.tel = { number, type: '' };
    return this.source.getValue().find((c) => c.ext === number || c.tels.some((t) => t.number === number)) ?? contact;
  }

  getContactByPhoneNumber(number: string): Contact | undefined {
    return this.flatContacts.find((c) => c.tel?.number === number);
  }

  isContactMatch(contact: Contact, filterValue: string) {
    return (
      contact.fullName?.toLowerCase().includes(filterValue) ||
      contact.username?.toLowerCase().includes(filterValue) ||
      contact.ext?.toLowerCase() === filterValue ||
      contact.tels.some((t) => t.number.includes(filterValue))
    );
  }

  public filterSMSContactsByValue(value: string, selectedContacts: LinkedSMSContact[]): LinkedSMSContact[] {
    const filterValue = value.toLowerCase();
    const selectedContactNumbers = new Set(selectedContacts.map((c) => c.number));

    const numbersToIgnore = this.currentUser?.tels.map((tel) => tel.number) || [];
    const ignoreNumbers = new Set(numbersToIgnore);

    return this.getData()
      .flatMap((contact) => {
        // Map each tel value to an individual entry.
        return (contact.tels ?? []).map((tel) => ({ tel, contact }));
      })
      .filter(({ tel, contact }) => {
        return (
          !selectedContactNumbers.has(tel.number) &&
          !ignoreNumbers.has(tel.number) &&
          (contact.fullName.toLowerCase().includes(filterValue) ||
            tel.number.startsWith(value) ||
            (tel.ext && tel.ext.toString().startsWith(value)))
        );
      })
      .map(({ tel, contact }) => {
        return { number: tel.number, contact: contact };
      })
      .sort((lhs, rhs) => {
        const index =
          lhs.contact.fullName.toLowerCase().indexOf(filterValue) -
          rhs.contact.fullName.toLowerCase().indexOf(filterValue);
        return index === 0
          ? lhs.contact.fullName.toLowerCase().localeCompare(rhs.contact.fullName.toLowerCase())
          : index;
      });
  }

  public exportContacts(contactType: ContactType): Observable<BlobPart> {
    return this.get(`/export?type=${contactType}`, {
      responseType: 'text' as never,
    });
  }

  public importContacts(formData: FormData) {
    return this.post(`/import`, formData);
  }
}
