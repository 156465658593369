import { Injectable } from '@angular/core';
import { CallHistory } from '@app/call-history/models/call-history.models';
import { environment } from '@environment/environment';
import Dexie from 'dexie';
import { LogLevel } from 'sip.js/lib/api/user-agent-options';

export interface CallReport {
  id?: number;
  sipLogs: SipLogs[];
  webRtc: string;
  callHistoryId?: string;
  date: Date | string;
  connectUcVersion: string;
  consoleLogs: Array<{ type: string; data: string }>;
}

interface SipLogs {
  sessionId: string;
  level: LogLevel;
  category: string;
  content: string;
  label?: string | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  callReport: CallReport | undefined;
  sipLogs: SipLogs[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private indexedDb: any;
  logStatements: Array<{ type: string; data: string }> = [];
  callLogs: Array<{ type: string; data: string }> = [];

  constructor() {
    this.initializeDatabase();
    this.captureConsole();
  }

  private initializeDatabase(): void {
    this.indexedDb = new Dexie('skySwitch');
    this.indexedDb.version(1).stores({
      callReport: '++id,sipLogs,webRtc,callHistoryId,date,connectUcVersion,consoleLogs',
    });
  }

  pushSipLog(sessionId: string, level: LogLevel, category: string, label: string | undefined, content: string) {
    this.sipLogs.push({ level, category, content, label, sessionId });
  }

  saveCallReport(webRtc: string, date: Date) {
    this.callReport = {
      sipLogs: this.sipLogs,
      webRtc,
      date,
      connectUcVersion: environment.awsCommitId,
      consoleLogs: this.callLogs,
    };
    this.sipLogs = [];
    this.callLogs = [];
  }

  async createCallReport(callHistoryId: string) {
    if (!this.callReport) {
      return;
    }
    await this.indexedDb.callReport.add({ ...this.callReport, callHistoryId });
    this.callReport = undefined;
  }

  getCallReports(): Promise<CallReport[]> {
    return this.indexedDb.callReport.toArray();
  }

  drop(isInitialize = false) {
    this.indexedDb.delete();
    if (isInitialize) {
      this.initializeDatabase();
    }
  }

  private captureConsole() {
    ['log', 'warn', 'info', 'debug', 'error'].forEach((logType) => {
      const originalMethod = console[logType];
      console[logType] = (...messages) => {
        this.logStatements.push({ type: logType, data: messages.toString() });
        this.callLogs.push({ type: logType, data: messages.toString() });
        originalMethod.apply(console, messages);
      };
    });
  }
  private formatMessage(level: string, message: string): string {
    const moduleIdentifier = 'uc-web-v2';
    if (message.includes(moduleIdentifier)) {
      return message;
    }
    const timestamp = new Date().toISOString();
    return `\n${level === 'log' ? 'console' : level} : ${timestamp} ${moduleIdentifier} - ${message}`;
  }

  getConsoleLogs(callReports: Array<CallHistory & { callReport?: CallReport }>) {
    let string = '';
    string += '\n';
    this.logStatements.forEach((log) => (string += this.formatMessage(log.type, log.data)));
    callReports.forEach((report) => {
      if (report.callReport) {
        report.callReport.consoleLogs.forEach((log) => (string += this.formatMessage(log.type, log.data)));
      }
    });

    return string.trim();
  }
}
