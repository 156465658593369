import { Injectable, OnDestroy } from '@angular/core';
import { UcLinkSocketMessageType } from '@app/core/types/uc-link-socket-message.type';

import { WebsocketInit } from '../models/connection-status';
import { ConnectedAppType } from '../models/link.model';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root',
})
export class LinkUCService implements OnDestroy, WebsocketInit {
  private webSocket: WebSocket | null = null;
  private socketUrl = 'ws://localhost:64444';
  private retryTime = 5000;
  private isLinkAppConnected = false;
  private isLinkWebSocketConnected = false;
  private currentRetryTimeout?: NodeJS.Timeout;

  constructor(private wsService: WsService) {}

  bindSocketEvents() {
    this.wsService.socket.on('UserConnectedAppTypes', (appTypes: ConnectedAppType[]) => {
      this.isLinkAppConnected = appTypes.includes('link');
      if (this.isLinkAppConnected) {
        this.setUpLinkWebSocket();
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.webSocket?.close();
  }

  private setUpLinkWebSocket() {
    this.webSocket = new WebSocket(this.socketUrl);
    this.webSocket.addEventListener('open', () => {
      console.log('LocalWebSocket', 'Open');
      this.isLinkWebSocketConnected = true;
      this.send(UcLinkSocketMessageType.UC_WEB_CONNECTED);
    });

    this.webSocket.addEventListener('message', (message) => {
      if (
        message.data === UcLinkSocketMessageType.UC_LINK_RELOADED ||
        message.data === UcLinkSocketMessageType.UC_LINK_CONNECTED
      ) {
        this.send(UcLinkSocketMessageType.UC_WEB_CONNECTED);
      }
    });

    this.webSocket.addEventListener('close', (close) => {
      if (this.isLinkWebSocketConnected) {
        console.log('LocalWebSocket closed:', close);
        this.send(UcLinkSocketMessageType.UC_WEB_DISCONNECTED);
      }
      this.isLinkWebSocketConnected = false;
      this.retry();
    });

    this.webSocket.addEventListener('error', (error) => {
      if (this.isLinkWebSocketConnected) {
        console.log('LocalWebSocket error:', error);
        this.send(UcLinkSocketMessageType.UC_WEB_DISCONNECTED);
      }
    });

    // Add event listener for beforeunload to handle page or browser close
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  send(data: string) {
    if (this.webSocket?.readyState === WebSocket.OPEN) {
      this.webSocket.send(data);
    }
  }

  handleBeforeUnload = () => {
    this.send(UcLinkSocketMessageType.UC_WEB_DISCONNECTED);
  };

  private retry() {
    clearTimeout(this.currentRetryTimeout);

    this.currentRetryTimeout = setTimeout(() => {
      if (this.isLinkAppConnected) {
        this.setUpLinkWebSocket();
      }
    }, this.retryTime);
  }
}
