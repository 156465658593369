import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { SocketUC } from '@app/core/services/socket-uc.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, ReplaySubject } from 'rxjs';

import { ConnectionStatus } from '../models/connection-status';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  private status = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Connecting);
  public readonly status$ = this.status.asObservable();
  private _isConnected$ = new ReplaySubject<boolean>(1);
  get isConnected$() {
    return this._isConnected$.pipe(debounceTime(300), distinctUntilChanged());
  }

  constructor(private authService: AuthService, public socket: SocketUC) {}

  init() {
    this.socket.on('connect_error', () => {
      this.status.next(ConnectionStatus.Disconnected);
      this._isConnected$.next(false);
    });

    this.socket.on('connect', () => {
      console.log('Websocket connected');
      this.socket.send('join', { channel: this.authService.jwtClaims.getValue()?.uid }); //we need to emit a join command that weird getValue()? is on me XD refactor is needed
      this.status.next(ConnectionStatus.Connected);
      this._isConnected$.next(true);
    });

    this.socket.on('connecting', () => {
      console.log('Websocket connecting');
    });

    this.socket.on('disconnect', () => {
      console.log('Websocket disconnected');
      this.status.next(ConnectionStatus.Disconnected);
      this._isConnected$.next(false);
    });

    this.authService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      if (isAuthenticated) {
        await this.connect();
      } else {
        this.disconnect();
      }
    });
  }

  async connect(resetCounter = false) {
    this.status.next(ConnectionStatus.Connecting);
    try {
      await this.socket.connect(resetCounter);
    } catch (error: unknown) {
      this.status.next(ConnectionStatus.Disconnected);
      throw error;
    }
  }

  disconnect() {
    this.socket.connectionStatus = 'Disconnecting';
    this.socket.disconnect();
  }
}
