import { Injectable } from '@angular/core';
import { Track } from '@app/shared/models/track.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioPlayerService {
  // Dynamic update of playlist
  tracks: Track[] = [];
  // Get the current track
  currentTrack!: Track;
  currentTrackSubject$: BehaviorSubject<Track> = new BehaviorSubject<Track>(this.currentTrack);

  // Get the current time
  currentTime: number = 0;
  currentTimeSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(this.currentTime);

  setCurrentTrack(currentTrack: Track) {
    this.currentTrack = currentTrack;
    this.currentTrackSubject$.next(this.currentTrack);
  }

  getCurrentTrack(): Observable<Track> {
    return this.currentTrackSubject$.asObservable();
  }

  setCurrentTime(currentTime: number) {
    this.currentTime = currentTime;
    this.currentTimeSubject$.next(this.currentTime);
  }
}
