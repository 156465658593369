export type AudioUtil = HTMLAudioElement & {
  setSinkId(deviceId: string): Promise<undefined>;
};

export const microphonePermission = {
  check: async function (): Promise<boolean> {
    if (!navigator.permissions) {
      return true;
    }
    try {
      const micPermission = await navigator.permissions.query({ name: 'microphone' as PermissionName });
      if (micPermission.state === 'granted') {
        return true;
      } else if (micPermission.state === 'prompt') {
        alert('Please grant access to the microphone permission to use this functionality.');
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaStream?.getTracks().forEach((track) => track.stop());
        await navigator.permissions.query({ name: 'popup' as PermissionName });
        return true;
      } else {
        alert('Microphone access denied.');
        return false;
      }
    } catch (error) {
      // Some permissions are not available for query at this time. If we get a TypeError assume Browser don't have the
      // permission and proceed.
      return error instanceof TypeError;
    }
  },
};

/**
 * `AudioContext.setSinkId` doesn't like `undefined or `'default'` being used as an argument. This method
 * takes those values and converts them to the expected default value of an empty string. For all other values,
 * the original value is returned.
 */
export const sanitizeAudioContextSinkId = (deviceId: string | undefined): string => {
  return !deviceId || deviceId === 'default' ? '' : deviceId;
};
