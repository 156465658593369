import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProvisionRole } from '@app/auth/models/users.model';
import { AuthService } from '@app/auth/services/auth.service';
import { AppFeature } from '@app/core/models/config.models';
import { Provision } from '@app/core/models/provision.model';
import { AppConfigService } from '@app/core/services/app-config.service';
import { BaseStateService } from '@app/core/services/base.state.service';
import { WsService } from '@app/core/services/ws.service';
import { SecondsToMinutesPipe } from '@app/shared/pipes/seconds-to-minutes.pipe';
import { environment } from '@environment/environment';
import { BehaviorSubject, catchError, firstValueFrom, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import {
  AgentCustomStatusPayload,
  AgentUpdatedPayload,
  AllQueuesResponse,
  CallCenterStatus,
  CallEndedPayload,
  CallNote,
  Disposition,
  LoginMethod,
  Queue,
  QueuePayload,
  QueuesResponse,
  Stats,
  StatsDisplayNames,
  StatsHoverInfo,
  StatsResponse,
} from '../models/call-center.models';

@Injectable({
  providedIn: 'root',
})
export class CallCenterService extends BaseStateService<Queue> {
  path = '/agent';
  protected override baseUrl = `${environment.faxHubGateway}/{me}`;
  protected readonly hasErrorQueuesSubject = new BehaviorSubject<boolean>(false);
  public readonly hasErrorQueues$ = this.hasErrorQueuesSubject.asObservable();
  protected readonly hasErrorStatsSubject = new BehaviorSubject<boolean>(false);
  public readonly hasErrorStats$ = this.hasErrorStatsSubject.asObservable();

  public readonly isOnlineSubject = new BehaviorSubject<boolean>(false);
  public readonly isOnline$ = this.isOnlineSubject.asObservable();
  public readonly currentStatusSubject = new BehaviorSubject<CallCenterStatus | null>(null);
  public readonly currentStatus$ = this.currentStatusSubject.asObservable();
  public readonly isCallCenterAgentSubject = new BehaviorSubject<boolean>(false);
  public readonly isCallCenterAgent$ = this.isCallCenterAgentSubject.asObservable();
  public readonly statsSubject = new BehaviorSubject<Stats[]>([]);
  public readonly stats$ = this.statsSubject.asObservable();
  public readonly dispositionsSubject = new BehaviorSubject<Disposition[]>([]);
  public readonly dispositions$ = this.dispositionsSubject.asObservable();
  public readonly loginMethodSubject = new BehaviorSubject<LoginMethod[]>([]);
  public readonly loginMethod$ = this.loginMethodSubject.asObservable();

  // Maps queues queue to message object.
  private queuesMap = new Map<string, Queue>();
  private allQueuesMap = new Map<string, Queue>();
  private domain: string;

  // ========== Lifecycle ===========

  constructor(
    httpClient: HttpClient,
    private wsService: WsService,
    private authService: AuthService,
    private configService: AppConfigService
  ) {
    super(httpClient);

    this.authService.jwtClaims.subscribe((value) => {
      if (value) {
        this.domain = value.uid.split('@')[1];
      }
    });

    this.wsService.socket.on('CallQueued', (message: QueuePayload) => {
      this.processQueueMessage(message);
    });
    this.wsService.socket.on('CallDispatched', (message: QueuePayload) => {
      this.processQueueMessage(message);
    });
    this.wsService.socket.on('CallActivated', (message: QueuePayload) => {
      this.processQueueMessage(message);
    });
    this.wsService.socket.on('CallEnded', (message: CallEndedPayload) => {
      this.queuesMap.forEach((item) => {
        const found = item.callersWaiting.find((call) => call.orig_callid === message.orig_callid);
        if (found) {
          const callersWaiting = item.callersWaiting.filter((call) => call.orig_callid !== found.orig_callid);
          item.callersWaiting = callersWaiting;
          item.callersWaitingCount = item.callersWaiting.filter((call) => call.status !== 'talking').length.toString();
          this.queuesMap.set(item.queue, item);
          this.setData([...this.queuesMap.values()]);
        }
      });
    });
    this.wsService.socket.on('AgentlogCreated', (message: AgentCustomStatusPayload) => {
      //need to check device parameter and set that value only in case UI has the same value selected
      if (message.device && message.device !== this.configService.callCenterSettings.loginMethod) {
        return;
      }
      this.currentStatusSubject.next(this.processStatusPayload(message.status));
    });

    this.wsService.socket.on('AgentUpdated', (message: AgentUpdatedPayload) => {
      //need to check device parameter and set that value only in case UI has the same value selected
      if (message.device && message.device !== this.configService.callCenterSettings.loginMethod) {
        return;
      }
      switch (message.entry_action) {
        case 'make_ma': {
          this.queuesMap.forEach((item) => {
            //action just for specific queue
            if (message.to_as_queue) {
              if (item.queue === message.queue) {
                item.loggedIn = false;
              }
            } else {
              item.loggedIn = false;
            }
          });
          this.setData([...this.queuesMap.values()]);
          // in case the agent is online, set it to offline if all queue are offline
          if (this.isOnlineSubject.value && this.getData().every((item) => !item.loggedIn)) {
            this.isOnlineSubject.next(false);
            this.currentStatusSubject.next(null);
          }

          break;
        }
        case 'make_im': {
          this.queuesMap.forEach((item) => {
            if (message.to_as_queue) {
              if (item.queue === message.queue) {
                item.loggedIn = true;
              }
            } else {
              item.loggedIn = true;
            }
          });
          this.setData([...this.queuesMap.values()]);
          // in case is status offline, set it to online
          if (!this.isOnlineSubject.value) {
            this.isOnlineSubject.next(true);
            this.currentStatusSubject.next(null);
          }

          break;
        }
        case 'make_av': {
          this.queuesMap.forEach((item) => {
            if (message.to_as_queue) {
              if (item.queue === message.queue) {
                item.loggedIn = true;
              }
            } else {
              item.loggedIn = true;
            }
          });
          // in case is status offline, set it to online
          if (!this.isOnlineSubject.value) {
            this.isOnlineSubject.next(true);
          }
          this.currentStatusSubject.next(CallCenterStatus.SingleCall);

          break;
        }
        case 'update': {
          console.warn('AgentUpdated uc-event received. Update message is not being handled');

          break;
        }
        // No default
      }
    });

    setInterval(() => {
      this.queuesMap.forEach((item) => {
        item.callersWaiting.forEach((call) => (call.duration += 1000));
      });
    }, 1000);
  }

  protected processQueueMessage(message: QueuePayload) {
    const normalizedQueue: Queue | undefined = this.queuesMap.get(message.queue);
    // maybe it should happen that queue is not found??
    if (normalizedQueue) {
      const index = normalizedQueue.callersWaiting.findIndex((caller) => caller.orig_callid === message.orig_callid);
      if (index >= 0) {
        normalizedQueue.callersWaiting[index].callerId = message.caller_id;
        normalizedQueue.callersWaiting[index].name = message.caller_name;
        normalizedQueue.callersWaiting[index].status = message.status;
        normalizedQueue.callersWaiting[index].orig_callid = message.orig_callid;
        normalizedQueue.callersWaiting[index].term_callid = message.term_callid;
        normalizedQueue.callersWaiting[index].to = message.to;
        normalizedQueue.callersWaiting[index].time_start = message.time_start;
        normalizedQueue.callersWaiting[index].duration = Date.now() - new Date(message.time_start).getTime();
      } else {
        normalizedQueue.callersWaiting.push({
          callerId: message.caller_id,
          name: message.caller_name,
          status: message.status,
          orig_callid: message.orig_callid,
          term_callid: message.term_callid,
          to: message.to,
          time_start: message.time_start,
          duration: Date.now() - new Date(message.time_start).getTime(),
        });
      }
      normalizedQueue.callersWaitingCount = normalizedQueue.callersWaiting
        .filter((call) => call.status !== 'talking')
        .length.toString();
      this.queuesMap.set(message.queue, normalizedQueue);
      this.setData([...this.queuesMap.values()]);
    }
  }

  processStatusPayload(status: string): CallCenterStatus | null {
    if (status in CallCenterStatus) {
      return CallCenterStatus[status];
    } else if (status === 'Manual-avail') {
      return CallCenterStatus.SingleCall;
    }
    return null;
  }

  getLoginMethods(): Observable<LoginMethod[]> {
    return this.get<LoginMethod[]>(`${this.path}/login-methods`).pipe(
      tap((response) => {
        this.loginMethodSubject.next(response);
        // select login method only in case it is in login methods array, otherwise select by default first one
        // select by default first one in case nothing was stored in config settings.
        const loginMethod = this.configService.callCenterSettings.loginMethod
          ? response.find((method) => method.device === this.configService.callCenterSettings.loginMethod)?.device ??
            response[0].device ??
            null
          : response[0].device ?? null;

        this.configService.callCenterSettings = { ...this.configService.callCenterSettings, loginMethod };
      }),
      catchError((error) => {
        console.log('Something wrong with LoginMethod api', error);
        return of([]);
      })
    );
  }

  public async getQueuesByLogin(loginMethod: string) {
    try {
      const response = await firstValueFrom(this.get<QueuesResponse | []>(`${this.path}/queues/${loginMethod}`));
      this.queuesMap.clear();
      // we have got response with at least 1 queue assigned to the agent
      if (!Array.isArray(response)) {
        this.isOnlineSubject.next(response.online);
        this.currentStatusSubject.next(response.status);
        response.queues.forEach((data: Queue) => {
          this.queuesMap.set(data.queue, data);
          //get calls in queue from websocket as contains more info then api request
          this.wsService.socket.send('calls', { domain: this.domain, queue: data.queue });
        });
      }
      this.setData([...this.queuesMap.values()]);
      this.hasErrorQueuesSubject.next(false);
      this.configService.callCenterSettings = { ...this.configService.callCenterSettings, loginMethod };
    } catch (error) {
      this.hasErrorQueuesSubject.next(true);
      this.configService.callCenterSettings = { ...this.configService.callCenterSettings, loginMethod: null };
      throw error;
    }
  }

  private getAllQueues(): Observable<Queue[]> {
    return this.get<AllQueuesResponse>(`${this.path}/queues/all`).pipe(
      map((response: AllQueuesResponse) => {
        Object.keys(response).forEach((key) => {
          if (typeof response[key] === 'object' && 'queues' in response[key]) {
            const queueResponse = response[key] as QueuesResponse;
            queueResponse.queues.forEach((data: Queue) => {
              this.allQueuesMap.set(data.queue, data);
            });
            if (key === this.configService.callCenterSettings.loginMethod) {
              this.isOnlineSubject.next(queueResponse.online);
              this.currentStatusSubject.next(queueResponse.status);
              queueResponse.queues.forEach((data: Queue) => {
                this.queuesMap.set(data.queue, data);
                //get calls in queue from websocket as contains more info then api request
                this.wsService.socket.send('calls', { domain: this.domain, queue: data.queue });
              });
            }
          }
        });
        return [...this.queuesMap.values()];
      }),
      catchError((error) => {
        console.log('Something wrong with queues/all api', error);
        this.hasErrorQueuesSubject.next(true);
        return of([]);
      })
    );
  }

  getStats(): Observable<Stats[]> {
    return this.get<StatsResponse>(`${this.path}/stats`).pipe(
      map((response) => {
        return this.processStatsResponse(response);
      }),
      catchError((error) => {
        console.log('Something wrong with stats api', error);
        this.hasErrorStatsSubject.next(true);
        return of([]);
      })
    );
  }

  getDispositions(): Observable<Disposition[]> {
    return this.get<Disposition[]>(`/call/dispositions`).pipe(
      tap((response) => {
        this.dispositionsSubject.next(response);
      }),
      catchError((error) => {
        console.log('Something wrong with disposition api', error);
        return of([]);
      })
    );
  }

  protected hasCallCenterRole(provision: Provision) {
    const roles = provision.data.roles;
    const hasCallCenterRole =
      roles.includes(ProvisionRole.CallCenterAgent) || roles.includes(ProvisionRole.CallCenterSupervisor);
    this.isCallCenterAgentSubject.next(hasCallCenterRole);
    return hasCallCenterRole;
  }

  override getHttpData(): Observable<Queue[]> {
    return this.configService.getProvision().pipe(
      catchError(() => of(null)),
      map((provision: Provision) => {
        return {
          hasAccessRole:
            (provision && this.hasCallCenterRole(provision)) ||
            this.configService.features[AppFeature.AttendantConsole],
        };
      }),
      switchMap(({ hasAccessRole }) => {
        return hasAccessRole
          ? this.getLoginMethods().pipe(
              switchMap((_loginMethods) => {
                return forkJoin([this.getStats(), this.getDispositions(), this.getAllQueues()]).pipe(
                  map(([_stats, _dispositions, queues]) => queues)
                );
              })
            )
          : of([]);
      })
    );
  }

  public async loginAgentQueue(queue: Queue) {
    try {
      if (this.configService.callCenterSettings.loginMethod == null) {
        throw new Error('Call center agent login method is null');
      }
      const path = `${this.path}/login/${queue.queue}/${this.configService.callCenterSettings.loginMethod}`;
      const response = await firstValueFrom(this.post<ResponseStatus>(path, {}));
      if (response.success) {
        const normalizedQueue: Queue = {
          ...queue,
          loggedIn: true,
        };
        if (this.queuesMap.has(queue.queue)) {
          this.queuesMap.set(queue.queue, normalizedQueue);
        }
        this.setData([...this.queuesMap.values()]);
        // in case is status offline, set it to online
        if (!this.isOnlineSubject.value) {
          this.isOnlineSubject.next(true);
          this.currentStatusSubject.next(null);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public async logoutAgentQueue(queue: Queue) {
    try {
      if (this.configService.callCenterSettings.loginMethod == null) {
        throw new Error('Call center agent login method is null');
      }
      const path = `${this.path}/logout/${queue.queue}/${this.configService.callCenterSettings.loginMethod}`;
      const response = await firstValueFrom(this.post<ResponseStatus>(path, {}));
      if (response.success) {
        const normalizedQueue: Queue = {
          ...queue,
          loggedIn: false,
        };
        if (this.queuesMap.has(queue.queue)) {
          this.queuesMap.set(queue.queue, normalizedQueue);
        }
        this.setData([...this.queuesMap.values()]);
        // in case the agent is online, set it to offline if all queue are offline
        if (this.isOnlineSubject.value && this.getData().every((item) => !item.loggedIn)) {
          this.isOnlineSubject.next(false);
          this.currentStatusSubject.next(null);
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public async updateStats() {
    try {
      const response = await firstValueFrom(this.get<StatsResponse>(`${this.path}/stats`));
      if (response) {
        this.processStatsResponse(response);
      }
    } catch (error) {
      throw error;
    }
  }

  private processStatsResponse(response: StatsResponse) {
    const stats: Stats[] = Object.keys(response).map((key) => {
      return {
        name: StatsDisplayNames[key],
        value: this.processStatsValues(response, key),
        info: StatsHoverInfo[key],
      };
    });
    this.statsSubject.next(stats);
    return stats;
  }

  processStatsValues(response: StatsResponse, key: string) {
    //avg time items should show minutes:seconds
    if (key.includes('vgTimeTalking')) {
      return new SecondsToMinutesPipe().transform(Math.round(response[key]));
    } else if (key.includes('TimeTalking')) {
      return Math.ceil(response[key] / 60);
    } else {
      return response[key];
    }
  }

  public async callNote(note: CallNote, id: string) {
    return await firstValueFrom(this.post<ResponseStatus>(`/call/${id}/notes`, note, { method: 'PUT' }));
  }

  private setStatus(status: CallCenterStatus) {
    const isOnline = [CallCenterStatus.Online, CallCenterStatus.SingleCall].includes(status);
    const currentStatus = [CallCenterStatus.Online, CallCenterStatus.Offline].includes(status);

    this.isOnlineSubject.next(isOnline);
    this.currentStatusSubject.next(currentStatus ? null : status);
    this.queuesMap.forEach((item) => (item.loggedIn = isOnline));
    this.setData([...this.queuesMap.values()]);
  }

  public async setCallCenterAgentStatus(status: CallCenterStatus) {
    const queues: string[] = this.getData().map((x) => x.queue);
    const body = {
      queues: queues,
      status: status,
    };

    try {
      const path = this.path + '/' + this.configService.callCenterSettings.loginMethod;
      const response = await firstValueFrom(this.post<ResponseStatus>(path, body, { method: 'PUT' }));
      if (response.success) {
        this.setStatus(status);
      }
    } catch (error) {
      throw error;
    }
  }

  public getQueueDescription(queue?: string): string | undefined {
    return queue ? this.allQueuesMap.get(queue)?.description : undefined;
  }
}
interface ResponseStatus {
  success: boolean;
}
