import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudioUtil } from '@app/shared/utils/audio.util';
import { BehaviorSubject } from 'rxjs';

import { AudioSettings, VideoSettings } from '../models/settings.models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private ringingVolumeWhileMeetingActive = 20;
  private defaultVolume = 50;
  private MaxVolume = 1;
  private inputDevicesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  private outputDevicesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  private audioSettingsSubject = new BehaviorSubject<AudioSettings>(this.getDefaultAudioSettings());

  public inputDevices$ = this.inputDevicesSubject.asObservable();
  public outputDevices$ = this.outputDevicesSubject.asObservable();
  public audioSettings$ = this.audioSettingsSubject.asObservable();

  private _videoSettings = new BehaviorSubject<VideoSettings>(this.getDefaultVideoSettings());
  public videoSettings$ = this._videoSettings.asObservable();
  // ========== Getters/Setters ==========

  private readonly _isSetSinkIdSupported: boolean;

  public get isSetSinkIdSupported(): boolean {
    return this._isSetSinkIdSupported;
  }

  get audioSettings(): AudioSettings {
    return this.audioSettingsSubject.value;
  }

  setAudioSettings(settings: AudioSettings) {
    this.audioSettingsSubject.next(settings);
  }

  get videoSettings(): VideoSettings {
    return this._videoSettings.value;
  }

  setVideoSettings(settings: VideoSettings) {
    this._videoSettings.next(settings);
  }

  get inputDevices(): MediaDeviceInfo[] {
    return this.inputDevicesSubject.value;
  }

  get outputDevices(): MediaDeviceInfo[] {
    return this.outputDevicesSubject.value;
  }

  getDefaultVideoSettings(): VideoSettings {
    return {
      cameraDevice: '',
      mirrorLocalVideo: true, // enabled by default since that's normally the default with video apps
      cameraOn: false, // disable camera by default
      blurBackground: false,
    };
  }

  getDefaultAudioSettings(): AudioSettings {
    return {
      quietRinging: false,
      microphoneDevice: 'default',
      speakerDevice: 'default',
      notificationSpeakerDevice: 'default',
      notificationSoundVolume: this.defaultVolume,
      ringingSoundVolume: this.defaultVolume,
    };
  }

  audioReplaceNullsWithDefaults(value: AudioSettings): AudioSettings {
    const defaultValue = this.getDefaultAudioSettings();
    for (const property in defaultValue) {
      if (value[property] == null) {
        value[property] = defaultValue[property];
      }
    }
    return value;
  }

  videoReplaceNullsWithDefaults(value: VideoSettings): VideoSettings {
    const defaultValue = this.getDefaultVideoSettings();
    for (const property in defaultValue) {
      if (value[property] == null) {
        value[property] = defaultValue[property];
      }
    }
    return value;
  }

  // ========== Lifecycle ==========

  constructor(protected http: HttpClient) {
    this._isSetSinkIdSupported = 'setSinkId' in HTMLAudioElement.prototype;
    navigator.permissions.query({ name: 'microphone' as PermissionName }).then(async (permissionStatus) => {
      if (permissionStatus.state === 'granted') {
        await this.scanForAudioDevices();
      }
    });
  }

  public async scanForAudioDevices() {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    this.inputDevicesSubject.next(mediaDevices.filter((device) => device.kind === 'audioinput'));
    this.outputDevicesSubject.next(mediaDevices.filter((device) => device.kind === 'audiooutput'));
  }

  async setSpeaker(audio: AudioUtil, speakerDevice: string = this.audioSettingsSubject.value.speakerDevice) {
    if (this.isSetSinkIdSupported) {
      try {
        await audio.setSinkId(speakerDevice);
      } catch (error) {
        console.warn(`Failed to set sink ID: ${error}`);
      }
    }
  }

  getSpeaker() {
    return this.audioSettingsSubject.value.speakerDevice;
  }

  getMicrophone(): string {
    return this.audioSettingsSubject.value.microphoneDevice;
  }

  getDefaultRingingVolume(isMeetingActive: boolean, isSecondCall: boolean): number {
    return (this.audioSettings.quietRinging && isMeetingActive) || isSecondCall
      ? this.ringingVolumeWhileMeetingActive
      : this.audioSettingsSubject.value.ringingSoundVolume;
  }

  async playAudio(
    audio: AudioUtil,
    volume?: number,
    speaker: string = this.audioSettingsSubject.value.notificationSpeakerDevice
  ) {
    if (volume !== undefined) {
      audio.volume = volume >= 0 && volume <= 100 ? volume / 100 : this.MaxVolume;
    }
    if (speaker) {
      await this.setSpeaker(audio, speaker);
    }

    await audio.play();
  }
}
