import { Device } from '@app/shared/models/utils.models';

export function detectDevice(userAgent: string): Device {
  // These regex patterns cover the most common mobile devices
  const mobileRegex = [/android/i, /webos/i, /iphone/i, /ipad/i, /ipod/i, /blackberry/i, /windows phone/i];
  const checkBrowser = !/chrome|chromium|crios|edg|opera|safari|firefox|msie/i.test(userAgent);
  const linkElectronRegex = [/link/i, /electron/i];

  if (linkElectronRegex.some((regex) => regex.test(userAgent))) {
    return Device.DESKTOP;
  }
  return mobileRegex.some((regex) => regex.test(userAgent)) || checkBrowser ? Device.MOBILE : Device.WEB;
}
